import React, { RefObject, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { ScanManagmentUrl } from '../../config/UrlsConfig';
import { ScanManagment } from '../../models/ScanManagment';
import { convertToLocalDate } from '../../utils/DateUtil';
import ExtraInfoComponent from './ExtraInfoComponent';
import { addSection, openDefaultValue, Statuses, WarningSatuses } from './utils';
import ContactOptionComponent from './components/contact-option';
import OptionComponent from './components/option';
import { requestCancel, requestReschedule } from '../../api/patient-scan-request/PatientScanRequestAPI';
import { replaceEmailToLink } from '../../utils/GeneralUtil';

const ScanBody: React.FunctionComponent<{ scan: ScanManagment; reloadScans: () => void }> = ({ scan, reloadScans }) => {
  const {
    TestRequestID,
    PatientStageDescription,
    DateRequested,
    WarningStatus,
    PatientStageID,
    ShowViewResultsBtn,
    ShowManageOrderBtn,
    ShowRequestRescheduleBtn,
    ShoCancelBtn,
    ShowContactUsBtn,
    FriendlyName,
    BodyCopy,
    WarningStatusID,
    CustomNotification,
    TitleCopy,
  } = scan;
  const [isExpanded, setIsExpanded] = useState(PatientStageID === Statuses.Scheduled || PatientStageID === Statuses.Completed);
  const [isOpenAddSection, setIsOpenAddSection] = useState<{ [key: string]: boolean }>(openDefaultValue);
  const addSectionRef = useRef<HTMLInputElement>(null);
  const topSectionRef = useRef<HTMLInputElement>(null);
  const history = useHistory();

  const toogleClick = () => {
    setIsExpanded(!isExpanded);
  };

  const isHidden = useMemo(() => !scan.AppointmentDateTime && !scan.Longitude && !scan.Latitude && !scan.ContactName, [scan]);

  const scrollTo = (elemRef: RefObject<HTMLInputElement>, headerOffset = 70) => {
    if (elemRef && elemRef.current) {
      const element = elemRef.current;
      if (element) {
        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth',
        });
      }
    }
  };

  const onCloseAddSection = (sectionName: string, isReload?: boolean) => {
    if (isOpenAddSection && isOpenAddSection[sectionName]) {
      isReload && reloadScans();
      scrollTo(topSectionRef);
      setIsOpenAddSection(previous => ({ ...previous, [sectionName]: false }));
    }
  };

  const openAddSection = (sectionName: string) => {
    if (isOpenAddSection && !isOpenAddSection[sectionName]) {
      scrollTo(addSectionRef);
      const sectionValue = Object.assign({}, openDefaultValue, { [sectionName]: true });
      setIsOpenAddSection(sectionValue);
    }
  };

  const notification = useMemo(() => {
    if (!CustomNotification) {
      return '';
    }

    return replaceEmailToLink(CustomNotification);
  }, [CustomNotification]);

  return (
    <div
      ref={topSectionRef}
      className={`control-panel-tabs_content_item ${PatientStageDescription.toLowerCase()} ${WarningStatus?.toLowerCase()}
      `}
    >
      <div className='control-panel_status'>
        <div className='status'>
          <span className='icon'></span>
          <div>
            <p>{FriendlyName}</p>
          </div>
        </div>
        <span className='btn-status'>{PatientStageDescription.toLowerCase()}</span>
      </div>
      {WarningStatusID === WarningSatuses.Warning ? (
        <div className='control-panel_status_fault status'>
          {TitleCopy}
          {TitleCopy && <span className='status-divider'></span>}
          <span className='status-text'>WARNING</span>
        </div>
      ) : WarningStatusID === WarningSatuses.Error ? (
        <div className='control-panel_status_fault status'>
          {TitleCopy}
          {TitleCopy && <span className='status-divider'></span>}
          <span className='status-text'>ERROR</span>
        </div>
      ) : (
        <></>
      )}
      <div className='control-panel-tabs_content_item_wrap'>
        <div className='control-panel_status-scan'>
          {CustomNotification ? (
            <div className='control-panel_hint'>
              <div className='title'>A message from your Care Coordinator:</div>
              <div className='text'>
                <p>{notification}</p>
              </div>
            </div>
          ) : (
            <p>{BodyCopy}</p>
          )}
          <div className='num'>
            <div className='title'>Scan ID</div>
            <span>{TestRequestID}</span>
          </div>
        </div>
        <div className={`control-panel_status_line ${PatientStageDescription.toLowerCase()} ${WarningStatus?.toLowerCase()}`}>
          <div className='control-panel_status_line_item'>
            <div className='title'>Requested</div>
            <span className='progress'></span>
          </div>
          <div className='control-panel_status_line_item'>
            <div className='title'>{PatientStageID >= Statuses.Scheduling ? 'Approved' : 'Approving'}</div>
            <span className='progress'></span>
          </div>
          <div className='control-panel_status_line_item'>
            <div className='title'>{PatientStageID >= Statuses.Scheduled ? 'Scheduled' : 'Scheduling'}</div>
            <span className='progress'></span>
          </div>
          <div className='control-panel_status_line_item'>
            <div className='title'>Completed</div>
            <span className='progress'></span>
          </div>
        </div>
        {isExpanded && <ExtraInfoComponent scan={scan} />}
        <div className='control-panel_time-list'>
          <div className='title'>Date ordered</div>
          <p>{convertToLocalDate(DateRequested, 'MMMM Do YYYY, hh:mm a')}</p>
        </div>
        <div className='btn-row'>
          {ShowViewResultsBtn ? <button className='btn'>View result</button> : null}
          {ShowManageOrderBtn ? (
            <button
              className='btn'
              onClick={() => history.push(`${ScanManagmentUrl}/${TestRequestID}`, { pathname: history.location.pathname })}
            >
              MANAGE ORDER
            </button>
          ) : null}
          {ShowContactUsBtn ? (
            <button className='btn btn-white' onClick={() => openAddSection(addSection.isContactOpen)}>
              Contact us
            </button>
          ) : null}
          {ShowRequestRescheduleBtn ? (
            <button className='btn btn-resc' onClick={() => openAddSection(addSection.isRescheduleOpen)}>
              Reschedule
            </button>
          ) : null}
          {ShoCancelBtn ? (
            <button className='btn' onClick={() => openAddSection(addSection.isCancelOpen)}>
              Cancel request
            </button>
          ) : null}
        </div>
        <div ref={addSectionRef} id='targetElement'>
          {isOpenAddSection.isContactOpen && (
            <ContactOptionComponent info={scan} onCloseCallback={() => onCloseAddSection(addSection.isContactOpen)} />
          )}
          {isOpenAddSection.isRescheduleOpen && (
            <OptionComponent
              info={scan}
              btnSubmitText='REQUEST RESCHEDULE'
              apiCall={requestReschedule}
              onCloseCallback={(isReload: boolean) => onCloseAddSection(addSection.isRescheduleOpen, isReload)}
            >
              <div className='title'>Request your reschedule</div>
              <div className='content'>
                <p>
                  Please provide some available dates and time for us to reschedule your appointment. A Medmo care team may reach out to you
                  to expedite the rescheduling of your appointment.
                </p>
              </div>
            </OptionComponent>
          )}
          {isOpenAddSection.isCancelOpen && (
            <OptionComponent
              info={scan}
              btnSubmitText='CANCEL REQUEST'
              apiCall={requestCancel}
              onCloseCallback={(isReload: boolean) => onCloseAddSection(addSection.isCancelOpen, isReload)}
            >
              <div className='title'>Cancel request</div>
              <div className='content'>
                <p>We are sorry you want to cancel your request, would you mind leaving us a reason why? Thanks!</p>
              </div>
            </OptionComponent>
          )}
        </div>
      </div>
      <button className={`control-panel-more ${isExpanded && 'show'}`} onClick={toogleClick} hidden={isHidden}>
        <span>{isExpanded ? 'Less Info' : 'Expand for More Info'}</span>
        <span className='icon-chevron'></span>
      </button>
    </div>
  );
};

export default ScanBody;
