import React, { SyntheticEvent } from 'react';
import { ScanManagment } from '../../models/ScanManagment';
import { convertToLocalDate, convertToLocalDatePlusHours, transformDate } from '../../utils/DateUtil';

const ExtraInfoComponent: React.FunctionComponent<{ scan: ScanManagment }> = ({ scan }) => {
  const {
    ContactName,
    ContactPhone,
    ContactEmail,
    AppointmentDateTime,
    Address2,
    Address1,
    City,
    State,
    ZipCode,
    Latitude,
    Longitude,
    FriendlyName,
    LocationName,
  } = scan;

  const showMap = (e: SyntheticEvent) => {
    e.preventDefault();
    window.open(`https://www.google.com/maps/search/?api=1&query=${Latitude},${Longitude}`, '_blank');
  };

  const showCalendar = (e: SyntheticEvent) => {
    e.preventDefault();
    const startDate = convertToLocalDate(AppointmentDateTime, 'YYYYMMDDTHHmmss');
    const endDate = convertToLocalDatePlusHours(AppointmentDateTime, 1, 'YYYYMMDDTHHmmss');
    window.open(
      `https://calendar.google.com/calendar/render?action=TEMPLATE&dates=${startDate}/${endDate}&text=${encodeURIComponent(FriendlyName)}`,
      '_blank',
    );
  };

  return (
    <div className='control-panel_items-info'>
      <div className='item'>
        <div className='wrap'>
          <div className='fig fig-calendar'></div>
          <div className='title'>Appointment date</div>
          <time className='time'>
            {AppointmentDateTime ? transformDate(AppointmentDateTime, 'MMMM Do') : ''} <br />
            {AppointmentDateTime ? transformDate(AppointmentDateTime, 'hh:mm a') : ''}
          </time>
          <div className='link' hidden={!AppointmentDateTime}>
            Add to calendar{' '}
            <a href='#' onClick={showCalendar}>
              here
            </a>
            .
          </div>
        </div>
      </div>
      <div className='item'>
        <div className='wrap'>
          <div className='fig fig-location'></div>
          <div className='title'>Location</div>
          <address>
            {LocationName && <p>{LocationName}</p>}
            {Address1}
            {City ? ', ' : ''}
            {City}
            {State ? ', ' : ''}
            {State} <br />
            {Address2 ? (
              <>
                <span>{Address2}</span> <br />
              </>
            ) : null}
            {ZipCode}
          </address>
          <div className='link' hidden={!Latitude || !Longitude}>
            View on map{' '}
            <a href='#' onClick={showMap}>
              here
            </a>
            .
          </div>
        </div>
      </div>
      <div className='item' hidden>
        <div className='wrap'>
          <div className='fig fig-contact'></div>
          <div className='title'>Contact info</div>
          <div className='info'>
            {ContactName} <br />
            <a href={`tel:${ContactPhone}`}>{ContactPhone}</a>
          </div>
          <a href={`mailto:${ContactEmail}`} className='email'>
            {ContactEmail}
          </a>
        </div>
      </div>
    </div>
  );
};

export default ExtraInfoComponent;
