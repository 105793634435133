import { PingScanRequest } from '../../views/patient-ping/utils';
import React from 'react';
import { FileType } from 'rsuite/Uploader';

export const PING_PACEMAKER_STEP_NAMES = {
  pacemaker: 'PACEMAKER',
  uploadPacemaker: 'UPLOAD_PACEMAKER',
  pacemakerDetails: 'PACEMAKER_DETAILS',
};

export type PacemakerWrapperProps = {
  goToPreviousStep: (stepName?: string) => void;
  scanRequestData: PingScanRequest;
  completeStepCallback: (partialScanData: Partial<PingScanRequest>, stepName?: string) => void;
};

export const initPacemakerData = {
  HasPacemakerOrDefibrillatorInBody: null,
};

export const initPacemakerDetailsData = {
  PaceMakerDetails: '',
};

export const initPacemakerBodyData = {
  ...initPacemakerData,
  ...initPacemakerDetailsData,
};

export const initPacemakerGeneralData = {
  data: initPacemakerBodyData,
  currentStep: 1,
  errorSubmitting: false,
};

export type PacemakerDetailsForm = {
  PaceMakerDetails: string | null;
};

export type PacemakerData = {
  HasPacemakerOrDefibrillatorInBody: number | null;
  PacemakerFileKey: string;
  pacemakerFile?: FileType | null;
};

export type PacemakerGeneralData = {
  data: PacemakerData;
  currentStep: number;
  errorSubmitting: boolean;
};

export type MetalBodyGeneralProps = {
  goToPreviousStep: (stepName?: string) => void;
  completeStepCallback: (partialScanData: Partial<PingScanRequest>, stepName?: string) => void;
  infoBox?: { title: string; content: React.ReactNode };
  stepName: string;
  scanRequestData: Partial<PingScanRequest>;
};

export const Questions = [
  { ID: 1, Response: 'Yes' },
  { ID: 0, Response: 'No' },
  { ID: 2, Response: 'Not sure' },
];
