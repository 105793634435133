import React from 'react';
import { GetStartedProps } from './interfaces';
import { HAVE_QUESTIONS_INFOBOX_DATA, STEP_NAMES } from '../PatientScanRequestUtils';
import InfoBox from '../../../components/info-box';

const GetStarted: React.FunctionComponent<GetStartedProps> = ({ completeStepCallback }) => {
  const { title, content, urlText, url } = HAVE_QUESTIONS_INFOBOX_DATA;
  return (
    <>
      <div className='main-started main-request-form'>
        <h2 id='Ready_to_get_started' className='h2'>
          Ready to get started?
        </h2>
        <div className='text-form'>
          <p>Here’s what you’ll need to schedule an appointment.</p>
        </div>
        <ul className='main-started-list'>
          <li className='row'>
            <div className='img-referral-icon icon'></div>
            <p>1. Imaging referral / prescription</p>
          </li>
          <li className='row'>
            <div className='contact-info-icon icon'></div>
            <p>2. Your contact information</p>
          </li>
          <li className='row'>
            <div className='insurance-card-icon icon'></div>
            <p>3. Insurance OR card information </p>
          </li>
        </ul>
        <div className='btn-row row justify-content-between'>
          <button onClick={() => completeStepCallback({}, STEP_NAMES.getStarted)} className='btn next'>
            Get Started
          </button>
        </div>
      </div>
      <InfoBox title={title} content={content} urlText={urlText} url={url} />
    </>
  );
};

export default GetStarted;
