import React, { useContext } from 'react';
import { APP_CORP_WEB_URL } from '../../config/FrontConfig';
import { MixpanelContext } from '../../contexts/MixpanelContext';
import { CONTACT_SUPPORT_AFTER_ERROR } from '../../utils/MixpanelEvents';
import { STEP_NAMES } from '../../views/patient-scan-request/PatientScanRequestUtils';
import { GeneralErrorProps } from './interfaces';
import { useTranslation } from 'react-i18next';

const GeneralError: React.FunctionComponent<GeneralErrorProps> = ({ goToPreviousStep, isFromPXB, mixpanelHandoffPayload }) => {
  const mixpanel = useContext(MixpanelContext);
  const { t } = useTranslation('translations');

  const contactSupport = (): void => {
    if (isFromPXB) {
      mixpanel.track(CONTACT_SUPPORT_AFTER_ERROR);
    } else {
      mixpanel.track(`HANDOFF_${CONTACT_SUPPORT_AFTER_ERROR}`, mixpanelHandoffPayload);
    }
    window.open(`${APP_CORP_WEB_URL}/contact-us/`);
  };

  return (
    <div className='main-request-form request-type'>
      <h2 id='Oops_something_went_wrong' className='h2'>
        {t('general_error.Oops, something went wrong')}
      </h2>
      <div className='text-wrap'>
        <p>{t('general_error.text')}</p>
      </div>
      <div className='btn-row row justify-content-between full-mob mb32'>
        <button className='btn back btn-white' onClick={() => goToPreviousStep(STEP_NAMES.generalError)}>
          {t('general_error.Back to previous page')}
        </button>
        <button className='btn next' onClick={contactSupport}>
          {t('general_error.Contact support')}
        </button>
      </div>
    </div>
  );
};

export default GeneralError;
