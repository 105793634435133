import React from 'react';
import InfoBox from '../../info-box';
import { useTranslation } from 'react-i18next';
import { MetalBodyGeneralProps } from '../../metal-in-body-wrapper/interfaces';
import { PING_PACEMAKER_STEP_NAMES, Questions } from '../interfaces';

const MetalBodyPaceMaker: React.FunctionComponent<MetalBodyGeneralProps> = ({
  goToPreviousStep,
  completeStepCallback,
  scanRequestData,
  infoBox,
  stepName = PING_PACEMAKER_STEP_NAMES.pacemaker,
}) => {
  const { t } = useTranslation('handoff');
  const { HasPacemakerOrDefibrillatorInBody } = scanRequestData;
  const selectResponse = (questionID: number): void => {
    completeStepCallback({ HasPacemakerOrDefibrillatorInBody: questionID }, stepName);
  };

  return (
    <>
      <div className='main-request-form'>
        <h2 id='Additional_questions' className='h2'>
          {t('metal.title_pacemaker')}
        </h2>
        <div className='main-form-appointment'>
          <div className='mb32'>
            {Questions.map(({ ID, Response }) => (
              <button
                className={`appointment-field appointment-field_btn ${ID === HasPacemakerOrDefibrillatorInBody ? 'active' : ''}`}
                key={ID}
                onClick={() => selectResponse(ID)}
              >
                {t(`metal.${Response}` as unknown as TemplateStringsArray)}
              </button>
            ))}
          </div>
          <div className='btn-row row justify-content-between full-mob'>
            <button className='btn btn-white back no-arrow' onClick={() => goToPreviousStep(stepName)}>
              {t('form.Back', { ns: 'translations' })}
            </button>
          </div>
        </div>
      </div>
      {infoBox ? <InfoBox title={infoBox.title} content={infoBox.content} /> : null}
    </>
  );
};

export default MetalBodyPaceMaker;
