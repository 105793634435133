import React, { useState } from 'react';
import InfoBox from '../info-box';
import { Form, Schema } from 'rsuite';
import TextField from '../text-field-component';
import { PING_STEP_NAMES, PingScanRequest } from '../../views/patient-ping/utils';
import { Trans, useTranslation } from 'react-i18next';
import Textarea from '../textarea';

const { StringType } = Schema.Types;

type PreferredEmailProps = {
  goToPreviousStep: (stepName?: string) => void;
  completeStepCallback: (partialScanData: Partial<AppointmentDetailsForm>, stepName?: string) => void;
  agentInfo?: { CxName: string; CxPhone: string; CxEmail: string };
  stepName?: string;
  scanRequestData: PingScanRequest;
};

type AppointmentDetailsForm = {
  AppointmentDetails: string;
};

const AppointmentDetailsSchema = Schema.Model({
  AppointmentDetails: StringType(),
});

const SchedulingDetails: React.FunctionComponent<PreferredEmailProps> = ({
  scanRequestData,
  goToPreviousStep,
  completeStepCallback,
  agentInfo,
  stepName = PING_STEP_NAMES.appointmentDetails,
}) => {
  const [formValue, setFormValue] = useState<AppointmentDetailsForm>({
    AppointmentDetails: scanRequestData.AppointmentDetails,
  });
  const { t } = useTranslation('handoff');

  const submit = () => {
    const result = {
      AppointmentDetails: formValue.AppointmentDetails,
    };
    completeStepCallback(result, stepName);
  };

  return (
    <>
      <div className='main-request-form main-request-form_size'>
        <h2 id='Additional_questions' className='h2'>
          {t('appointment_details.title')}
        </h2>
        <div className='text-form fw600'>
          <p>{t('appointment_details.sub-title')}</p>
        </div>
        <Form
          model={AppointmentDetailsSchema}
          onChange={formValue => setFormValue(formValue as AppointmentDetailsForm)}
          formValue={formValue}
          fluid
          noValidate
        >
          <div className='input-wrap textarea-wrap h80 mb32'>
            <TextField
              placeholder={`${t('appointment_details.placeholder')}`}
              accepter={Textarea}
              value={formValue.AppointmentDetails}
              name='AppointmentDetails'
            />
          </div>
          <div className='btn-row row justify-content-between full-mob'>
            <button className='btn btn-white back no-arrow' onClick={() => goToPreviousStep(stepName)}>
              {t('form.Back', { ns: 'translations' })}
            </button>
            <button className='btn next no-arrow' onClick={submit}>
              {t('form.Next', { ns: 'translations' })}
            </button>
          </div>
        </Form>
      </div>
      {agentInfo ? (
        <InfoBox
          title={t('box.HAVE_QUESTIONS_PING_PATIENT_INFOBOX_DATA.title', { ns: 'translations' })}
          content={
            <Trans
              i18nKey='box.HAVE_QUESTIONS_PING_PATIENT_INFOBOX_DATA.content'
              values={{
                CxName: agentInfo?.CxName || '[CX First Name]',
                CxPhone: agentInfo?.CxPhone || '[CX phone]',
                CxEmail: agentInfo?.CxEmail || '[CX email]',
              }}
              components={{ linkTo: <a /> }}
            />
          }
        />
      ) : null}
    </>
  );
};

export default SchedulingDetails;
