import { SERVER_URL } from '../../config/ServerConfig';
import { SuccessResponse } from '../../models/SuccessResponse';
import { ServerUserResponse } from '../../models/User';
import { LoginFormData } from '../../views/login/interfaces';
import { request } from '../Request';
import { ForgotPassword } from './interfaces';

export function login(data: LoginFormData): Promise<ServerUserResponse> {
  return request({
    url: `${SERVER_URL}/users/login`,
    method: 'POST',
    body: JSON.stringify(data),
  });
}

export function logout(): Promise<SuccessResponse> {
  return request({
    url: `${SERVER_URL}/users/logout`,
    method: 'POST',
  });
}

export function checkToken(token: string): Promise<SuccessResponse> {
  return request({
    url: `${SERVER_URL}/users/check/${token}`,
    method: 'GET',
  });
}

export function forgotPassword(email: string): Promise<ForgotPassword> {
  return request({
    method: 'PUT',
    url: `${SERVER_URL}/users/forgot-password`,
    body: JSON.stringify({
      email,
    }),
  });
}

export function createNewPassword(pass: string, token: string): Promise<{ info: string }> {
  return request({
    method: 'PUT',
    url: `${SERVER_URL}/users/reset-password/${token}`,
    body: JSON.stringify({
      password: pass,
    }),
  });
}

export function logPatient(TypeID: number, UserID: number): Promise<{ info: string }> {
  return request({
    method: 'POST',
    url: `${SERVER_URL}/log/patient`,
    body: JSON.stringify({
      TypeID: TypeID,
      UserID: UserID,
    }),
  });
}
