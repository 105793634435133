import { SERVER_URL } from '../../config/ServerConfig';
import { request } from '../Request';
import { BiologicalSex } from '../../models/BiologicalSex';

export function getGendersAPI(): Promise<BiologicalSex[]> {
  return request({
    url: `${SERVER_URL}/genders`,
    method: 'GET',
  });
}
