import { SERVER_URL } from '../../config/ServerConfig';
import { request } from '../Request';
import { PlaceDetails } from './interfaces';
import { Place } from '../../models/Place';

export function searchAutocomplete(searchQuery: string): Promise<Place[]> {
  return request({
    url: `${SERVER_URL}/google-maps/autocomplete?searchQuery=${encodeURIComponent(searchQuery)}`,
    method: 'GET',
  });
}

export function getPlaceLocation(placeId: string): Promise<PlaceDetails> {
  return request({
    url: `${SERVER_URL}/google-maps/location/${placeId}`,
    method: 'GET',
  });
}
