import React, { SyntheticEvent, useState } from 'react';
import { Form } from 'rsuite';
import TextField from '../text-field-component';
import { PRIVACY_INFOBOX_DATA, STEP_NAMES } from '../../views/patient-scan-request/PatientScanRequestUtils';
import { ReasonForExamProps } from './interfaces';
import InfoBox from '../info-box';
import { FormError } from '../../models/General';
import { ReasonForExamSchema } from './utils';
import Textarea from '../textarea';

const ReasonForExam: React.FunctionComponent<ReasonForExamProps> = ({
  goToPreviousStep,
  scanRequestData,
  completeStepCallback,
}: ReasonForExamProps) => {
  const { title, content } = PRIVACY_INFOBOX_DATA;
  const [formValue, setFormValue] = useState<{ Reason: string }>({ Reason: scanRequestData.Reason });
  const [formError, setFormError] = useState<FormError>({});

  const handleConfirm = (event: SyntheticEvent): void => {
    event.preventDefault();
    completeStepCallback(formValue, STEP_NAMES.reasonForExam);
  };

  const getBack = (event: SyntheticEvent): void => {
    event.preventDefault();
    goToPreviousStep(STEP_NAMES.reasonForExam);
  };

  return (
    <>
      <div className='request-type'>
        <h2 id='Reason_for_your_exam' className='h2'>
          Reason for your exam
        </h2>
        <div className='text-wrap'>
          <p>
            In a brief sentence or two, please provide the reason for your exam. Our imaging partners use this information to ensure the
            right radiologists are available. If you’re using insurance, this information is required.
          </p>
        </div>
        <Form
          model={ReasonForExamSchema}
          formValue={formValue}
          onChange={formValue => setFormValue(formValue as { Reason: string })}
          onCheck={error => setFormError(error)}
          fluid
        >
          <div className='input-wrap textarea-wrap'>
            <TextField placeholder='Reason for exam' accepter={Textarea} value={formValue.Reason} name='Reason' error={formError.Reason} />
          </div>
          <div className='btn-row row justify-content-between full-mob'>
            <button className='btn btn-white back' onClick={getBack}>
              Back
            </button>
            <button className='btn next' onClick={handleConfirm} disabled={!formValue.Reason}>
              Confirm
            </button>
          </div>
        </Form>
      </div>
      <InfoBox title={title} content={content} />
    </>
  );
};

export default ReasonForExam;
