import { PingScanRequest } from '../../views/patient-ping/utils';
import React from 'react';

export const PING_DIABETES_STEP_NAMES = {
  diabetes: 'DIABETES',
  diabetesTypes: 'DIABETES_TYPES',
  diabetesDetails: 'DIABETES_DETAILS',
};

export type DiabetesWrapperProps = {
  goToPreviousStep: (stepName?: string) => void;
  scanRequestData: PingScanRequest;
  completeStepCallback: (partialScanData: Partial<PingScanRequest>, stepName?: string) => void;
};

export const initDiabetesTypeData = {
  DiabetesType: null,
};

export const initDiabetesData = {
  HasDiabetes: null,
};

export const initGlucoseMonitorData = {
  OnBodyGlucoseMonitor: null,
};

export const initDiabetesBodyData = {
  ...initDiabetesData,
  ...initDiabetesTypeData,
  ...initGlucoseMonitorData,
};

export const initDiabetesGeneralData = {
  data: initDiabetesBodyData,
  currentStep: 1,
  errorSubmitting: false,
};

export type DiabetesTypeForm = {
  DiabetesType: number | null;
};

export type GlucoseMonitorBodyForm = {
  OnBodyGlucoseMonitor: number | null;
};

export type DiabetesBodyForm = {
  HasDiabetes: number | null;
};

export type DiabetesData = DiabetesBodyForm & DiabetesTypeForm & GlucoseMonitorBodyForm;

export type DiabetesGeneralData = {
  data: DiabetesData;
  currentStep: number;
  errorSubmitting: boolean;
};

export type BodyGeneralProps = {
  goToPreviousStep: (stepName?: string) => void;
  completeStepCallback: (partialScanData: Partial<PingScanRequest>, stepName?: string) => void;
  infoBox?: { title: string; content: React.ReactNode };
  stepName: string;
  scanRequestData: Partial<PingScanRequest>;
};

export const Types = [
  { ID: 1, Response: 'Type I' },
  { ID: 2, Response: 'Type II' },
];

export const SmallQuestions = [
  { ID: 1, Response: 'Yes' },
  { ID: 0, Response: 'No' },
];

export const Questions = [
  { ID: 1, Response: 'Yes' },
  { ID: 0, Response: 'No' },
  { ID: 2, Response: 'Not sure' },
];
