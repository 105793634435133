import React, { useCallback, useEffect, useMemo, useState } from 'react';
import InfoBox from '../info-box';
import { Trans, useTranslation } from 'react-i18next';
import {
  AssistanceWrapperProps,
  AssistanceData,
  GeneralData,
  initDetailsData,
  initGeneralData,
  PING_ASSISTANCE_STEP_NAMES,
} from './interfaces';
import AssistanceInformation from './information';
import AssistanceDetails from './details';

const AssistanceWrapper: React.FunctionComponent<AssistanceWrapperProps> = ({
  goToPreviousStep,
  scanRequestData,
  completeStepCallback,
}: AssistanceWrapperProps) => {
  const { t } = useTranslation('translations');
  const [generalData, setGeneralData] = useState<GeneralData>(() => {
    const { Wheelchair, Walker, Interpreter, NoAssistance, AssistanceDetails, OtherAssistance } = scanRequestData;
    const newData = {
      Wheelchair,
      Walker,
      Interpreter,
      NoAssistance,
      OtherAssistance,
      AssistanceDetails,
    };
    return {
      ...initGeneralData,
      currentStep: 1,
      data: { ...initGeneralData.data, ...newData },
    };
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [generalData.currentStep]);

  const previousStep = useCallback(
    (stepName?: string): void => {
      if (PING_ASSISTANCE_STEP_NAMES.assistanceInformation === stepName) {
        return goToPreviousStep(PING_ASSISTANCE_STEP_NAMES.assistanceInformation);
      }

      setGeneralData(({ currentStep, data }) => {
        const prevStep = 1;
        return {
          data,
          currentStep: currentStep - prevStep,
          errorSubmitting: false,
        };
      });
    },
    [generalData],
  );

  const completeStep = useCallback(
    (stepMetadata?: Partial<AssistanceData>, stepName?: string): void => {
      if (
        PING_ASSISTANCE_STEP_NAMES.assistanceInformation === stepName &&
        !(stepMetadata?.Wheelchair || stepMetadata?.Walker || stepMetadata?.Interpreter || stepMetadata?.OtherAssistance)
      ) {
        stepMetadata = { ...stepMetadata, ...initDetailsData };
        const newData = { ...generalData.data, ...stepMetadata };
        return completeStepCallback(newData, stepName);
      }

      if (PING_ASSISTANCE_STEP_NAMES.assistanceDetails === stepName) {
        const newData = { ...generalData.data, ...stepMetadata };
        return completeStepCallback(newData, stepName);
      }

      setGeneralData(({ currentStep, data }) => {
        const nextStep = 1;
        const newData = { ...data, ...stepMetadata };
        return {
          data: newData,
          currentStep: currentStep + nextStep,
          errorSubmitting: false,
        };
      });
    },
    [generalData],
  );

  const currentStepDisplayed = useMemo<React.ReactNode | null>(() => {
    const { currentStep, data } = generalData;
    if (!generalData) {
      return null;
    }
    switch (currentStep) {
      case 1: {
        return <AssistanceInformation goToPreviousStep={previousStep} completeStepCallback={completeStep} scanRequestData={data} />;
      }
      case 2:
        return <AssistanceDetails goToPreviousStep={previousStep} scanRequestData={data} completeStepCallback={completeStep} />;
      default:
        return null;
    }
  }, [generalData]);

  return (
    <>
      <>{currentStepDisplayed}</>
      <InfoBox
        title={t('box.WHY_DO_YOU_NEED_INFOBOX_DATA.title')}
        content={<Trans i18nKey='box.WHY_DO_YOU_NEED_INFOBOX_DATA.content' components={{ br: <br /> }} />}
      />
    </>
  );
};

export default AssistanceWrapper;
