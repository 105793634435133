import React from 'react';
import { ErrorBoxProps } from './interfaces';

const ErrorBox: React.FunctionComponent<ErrorBoxProps> = ({ title, content }) => (
  <div className='payment-error'>
    <span className='icon-error'></span>
    <div className='title'>{title}</div>
    <div className='content'>{content}</div>
  </div>
);

export default ErrorBox;
