import React from 'react';
import InfoBox from '../../../../components/info-box';
import { CLEERLY_HANDOFF_STEP_NAMES } from '../utils';
import { CustomInstructionsProps } from './interfaces';
import { HAVE_QUESTIONS_HANDOFF_INFOBOX_DATA } from '../../standard/utils';

const CustomInstructions: React.FunctionComponent<CustomInstructionsProps> = ({ completeStepCallback }) => {
  const { title, content } = HAVE_QUESTIONS_HANDOFF_INFOBOX_DATA;

  return (
    <>
      <div className='request-type handoff-process'>
        <div className='scan-service-edit_header'>
          <h2 className='h2'>Instructions</h2>
        </div>
        <div className='how-medmo-works semi-bold'>
          <p>
            Your provider ordered you a Cleerly CCTA exam. Cleerly provides you with a better understanding of heart disease and your
            personal risk factors, giving you the insights needed to make better choices for your heart health.
          </p>
          <p>Cleerly partners with Medmo to help facilitate the process. Here’s how it works.</p>
        </div>
        <div className='how-medmo-works'>
          <div className='row info'>
            <div className='circle-container'>
              <div className='circle'>
                <span className='number semi-bold'>1</span>
              </div>
            </div>
            <div className='texts'>
              <p>Fill out the following form so we can get started with your CCTA exam and Cleerly analysis.</p>
            </div>
          </div>
          <div className='row info'>
            <div className='circle-container'>
              <div className='circle'>
                <span className='number semi-bold'>2</span>
              </div>
            </div>
            <div className='texts'>
              <p>Medmo’s Care Team will schedule you the best appointment based on your preferences.</p>
            </div>
          </div>
          <div className='row info'>
            <div className='circle-container'>
              <div className='circle'>
                <span className='number semi-bold'>3</span>
              </div>
            </div>
            <div className='texts'>
              <p>Get your CCTA exam and Cleerly will follow-up with analysis and consultation.</p>
            </div>
          </div>
        </div>
        <div className='btn-row row justify-content-between'>
          <button className='btn next' onClick={() => completeStepCallback({}, CLEERLY_HANDOFF_STEP_NAMES.instructions)}>
            Get started
          </button>
        </div>
      </div>
      <InfoBox title={title} content={content} />
    </>
  );
};

export default CustomInstructions;
