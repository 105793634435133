import React, { useState } from 'react';
import { Form } from 'rsuite';
import { Trans, useTranslation } from 'react-i18next';
import { GENERAL_QUESTION_STEP_NAMES, GeneralPingProps, GeneralQuestionData, GeneralQuestionForm, QuestionSchema } from './interface';
import TextField from '../text-field-component';
import Textarea from '../textarea';
import InfoBox from '../info-box';
import { FormError } from '../../models/General';

const GeneralQuestion: React.FunctionComponent<GeneralPingProps> = ({
  goToPreviousStep,
  question,
  scanRequestData,
  completeStepCallback,
}: GeneralPingProps) => {
  const { t } = useTranslation('handoff');
  const { GeneralAnswer } = scanRequestData;
  const [formError, setFormError] = useState<FormError>({});
  const [formValue, setFormValue] = useState<GeneralQuestionForm>({
    GeneralAnswer,
  });

  const onErrorHandler = (error: any) => {
    if (error && error.GeneralAnswer) {
      const message = t(`form.${error.GeneralAnswer}`, { ns: 'translations', defaultValue: '' });
      return setFormError({ ...error, GeneralAnswer: message });
    }
    setFormError(error);
  };

  const onNextHandler = (): void => {
    const { GeneralAnswer } = formValue;
    const payload: Partial<GeneralQuestionData> = {
      GeneralAnswer,
    };

    completeStepCallback(payload, GENERAL_QUESTION_STEP_NAMES.question);
  };

  return (
    <>
      <div className='main-request-form'>
        <h2 id='When_would_you_like_your_appointment' className='h2'>
          {question}
        </h2>
        <Form
          model={QuestionSchema}
          onChange={value => setFormValue(value as GeneralQuestionForm)}
          formValue={formValue}
          onCheck={error => onErrorHandler(error)}
          className='main-form-appointment'
          fluid
        >
          <div className='input-wrap textarea-wrap h80 mb32'>
            <TextField
              label={t('mammogram.Enter your answer here')}
              accepter={Textarea}
              error={formError.GeneralAnswer}
              value={formValue.GeneralAnswer}
              name='GeneralAnswer'
            />
          </div>
          <div className='btn-row row justify-content-between full-mob'>
            <button className='btn btn-white back no-arrow' onClick={() => goToPreviousStep(GENERAL_QUESTION_STEP_NAMES.question)}>
              {t('form.Back', { ns: 'translations' })}
            </button>
            <button
              className='btn next no-arrow'
              disabled={formValue.GeneralAnswer === '' || !!Object.keys(formError).length}
              onClick={onNextHandler}
            >
              {t('form.Next', { ns: 'translations' })}
            </button>
          </div>
        </Form>
      </div>
      <InfoBox
        title={t('box.WHY_DO_YOU_NEED_INFOBOX_DATA.title', { ns: 'translations' })}
        content={<Trans ns='translations' i18nKey='box.WHY_DO_YOU_NEED_INFOBOX_DATA.content' components={{ br: <br /> }} />}
      />
    </>
  );
};

export default GeneralQuestion;
