export const STEP_COMPLETED = 'STEP_COMPLETED';
export const STEP_BACK = 'STEP_BACK';
export const LOCATION_SELECTED = 'LOCATION_SELECTED';
export const ZIP_CODE_SELECTED = 'ZIP_CODE_SELECTED';
export const SCAN_TYPE_SELECTED = 'SCAN_TYPE_SELECTED';
export const AVAILABLE_ICS_NEARBY_FOUND = 'AVAILABLE_ICS_NEARBY_FOUND';
export const AVAILABLE_ICS_NEARBY_NOT_FOUND = 'AVAILABLE_ICS_NEARBY_NOT_FOUND';
export const WILL_TRAVEL_FURTHER = 'WILL_TRAVEL_FURTHER';
export const BODY_PART_SELECTED = 'BODY_PART_SELECTED';
export const BACK_FROM_BODY_PART = 'BACK_FROM_BODY_PART';
export const SCAN_DETAILS_SELECTED = 'SCAN_DETAILS_SELECTED';
export const BACK_FROM_SCAN_DETAILS = 'BACK_FROM_SCAN_DETAILS';
export const BACK_FROM_SCAN_FINAL_DETAILS = 'BACK_FROM_SCAN_FINAL_DETAILS';
export const ALREADY_LOGGED_IN = 'ALREADY_LOGGED_IN';
export const ALREADY_HAVE_AN_ACCOUNT_PXB = 'ALREADY_HAVE_AN_ACCOUNT_PXB';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const DONT_HAVE_AN_ACCOUNT_PXB = 'DONT_HAVE_AN_ACCOUNT_PXB';
export const PHYSICIAN_SELECTED = 'PHYSICIAN_SELECTED';
export const PRESCRIPTION_FILE_SELECTED = 'PRESCRIPTION_FILE_SELECTED';
export const PRESCRIPTION_UPLOADED = 'PRESCRIPTION_UPLOADED';
export const INSURANCE_PLAN_SELECTED = 'INSURANCE_PLAN_SELECTED';
export const FRONT_OF_INSURANCE_CARD_FILE_SELECTED = 'FRONT_OF_INSURANCE_CARD_FILE_SELECTED';
export const BACK_OF_INSURANCE_CARD_FILE_SELECTED = 'BACK_OF_INSURANCE_CARD_FILE_SELECTED';
export const FRONT_OF_INSURANCE_CARD_FILE_UPLOADED = 'FRONT_OF_INSURANCE_CARD_FILE_UPLOADED';
export const BACK_OF_INSURANCE_CARD_FILE_UPLOADED = 'BACK_OF_INSURANCE_CARD_FILE_UPLOADED';
export const CONTACT_METHODS_ADDED = 'CONTACT_METHODS_ADDED';
export const MANAGE_REQUESTS_FROM_SCAN_COMPLETED = 'MANAGE_REQUESTS_FROM_SCAN_COMPLETED';
export const CONTACT_SUPPORT_AFTER_ERROR = 'CONTACT_SUPPORT_AFTER_ERROR';

export const HANDOFF_PROCESS_SESSION_START = 'HANDOFF_PROCESS_SESSION_START ';
export const HANDOFF_INVALID_EXPIRED_INVITATION_ID = 'HANDOFF_INVALID_EXPIRED_INVITATION_ID';
export const HANDOFF_CONTACT_SUPPORT_ON_INVALID_EXPIRED_INVITATION_ID = 'HANDOFF_CONTACT_SUPPORT_ON_INVALID_EXPIRED_INVITATION_ID';
export const HANDOFF_WRONG_DOB = 'HANDOFF_WRONG_DOB';
export const HANDOFF_MAXIMUM_NUMBER_OF_ATTEMPTS_EXCEEDED = 'HANDOFF_MAXIMUM_NUMBER_OF_ATTEMPTS_EXCEEDED';
export const HANDOFF_CONTINUE_AS_GUEST = 'HANDOFF_CONTINUE_AS_GUEST';
export const HANDOFF_ALREADY_HAVE_AN_ACCOUNT = 'HANDOFF_ALREADY_HAVE_AN_ACCOUNT';
export const HANDOFF_DONT_HAVE_AN_ACCOUNT = 'HANDOFF_DONT_HAVE_AN_ACCOUNT';
export const HANDOFF_POPULUS = 'HANDOFF_POPULUS';

export const PATIENT_PING_PROCESS_SESSION_START = 'PATIENT_PING_PROCESS_SESSION_START ';
export const PATIENT_PING_WRONG_DOB = 'PATIENT_PING_WRONG_DOB';
export const PATIENT_PING_MAXIMUM_NUMBER_OF_ATTEMPTS_EXCEEDED = 'PATIENT_PING_MAXIMUM_NUMBER_OF_ATTEMPTS_EXCEEDED';
export const PATIENT_PING_POPULUS = 'PATIENT_PING_POPULUS';

export const LEARN_MORE_SOLV = 'LEARN_MORE_SOLV';
export const APPOINTMENT_REMINDER_AUTHENTICATION_FAILED = 'APPOINTMENT_REMINDER_AUTHENTICATION_FAILED';
export const APPOINTMENT_REMINDER_OPENED = 'APPOINTMENT_REMINDER_OPENED';
export const APPOINTMENT_REMINDER_SUCCESSFUL_AUTHENTICATION = 'APPOINTMENT_REMINDER_SUCCESSFUL_AUTHENTICATION';
export const APPOINTMENT_REMINDER_FILE_DOWNLOADED = 'APPOINTMENT_REMINDER_FILE_DOWNLOADED';
export const APPOINTMENT_REMINDER_ALL_FILES_DOWNLOADED = 'APPOINTMENT_REMINDER_ALL_FILES_DOWNLOADED';
export const APPOINTMENT_REMINDER_LANGUAGE_CHANGED = 'APPOINTMENT_REMINDER_LANGUAGE_CHANGED';

export const SEND_FILES_LANGUAGE_CHANGED = 'SEND_FILES_LANGUAGE_CHANGED';
export const SEND_FILES_OPENED = 'SEND_FILES_OPENED';
export const SEND_FILES_AUTHENTICATION_FAILED = 'SEND_FILES_AUTHENTICATION_FAILED';
export const SEND_FILES_SUCCESSFUL_AUTHENTICATION = 'SEND_FILES_SUCCESSFUL_AUTHENTICATION';
export const SEND_FILES_FILE_DOWNLOADED = 'SEND_FILES_FILE_DOWNLOADED';
export const SEND_FILES_ALL_FILES_DOWNLOADED = 'SEND_FILES_ALL_FILES_DOWNLOADED';
