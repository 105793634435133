import React from 'react';
import { PreviewBarProps } from './interfaces';
import { Button } from 'rsuite';
import { FaSearchMinus, FaSearchPlus, FaFileUpload } from 'react-icons/fa';

const PreviewBar: React.FC<PreviewBarProps> = ({ onDownload, onZoomIn, onZoomOut }) => {
  return (
    <div className='preview-bar'>
      <div className='preview-bar-rigth'>
        <Button onClick={onZoomOut} className='icon-lg grey'>
          <FaSearchMinus />
        </Button>
        <Button onClick={onZoomIn} className='icon-lg grey'>
          <FaSearchPlus />
        </Button>
        <Button onClick={onDownload} className='icon-lg grey'>
          <FaFileUpload />
        </Button>
      </div>
    </div>
  );
};

export default PreviewBar;
