import { ScanRequest } from '../../../../models/ScanRequest';

export type ReviewHandoffProcessProps = {
  scanRequestData: ScanRequest;
};

export const FINISHING_SCAN_REQUEST_WARNING_BOX_DATA = {
  title: 'What happens next?',
  content:
    'After submitting your scan request, our Care Team will schedule you the best appointment based on the criteria you requested. You’ll hear from us within a few business hours with details of your appointment via email and text.',
};
