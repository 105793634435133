import React, { SyntheticEvent, useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { Form } from 'rsuite';
import { ScanRequestContactMethods } from '../../../api/patient-scan-request/interfaces';
import { addContactMethodsToScanRequest } from '../../../api/patient-scan-request/PatientScanRequestAPI';
import { ScanManagmentUrl } from '../../../config/UrlsConfig';
import LoaderComponent from '../../../components/loader-component';
import WarningBox from '../../../components/warning-box';
import { ContactMethodsForm, ScanRequestCreatedProps } from './interfaces';
import { InfoModalTexts } from '../../../models/General';
import { ADD_CONTACT_METHODS_ERROR } from './utils';
import InfoModalComponent from '../../../components/modals/info-modal';
import { MixpanelContext } from '../../../contexts/MixpanelContext';
import { CONTACT_METHODS_ADDED, MANAGE_REQUESTS_FROM_SCAN_COMPLETED } from '../../../utils/MixpanelEvents';
import { HANDOFF_SOURCE, PXB_SOURCE } from '../../../utils/GeneralUtil';
import { CONCIERGE_COST } from '../../../config/FrontConfig';
import { ScanType } from '../../../models/ScanType';
import { Trans, useTranslation } from 'react-i18next';
import { Dict } from 'mixpanel-browser';

const ScanRequestCreated: React.FunctionComponent<ScanRequestCreatedProps> = ({
  scanRequestCreatedID,
  clearProcessData,
  isFromPXB,
  isAuthenticated,
  mixpanelHandoffPayload,
  scanRequestData,
}) => {
  const {
    HomeAddressData,
    SecondaryAddressData,
    SelfPay,
    IsAvailableAnytime,
    readableAvailableDatetimes,
    scanServiceSelected,
    InsurancePlan,
    IsConcierge,
  } = scanRequestData;
  const { t } = useTranslation('handoff');
  const { FullName = '', MinPrice, TotalPrice } = scanServiceSelected as ScanType;
  const [formValue] = useState<ContactMethodsForm>({
    PreferPhone: true,
    PreferText: true,
    PreferEmail: true,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasConfirmed, setHasSubmittedContactMethods] = useState(true);
  const [errorDataForModal, setErrorDataForModal] = useState<InfoModalTexts | null>(null);
  const history = useHistory();
  const mixpanel = useContext(MixpanelContext);
  const renderSelectedDates = (): JSX.Element[] | undefined => {
    return readableAvailableDatetimes?.map((readableDatetime, index) => <li key={index}>{readableDatetime}</li>);
  };

  useEffect(() => {
    setTimeout(() => clearProcessData(), 500);
  }, []);

  // useEffect(() => {
  //   window.scroll(0, 0);
  // }, [hasConfirmed]);

  const manageMyRequests = (event: SyntheticEvent): void => {
    event.preventDefault();
    if (hasConfirmed) {
      trackMixpanelEvent(MANAGE_REQUESTS_FROM_SCAN_COMPLETED);
      history.push(ScanManagmentUrl);
      return;
    }
  };

  function trackMixpanelEvent<T>(event: string, data?: T): void {
    const eventName = isFromPXB ? event : `HANDOFF_${event}`;
    const payload = isFromPXB ? data : { ...mixpanelHandoffPayload, ...data };
    mixpanel.track(eventName, payload as Dict);
  }

  const addContactMethods = (event: SyntheticEvent): void => {
    event.preventDefault();
    setIsLoading(true);
    const contactMethodsPayload: ScanRequestContactMethods = createContactMethodsPayload();
    addContactMethodsToScanRequest(contactMethodsPayload, isFromPXB ? PXB_SOURCE : HANDOFF_SOURCE)
      .then(() => {
        setHasSubmittedContactMethods(true);
        clearProcessData();
        trackMixpanelEvent<typeof contactMethodsPayload>(CONTACT_METHODS_ADDED, contactMethodsPayload);
      })
      .catch(() => setErrorDataForModal(ADD_CONTACT_METHODS_ERROR))
      .finally(() => setIsLoading(false));
  };

  const createContactMethodsPayload = (): ScanRequestContactMethods => ({
    ScanRequestID: scanRequestCreatedID,
    PreferPhone: formValue.PreferPhone ? 1 : 0,
    PreferText: formValue.PreferText ? 1 : 0,
    PreferEmail: formValue.PreferEmail ? 1 : 0,
  });

  if (isLoading) {
    return <LoaderComponent className='loader-block-center' />;
  }

  return (
    <>
      <div className='main-request-form main-request-successfully'>
        <h2 id='Your_request_was_successfully_created' className='h2'>
          {t('created.Your request was successfully created!')}
        </h2>
        <div className='request-number'>
          {t('Request ID').toUpperCase()}: <span>{scanRequestCreatedID}</span>
        </div>
        <div className='text-form opt7'>
          <p>{t('created.text')}</p>
        </div>
        <div className='exam-order'>
          <div className='row'>
            <div>{t('review.Imaging Order')}:</div>
            <div>
              <b>{t(`${FullName}` as unknown as string | string[], { ns: 'scanTypesFNList', defaultValue: FullName })}</b>
            </div>
          </div>
          <div className='row'>
            <div>{t('review.Availability')}:</div>
            <div>
              <b>{IsAvailableAnytime ? 'Flexible over the next few weeks' : 'Specific dates'}</b>
              <ul className='appointment-field_data'>{renderSelectedDates()}</ul>
            </div>
          </div>
          {HomeAddressData?.RawString && (
            <div className='row'>
              <div>{t('review.Home address OR Additional Address')}:</div>
              <div>
                <b>
                  {HomeAddressData?.RawString}
                  {!!SecondaryAddressData && ` / ${SecondaryAddressData.RawString}`}
                </b>
              </div>
            </div>
          )}
          {SelfPay ? (
            <>
              <div className='row payment-row'>
                <div>{t('review.Payment Type')}</div>
                <div>
                  <b>Self-pay</b>
                </div>
              </div>
              <div className='row'>
                <div>{t('review.Remaining payment')}</div>
                <div>
                  <div className='row'>
                    <b>${MinPrice}&nbsp;</b>
                    <div className='total'>
                      {t('review.of total')} ${TotalPrice}
                    </div>
                  </div>
                  <p>
                    <Trans ns='handoff' i18nKey='review.payment_note' components={{ b: <b /> }} />
                  </p>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className='row'>
                <div>{t('review.Coverage type')}:</div>
                <div>
                  <b>{InsurancePlan}</b>
                </div>
              </div>
              {IsConcierge !== undefined && IsConcierge !== null ? (
                <>
                  <div className='row row-nowrap'>
                    <div>Service type:</div>
                    <div className='row row-wrap flex1'>
                      {IsConcierge ? (
                        <>
                          <b>{t('Medmo Concierge')}</b>
                          <span className='icon-ctm'></span>
                        </>
                      ) : (
                        <>
                          <b>{t('Medmo Assist')}</b>
                          <span className='icon-list'></span>
                        </>
                      )}
                    </div>
                  </div>
                  {IsConcierge ? (
                    <div className='row payment-row'>
                      <div>{t('Service Cost')}:</div>
                      <div>
                        <div className='row'>
                          <b>${CONCIERGE_COST}</b>
                          <div className='total paid-full paid-full-style2'>Paid in full</div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </>
              ) : null}
            </>
          )}
        </div>
        <Form formValue={formValue} fluid>
          {/*  {hasConfirmed ? (*/}
          {/*    <div className='request-field-info'>*/}
          {/*      <span className='icon-checkmark-circle'>*/}
          {/*        <span className='path1'></span>*/}
          {/*        <span className='path2'></span>*/}
          {/*      </span>*/}
          {/*      <p>Your contact method was updated</p>*/}
          {/*    </div>*/}
          {/*  ) : (*/}
          {/*    <div className='input-ckeckbox_options'>*/}
          {/*      <div className='text'>*/}
          {/*        <p>Please let us know your preferred contact method for additional information about your appointment.</p>*/}
          {/*      </div>*/}
          {/*      <div className='input-checkbox'>*/}
          {/*        <Checkbox*/}
          {/*          checked={formValue.PreferPhone}*/}
          {/*          onChange={() => setFormValue(previous => ({ ...previous, PreferPhone: !previous.PreferPhone }))}*/}
          {/*        >*/}
          {/*          Phone call*/}
          {/*        </Checkbox>*/}
          {/*      </div>*/}
          {/*      <div className='input-checkbox'>*/}
          {/*        <Checkbox*/}
          {/*          checked={formValue.PreferText}*/}
          {/*          onChange={() => setFormValue(previous => ({ ...previous, PreferText: !previous.PreferText }))}*/}
          {/*        >*/}
          {/*          Text messaging*/}
          {/*        </Checkbox>*/}
          {/*      </div>*/}
          {/*      <div className='input-checkbox'>*/}
          {/*        <Checkbox*/}
          {/*          checked={formValue.PreferEmail}*/}
          {/*          onChange={() => setFormValue(previous => ({ ...previous, PreferEmail: !previous.PreferEmail }))}*/}
          {/*        >*/}
          {/*          Email*/}
          {/*        </Checkbox>*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*  )}*/}
          {hasConfirmed ? (
            <>
              {(isFromPXB || isAuthenticated) && (
                <div className='btn-row row justify-content-end'>
                  <button className='btn mb32' onClick={manageMyRequests}>
                    {t('Manage my requests', { ns: 'translations' })}
                  </button>
                </div>
              )}
            </>
          ) : (
            <div className='btn-row row justify-content-end'>
              <button className='btn mb32' onClick={addContactMethods}>
                {t('Confirm', { ns: 'translations' })}
              </button>
            </div>
          )}
        </Form>
        <WarningBox title={t('review.box.title')}>
          <p>{t('review.box.content')}</p>
        </WarningBox>
      </div>
      {errorDataForModal && <InfoModalComponent type='error' texts={errorDataForModal} onClose={() => setErrorDataForModal(null)} />}
    </>
  );
};

export default ScanRequestCreated;
