import { ResponseErrorType } from '../errors/interfaces';
import ResponseError from '../errors/ResponseError';

export default function (Promise: PromiseConstructor): void {
  const originalCatch = Promise.prototype.catch;

  Promise.prototype.catch = function <TResult = never>(onrejected?: ((reason: any) => TResult | PromiseLike<TResult>) | null | undefined) {
    const oldRejected = onrejected;
    const newRejected = (reason: Error | ResponseErrorType) => {
      if (reason instanceof ResponseError && reason?.status === 401) {
        throw reason;
      }

      if (oldRejected) {
        oldRejected(reason);
      }
    };

    return originalCatch.call(this, newRejected);
  };
}
