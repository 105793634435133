import { ScanRequest } from '../../models/ScanRequest';

export type HowToCoverCCTAAppointmentProps = {
  goToPreviousStep: (stepName: string) => void;
  scanRequestData: ScanRequest;
  completeStepCallback: (partialScanData: Partial<ScanRequest>, stepName?: string) => void;
};

export const COVERAGE_CCTA_METHODS_WARNING_BOX_DATA = {
  insuranceSubtitle: 'Insurance coverage',
  insuranceContent: ' - we accept all major insurance carriers and make sure you’re only scheduled at an in-network center.',
  selfPaySubtitle: 'Self pay option',
  selfPayContent1:
    ' - If you don’t have insurance, you have a high-deductible, or you can’t get your appointment approved by insurance, we offer exclusive self-pay rates. If you choose this option, however, you can’t apply the expense towards your insurance plan deductible.  Our care team will confirm pricing for your appointment shortly after you complete this form.',
  selfPayContent2: ' Our care team will confirm pricing for your appointment shortly after you complete this form.',
};
