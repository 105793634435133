import { FileType } from 'rsuite/Uploader';

export const ACCEPTED_TYPES = '.jpeg,.jpg,.png,.pdf';

export const isFileTypeAllowed = (file: FileType): boolean => {
  const fileNameParts = file.name?.split('.');
  const fileExtension = [...(fileNameParts || [])].pop() || '';
  return !!fileNameParts && fileNameParts?.length > 1 && ACCEPTED_TYPES.includes(fileExtension.toLowerCase());
};

/**
 * @todo Review if there is any case where we might want to save a unique originalFileName. If so, uncomment and fix this function.
 * For now we only rely on the UUID generated when uploading to S3 bucket so we don't need this function for now
 */
/* export const renameFile = async (blobFile: File): Promise<any> => {
  if (!/[^\w.]/.test(blobFile?.name)) {
    return;
  }

  const arrayBuffer = await blobFile.arrayBuffer();
  const newFile = new File([arrayBuffer], blobFile?.name.replace(/[^\w.]/g, ''), {
    type: blobFile.type,
    lastModified: blobFile.lastModified,
  });
  return newFile;
}; */
