import React from 'react';
import WarningBox from '../warning-box';
import { COVERAGE_CCTA_METHODS_WARNING_BOX_DATA, HowToCoverCCTAAppointmentProps } from './interfaces';
import { CLEERLY_HANDOFF_STEP_NAMES } from '../../views/handoff-process/cleerly/utils';

const HowToCoverCCTAAppointment: React.FunctionComponent<HowToCoverCCTAAppointmentProps> = ({ goToPreviousStep, completeStepCallback }) => {
  const { insuranceSubtitle, insuranceContent, selfPaySubtitle, selfPayContent1, selfPayContent2 } = COVERAGE_CCTA_METHODS_WARNING_BOX_DATA;

  const selectOption = (isSelfPay: boolean): void => {
    completeStepCallback({ SelfPay: isSelfPay ? 1 : 0 }, CLEERLY_HANDOFF_STEP_NAMES.howToCoverAppointment);
  };

  return (
    <div className='request-type'>
      <h2 id='How_would_you_like_to_cover_your_appointment' className='h2'>
        How would you like to cover the CCTA portion of your exam?
      </h2>
      <div className='text-wrap'>
        <p>Please select your coverage type below.</p>
      </div>
      <div className='request-type-payments'>
        <a className='request-type-payments_item pointer-cursor' onClick={() => selectOption(true)}>
          <div className='row'>
            <p>
              <b>Self pay</b>
            </p>
            <p>(See below for cost info)</p>
          </div>
        </a>
        <a className='request-type-payments_item pointer-cursor' onClick={() => selectOption(false)}>
          <div className='row'>
            <p>
              <b>Insurance</b>
            </p>
          </div>
        </a>
      </div>
      <WarningBox>
        <p>
          <b>{selfPaySubtitle}</b>
          {selfPayContent1}
          <b style={{ color: '#254c4f' }}>{selfPayContent2}</b>
        </p>
        <p>
          <b>{insuranceSubtitle}</b>
          {insuranceContent}
        </p>
      </WarningBox>
      <div className='btn-row row full-mob justify-content-between'>
        <button className='btn prev btn-white' onClick={() => goToPreviousStep(CLEERLY_HANDOFF_STEP_NAMES.howToCoverAppointment)}>
          Back
        </button>
      </div>
    </div>
  );
};

export default HowToCoverCCTAAppointment;
