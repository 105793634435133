import React, { useCallback, useEffect, useMemo, useState } from 'react';
import InfoBox from '../info-box';
import { Trans, useTranslation } from 'react-i18next';
import {
  initPacemakerGeneralData,
  PacemakerData,
  PacemakerGeneralData,
  PacemakerWrapperProps,
  PING_PACEMAKER_STEP_NAMES,
} from './interfaces';
import MetalBodyPaceMaker from './metal-body-pacemaker';
import UploadPacemaker from './upload-pacemaker';
import PacemakerDetails from './details';

const PacemakerWrapper: React.FunctionComponent<PacemakerWrapperProps> = ({
  goToPreviousStep,
  scanRequestData,
  completeStepCallback,
}: PacemakerWrapperProps) => {
  const { t } = useTranslation('translations');
  const { HasPacemakerOrDefibrillatorInBody, PacemakerFileKey, pacemakerFile, PaceMakerDetails } = scanRequestData;
  const [pacemakerData, setPacemakerData] = useState<PacemakerGeneralData>(() => {
    const newData = {
      HasPacemakerOrDefibrillatorInBody,
      PacemakerFileKey,
      pacemakerFile,
      PaceMakerDetails,
    };
    return {
      ...initPacemakerGeneralData,
      currentStep: 1,
      data: { ...initPacemakerGeneralData.data, ...newData },
    };
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pacemakerData.currentStep]);

  const previousStep = useCallback(
    (stepName?: string): void => {
      if (PING_PACEMAKER_STEP_NAMES.pacemaker === stepName) {
        return goToPreviousStep(PING_PACEMAKER_STEP_NAMES.pacemaker);
      }

      setPacemakerData(({ currentStep, data }) => {
        const prevStep = 1;
        return {
          data,
          currentStep: currentStep - prevStep,
          errorSubmitting: false,
        };
      });
    },
    [pacemakerData],
  );

  const completeStep = useCallback(
    (stepMetadata?: Partial<PacemakerData>, stepName?: string): void => {
      if (PING_PACEMAKER_STEP_NAMES.pacemaker === stepName && stepMetadata?.HasPacemakerOrDefibrillatorInBody === 0) {
        stepMetadata = { ...stepMetadata };
        const newData = { ...pacemakerData.data, ...stepMetadata };
        return completeStepCallback(newData, stepName);
      } else if (PING_PACEMAKER_STEP_NAMES.uploadPacemaker === stepName && stepMetadata?.PacemakerFileKey !== null) {
        stepMetadata = { ...stepMetadata };
        const newData = { ...pacemakerData.data, ...stepMetadata };
        return completeStepCallback(newData, stepName);
      } else if (PING_PACEMAKER_STEP_NAMES.pacemakerDetails === stepName) {
        stepMetadata = { ...stepMetadata };
        const newData = { ...pacemakerData.data, ...stepMetadata };
        return completeStepCallback(newData, stepName);
      }
      setPacemakerData(({ currentStep, data }) => {
        const nextStep = 1;
        const newData = { ...data, ...stepMetadata };
        return {
          data: newData,
          currentStep: currentStep + nextStep,
          errorSubmitting: false,
        };
      });
    },
    [pacemakerData],
  );

  const currentStepDisplayed = useMemo<React.ReactNode | null>(() => {
    const { currentStep, data } = pacemakerData;
    if (!pacemakerData) {
      return null;
    }
    switch (currentStep) {
      case 1: {
        return (
          <MetalBodyPaceMaker
            goToPreviousStep={previousStep}
            completeStepCallback={completeStep}
            scanRequestData={data}
            stepName={PING_PACEMAKER_STEP_NAMES.pacemaker}
          />
        );
      }
      case 2: {
        return (
          <UploadPacemaker
            goToPreviousStep={previousStep}
            completeStepCallback={completeStep}
            scanRequestData={data}
            stepName={PING_PACEMAKER_STEP_NAMES.uploadPacemaker}
          />
        );
      }
      case 3:
        return (
          <PacemakerDetails
            goToPreviousStep={previousStep}
            completeStepCallback={completeStep}
            scanRequestData={data}
            stepName={PING_PACEMAKER_STEP_NAMES.pacemakerDetails}
          />
        );
      default:
        return null;
    }
  }, [pacemakerData]);

  return (
    <>
      <>{currentStepDisplayed}</>
      <InfoBox
        title={t('box.WHY_DO_YOU_NEED_INFOBOX_DATA.title')}
        content={<Trans i18nKey='box.WHY_DO_YOU_NEED_INFOBOX_DATA.content' components={{ br: <br /> }} />}
      />
    </>
  );
};

export default PacemakerWrapper;
