import React, { useState } from 'react';
import { Form } from 'rsuite';
import TextField from '../../text-field-component';
import Textarea from '../../textarea';
import InfoBox from '../../info-box';
import { useTranslation } from 'react-i18next';
import { MetalBodyGeneralProps, PacemakerDetailsForm, PING_PACEMAKER_STEP_NAMES } from '../interfaces';

const PacemakerDetails: React.FunctionComponent<MetalBodyGeneralProps> = ({
  goToPreviousStep,
  completeStepCallback,
  scanRequestData,
  infoBox,
  stepName = PING_PACEMAKER_STEP_NAMES.pacemakerDetails,
}) => {
  const { t } = useTranslation('handoff');
  const { PaceMakerDetails = '' } = scanRequestData;

  const [formValue, setFormValue] = useState<PacemakerDetailsForm>({
    PaceMakerDetails,
  });

  const selectResponse = (): void => {
    completeStepCallback({ PaceMakerDetails: formValue.PaceMakerDetails }, PING_PACEMAKER_STEP_NAMES.pacemakerDetails);
  };

  return (
    <>
      <div className='main-request-form'>
        <h2 id='Additional_questions' className='h2'>
          {t('pacemaker.Please provide details regarding your pacemaker and/or defibrillator')}
        </h2>
        <div className='text-form'>
          <p>{t('pacemaker.Please provide information such as')}</p>
          <ul className='style-disc'>
            <li>{t('pacemaker.Pacemaker or defibrillator')}</li>
            <li>{t('pacemaker.Type')}</li>
            <li>{t('pacemaker.Model')}</li>
            <li>{t('pacemaker.Brand')}</li>
            <li>{t('pacemaker.Serial number')}</li>
            <li>{t('pacemaker.When it was placed')}</li>
            <li>{t('pacemaker.Name of provider who implanted it')}</li>
          </ul>
          <p className='fw600'>{t('pacemaker.If youre not sure, please click Next to continue.')}</p>
        </div>
        <Form onChange={value => setFormValue(value as PacemakerDetailsForm)} formValue={formValue} className='main-form-appointment' fluid>
          <div className='input-wrap textarea-wrap mb32'>
            <TextField
              name='PaceMakerDetails'
              value={formValue.PaceMakerDetails}
              placeholder={t('pacemaker.My defibrillator was implanted on 2019 by my doctor. Serial number is 400XXXXXX')}
              accepter={Textarea}
            />
          </div>
        </Form>
        <div className='btn-row full-mob row justify-content-between'>
          <button onClick={() => goToPreviousStep(stepName)} className='btn prev btn-white'>
            {t('form.Back', { ns: 'translations' })}
          </button>
          <button className='btn next' onClick={() => selectResponse()}>
            {t('form.Next', { ns: 'translations' })}
          </button>
        </div>
      </div>
      {infoBox ? <InfoBox title={infoBox.title} content={infoBox.content} /> : null}
    </>
  );
};

export default PacemakerDetails;
