import { initUserInformationData } from '../handoff-process/cleerly/user-information/interfaces';

const CLEERLY_SCAN_REQUEST_INITIAL_DATA = {
  ImagingCenterName: '',
  ProviderName: '',
  ProviderPhone: '',
  PrescriptionFileKey: '',
  CardExpirationDate: '',
  CardLast4: null,
  CardType: '',
  PaymentID: '',
  PaymentSecret: '',
  ...initUserInformationData,
};

export const CLEERLY_INITIAL_DATA = {
  currentStep: 0,
  errorSubmitting: false,
  scanRequestData: CLEERLY_SCAN_REQUEST_INITIAL_DATA,
};

export const PatientCleerly = {
  FirstName: '',
  LastName: '',
  DOB: '',
  Email: '',
  Phone: '',
};

export const CLEERLY_SELF_SERVICE_STEP_NAMES = {
  instructions: 'INSTRUCTIONS',
  userInformation: 'USER_INFORMATION',
  imagingCenter: 'IMAGING_CENTER',
  provider: 'PROVIDER',
  paymentInformation: 'PAYMENT_INFORMATION_CLEERLY',
  reviewScanRequest: 'REVIEW_SCAN_REQUEST',
  purchaseInformation: 'PURCHASE_INFORMATION_CLEERLY',
  generalError: 'GENERAL_ERROR',
};

export const additionalPurchaseData = {
  title: 'Purchase a Cleerly analysis',
  description:
    'Your Cleerly analysis will be conducted after your CCTA exam is completed. ' +
    'Your Cleerly Exam includes an interpretation of your results, a consultation and follow up guidance.',
};

export const FINISHING_WARNING_BOX_DATA = {
  title: 'What happens next?',
  content: 'You will receive an email voucher that serves as proof of payment.',
};
