import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Progress } from 'rsuite';
import { HandoffProcessPayload, InvitationDataResponse } from '../../../api/handoff-process/interfaces';
import { SCAN_REQUEST_INITIAL_DATA } from '../../patient-scan-request/PatientScanRequestUtils';
import { CustomHandoffProcessData } from '../interfaces';
import { CLEERLY_HANDOFF_STEP_NAMES, CLEERLY_HANDOFF_TOTAL_STEPS } from './utils';
import ConfirmYourIdentity from '../components/confirm-your-identity';
import CustomInstructions from './instructions';
import { ScanRequest } from '../../../models/ScanRequest';
import PatientLocation from '../../../components/patient-location';
import { HANDOFF_SOURCE } from '../../../utils/GeneralUtil';
import SelectAppointmentDatetimes from '../../../components/select-appointment-datetimes';
import ReviewHandoffProcess from './review-handoff-process';
import UserInformation from './user-information';
import CCTAPaymentCMethodComponent from '../../../components/payment-ccta-method';
import HowToCoverCCTAAppointment from '../../../components/how-to-cover-ccta-appointment';
import PurchaseInformation from '../../../components/purchase-information';
import { finishCleerlyHandoffProcess } from '../../../api/handoff-process/HandoffProcessAPI';
import { HAVE_QUESTIONS_HANDOFF_INFOBOX_DATA } from '../standard/utils';
import { useParams } from 'react-router';
import { Address } from '../../../models/Address';
import { NumberBoolean } from '../../../models/General';
import GeneralError from '../../../components/general-error';
import LoaderComponent from '../../../components/loader-component';
import Roi from '../../../components/roi';

const CleerlyHandoffProcess: React.FunctionComponent<{ invitationData: InvitationDataResponse }> = ({ invitationData }) => {
  const { Line } = Progress;
  const { invitationID } = useParams<{ invitationID: string }>();
  const [isLoading, setIsLoading] = useState(false);
  const infoBoxData = HAVE_QUESTIONS_HANDOFF_INFOBOX_DATA;
  const [purchaseAddInfo, setPurchaseAddInfo] = useState({ isCardErrorMessageShown: false });
  const [handoffData, setHandoffData] = useState<CustomHandoffProcessData>({
    currentStep: 0,
    scanRequestData: SCAN_REQUEST_INITIAL_DATA,
    errorSubmitting: false,
    isFirstLoad: true,
    attemptCount: 0,
    patientInfo: {
      Email: '',
      FirstName: '',
      LastName: '',
      GenderId: 0,
      Phone: '',
      DOB: '',
    },
    physicians: {
      prescriberName: '',
      prescriberPhone: '',
      physicianOrganizationName: '',
    },
    UserID: 0,
  });

  const percentage = useMemo(() => (handoffData.currentStep / CLEERLY_HANDOFF_TOTAL_STEPS) * 100, [handoffData.currentStep]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [handoffData.currentStep]);

  useEffect(() => {
    invitationData && validateInvitation();
  }, [invitationData]);

  const getScanRequestObtainedData = (invitationResponse: InvitationDataResponse): Partial<ScanRequest> => {
    const { ScanServiceID, ScanService, Prescriber, TestRequestID, PatientID, ScanCost } = invitationResponse;
    return {
      ScanCost,
      ScanServiceID,
      scanServiceSelected: ScanService,
      PhysicianID: Prescriber.ID,
      ID: TestRequestID,
      PatientID,
    };
  };

  const validateInvitation = (): void => {
    const { Prescriber, PhysicianOrganization, AttemptCount, PatientInfo, UserID = 0 } = invitationData;
    const { FirstName, LastName, Phone } = Prescriber;
    const scanRequestObtainedData = getScanRequestObtainedData(invitationData);
    const updateData = {
      attemptCount: AttemptCount,
      patientInfo: PatientInfo,
      physicians: {
        prescriberName: `${FirstName} ${LastName}`,
        physicianOrganizationName: PhysicianOrganization.Name,
        prescriberPhone: Phone,
      },
      UserID: UserID,
    };
    setHandoffData(prev => ({ ...prev, scanRequestData: { ...prev.scanRequestData, ...scanRequestObtainedData }, ...updateData }));
  };

  const completeStepCallback = useCallback(
    (stepMetadata?: Partial<ScanRequest>, stepName?: string) => {
      const stepDataToSave = stepMetadata || {};
      if (stepName === CLEERLY_HANDOFF_STEP_NAMES.userInformation) {
        return setHandoffData(({ currentStep, ...rest }) => ({
          ...rest,
          currentStep: currentStep + 1,
          patientInfo: { ...rest.patientInfo, ...stepDataToSave },
        }));
      }
      const { scanRequestData, currentStep } = handoffData;
      const { ScanCost } = scanRequestData;
      if (currentStep && currentStep === 7 && ScanCost && Number(ScanCost) <= 0) {
        const metaData = {
          PaymentID: '',
          PaymentSecret: '',
          CardType: '',
          CardLast4: 0,
        };
        return submitCallback({ ...stepMetadata, ...metaData }, CLEERLY_HANDOFF_STEP_NAMES.purchaseInformation);
      }
      setHandoffData(({ currentStep, ...rest }) => ({
        ...rest,
        currentStep: currentStep + 1,
        scanRequestData: { ...rest.scanRequestData, ...stepDataToSave },
      }));
    },
    [handoffData],
  );

  const buildBasePayload = (handoffScanRequestData: ScanRequest): HandoffProcessPayload => {
    return {
      TestRequestID: handoffScanRequestData.ID as number,
      PatientID: null,
      HomeAddressData: handoffScanRequestData.HomeAddressData as Address,
      ShouldFindNearHomeAddress: handoffScanRequestData.ShouldFindNearHomeAddress as NumberBoolean,
      UserBrowserTimezone: handoffScanRequestData.UserBrowserTimezone,
      IsAvailableAnytime: handoffScanRequestData.IsAvailableAnytime as NumberBoolean,
      AvailableDatetimes: handoffScanRequestData.AvailableDatetimes,
      LogisticalNotes: handoffScanRequestData.LogisticalNotes,
      SelfPay: handoffScanRequestData.SelfPay as NumberBoolean,
      CardLast4: handoffScanRequestData.CardLast4 as number,
      ConsentAccept: handoffScanRequestData.ConsentAccept as number,
      CardType: handoffScanRequestData.CardType as string,
      PaymentID: handoffScanRequestData.PaymentID as string,
      PaymentSecret: handoffScanRequestData.PaymentSecret as string,
    };
  };

  const addInsuranceFields = (handoffPayload: HandoffProcessPayload, scanRequestData: ScanRequest): HandoffProcessPayload => {
    const { InsurancePlanID, InsurancePlan, PolicyNumber, InsuranceCardFront, InsuranceCardBack } = scanRequestData;
    handoffPayload = {
      ...handoffPayload,
      InsurancePlanID,
      InsurancePlan,
      PolicyNumber,
      FrontOfInsuranceCardFileKey: InsuranceCardFront?.key || null,
      BackOfInsuranceCardFileKey: InsuranceCardBack?.key || null,
    };
    return handoffPayload;
  };

  const buildHandoffDataPayload = (handoffScanRequestData: ScanRequest): HandoffProcessPayload => {
    let handoffPayload = buildBasePayload(handoffScanRequestData);
    if (!handoffScanRequestData.ShouldFindNearHomeAddress) {
      const { SecondaryAddressData } = handoffScanRequestData;
      handoffPayload = { ...handoffPayload, SecondaryAddressData };
    }
    if (!handoffScanRequestData.SelfPay) {
      handoffPayload = addInsuranceFields(handoffPayload, handoffScanRequestData);
    }
    return handoffPayload;
  };

  const submitCallback = async (stepMetadata?: Partial<ScanRequest>, stepName?: string) => {
    const stepDataToSave = stepMetadata || {};
    const payload = buildHandoffDataPayload({ ...handoffData.scanRequestData, ...stepDataToSave });
    if (isLoading) {
      return;
    }
    try {
      setIsLoading(true);
      await finishCleerlyHandoffProcess(invitationID, {
        ...payload,
        PatientInfo: { ...handoffData.patientInfo, UserID: handoffData.UserID },
      });
    } catch (error: any) {
      setIsLoading(false);
      const message = error.message;
      setHandoffData(previous => ({ ...previous, currentStep: 9, errorSubmitting: true }));
      if (error.status && error.status === 400 && message && message.toLowerCase().indexOf('payment message') > -1) {
        setPurchaseAddInfo(prevState => ({ ...prevState, isCardErrorMessageShown: true }));
        setHandoffData(previous => ({ ...previous, currentStep: 8, errorSubmitting: false }));
        return;
      }
      return setHandoffData(previous => ({ ...previous, currentStep: 9, errorSubmitting: true }));
    }
    setIsLoading(false);
    if (stepName) {
      setHandoffData(data => ({
        ...data,
        currentStep: 9,
        scanRequestData: { ...data.scanRequestData, ...stepDataToSave },
      }));
    }
  };

  const goToPreviousStep = useCallback((stepName?: string): void => {
    const { scanRequestData, currentStep } = handoffData;
    const { ScanCost } = scanRequestData;
    if (currentStep && currentStep === 9 && ScanCost && Number(ScanCost) <= 0) {
      return setHandoffData(({ currentStep, ...rest }) => ({
        ...rest,
        currentStep: currentStep - 2,
        errorSubmitting: false,
      }));
    } else if (stepName) {
      return setHandoffData(({ currentStep, ...rest }) => ({
        ...rest,
        currentStep: currentStep - 1,
        errorSubmitting: false,
      }));
    }
    setHandoffData(({ currentStep, ...rest }) => ({
      ...rest,
      currentStep: currentStep - 1,
      errorSubmitting: false,
    }));
  }, []);

  const userInfoInfobox = HAVE_QUESTIONS_HANDOFF_INFOBOX_DATA;

  const currentStepDisplayed = useMemo<React.ReactNode | null>(() => {
    const { currentStep, scanRequestData, physicians, attemptCount, errorSubmitting, patientInfo } = handoffData;
    switch (currentStep) {
      case 0:
        return (
          <ConfirmYourIdentity
            physicianDataToDisplay={physicians}
            scanRequestID={scanRequestData.ID as number}
            attemptsSubmitted={attemptCount}
            completeStepCallback={completeStepCallback}
          />
        );
      case 1:
        return <CustomInstructions completeStepCallback={completeStepCallback} />;
      case 2:
        return (
          <UserInformation
            infoBoxData={userInfoInfobox}
            patientDataToPrepopulate={patientInfo}
            completeStepCallback={completeStepCallback}
          />
        );
      case 3:
        return (
          <PatientLocation
            source={HANDOFF_SOURCE}
            goToPreviousStep={goToPreviousStep}
            scanRequestData={scanRequestData}
            completeStepCallback={completeStepCallback}
          />
        );
      case 4:
        return (
          <SelectAppointmentDatetimes
            goToPreviousStep={goToPreviousStep}
            scanRequestData={handoffData.scanRequestData}
            completeStepCallback={completeStepCallback}
          />
        );
      case 5:
        return (
          <Roi
            goToPreviousStep={goToPreviousStep}
            completeStepCallback={completeStepCallback}
            scanRequestData={handoffData.scanRequestData}
            infoBox={infoBoxData}
          />
        );
      case 6:
        return (
          <HowToCoverCCTAAppointment
            goToPreviousStep={goToPreviousStep}
            scanRequestData={scanRequestData}
            completeStepCallback={completeStepCallback}
          />
        );
      case 7:
        return (
          <CCTAPaymentCMethodComponent
            goToPreviousStep={goToPreviousStep}
            scanRequestData={scanRequestData}
            completeStepCallback={completeStepCallback}
            source={HANDOFF_SOURCE}
          />
        );
      case 8: {
        return (
          <PurchaseInformation
            patientData={patientInfo}
            goToPreviousStep={goToPreviousStep}
            scanRequestData={scanRequestData}
            additionalInfo={purchaseAddInfo}
            completeStepCallback={submitCallback}
          />
        );
      }
      case 9:
        return errorSubmitting ? (
          <GeneralError goToPreviousStep={goToPreviousStep} isFromPXB={false} />
        ) : (
          <ReviewHandoffProcess scanRequestData={handoffData.scanRequestData} />
        );
      default:
        return null;
    }
  }, [handoffData]);

  return (
    <section className='main-request-section'>
      <Line percent={percentage} strokeColor='#ff8a35' />
      <div className='container'>
        <div className='row'>{isLoading ? <LoaderComponent className='loader-block-center' /> : currentStepDisplayed}</div>
      </div>
    </section>
  );
};

export default CleerlyHandoffProcess;
