import React, { useCallback, useEffect, useMemo, useState } from 'react';
import InfoBox from '../info-box';
import { Trans, useTranslation } from 'react-i18next';
import {
  ClaustrophobiaData,
  ClaustrophobiaGeneralData,
  ClaustrophobiaWrapperProps,
  initClaustrophobiaGeneralData,
  PING_CLAUSTROPHOBIA_STEP_NAMES,
} from './interfaces';
import BodyClaustrophobia from './body-claustrophobia';
import ClaustrophobiaGeneralDetails from './details';

const ClaustrophobiaWrapper: React.FunctionComponent<ClaustrophobiaWrapperProps> = ({
  goToPreviousStep,
  scanRequestData,
  completeStepCallback,
}: ClaustrophobiaWrapperProps) => {
  const { t } = useTranslation('translations');
  const { HasClaustrophobia, ClaustrophobiaDetails } = scanRequestData;
  const [claustrophobiaData, setClaustrophobiaData] = useState<ClaustrophobiaGeneralData>(() => {
    const newData = {
      HasClaustrophobia,
      ClaustrophobiaDetails,
    };
    return {
      ...initClaustrophobiaGeneralData,
      currentStep: 1,
      data: { ...initClaustrophobiaGeneralData.data, ...newData },
    };
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [claustrophobiaData.currentStep]);

  const previousStep = useCallback(
    (stepName?: string): void => {
      if (PING_CLAUSTROPHOBIA_STEP_NAMES.claustrophobia === stepName) {
        return goToPreviousStep(PING_CLAUSTROPHOBIA_STEP_NAMES.claustrophobia);
      }

      setClaustrophobiaData(({ currentStep, data }) => {
        const prevStep = 1;
        return {
          data,
          currentStep: currentStep - prevStep,
          errorSubmitting: false,
        };
      });
    },
    [claustrophobiaData],
  );

  const completeStep = useCallback(
    (stepMetadata?: Partial<ClaustrophobiaData>, stepName?: string): void => {
      if (PING_CLAUSTROPHOBIA_STEP_NAMES.claustrophobia === stepName && stepMetadata?.HasClaustrophobia === 0) {
        stepMetadata = { ...stepMetadata };
        const newData = { ...claustrophobiaData.data, ...stepMetadata };
        return completeStepCallback(newData, stepName);
      } else if (PING_CLAUSTROPHOBIA_STEP_NAMES.claustrophobiaDetails === stepName) {
        stepMetadata = { ...stepMetadata };
        const newData = { ...claustrophobiaData.data, ...stepMetadata };
        return completeStepCallback(newData, stepName);
      }

      setClaustrophobiaData(({ currentStep, data }) => {
        const nextStep = 1;
        const newData = { ...data, ...stepMetadata };
        return {
          data: newData,
          currentStep: currentStep + nextStep,
          errorSubmitting: false,
        };
      });
    },
    [claustrophobiaData],
  );

  const currentStepDisplayed = useMemo<React.ReactNode | null>(() => {
    const { currentStep, data } = claustrophobiaData;
    if (!claustrophobiaData) {
      return null;
    }
    switch (currentStep) {
      case 1: {
        return (
          <BodyClaustrophobia
            goToPreviousStep={previousStep}
            completeStepCallback={completeStep}
            scanRequestData={data}
            stepName={PING_CLAUSTROPHOBIA_STEP_NAMES.claustrophobia}
          />
        );
      }
      case 2:
        return (
          <ClaustrophobiaGeneralDetails
            goToPreviousStep={previousStep}
            completeStepCallback={completeStep}
            scanRequestData={data}
            stepName={PING_CLAUSTROPHOBIA_STEP_NAMES.claustrophobiaDetails}
          />
        );
      default:
        return null;
    }
  }, [claustrophobiaData]);

  return (
    <>
      <>{currentStepDisplayed}</>
      <InfoBox
        title={t('box.WHY_DO_YOU_NEED_INFOBOX_DATA.title')}
        content={<Trans i18nKey='box.WHY_DO_YOU_NEED_INFOBOX_DATA.content' components={{ br: <br /> }} />}
      />
    </>
  );
};

export default ClaustrophobiaWrapper;
