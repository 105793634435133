import { ScanRequest } from '../../models/ScanRequest';
import { buildReadableAvailableDatetimes, parseStringToDatetimes } from '../../utils/DateUtil';
import { convertToPriceStr } from '../../utils/PriceUtils';

export const textsMap = {
  editInfoTitle: 'You can edit any of this.',
  editInfoContent:
    'If anything changed after you created a request, this is the time to change that before an Imaging Center schedules you an appointment.',
};

export enum ScanEditType {
  All = '',
  Availability = 'Availability',
  ScanTypeSetter = 'ScanTypeSetter',
  ScanType = 'ScanType',
  Doctor = 'Doctor',
  Locations = 'Locations',
  Payment = 'Payment',
  Reason = 'Reason',
  Rx = 'Rx',
}

export const updateData = (data: ScanRequest): ScanRequest => ({
  ...data,
  readableAvailableDatetimes: data.AvailableDatetimes
    ? buildReadableAvailableDatetimes(parseStringToDatetimes(data.AvailableDatetimes, 'MMM do, yyyy'))
    : [],
  scanServiceSelected: {
    ScanServiceID: data.ScanServiceID,
    ScanTypeName: data.SelectedScan || '',
    MinPrice: data.DueAtIC || '',
    Fee: data.MedmoFee || '',
    AvgPrice: data.AvgPrice || '',
    TotalPrice: data.MedmoFee && data.DueAtIC ? convertToPriceStr(Number(data.MedmoFee) + Number(data.DueAtIC)) : '',
  },
});
