import React from 'react';
import MaskedInput from 'react-text-mask';
import { InputMaskedProps } from './interfaces';

const InputMasked: React.FunctionComponent<InputMaskedProps> = ({ onChange, ...rest }) => {
  return (
    <MaskedInput
      {...rest}
      className='rs-input'
      onChange={event => {
        onChange(event.target.value);
      }}
    />
  );
};

export default InputMasked;
