import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Tooltip, Whisper } from 'rsuite';
import { menuListType } from './interface';
import { menuList } from './utils';

const SideMenu: React.FunctionComponent<{ logOut: () => void }> = ({ logOut }) => {
  const [showText, setShowText] = useState(false);

  return (
    <>
      <div className='btn-menu btn-menu_sidebar' onClick={() => setShowText(prev => !prev)}>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <aside className={`menu-sidebar ${showText && 'show'}`}>
        <div className='menu-sidebar-mask' onClick={() => setShowText(false)}></div>
        <div className='menu-sidebar-wrap'>
          <div className='menu-sidebar-arrow' onClick={() => setShowText(prev => !prev)}></div>
          <nav className='menu-sidebar-nav'>
            <ul>
              {menuList.map((menuItem: menuListType, i: number) => (
                <li key={i}>
                  {!showText ? (
                    <Whisper placement='right' trigger='hover' speaker={<Tooltip>{menuItem.text}</Tooltip>}>
                      <NavLink to={menuItem.link} activeClassName='is-active'>
                        <span className={`icon ` + menuItem.icon}></span>
                      </NavLink>
                    </Whisper>
                  ) : (
                    <NavLink to={menuItem.link} activeClassName='is-active'>
                      <span className={`icon ` + menuItem.icon}></span>
                      <div className='text'>{menuItem.text}</div>
                    </NavLink>
                  )}
                </li>
              ))}
              {/* <li>
                <NavLink to='/results' activeClassName='is-active'>
                  <span className='icon icon-results'></span>
                  <div className='text'>Results</div>
                </NavLink>
              </li>
              <li>
                <NavLink to='/resource' activeClassName='is-active'>
                  <span className='icon icon-resource'></span>
                  <div className='text'>Resource Center</div>
                </NavLink>
              </li> */}
              <li>
                {!showText ? (
                  <Whisper placement='right' trigger='hover' speaker={<Tooltip>Logout</Tooltip>}>
                    <button onClick={logOut}>
                      <span className='icon icon-logout'></span>
                      <div className='text'>Logout</div>
                    </button>
                  </Whisper>
                ) : (
                  <button onClick={logOut}>
                    <span className='icon icon-logout'></span>
                    <div className='text'>Logout</div>
                  </button>
                )}
              </li>
            </ul>
            <div className='menu-sidebar_footer'>
              <Link to='/terms'>Terms</Link>&<Link to='/privacy'>Privacy</Link>
            </div>
          </nav>
        </div>
      </aside>
    </>
  );
};

export default SideMenu;
