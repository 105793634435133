import { Schema } from 'rsuite';

const { DateType } = Schema.Types;
export const DOBInvalidMessage = 'dob_invalid_message';
export const ConfirmYourIdentitySchema = Schema.Model({
  DOB: DateType(DOBInvalidMessage)
    .isRequired('Please enter a valid date of birth (i.e. mm/dd/yyyy)')
    .max(new Date(), 'Date of birth cannot be a future date'),
});

export const WRONG_DOB_ERROR_TEXTS = {
  title: 'There’s no User associated to this date of birth. Review the information you’ve provided',
  content: 'Please contact Medmo’s Care Team if you are experiencing trouble with your date of birth.',
};

export const MAXIMUM_ATTEMPTS_EXCEEDED_ERROR_TEXTS = {
  title: 'You’ve exceed the maximum attempts allowed to enter the date of birth associated with this request',
  content: 'Please contact Medmo’s Care Team if you are experiencing trouble with your date of birth.',
};
