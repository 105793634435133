import React from 'react';
import ReactDOM from 'react-dom';
import './styles/style.scss';
import initPromise from './utils/PromiseUtil';
import './instrument';
import App from './App';
import ErrorBoundary from './components/error-boundary';
import TagManager from 'react-gtm-module';
import { GTM_ID } from './config/FrontConfig';
import './i18n/config';

const tagManagerArguments = { gtmId: GTM_ID };

TagManager.initialize(tagManagerArguments);

initPromise(Promise);

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root'),
);
