export const initData = {
  FirstName: '',
  LastName: '',
  GenderId: '',
  DOB: '',
  Email: '',
  Phone: '',
  AllowTexts: true,
};

export const editPasswordInitData = {
  oldPassword: '',
  newPassword: '',
  confirmPassword: '',
};

export const textsMap = {
  safeInfoTitle: 'Your information is Safe',
  safeInfoContent:
    'Security and privacy protection is critically important to us. Your information is protected using best in class security protocols and procedures.',
  logWithPasswordInfo: 'Manage and track your personal information along with viewing your results through our portal!',
  editInfo: 'Edit your personal information if you have the need to change it.',
};

export const modalTexts = {
  error: {
    passwordUpdate: {
      title: 'Error',
      content: 'Failed to update password',
    },
    personalInfoUpdate: {
      title: 'Error',
      content: 'Failed to update personal information',
    },
  },
  success: {
    passwordUpdate: {
      title: 'Password update',
      content: 'Your password has been updated successfully.',
      buttonText: 'Continue',
    },
    personalInfoUpdate: {
      title: 'Personal information update',
      content: 'Your personal information has been updated successfully.',
      buttonText: 'Continue',
    },
  },
};

export enum GenderEnum {
  'Prefer not to say',
  Female,
  Male,
}

export type ConnectSocialDataType = {
  ProviderName: string;
  ProviderID: string;
  remember: boolean;
};

export const socialTexts = {
  error: {
    facebook: {
      connectionError: {
        title: 'Error',
        content: 'Failed to connect facebook',
      },
      disconnectionError: {
        title: 'Error',
        content: 'Failed to disconnect facebook',
      },
    },
    google: {
      connectionError: {
        title: 'Error',
        content: 'Failed to connect google',
      },
      disconnectionError: {
        title: 'Error',
        content: 'Failed to disconnect google',
      },
    },
  },
};
