import React from 'react';
import { ScanRequest } from '../../../models/ScanRequest';
import Logos from '../../../assets/medmo_healthyr-logo.svg';
import { STEP_NAMES } from '../../patient-scan-request/PatientScanRequestUtils';

type HowItWorksHealthyrProps = {
  completeStepCallback: (stepData: Partial<ScanRequest>, stepName?: string) => void;
};

const HowItWorksHealthyr: React.FunctionComponent<HowItWorksHealthyrProps> = ({ completeStepCallback }) => {
  return (
    <div className='main-started main-request-form main-request-form-partner'>
      <div className='main-partner-logo'>
        <img src={Logos} alt='Medmo + Healthyr' />
      </div>
      <div className='text-wrap mb32'>
        <p>
          Medmo is the best way to schedule high-quality imaging appointments near you at the best rates. You can use your insurance or
          self-pay with exclusive rates. A specialized care coordinator is there for you each step of the way.
        </p>
      </div>
      <div className='btn-row mb16'>
        <button onClick={() => completeStepCallback({}, STEP_NAMES.howItWorks)} className='btn next'>
          Get Started
        </button>
      </div>
      <h2 id='Ready_to_get_started' className='h2 mb24'>
        How it works
      </h2>
      <ul className='main-started-list main-started-list-partner'>
        <li className='row'>
          <div className='img-referral-icon icon'></div>
          <div className='box'>
            <strong className='title'>1. Obtain a referral for your appointment.</strong>
            <p>If you need a prescription for your appointment, we’ll help you with a consultation before scheduling your appointment.</p>
          </div>
        </li>
        <li className='row'>
          <div className='contact-info-icon icon'></div>
          <div className='box'>
            <strong className='title'>2. Schedule your appointment.</strong>
            <p>
              We’ll schedule you the best, high-quality appointment for you. Our care team coordinates with your providers to make sure it
              goes smoothly.
            </p>
          </div>
        </li>
        <li className='row'>
          <div className='insurance-card-icon icon'></div>
          <div className='box'>
            <strong className='title'>3. Get your exam.</strong>
            <p>
              Sit back and relax! Simply show up for your appointment and we’ll handle the rest. You and your physician will get a copy of
              your report and images.
            </p>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default HowItWorksHealthyr;
