import { PingScanRequest } from '../../views/patient-ping/utils';
import React from 'react';

export const PING_SURGERY_STEP_NAMES = {
  surgery: 'SURGERY',
  surgeryDetails: 'SURGERY_DETAILS',
};

export type SurgeryWrapperProps = {
  goToPreviousStep: (stepName?: string) => void;
  scanRequestData: PingScanRequest;
  completeStepCallback: (partialScanData: Partial<PingScanRequest>, stepName?: string) => void;
};

export const initSurgeryData = {
  SurgeryPast90Days: null,
};

export const initSurgeryDetailsData = {
  SurgeryDetails: '',
};

export const initSurgeryBodyData = {
  ...initSurgeryData,
  ...initSurgeryDetailsData,
};

export const initSurgeryGeneralData = {
  data: initSurgeryBodyData,
  currentStep: 1,
  errorSubmitting: false,
};

export type SurgeryBodyForm = {
  SurgeryPast90Days: number | null;
};

export type SurgeryDetailsForm = {
  SurgeryDetails: string;
};

export type SurgeryData = SurgeryBodyForm & SurgeryDetailsForm;

export type SurgeryGeneralData = {
  data: SurgeryData;
  currentStep: number;
  errorSubmitting: boolean;
};

export type MetalBodyGeneralProps = {
  goToPreviousStep: (stepName?: string) => void;
  completeStepCallback: (partialScanData: Partial<PingScanRequest>, stepName?: string) => void;
  infoBox?: { title: string; content: React.ReactNode };
  stepName: string;
  scanRequestData: Partial<PingScanRequest>;
};

export const Questions = [
  { ID: 1, Response: 'Yes' },
  { ID: 0, Response: 'No' },
  { ID: 2, Response: 'Not sure' },
];
