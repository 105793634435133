import { Schema } from 'rsuite';

const { StringType } = Schema.Types;

export const PhysicianSchema = Schema.Model({
  FirstName: StringType().isRequired('This field is required.').containsLetter('Please add a valid name'),
  LastName: StringType().isRequired('This field is required.').containsLetter('Please add a valid last name'),
  Phone: StringType().pattern(/^(\()?\d{3}(\))?(-|\s)?\d{3}(-|\s)\d{4}$/, 'Please enter a valid phone number.'),
});

export const ERROR_MESSAGES = {
  generalError: 'Error searching physicians.',
  noResultsFound: 'No results found. If you can’t find your physician, you can manually fill their data into the fields below.',
};
