import React, { useState } from 'react';
import { Form } from 'rsuite';
import { CustomCleerlyProps } from '../interfaces';
import TextField from '../../../components/text-field-component';
import { CLEERLY_SELF_SERVICE_STEP_NAMES } from '../utils';
import { Schema } from 'rsuite';
const { StringType } = Schema.Types;

export const ImagingCenterSchema = Schema.Model({
  ImagingCenterName: StringType().isRequired('This field is required.'),
});

const ImagingCenterComponent: React.FunctionComponent<CustomCleerlyProps> = ({
  goToPreviousStep,
  scanRequestData,
  completeStepCallback,
}) => {
  const [formData, setFormData] = useState<{ ImagingCenterName: string }>({
    ImagingCenterName: scanRequestData?.ImagingCenterName ? scanRequestData.ImagingCenterName : '',
  });

  const handleSubmit = (): void => {
    completeStepCallback(formData, CLEERLY_SELF_SERVICE_STEP_NAMES.imagingCenter);
  };

  return (
    <div className='main-request-form'>
      <h2 id='When_would_you_like_your_appointment' className='h2'>
        Please provide the name of the imaging center where your CCTA is or will be performed
      </h2>
      <Form
        model={ImagingCenterSchema}
        onChange={value => setFormData(value as { ImagingCenterName: string })}
        formValue={formData}
        className='main-form-appointment'
        fluid
      >
        <div className='input-wrap textarea-wrap'>
          <TextField label='Imaging center name' value={formData.ImagingCenterName} name='ImagingCenterName' />
        </div>
        <div className='btn-row row justify-content-between full-mob'>
          <button
            className='btn btn-white back'
            onClick={() => goToPreviousStep && goToPreviousStep(CLEERLY_SELF_SERVICE_STEP_NAMES.imagingCenter)}
          >
            Back
          </button>
          <button className='btn next' disabled={!formData.ImagingCenterName} onClick={handleSubmit}>
            Confirm
          </button>
        </div>
      </Form>
    </div>
  );
};

export default ImagingCenterComponent;
