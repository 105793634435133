import React from 'react';
import InfoBox from '../../../../components/info-box';
import { HANDOFF_STEP_NAMES } from '../utils';
import { HowMedmoWorksProps } from './interfaces';
import { Trans, useTranslation } from 'react-i18next';

const HowMedmoWorks: React.FunctionComponent<HowMedmoWorksProps> = ({ scanRequestID, completeStepCallback }) => {
  const { t } = useTranslation('handoff');

  return (
    <>
      <div className='request-type handoff-process'>
        <div className='scan-service-edit_header'>
          <h2 className='h2'>{t('medmo_works.title')}</h2>
          <div className='row request-id'>
            <div className='box'>
              <div className='title'>{t('Request ID')}</div>
              <div className='num semi-bold'>{scanRequestID}</div>
            </div>
          </div>
        </div>
        <div className='how-medmo-works'>
          <div className='row info'>
            <div className='circle-container'>
              <div className='circle'>
                <span className='number semi-bold'>1</span>
              </div>
            </div>
            <div className='texts'>
              <div className='row point-title semi-bold'>
                <span>{t('medmo_works.one.title')}</span>
              </div>
              <p>{t('medmo_works.one.text')}</p>
            </div>
          </div>
          <div className='row info'>
            <div className='circle-container'>
              <div className='circle'>
                <span className='number semi-bold'>2</span>
              </div>
            </div>
            <div className='texts'>
              <div className='row point-title semi-bold'>
                <span>{t('medmo_works.two.title')}</span>
              </div>
              <p>{t('medmo_works.two.text')}</p>
            </div>
          </div>
          <div className='row info'>
            <div className='circle-container'>
              <div className='circle'>
                <span className='number semi-bold'>3</span>
              </div>
            </div>
            <div className='texts'>
              <div className='row point-title semi-bold'>
                <span>{t('medmo_works.three.title')}</span>
              </div>
              <p>{t('medmo_works.three.text')}</p>
            </div>
          </div>
        </div>
        <div className='btn-row row justify-content-between'>
          <button className='btn next' onClick={() => completeStepCallback({}, HANDOFF_STEP_NAMES.howMedmoWorks)}>
            {t('Got it', { ns: 'translations' })}
          </button>
        </div>
      </div>
      <InfoBox
        title={t('question.title', { ns: 'translations' })}
        content={
          <Trans
            i18nKey='question.text'
            values={{ email: 'support@medmo.com', phone: '8334463366', phone_format: '(833) 446-3366' }}
            components={{ linkTo: <a /> }}
          />
        }
      />
    </>
  );
};

export default HowMedmoWorks;
