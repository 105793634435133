import React from 'react';

export const HANDOFF_TOTAL_STEPS = 8;

export const HANDOFF_SESSION_STORAGE_KEYS = {
  currentStep: 'handoffCurrentStep',
  scanData: 'handoffScanData',
  errorSubmitting: 'handoffErrorSubmitting',
  isFirstLoad: 'handoffIsFirstLoad',
};

export const HAVE_QUESTIONS_HANDOFF_INFOBOX_DATA = {
  title: 'Have questions?',
  content: (
    <>
      Email us at <a href='mailto:support@medmo.com'>support@medmo.com</a> or call <a href='tel:8334463366'>(833) 446-3366</a> and we’ll get
      back to you within one business hour.
    </>
  ),
};

export const HANDOFF_STEP_NAMES = {
  confirmYourIdentity: 'CONFIRM_YOUR_IDENTITY',
  confirmYourIdentityAdSection: 'CONFIRM_YOUR_IDENTITY_AD_SECTION',
  howMedmoWorks: 'HOW_MEDMO_WORKS',
  login: 'LOGIN',
  createAccount: 'CREATE_ACCOUNT',
  patientLocation: 'PATIENT_LOCATION',
  selectAppointmentDatetimes: 'SELECT_APPOINTMENT_DATETIMES',
  howToCoverAppointment: 'HOW_TO_COVER_APPOINTMENT',
  insuranceInformation: 'INSURANCE_INFORMATION',
  paymentInformation: 'PAYMENT_INFORMATION',
  reviewScanRequest: 'REVIEW_SCAN_REQUEST',
  generalError: 'GENERAL_ERROR',
};
