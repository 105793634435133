import React, { useState } from 'react';
import InfoBox from '../info-box';
import { FormError } from '../../models/General';
import { Form, Schema } from 'rsuite';
import TextField from '../text-field-component';
import { PING_STEP_NAMES, PingScanRequest } from '../../views/patient-ping/utils';
import { Trans, useTranslation } from 'react-i18next';

const { StringType } = Schema.Types;

type PreferredEmailProps = {
  goToPreviousStep: (stepName?: string) => void;
  completeStepCallback: (partialScanData: Partial<PreferredEmailForm>, stepName?: string) => void;
  agentInfo?: { CxName: string; CxPhone: string; CxEmail: string };
  stepName?: string;
  scanRequestData: PingScanRequest;
};

type PreferredEmailForm = {
  Email: string;
};

const PreferredEmailSchema = Schema.Model({
  Email: StringType().isEmail('Please enter a valid email format.'),
});

const PreferredEmail: React.FunctionComponent<PreferredEmailProps> = ({
  scanRequestData,
  goToPreviousStep,
  completeStepCallback,
  agentInfo,
  stepName = PING_STEP_NAMES.preferredEmail,
}) => {
  const [formValue, setFormValue] = useState<PreferredEmailForm>({
    Email: scanRequestData.Email,
  });
  const [formError, setFormError] = useState<FormError>({});
  const { t } = useTranslation('handoff');

  const submit = () => {
    const result = {
      Email: formValue.Email,
    };
    completeStepCallback(result, stepName);
  };

  const onErrorHandler = (error: any) => {
    if (error && error.Email) {
      const message = t(`email.error`, { ns: 'handoff', defaultValue: '' });
      return setFormError({ ...error, Email: message });
    }
    setFormError(error);
  };

  return (
    <>
      <div className='main-request-form main-request-form_size'>
        <h2 id='Additional_questions' className='h2'>
          {t('email.title')}
        </h2>
        <div className='text-form'>
          <p>
            <Trans ns='handoff' i18nKey='email.sub-title' components={{ b: <b /> }} />
          </p>
        </div>
        <Form
          model={PreferredEmailSchema}
          onChange={formValue => setFormValue(formValue as PreferredEmailForm)}
          formValue={formValue}
          onCheck={error => onErrorHandler(error)}
          fluid
          noValidate
        >
          <div className='input-wrap mb32'>
            <TextField label={t('email.placeholder')} autoComplete='off' value={formValue.Email} name='Email' error={formError.Email} />
          </div>
          <div className='btn-row row justify-content-between full-mob'>
            <button className='btn btn-white back no-arrow' onClick={() => goToPreviousStep(stepName)}>
              {t('form.Back', { ns: 'translations' })}
            </button>
            <button className='btn next no-arrow' disabled={!!Object.keys(formError).length} onClick={submit}>
              {t('form.Next', { ns: 'translations' })}
            </button>
          </div>
        </Form>
      </div>
      {agentInfo ? (
        <InfoBox
          title={t('box.HAVE_QUESTIONS_PING_PATIENT_INFOBOX_DATA.title', { ns: 'translations' })}
          content={
            <Trans
              i18nKey='box.HAVE_QUESTIONS_PING_PATIENT_INFOBOX_DATA.content'
              values={{
                CxName: agentInfo?.CxName || '[CX First Name]',
                CxPhone: agentInfo?.CxPhone || '[CX phone]',
                CxEmail: agentInfo?.CxEmail || '[CX email]',
              }}
              components={{ linkTo: <a /> }}
            />
          }
        />
      ) : null}
    </>
  );
};

export default PreferredEmail;
