import React, { useEffect, useState } from 'react';
import LoaderComponent from '../../components/loader-component';
import { useParams } from 'react-router';
import { FilePreviewer } from '../../components/file-viewer';
import { getFileURL } from '../../api/pre-appointment/PreAppointmentAPI';

const FileProcess: React.FunctionComponent = () => {
  const { typeID, key } = useParams<{ typeID: string; key: string }>();
  const [isLoading, setIsLoading] = useState(true);
  const [URL, setUrl] = useState('');

  useEffect(() => {
    getFileURL(Number(typeID), key)
      .then(data => setUrl(data.URL))
      .finally(() => setIsLoading(false));
  }, []);

  if (isLoading) {
    return <LoaderComponent className='loader-block-center' />;
  }

  return <FilePreviewer file={{ url: URL }} />;
};

export default FileProcess;
