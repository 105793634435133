import { PingScanRequest } from '../../views/patient-ping/utils';

export type PulmonaryConditionsComponentProps = {
  goToPreviousStep: (stepName?: string) => void;
  scanRequestData: PingScanRequest;
  completeStepCallback: (partialScanData: Partial<PingScanRequest>, stepName?: string) => void;
};

export const initPulmonaryData = {
  HasCOPD: false,
  HasLungFibrosis: false,
  HasAsthma: false,
  HasNoneOfTheAbovePulmonary: false,
};

export type PulmonaryData = {
  HasCOPD: boolean;
  HasLungFibrosis: boolean;
  HasAsthma: boolean;
  HasNoneOfTheAbovePulmonary: boolean;
};
