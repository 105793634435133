import React, { useState } from 'react';
import { Checkbox, Form } from 'rsuite';
import { MAMMOGRAM_STEP_NAMES, MammogramData, MammogramProps, MammogramQuestionsForm } from '../interfaces';
import { useTranslation } from 'react-i18next';

const MammogramQuestions: React.FunctionComponent<MammogramProps> = ({
  goToPreviousStep,
  scanRequestData,
  completeStepCallback,
}: MammogramProps) => {
  const { t } = useTranslation('handoff');
  const { HaveAnyBreastImplants, HaveAnyFamilyHistoryOfBreastCancer } = scanRequestData;
  const [formValue, setFormValue] = useState<MammogramQuestionsForm>({
    HaveAnyBreastImplants,
    HaveAnyFamilyHistoryOfBreastCancer,
  });

  const onNextHandler = (): void => {
    const { HaveAnyBreastImplants, HaveAnyFamilyHistoryOfBreastCancer } = formValue;
    const payload: Partial<MammogramData> = {
      HaveAnyBreastImplants,
      HaveAnyFamilyHistoryOfBreastCancer,
    };

    completeStepCallback(payload, MAMMOGRAM_STEP_NAMES.questions);
  };

  return (
    <div className='main-request-form'>
      <h2 id='When_would_you_like_your_appointment' className='h2'>
        {t('mammogram.add_title')}
      </h2>
      <Form onChange={value => setFormValue(value as MammogramQuestionsForm)} formValue={formValue} className='main-form-appointment' fluid>
        <div className='input-checkbox_wrap'>
          <div className='input-checkbox mb8'>
            <Checkbox
              checked={formValue.HaveAnyBreastImplants}
              onChange={() =>
                setFormValue(previous => ({
                  ...previous,
                  HaveAnyBreastImplants: !previous.HaveAnyBreastImplants,
                }))
              }
            >
              {t('mammogram.Do you have any breast implants?')}
            </Checkbox>
          </div>
          <div className='input-checkbox mb8'>
            <Checkbox
              checked={formValue.HaveAnyFamilyHistoryOfBreastCancer}
              onChange={() =>
                setFormValue(previous => ({
                  ...previous,
                  HaveAnyFamilyHistoryOfBreastCancer: !previous.HaveAnyFamilyHistoryOfBreastCancer,
                }))
              }
            >
              {t('mammogram.Do you have any family history of breast cancer?')}
            </Checkbox>
          </div>
        </div>
        <div className='btn-row row full-mob justify-content-between'>
          <button className='btn btn-white back no-arrow' onClick={() => goToPreviousStep(MAMMOGRAM_STEP_NAMES.questions)}>
            {t('form.Back', { ns: 'translations' })}
          </button>
          <button className='btn next no-arrow' onClick={onNextHandler}>
            {t('form.Next', { ns: 'translations' })}
          </button>
        </div>
      </Form>
    </div>
  );
};

export default MammogramQuestions;
