import { SCAN_REQUEST_INITIAL_DATA } from '../patient-scan-request/PatientScanRequestUtils';
import React from 'react';
import { ScanRequest } from '../../models/ScanRequest';
import { initMammogramData, MammogramData } from '../../components/mammogram/interfaces';
import { ContrastData, initContrastData } from '../../components/contrast-component/interfaces';
import { MammogramData as MammogramDataBreast } from '../../components/mammogram-breast/interfaces';
import { initMetalBodyData, MetalBodyData, PING_METAL_STEP_NAMES } from '../../components/metal-body-wrapper/interfaces';
import { AssistanceData, initAssistanceData } from '../../components/assistance/interfaces';
import { AvailableData, initAvailability } from '../../components/availability/interfaces';
import { initPacemakerDetailsData, PacemakerDetailsForm } from '../../components/pacemaker-wrapper/interfaces';
import { ClaustrophobiaData, initClaustrophobiaBodyData } from '../../components/claustrophobia/interfaces';
import { DiabetesData, initDiabetesBodyData } from '../../components/diabetes/interfaces';
import { initSurgeryBodyData, SurgeryData } from '../../components/surgery/interfaces';
import { AllergiesData, initAllergiesData } from '../../components/allergies/interfaces';
import { initPulmonaryData, PulmonaryData } from '../../components/pulmonary/interfaces';
import { initPhysicalSymptomsData, PhysicalSymptomsData } from '../../components/physical-symptoms/interfaces';
import { ChemotherapyData, initChemotherapyBodyData } from '../../components/chemotherapy/interfaces';
import { HistoryOfCancerData, initCancerBodyData } from '../../components/cancer-history/interfaces';
import { initSmokerBodyData, SmokerData } from '../../components/smoking-habits/interfaces';

const PING_SCAN_REQUEST_INITIAL_DATA: PingScanRequest = {
  ...initAvailability,
  ...initAllergiesData,
  ...initAssistanceData,
  ...initDiabetesBodyData,
  ...initSurgeryBodyData,
  ...initPacemakerDetailsData,
  ...initClaustrophobiaBodyData,
  ...initMetalBodyData,
  ...initContrastData,
  ...initMammogramData,
  ...initPulmonaryData,
  ...initPhysicalSymptomsData,
  ...initChemotherapyBodyData,
  ...initCancerBodyData,
  ...initSmokerBodyData,
  ...SCAN_REQUEST_INITIAL_DATA,
  OtherSymptoms: false,
  HeightFeet: '',
  HeightInches: '',
  WeightPounds: '',
  ConsentAccept: -1,
  MetalBodyQuestionID: null,
  Medications: null,
  GeneralAnswer: '',
  PaymentID: '',
  PaymentSecret: '',
  CardType: '',
  CardLast4: 0,
  FullNameOnCard: '',
  CardExpirationDate: '',
  Email: '',
  AppointmentDetails: '',
};

export const transformPingData = (data: any): Partial<PingScanRequest> => {
  return {
    HomeAddressData: {
      Address1: data?.Address1,
      Address2: data?.Address2,
      City: data?.City,
      State: data?.State,
      Zip: data?.ZipCode,
      RawString: data?.RawString,
      Link: data?.Link,
      TimeZone: data?.TimeZone,
      Longitude: data?.Longitude,
      Latitude: data?.Latitude,
    },
    HomeAddressString: data?.RawString,
    SecondaryAddressData: {
      Address1: data?.Address1_2,
      Address2: data?.Address2_2,
      City: data?.City_2,
      State: data?.State_2,
      Zip: data?.ZipCode_2,
      RawString: data?.RawString_2,
      Link: data?.Link_2,
      TimeZone: data?.TimeZone_2,
      Longitude: data?.Longitude_2,
      Latitude: data?.Latitude_2,
    },
    SecondaryAddressString: data?.RawString_2,
    ShouldFindNearHomeAddress: data?.RawString_2 ? 0 : 1,
    HeightFeet: data?.HeightFeet,
    HeightInches: data?.HeightInches,
    WeightPounds: data?.WeightPlbs,
    ConsentAccept: data?.ConfirmedROI,
    CovidResponseID: data?.CovidResponseID,
    Wheelchair: data?.Wheelchair,
    Walker: data?.Walker,
    Interpreter: data?.Interpreter,
    NoAssistance: data?.NoAssistance,
    OtherAssistance: data?.OtherAssistance,
    AssistanceDetails: data?.AssistanceDetails,
    MetalBodyQuestionID: data?.HasMetal,
    HasTitaniumRod: data?.TitaniumRod,
    HasEyeInjury: data?.EyeInjury,
    HasAnheurysmClips: data?.Aneurysm,
    HasOtherMetalInBody: data?.OtherMetal,
    HasNotSure: data?.NotSure,
    MetalInfo: data?.MetalInBodyInfo,
    MetalLocation: data?.MetalInBodyLocation,
    HasPacemakerOrDefibrillatorInBody: data?.HasPaceMaker,
    PacemakerFileKey: data?.PathToFile,
    PaceMakerDetails: data?.PaceMakerDetails,
    HasClaustrophobia: data?.HasClautrophobia,
    ClaustrophobiaDetails: data?.ClautrophobiaDetails,
    SymptomsNotes: data?.ApplicableDetails,
    IsBreastPain: data?.BreastPain,
    IsNippleDischarge: data?.NippleDischarge,
    IsLumpsInYourBreast: data?.LumpsInBreast,
    HaveNotSymptoms: data?.NoneApply,
    HaveAnyBreastImplants: data?.BreastImplants,
    OtherSymptoms: data?.OtherSymptoms,
    HaveAnyFamilyHistoryOfBreastCancer: data?.HistoryBreastCancer,
    HasDiabetes: data?.HasDiabetes,
    DiabetesType: data?.DiabetesType,
    OnBodyGlucoseMonitor: data?.OnBodyGlucoseMonitor,
    HaveNotSymptomsOfContrast: data?.NoneOfTheAbove,
    SymptomsNotesOfContrast: data?.AllergiesDetails,
    HasAllergicToIodine: data?.AllergyIodine,
    HasHighBloodPressure: data?.HighBP,
    HasHistoryOfCancer: data?.HistoryOfCancer,
    HasHistoryOfKidney: data?.HistoryOfKidneyProblems,
    OtherAllergies: data?.OtherAllergies,
    Medications: data?.CurrentMedications,
    SurgeryPast90Days: data?.SurgeryPast90Days,
    SurgeryDetails: data?.SurgeryDetails,
    AppointmentDetails: data?.SchedulingDetails,
    LastMammogramResponseID: data?.LastMammogram,
    AvailableMammogramDatetime: data?.LastMammogramDT,
    LocationNotes: data?.LastMammogramLocation,
    Chemotherapy: data?.Chemotherapy,
    CompleteChemotherapy: data?.CompleteChemotherapy,
    HistoryOfCancer: data?.HistoryOfCancer,
    HistoryOfCancerDetails: data?.HistoryOfCancerDetails,
    IsSmoker: data?.IsSmoker,
    CigarettesPerDay: data?.CigarettesPerDay,
    ActiveSmokerYears: data?.ActiveSmokerYears,
    HasHighFever: data?.HighFever,
    HasWeightLoss: data?.WeightLoss,
    HasBloodWhileCoughing: data?.BloodWhileCoughing,
    HasCOPD: data?.COPD,
    HasLungFibrosis: data?.LungFibrosis,
    HasAsthma: data?.Asthma,
  };
};

export const PATIENT_PING_INITIAL_DATA = {
  currentStep: 0,
  scanRequestData: PING_SCAN_REQUEST_INITIAL_DATA,
};

export const PATIENT_PING_INSURANCE_IMAGES_ADD_INFO = {
  title: 'Please provide images of your insurance card',
  uploadCardText: 'Upload your insurance card to speed up the booking process.',
  isOnlyUploadEnabled: true,
};

export const PING_STEP_NAMES = {
  confirmYourIdentity: 'CONFIRM_YOUR_IDENTITY',
  confirmYourIdentityAdSection: 'CONFIRM_YOUR_IDENTITY_AD_SECTION',
  getStarted: 'GET_STARTED',
  covid: 'COVID',
  heightAndWeight: 'HEIGHT_AND_WEIGHT',
  preferredEmail: 'PREFERRED_EMAIL',
  appointmentDetails: 'APPOINTMENT_DETAILS',
  availability: 'AVAILABILITY',
  roiConsent: 'ROI_CONSENT',
  ...PING_METAL_STEP_NAMES,
  contrastQuestion: 'CONTRAST_QUESTION',
  medications: 'MEDICATIONS',
  pulmonary: 'PULMONARY_CONDITIONS',
  physicalSymptoms: 'PHYSICAL_SYMPTOMS',
};

export const HAVE_QUESTIONS_PING_PATIENT_INFOBOX_DATA = {
  title: 'Need help?',
  content: (CxName = '[CX First Name]', CxPhone = '[CX phone]', CxEmail = '[Cx Email]') => (
    <>
      If you have any questions or need help you can get in touch with your Care Team member {CxName} here{' '}
      <a href={`mailto:${CxEmail}`}>{CxEmail}</a> or by calling {CxPhone}.
    </>
  ),
};

export const WHY_DO_YOU_NEED_INFOBOX_DATA = {
  title: 'Why do you need this?',
  content: () => (
    <>
      This information is helpful the imaging center to know in advance of your appointment if you have it. <br />
      <br />
      We take the security and confidentiality of your personal health information seriously, and follow all relevant standards including
      HIPAA to protect it.
    </>
  ),
};

export type PingScanRequest = ScanRequest &
  MammogramData &
  MammogramDataBreast &
  PacemakerDetailsForm &
  DiabetesData &
  SurgeryData &
  ClaustrophobiaData &
  MetalBodyData &
  AssistanceData &
  AvailableData &
  AllergiesData &
  PulmonaryData &
  PhysicalSymptomsData &
  ChemotherapyData &
  HistoryOfCancerData &
  SmokerData &
  ContrastData & {
    HeightFeet: number | string;
    HeightInches: number | string;
    WeightPounds: number | string;
    ConsentAccept: string | number;
    MetalBodyQuestionID: number | null;
    GeneralAnswer: string;
    Email: string;
    AppointmentDetails: string;
    Medications: number | null;
  };

const cleanedNumbers = (numbers: string) => {
  return ('' + numbers).replace(/\D/g, '');
};

export const formatPhoneNumber = (phoneNumberString: string) => {
  const cleaned = cleanedNumbers(phoneNumberString);
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ')' + match[2] + '-' + match[3];
  }
  return null;
};
