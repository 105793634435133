import React, { SyntheticEvent, useCallback, useState } from 'react';
import { FileType } from 'rsuite/Uploader';
import { Trans, useTranslation } from 'react-i18next';
import { MetalBodyGeneralProps, PING_METAL_STEP_NAMES } from '../interfaces';
import LoaderComponent from '../../loader-component';
import FilesUploader from '../../files-uploader';
import { FileResponse } from '../../../models/FileResponse';
import { uploadPacemakerFile } from '../../../api/patient-ping/PatientPingAPI';
import { UPLOADING_PRESCRIPTION_ERROR } from '../../upload-prescription/utils';
import { InfoModalTexts } from '../../../models/General';
import InfoModalComponent from '../../modals/info-modal';
import InfoBox from '../../info-box';

const UploadPacemaker: React.FunctionComponent<MetalBodyGeneralProps> = ({
  goToPreviousStep,
  completeStepCallback,
  infoBox,
  scanRequestData,
  stepName = PING_METAL_STEP_NAMES.metalBodyUploadPacemaker,
}) => {
  const [pacemakerFiles, setPacemakerFiles] = useState<FileType[]>(scanRequestData.pacemakerFile ? [scanRequestData.pacemakerFile] : []);
  const [isLoading, setIsLoading] = useState(false);
  const [errorDataForModal, setErrorDataForModal] = useState<InfoModalTexts | null>(null);
  const { t } = useTranslation('handoff');

  const filesSelectedCallback = useCallback(
    (files: FileType[]) => {
      setPacemakerFiles(files);
    },
    [pacemakerFiles],
  );

  const handleConfirm = async (event: SyntheticEvent): Promise<void> => {
    event.preventDefault();
    if (
      pacemakerFiles.length ||
      (pacemakerFiles.length && scanRequestData.pacemakerFile && scanRequestData.pacemakerFile.fileKey !== pacemakerFiles[0].fileKey)
    ) {
      return await uploadFile();
    } else if (!pacemakerFiles.length && scanRequestData.pacemakerFile && scanRequestData.pacemakerFile.fileKey) {
      const payload = {
        PacemakerFileKey: '',
        pacemakerFile: null,
      };
      return completeStepCallback(payload, PING_METAL_STEP_NAMES.metalBodyUploadPacemaker);
    }
    completeStepCallback({}, PING_METAL_STEP_NAMES.metalBodyUploadPacemaker);
  };

  const uploadFile = async (): Promise<void> => {
    const fileData = new FormData();
    setIsLoading(true);
    const { blobFile } = pacemakerFiles[0];
    if (!blobFile) {
      return;
    }
    fileData.append('file', blobFile);
    uploadPacemakerFile(fileData)
      .then(fileUploaded => handleFileUploaded(fileUploaded))
      .catch(() => {
        setErrorDataForModal(UPLOADING_PRESCRIPTION_ERROR);
        setIsLoading(false);
      });
  };

  const handleFileUploaded = (fileUploaded: FileResponse): void => {
    const payload = {
      PacemakerFileKey: fileUploaded.key,
      pacemakerFile: pacemakerFiles[0],
    };
    completeStepCallback(payload, stepName);
  };

  if (isLoading) {
    return <LoaderComponent className='loader-block-center' />;
  }

  return (
    <>
      <div className='main-request-form'>
        <h2 id='Please_upload_your_imaging_prescription_referral' className='h2'>
          {t('metal.Please upload your pacemaker or defibrillator documents')}
        </h2>
        <div className='text-form'>
          <p>
            <Trans ns='handoff' i18nKey='metal.pacemaker_text' components={{ b: <b /> }} />
          </p>
        </div>
        <div className='main-request-file'>
          <FilesUploader onChange={filesSelectedCallback} files={pacemakerFiles} multiple={false}>
            {<Trans ns='handoff' i18nKey='metal.Upload documents' components={{ b: <strong /> }} />}
          </FilesUploader>
          <div className='request-type-payments row'>
            <span className='icon-secure'></span>
            {t('metal.secure_msg')}
          </div>
          <div className='btn-row row justify-content-between full-mob'>
            <button className='btn btn-white back' onClick={() => goToPreviousStep(stepName)}>
              {t('form.Back', { ns: 'translations' })}
            </button>
            <button className='btn next' onClick={handleConfirm}>
              {t('form.Next', { ns: 'translations' })}
            </button>
          </div>
        </div>
      </div>
      {infoBox ? <InfoBox title={infoBox.title} content={infoBox.content} /> : null}
      {errorDataForModal && <InfoModalComponent type='error' texts={errorDataForModal} onClose={() => setErrorDataForModal(null)} />}
    </>
  );
};

export default UploadPacemaker;
