import React, { useState } from 'react';
import { Form } from 'rsuite';
import TextField from '../../text-field-component';
import Textarea from '../../textarea';
import InfoBox from '../../info-box';
import { useTranslation } from 'react-i18next';
import { ClaustrophobiaDetailsForm, MetalBodyGeneralProps, PING_CLAUSTROPHOBIA_STEP_NAMES } from '../interfaces';

const ClaustrophobiaGeneralDetails: React.FunctionComponent<MetalBodyGeneralProps> = ({
  goToPreviousStep,
  completeStepCallback,
  scanRequestData,
  infoBox,
  stepName = PING_CLAUSTROPHOBIA_STEP_NAMES.claustrophobiaDetails,
}) => {
  const { t } = useTranslation('handoff');
  const { ClaustrophobiaDetails = null } = scanRequestData;

  const [formValue, setFormValue] = useState<ClaustrophobiaDetailsForm>({
    ClaustrophobiaDetails,
  });

  const selectResponse = (): void => {
    completeStepCallback({ ClaustrophobiaDetails: formValue.ClaustrophobiaDetails }, PING_CLAUSTROPHOBIA_STEP_NAMES.claustrophobiaDetails);
  };

  return (
    <>
      <div className='main-request-form'>
        <h2 id='Additional_questions' className='h2'>
          {t('claustrophobia.Please provide details regarding your claustrophobia')}
        </h2>
        <div className='text-form fw600'>
          <p>{t('claustrophobia.If youre not sure, please click Next to continue.')}</p>
        </div>
        <Form
          onChange={value => setFormValue(value as ClaustrophobiaDetailsForm)}
          formValue={formValue}
          className='main-form-appointment'
          fluid
        >
          <div className='input-wrap textarea-wrap mb32'>
            <TextField
              name='ClaustrophobiaDetails'
              value={formValue.ClaustrophobiaDetails}
              placeholder={t('claustrophobia.Im not able to be in a closed setting for more than 30 minutes')}
              accepter={Textarea}
            />
          </div>
        </Form>
        <div className='btn-row full-mob row justify-content-between'>
          <button onClick={() => goToPreviousStep(stepName)} className='btn prev btn-white'>
            {t('form.Back', { ns: 'translations' })}
          </button>
          <button className='btn next' onClick={() => selectResponse()}>
            {t('form.Next', { ns: 'translations' })}
          </button>
        </div>
      </div>
      {infoBox ? <InfoBox title={infoBox.title} content={infoBox.content} /> : null}
    </>
  );
};

export default ClaustrophobiaGeneralDetails;
