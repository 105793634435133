import React, { SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { saveAs } from 'file-saver';
import LoaderComponent from '../../components/loader-component';
import { editScanById, getScanById } from '../../api/patient-scan-request/PatientScanRequestAPI';
import EditScanPart from './scan-part-edit-component';
import { ScanManagmentUrl } from '../../config/UrlsConfig';
import { ScanRequest } from '../../models/ScanRequest';
import { ScanEditType, updateData } from './utils';
import { FilePreviewerThumbnail } from '../../components/file-viewer';
import FileViewer from '../../components/doc-viewer-modal';
import InfoModalComponent from '../../components/modals/info-modal';
import { InfoModalTexts } from '../../models/General';
import { FileResponse } from '../../models/FileResponse';
import { EMPTY_NON_CONFIRMED_SCAN_TYPE_SELECTION, SCAN_REQUEST_INITIAL_DATA } from '../patient-scan-request/PatientScanRequestUtils';
import { NonConfirmedScanTypeSelection } from '../patient-scan-request/interfaces';
import PatientLocation from '../../components/patient-location';
import SelectScanType from '../../components/select-scan-type';
import ReviewScanTypeSelection from '../../components/review-scan-type-selection';
import ReasonForExam from '../../components/reason-for-exam';
import SelectAppointmentDatetimes from '../../components/select-appointment-datetimes';
import UploadPrescription from '../../components/upload-prescription';
import SelectPhysician from '../../components/select-physician';
import { Tooltip, Whisper } from 'rsuite';
import { Address } from '../../models/Address';
import PaymentMethodComponent from '../../components/payment-method-component';
import { EDIT_SOURCE } from '../../utils/GeneralUtil';

const ScanServiceEditComponent: React.FunctionComponent = () => {
  const [formData, setFormData] = useState<ScanRequest>(SCAN_REQUEST_INITIAL_DATA);
  const [currentStep, setCurrentStep] = useState<ScanEditType>(ScanEditType.All);
  const [isLoading, setIsLoading] = useState(false);
  const [currentFile, setCurrentFile] = useState<FileResponse>({
    url: '',
    originalName: '',
    key: '',
  });
  const [isShow, setIsShow] = useState<boolean>(false);
  const [nonConfirmedScanTypeSelection, setNonConfirmedScanTypeSelection] = useState<NonConfirmedScanTypeSelection>(
    EMPTY_NON_CONFIRMED_SCAN_TYPE_SELECTION,
  );
  const [error, setError] = useState<InfoModalTexts | null>(null);
  const { scanID } = useParams<{ scanID: string }>();
  const history = useHistory<{ pathname: string }>();
  const locationFrom = history.location?.state?.pathname;
  const madeChanges = useRef(false);

  useEffect(() => {
    setIsLoading(true);
    getScanById(scanID)
      .then(data => {
        const scan = updateData(data);
        setFormData(scan);
      })
      .catch(() => setFormData(SCAN_REQUEST_INITIAL_DATA))
      .finally(() => setIsLoading(false));
  }, []);

  const goToPreviousStep = () => {
    setCurrentStep(ScanEditType.All);
  };

  const nonConfirmedScanTypeSelectionCallback = useCallback(data => {
    setNonConfirmedScanTypeSelection(data);
  }, []);

  const goToStep = (step: ScanEditType) => {
    window.scroll(0, 0);
    setError(null);
    setCurrentStep(step);
  };

  const setPartialScanData = useCallback(
    (partialScanData: Partial<ScanRequest>) => {
      madeChanges.current = true;
      let data = { ...partialScanData };

      if (currentStep === ScanEditType.Availability) {
        data = {
          IsAvailableAnytime: data.IsAvailableAnytime,
          AvailableDatetimes: data.AvailableDatetimes,
          SelfPay: formData.SelfPay,
          LogisticalNotes: data.LogisticalNotes,
        };
      }

      if (currentStep === ScanEditType.Locations) {
        data = {
          SecondaryAddressData: data.SecondaryAddressData,
          HomeAddressData: data.HomeAddressData,
          HasSecondAddress: data.ShouldFindNearHomeAddress ? 0 : 1,
        };
      }

      if (currentStep === ScanEditType.Rx) {
        delete data.RXFile;
        delete data.prescriptionFile;
      }

      if (currentStep === ScanEditType.Payment && formData.SelfPay === 0) {
        delete data.InsurancePlan;
        delete data.InsuranceCardBack;
        delete data.InsuranceCardFront;
      }

      editScanById(scanID, currentStep, data)
        .then(() => {
          setFormData(prev => ({
            ...prev,
            ...partialScanData,
          }));
          setCurrentStep(ScanEditType.All);
        })
        .catch(err =>
          setError({
            title: 'Error',
            content: err.message,
          }),
        );
    },
    [currentStep],
  );

  const previewFile = (e: SyntheticEvent, file: FileResponse) => {
    e.preventDefault();
    e.stopPropagation();
    setCurrentFile(file);
    setIsShow(true);
  };

  const onCloseFileViewer = (): void => {
    setIsShow(false);
  };

  const onDownload = () => {
    if (!currentFile.url) {
      return;
    }

    saveAs(currentFile.url, currentFile.fileTitle || currentFile.originalName);
  };

  const showCurrentStep = (): React.ReactNode | null => {
    switch (currentStep) {
      case ScanEditType.Locations:
        return (
          <PatientLocation
            source={EDIT_SOURCE}
            goToPreviousStep={goToPreviousStep}
            scanRequestData={formData}
            completeStepCallback={setPartialScanData}
          />
        );
      case ScanEditType.ScanTypeSetter:
        return (
          <SelectScanType
            goToNextStep={() => goToStep(ScanEditType.ScanType)}
            goToPreviousStep={goToPreviousStep}
            scanRequestData={formData}
            nonConfirmedScanTypeSelection={nonConfirmedScanTypeSelection}
            nonConfirmedScanTypeSelectionCallback={nonConfirmedScanTypeSelectionCallback}
          />
        );
      case ScanEditType.ScanType:
        return (
          <ReviewScanTypeSelection
            goToPreviousStep={() => goToStep(ScanEditType.ScanTypeSetter)}
            completeStepCallback={setPartialScanData}
            scanRequestData={formData}
            nonConfirmedScanTypeSelection={nonConfirmedScanTypeSelection}
            isEditMode={true}
          />
        );
      case ScanEditType.Reason:
        return <ReasonForExam goToPreviousStep={goToPreviousStep} scanRequestData={formData} completeStepCallback={setPartialScanData} />;
      case ScanEditType.Availability:
        return (
          <SelectAppointmentDatetimes
            goToPreviousStep={goToPreviousStep}
            scanRequestData={formData}
            completeStepCallback={setPartialScanData}
          />
        );
      case ScanEditType.Rx:
        return (
          <UploadPrescription goToPreviousStep={goToPreviousStep} scanRequestData={formData} completeStepCallback={setPartialScanData} />
        );
      case ScanEditType.Payment:
        return (
          <PaymentMethodComponent
            goToPreviousStep={goToPreviousStep}
            scanRequestData={formData}
            completeStepCallback={setPartialScanData}
            source={EDIT_SOURCE}
            isInsuranceRequired={true}
          />
        );
      case ScanEditType.Doctor:
        return <SelectPhysician goToPreviousStep={goToPreviousStep} scanRequestData={formData} completeStepCallback={setPartialScanData} />;
      default:
        return null;
    }
  };

  const renderSelectedDates = useCallback(() => {
    return formData.readableAvailableDatetimes?.map((readableDatetime, index) => <li key={index}>{readableDatetime}</li>);
  }, [formData.readableAvailableDatetimes]);

  const createAddressPart = (part1: string, part2: string, includeApt?: boolean): string => {
    if (includeApt) {
      return `${part1}${part1 && part2 ? ' Apt. ' : ''}${part2}`;
    }
    return `${part1}${part1 && part2 ? ' ' : ''}${part2}`;
  };

  const getFullAddress = (dataKey: keyof ScanRequest): string => {
    if (formData[dataKey]) {
      const { Address1, Address2, City, State, Zip } = formData[dataKey] as Address;
      const address1 = Address1 ? Address1.trim() : '';
      const address2 = Address2 ? Address2.trim() : '';
      const city = City ? City.trim() : '';
      const state = State ? State.trim() : '';
      const zip = Zip ? Zip.trim() : '';
      const addressArr = [];
      const part1 = createAddressPart(address1, address2, true);
      const part2 = createAddressPart(city, state);

      if (part1) {
        addressArr.push(part1);
      }

      if (part2) {
        addressArr.push(part2);
      }

      if (zip) {
        addressArr.push(zip);
      }

      return addressArr.join(', ');
    }

    return '';
  };

  const preparedAddress1 = useMemo(() => {
    const fullAddress1 = getFullAddress('HomeAddressData');

    if (fullAddress1) {
      return fullAddress1 + ' (Home)';
    }

    return '';
  }, [formData.HomeAddressData]);

  const preparedAddress2 = useMemo(() => {
    const fullAddress2 = getFullAddress('SecondaryAddressData');

    if (fullAddress2) {
      return fullAddress2 + ' (Near center)';
    }

    return '';
  }, [formData.SecondaryAddressData]);

  const prescriber = useMemo(() => {
    return `${formData.FirstName || ''} ${formData.LastName || ''} ${formData.Phone || ''}`;
  }, [formData.FirstName, formData.LastName, formData.Phone]);

  if (isLoading) {
    return <LoaderComponent />;
  }

  const getTooltipText = (isDisabled: boolean, sectionName: string) => {
    return `You can${isDisabled ? 'not' : ''} edit ${sectionName}`;
  };

  const goBack = () => {
    if (locationFrom) {
      history.push(locationFrom);
      return;
    }
    history.push(ScanManagmentUrl);
  };

  return (
    <section className='main-request-section'>
      <button className='edit-form-data back' onClick={goBack}>
        Go back<span className='icon icon-arrow-thin'></span>
      </button>
      <div className='container'>
        <div className='row'>
          {currentStep ? (
            showCurrentStep()
          ) : (
            <>
              <div className='scan-service-edit main-request-form'>
                <div className='scan-service-edit_header'>
                  <h1 className='h1'>What would you like to edit?</h1>
                  <div className='row request-id'>
                    <div className='box'>
                      <div className='title'>Request ID</div>
                      <div className='num'>{scanID}</div>
                    </div>
                  </div>
                </div>
                <div className='scan-details'>
                  <EditScanPart
                    className='scan-details_item'
                    titleClassName='scan-details_item_title'
                    label='Scan Information'
                    value={formData.SelectedScan}
                    tooltipHint={getTooltipText(!formData.AllowEditScanType, 'Scan Information')}
                    disabled={!formData.AllowEditScanType}
                    handleEdit={() => {
                      setNonConfirmedScanTypeSelection(EMPTY_NON_CONFIRMED_SCAN_TYPE_SELECTION);
                      goToStep(ScanEditType.ScanTypeSetter);
                    }}
                  />
                  <EditScanPart
                    className='scan-details_item'
                    titleClassName='scan-details_item_title'
                    label='Locations'
                    disabled={!formData.AllowEditLocations}
                    tooltipHint={getTooltipText(!formData.AllowEditLocations, 'Locations')}
                    values={[preparedAddress1, preparedAddress2]}
                    handleEdit={() => goToStep(ScanEditType.Locations)}
                  />
                  <div
                    className={`scan-details_item ${formData.AllowEditPayment === 0 ? 'disabled' : ''}`}
                    onClick={() => (formData.AllowEditPayment === 0 ? 0 : goToStep(ScanEditType.Payment))}
                  >
                    <Whisper
                      placement='top'
                      trigger='hover'
                      speaker={<Tooltip>{getTooltipText(formData.AllowEditPayment === 0, 'Payment')}</Tooltip>}
                    >
                      <div className='tooltip-mask'></div>
                    </Whisper>
                    <div className='scan-details_item_title'>Payment</div>
                    <div className='scan-details_item_value'>
                      {formData.SelfPay
                        ? formData.CardType
                          ? `${formData.CardType} ending in ${formData.CardLast4}`
                          : ''
                        : `${formData.InsurancePlan || ''} ${formData.PolicyNumber || ''}`}
                    </div>
                    {!formData.SelfPay && (
                      <div className='input-wrap'>
                        <div className='input-wrap-field'>
                          {(formData.InsuranceCardFront || formData.InsuranceCardBack) && (
                            <div className='payment-doc row'>
                              {formData.InsuranceCardFront && (
                                <FilePreviewerThumbnail
                                  style={{ width: '150px', height: '95px' }}
                                  file={formData.InsuranceCardFront}
                                  className='payment-doc-front'
                                  width={150}
                                  height={95}
                                  handleClick={(e: SyntheticEvent) => previewFile(e, formData.InsuranceCardFront as FileResponse)}
                                />
                              )}
                              {formData.InsuranceCardBack && (
                                <FilePreviewerThumbnail
                                  style={{ width: '150px', height: '95px' }}
                                  file={formData.InsuranceCardBack}
                                  className='payment-doc-front'
                                  width={150}
                                  height={95}
                                  handleClick={(e: SyntheticEvent) => previewFile(e, formData.InsuranceCardBack as FileResponse)}
                                />
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  <EditScanPart
                    className='scan-details_item'
                    titleClassName='scan-details_item_title'
                    label='Availability'
                    disabled={!formData.AllowEditAvailability}
                    tooltipHint={getTooltipText(!formData.AllowEditAvailability, 'Availability')}
                    value={
                      <>
                        <p>
                          {formData.IsAvailableAnytime === 1 ? (
                            'I’m flexible, anytime over the next two week'
                          ) : formData.IsAvailableAnytime === 0 ? (
                            <span className='data-text'>Specific dates</span>
                          ) : (
                            ''
                          )}
                        </p>
                        {formData.IsAvailableAnytime === 0 && formData.AvailableDatetimes && (
                          <ul className='appointment-field_data'>{renderSelectedDates()}</ul>
                        )}
                      </>
                    }
                    handleEdit={() => goToStep(ScanEditType.Availability)}
                  />
                  <EditScanPart
                    className='scan-details_item'
                    titleClassName='scan-details_item_title'
                    disabled={!formData.AllowEditReason}
                    tooltipHint={getTooltipText(!formData.AllowEditReason, 'Reason')}
                    label='Reason'
                    value={formData.Reason}
                    handleEdit={() => goToStep(ScanEditType.Reason)}
                  />
                  <EditScanPart
                    className='scan-details_item'
                    titleClassName='scan-details_item_title'
                    label='Physician information'
                    disabled={!formData.AllowEditDoctor}
                    tooltipHint={getTooltipText(!formData.AllowEditDoctor, 'Physician information')}
                    handleEdit={() => goToStep(ScanEditType.Doctor)}
                    value={prescriber}
                  />
                  <div
                    className={`scan-details_item ${formData.AllowEditRx === 0 ? 'disabled' : ''}`}
                    onClick={() => (formData.AllowEditRx === 0 ? 0 : goToStep(ScanEditType.Rx))}
                  >
                    <Whisper
                      placement='top'
                      trigger='hover'
                      speaker={<Tooltip>{getTooltipText(formData.AllowEditRx === 0, 'Prescription')}</Tooltip>}
                    >
                      <div className='tooltip-mask'></div>
                    </Whisper>
                    <div className='scan-details_item_title'>Prescription</div>
                    <div className='scan-details_item_value'>Attachments:</div>
                    {formData.RXFile && (
                      <div className='input-wrap'>
                        <div className='input-wrap-field'>
                          <div className='pdf-view'>
                            <FilePreviewerThumbnail
                              file={formData.RXFile}
                              className='pdf-img'
                              width={140}
                              height={180}
                              handleClick={(e: SyntheticEvent) => previewFile(e, formData.RXFile as FileResponse)}
                            />
                            {formData.RXFile?.fileTitle && (
                              <div className='pdf-name'>
                                <span>{formData.RXFile.fileTitle}</span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className='btn-row row justify-content-between'>
                  <button className='btn btn-white' onClick={() => history.push(ScanManagmentUrl)}>
                    Cancel
                  </button>
                </div>
              </div>
              {/* <InfoBox title={textsMap.editInfoTitle} content={textsMap.editInfoContent} /> */}
            </>
          )}
        </div>
      </div>
      {!!error && <InfoModalComponent type='error' texts={error} onClose={() => setError(null)} />}
      <FileViewer isShow={isShow} onDownloadFile={onDownload} file={currentFile} onClose={() => onCloseFileViewer()} />
    </section>
  );
};

export default ScanServiceEditComponent;
