export const HomeUrl = '/';
export const DashboardUrl = '/dashboard';
export const LoginUrl = '/login';
export const NotFoundUrl = '/not-found';
export const ImagingPartnersUrl = '/partner';
export const OrderingPhysiciansUrl = '/refer';
export const NewScanUrl = '/new-scan';
export const PrivacyUrl = '/privacy';
export const TermsUrl = '/terms';
export const QualityUrl = '/quality';
export const ForgotPasswordUrl = '/forgot-password';
export const ResetPasswordUrl = '/reset/:token';
export const CreateAccountUrl = '/create-account';
export const ScanServiceEditUrl = '/appointments/:scanID';
export const ScanManagmentUrl = '/appointments';
export const SettingsUrl = '/settings';
export const EditInfoUrl = '/settings/edit-info';
export const EditPasswordUrl = '/settings/edit-password';
export const PatientProviderAgreementInsuranceUrl = '/patient-provider/insurance';
export const PatientProviderAgreementSelfPayUrl = '/patient-provider/self-pay/:scanServiceID';
export const HandoffProcessUrl = '/handoff-process/:invitationID';
export const SolvUrl = '/solv';
export const HealthyrUrl = '/healthyr';
export const PatientPingProcessUrl = '/form/:invitationID';
export const AddPatientPingProcessUrl = '/p/:invitationID';
export const PreAppointmentProcessUrl = '/pa/:invitationID';
export const SendFilesProcessUrl = '/sf/:invitationID';
export const FileProcessUrl = '/file/:typeID/:key';
export const BaaUrl = '/baa';
export const CleerlyUrl = '/cleerly';
export const SmsUnsubscribeUrl = '/sms/:hash';
