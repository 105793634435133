import { PingScanRequest } from '../../views/patient-ping/utils';
import React from 'react';

export const PING_CLAUSTROPHOBIA_STEP_NAMES = {
  claustrophobia: 'CLAUSTROPHOBIA',
  claustrophobiaDetails: 'CLAUSTROPHOBIA_DETAILS',
};

export type ClaustrophobiaWrapperProps = {
  goToPreviousStep: (stepName?: string) => void;
  scanRequestData: PingScanRequest;
  completeStepCallback: (partialScanData: Partial<PingScanRequest>, stepName?: string) => void;
};

export const initClaustrophobiaData = {
  HasClaustrophobia: null,
};

export const initClaustrophobiaDetailsData = {
  ClaustrophobiaDetails: '',
};

export const initClaustrophobiaBodyData = {
  ...initClaustrophobiaData,
  ...initClaustrophobiaDetailsData,
};

export const initClaustrophobiaGeneralData = {
  data: initClaustrophobiaBodyData,
  currentStep: 1,
  errorSubmitting: false,
};

export type ClaustrophobiaBodyForm = {
  HasClaustrophobia: number | null;
};

export type ClaustrophobiaDetailsForm = {
  ClaustrophobiaDetails: string | null;
};

export type ClaustrophobiaData = ClaustrophobiaBodyForm & ClaustrophobiaDetailsForm;

export type ClaustrophobiaGeneralData = {
  data: ClaustrophobiaData;
  currentStep: number;
  errorSubmitting: boolean;
};

export type MetalBodyGeneralProps = {
  goToPreviousStep: (stepName?: string) => void;
  completeStepCallback: (partialScanData: Partial<PingScanRequest>, stepName?: string) => void;
  infoBox?: { title: string; content: React.ReactNode };
  stepName: string;
  scanRequestData: Partial<PingScanRequest>;
};

export const Questions = [
  { ID: 1, Response: 'Yes' },
  { ID: 0, Response: 'No' },
  { ID: 2, Response: 'Not sure' },
];
