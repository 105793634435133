import {
  LoginUrl,
  NotFoundUrl,
  HomeUrl,
  ImagingPartnersUrl,
  OrderingPhysiciansUrl,
  NewScanUrl,
  CreateAccountUrl,
  ForgotPasswordUrl,
  PrivacyUrl,
  TermsUrl,
  QualityUrl,
  ScanServiceEditUrl,
  ScanManagmentUrl,
  SettingsUrl,
  EditInfoUrl,
  EditPasswordUrl,
  ResetPasswordUrl,
  PatientProviderAgreementInsuranceUrl,
  PatientProviderAgreementSelfPayUrl,
  HandoffProcessUrl,
  AddPatientPingProcessUrl,
  PatientPingProcessUrl,
  BaaUrl,
  DashboardUrl,
  SolvUrl,
  CleerlyUrl,
  SmsUnsubscribeUrl,
  HealthyrUrl,
  PreAppointmentProcessUrl,
  SendFilesProcessUrl,
  FileProcessUrl,
} from '../config/UrlsConfig';
import NotFoundComponent from '../views/not-found';
import ImagingPartnerComponent from '../views/imaging-partner';
import OrderingPhysicianComponent from '../views/ordering-physician';
import PrivacyComponent from '../views/privacy';
import TermsComponent from '../views/terms';
import QualityComponent from '../views/quality';
import CreateAccountComponent from '../views/create-account';
import LoginComponent from '../views/login';
import PatientScanRequest from '../views/patient-scan-request';
import ScanServiceEditComponent from '../views/scan-edit';
import ScanManagementComponent from '../views/scan-management';
import SettingsComponent from '../views/settings';
import EditPersonalInfoComponent from '../views/settings/EditPersonalInfoComponent';
import EditPasswordComponent from '../views/settings/EditPasswordComponent';
import PatientProviderAgreementInsurance from '../views/patient-provider-agreement-insurance';
import PatientProviderAgreementSelfPay from '../views/patient-provider-agreement-self-pay';
import { CreateNewPasswordComponent, ForgotPasswordComponent } from '../views/forgot-password';
import WrapperHandoffProcess from '../views/handoff-process';
import BaaComponent from '../views/baa';
import DashboardComponent from '../views/dashboard';
import PatientPingProcess from '../views/patient-ping';
import SolvComponent from '../views/solv';
import CleerlyComponent from '../views/cleerly';
import SmsUnsubscribeComponent from '../views/sms';
import HealthyrComponent from '../views/healthyr';
import PreAppointmentProcess from '../views/pre-appointment';
import FileProcess from '../views/file-process';

const defaultPredicate = () => true;
const defaultFalsePredicate = () => false;
const authorizedPredicate = isAuthenticated => isAuthenticated;
const unauthorizedPredicate = isAuthenticated => !isAuthenticated;

const authorizedRoutePart = [];

const unauthorizedRoutePart = [
  {
    path: HomeUrl,
    exact: true,
    component: PatientScanRequest,
    isActive: unauthorizedPredicate,
    isGeneral: defaultFalsePredicate,
    isIntroductory: defaultFalsePredicate,
  },
  {
    path: NewScanUrl,
    exact: true,
    component: PatientScanRequest,
    isActive: authorizedPredicate,
    isGeneral: defaultFalsePredicate,
    isIntroductory: defaultFalsePredicate,
  },
  {
    path: DashboardUrl,
    exact: true,
    component: DashboardComponent,
    isActive: authorizedPredicate,
    isGeneral: defaultFalsePredicate,
    isIntroductory: defaultFalsePredicate,
  },
  {
    path: NotFoundUrl,
    exact: true,
    component: NotFoundComponent,
    isActive: authorizedPredicate,
    isGeneral: defaultPredicate,
    isIntroductory: defaultFalsePredicate,
  },
  {
    path: LoginUrl,
    exact: true,
    component: LoginComponent,
    isActive: unauthorizedPredicate,
    isGeneral: defaultFalsePredicate,
    isIntroductory: defaultPredicate,
  },
  {
    path: CreateAccountUrl,
    exact: true,
    component: CreateAccountComponent,
    isActive: unauthorizedPredicate,
    isGeneral: defaultFalsePredicate,
    isIntroductory: defaultPredicate,
  },
  {
    path: ForgotPasswordUrl,
    exact: true,
    component: ForgotPasswordComponent,
    isActive: unauthorizedPredicate,
    isGeneral: defaultFalsePredicate,
    isIntroductory: defaultPredicate,
  },
  {
    path: ResetPasswordUrl,
    exact: true,
    component: CreateNewPasswordComponent,
    isActive: unauthorizedPredicate,
    isGeneral: defaultFalsePredicate,
    isIntroductory: defaultPredicate,
  },
  {
    path: ImagingPartnersUrl,
    exact: true,
    component: ImagingPartnerComponent,
    isActive: unauthorizedPredicate,
    isGeneral: defaultFalsePredicate,
    isIntroductory: defaultFalsePredicate,
  },
  {
    path: OrderingPhysiciansUrl,
    exact: true,
    component: OrderingPhysicianComponent,
    isActive: unauthorizedPredicate,
    isGeneral: defaultFalsePredicate,
    isIntroductory: defaultFalsePredicate,
  },
  {
    path: PrivacyUrl,
    exact: true,
    component: PrivacyComponent,
    isActive: defaultPredicate,
    isGeneral: defaultPredicate,
    isIntroductory: defaultFalsePredicate,
  },
  {
    path: TermsUrl,
    exact: true,
    component: TermsComponent,
    isActive: defaultPredicate,
    isGeneral: defaultPredicate,
    isIntroductory: defaultFalsePredicate,
  },
  {
    path: QualityUrl,
    exact: true,
    component: QualityComponent,
    isActive: defaultPredicate,
    isGeneral: defaultPredicate,
    isIntroductory: defaultFalsePredicate,
  },
  {
    path: ScanServiceEditUrl,
    exact: true,
    component: ScanServiceEditComponent,
    isActive: authorizedPredicate,
    isGeneral: defaultFalsePredicate,
    isIntroductory: defaultFalsePredicate,
  },
  {
    path: ScanManagmentUrl,
    exact: true,
    component: ScanManagementComponent,
    isActive: authorizedPredicate,
    isGeneral: defaultFalsePredicate,
    isIntroductory: defaultFalsePredicate,
  },
  {
    path: SettingsUrl,
    exact: true,
    component: SettingsComponent,
    isActive: authorizedPredicate,
    isGeneral: defaultFalsePredicate,
    isIntroductory: defaultFalsePredicate,
  },
  {
    path: EditInfoUrl,
    exact: true,
    component: EditPersonalInfoComponent,
    isActive: authorizedPredicate,
    isGeneral: defaultFalsePredicate,
    isIntroductory: defaultFalsePredicate,
  },
  {
    path: EditPasswordUrl,
    exact: true,
    component: EditPasswordComponent,
    isActive: authorizedPredicate,
    isGeneral: defaultFalsePredicate,
    isIntroductory: defaultFalsePredicate,
  },
  {
    path: PatientProviderAgreementInsuranceUrl,
    exact: true,
    component: PatientProviderAgreementInsurance,
    isActive: defaultPredicate,
    isGeneral: defaultPredicate,
    isIntroductory: defaultFalsePredicate,
  },
  {
    path: PatientProviderAgreementSelfPayUrl,
    exact: true,
    component: PatientProviderAgreementSelfPay,
    isActive: defaultPredicate,
    isGeneral: defaultPredicate,
    isIntroductory: defaultFalsePredicate,
  },
  {
    path: HandoffProcessUrl,
    exact: true,
    component: WrapperHandoffProcess,
    isActive: defaultPredicate,
    isGeneral: defaultPredicate,
    isIntroductory: defaultFalsePredicate,
  },
  {
    path: SolvUrl,
    exact: true,
    component: SolvComponent,
    isActive: defaultPredicate,
    isGeneral: defaultPredicate,
    isIntroductory: defaultFalsePredicate,
  },
  {
    path: CleerlyUrl,
    exact: true,
    component: CleerlyComponent,
    isActive: defaultPredicate,
    isGeneral: defaultPredicate,
    isIntroductory: defaultFalsePredicate,
  },
  {
    path: PatientPingProcessUrl,
    exact: true,
    component: PatientPingProcess,
    isActive: defaultPredicate,
    isGeneral: defaultPredicate,
    isIntroductory: defaultFalsePredicate,
  },
  {
    path: AddPatientPingProcessUrl,
    exact: true,
    component: PatientPingProcess,
    isActive: defaultPredicate,
    isGeneral: defaultPredicate,
    isIntroductory: defaultFalsePredicate,
  },
  {
    path: SendFilesProcessUrl,
    exact: true,
    component: PreAppointmentProcess,
    isActive: defaultPredicate,
    isGeneral: defaultPredicate,
    isIntroductory: defaultFalsePredicate,
  },
  {
    path: PreAppointmentProcessUrl,
    exact: true,
    component: PreAppointmentProcess,
    isActive: defaultPredicate,
    isGeneral: defaultPredicate,
    isIntroductory: defaultFalsePredicate,
  },
  {
    path: FileProcessUrl,
    exact: true,
    component: FileProcess,
    isActive: defaultPredicate,
    isGeneral: defaultPredicate,
    isIntroductory: defaultFalsePredicate,
  },
  {
    path: BaaUrl,
    exact: true,
    component: BaaComponent,
    isActive: defaultPredicate,
    isGeneral: defaultPredicate,
    isIntroductory: defaultFalsePredicate,
  },
  {
    path: SmsUnsubscribeUrl,
    exact: true,
    component: SmsUnsubscribeComponent,
    isActive: defaultPredicate,
    isGeneral: defaultPredicate,
    isIntroductory: defaultFalsePredicate,
  },
  {
    path: HealthyrUrl,
    exact: true,
    component: HealthyrComponent,
    isActive: defaultPredicate,
    isGeneral: defaultPredicate,
    isIntroductory: defaultFalsePredicate,
  },
];

export const RouteConfig = [...authorizedRoutePart, ...unauthorizedRoutePart];
