import React, { useState } from 'react';
import { Uploader } from 'rsuite';
import { FileType } from 'rsuite/Uploader';
import { InfoModalTexts } from '../../models/General';
import { ACCEPTED_TYPES, isFileTypeAllowed } from '../../utils/FileUtil';
import { INCORRECT_FILE_TYPE_ERROR, ONLY_ONE_FILE_ERROR } from '../../views/patient-scan-request/PatientScanRequestUtils';
import InfoModalComponent from '../modals/info-modal';
import { FilesUploaderProps } from './interfaces';
import { useTranslation } from 'react-i18next';

const FilesUploader: React.FunctionComponent<FilesUploaderProps> = props => {
  const { onChange, files, className, multiple, children } = props;
  const [errorDataForModal, setErrorDataForModal] = useState<InfoModalTexts | null>(null);
  const { t } = useTranslation('handoff');

  const checkFile = (files: FileType[]) => {
    if (files.length > 1) {
      setErrorDataForModal(ONLY_ONE_FILE_ERROR);
      return false;
    }
    if (files.length && !isFileTypeAllowed(files[0])) {
      const new_file_type_error = { ...INCORRECT_FILE_TYPE_ERROR, content: t('INCORRECT_FILE_TYPE_ERROR') };
      setErrorDataForModal(new_file_type_error);
      return false;
    }
    return true;
  };

  return (
    <>
      <Uploader
        action=''
        accept={ACCEPTED_TYPES}
        className={`active ${className ? className : ''}`}
        onChange={onChange}
        fileList={files}
        fileListVisible={files.length > 0}
        listType='text'
        shouldUpload={() => false}
        shouldQueueUpdate={fileList => checkFile(fileList)}
        draggable
        multiple={multiple}
        renderFileInfo={(file: FileType): React.ReactElement => {
          return (
            <>
              {files.length && (
                <>
                  <span className='icon-file'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                  </span>
                  <div className='file-name'>{file.name}</div>
                </>
              )}
            </>
          );
        }}
      >
        <div hidden={!!files.length} className='rs-uploader-trigger-btn'>
          {children}
        </div>
      </Uploader>
      {errorDataForModal && <InfoModalComponent type='error' texts={errorDataForModal} onClose={() => setErrorDataForModal(null)} />}
    </>
  );
};

export default FilesUploader;
