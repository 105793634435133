import { CardElement } from '@stripe/react-stripe-js';
import React from 'react';
import { Form } from 'rsuite';
import { CardFieldProps } from './interfaces';

const CARD_OPTIONS: { [key: string]: any } = {
  iconStyle: 'solid',
  hidePostalCode: true,
  hideIcon: true,
  style: {
    base: {
      iconColor: '#040508',
      color: '#040508',
      fontWeight: 400,
      fontFamily: 'Montserrat, sans-serif',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      '::placeholder': {
        font: '400 16px/1.5 Montserrat, sans-serif',
        color: 'rgba(4, 5, 8, 0.5)',
      },
    },
    invalid: {
      iconColor: '#C2002B',
      color: '#C2002B',
    },
  },
};

const CardField: React.FunctionComponent<CardFieldProps> = ({ errorMessage = '', onChange }) => (
  <div className='input-wrap input-wrap-stripe'>
    <div className='form-new-scan-group-fields' style={{ borderColor: `${!errorMessage ? '#DDDFE4' : 'rgba(194, 0, 43, 1)'}` }}>
      <div className='form-new-scan-group-fields_stripe'>
        <CardElement options={CARD_OPTIONS} onChange={onChange} />
      </div>
    </div>
    <Form.ErrorMessage className='error-text' show={!!errorMessage} placement={'bottomStart'}>
      <>{errorMessage}</>
    </Form.ErrorMessage>
  </div>
);

export default CardField;
