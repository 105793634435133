import React, { useMemo, useState } from 'react';
import { Checkbox, Form } from 'rsuite';
import InfoBox from '../../info-box';
import { useTranslation } from 'react-i18next';
import { initMetalBodyTypeData, MetalBodyGeneralProps, MetalBodyTypesForm, PING_METAL_STEP_NAMES } from '../interfaces';

const MetalBodyType: React.FunctionComponent<MetalBodyGeneralProps> = ({
  goToPreviousStep,
  completeStepCallback,
  infoBox,
  scanRequestData,
  stepName = PING_METAL_STEP_NAMES.metalBodyType,
}) => {
  const { t } = useTranslation('handoff');
  const {
    HasTitaniumRod = initMetalBodyTypeData.HasTitaniumRod,
    HasEyeInjury = initMetalBodyTypeData.HasEyeInjury,
    HasAnheurysmClips = initMetalBodyTypeData.HasAnheurysmClips,
    HasOtherMetalInBody = initMetalBodyTypeData.HasOtherMetalInBody,
    OtherMetalInBodyDescription = initMetalBodyTypeData.OtherMetalInBodyDescription,
    HasNotSure = initMetalBodyTypeData.HasNotSure,
  } = scanRequestData;

  const [formValue, setFormValue] = useState<MetalBodyTypesForm>({
    HasTitaniumRod: Boolean(HasTitaniumRod),
    HasEyeInjury: Boolean(HasEyeInjury),
    HasAnheurysmClips: Boolean(HasAnheurysmClips),
    HasOtherMetalInBody: Boolean(HasOtherMetalInBody),
    HasNotSure: Boolean(HasNotSure),
    OtherMetalInBodyDescription: OtherMetalInBodyDescription,
  });

  const selectResponse = (): void => {
    completeStepCallback(
      {
        ...formValue,
      },
      PING_METAL_STEP_NAMES.metalBodyType,
    );
  };

  const isConfirmEnabled = useMemo<boolean>(() => {
    const { HasTitaniumRod, HasEyeInjury, HasAnheurysmClips, HasOtherMetalInBody, HasNotSure } = formValue;
    const areRequiredFieldsPresent = HasTitaniumRod || HasEyeInjury || HasAnheurysmClips || HasNotSure;
    return areRequiredFieldsPresent || !!HasOtherMetalInBody;
  }, [formValue]);

  return (
    <>
      <div className='main-request-form'>
        <h2 id='Additional_questions' className='h2'>
          {t('metal.What type of metal do you have in your body?')}
        </h2>
        <Form onChange={value => setFormValue(value as MetalBodyTypesForm)} formValue={formValue} className='main-form-appointment' fluid>
          <div className='input-checkbox_wrap'>
            <div className='input-checkbox mb0'>
              <Checkbox
                checked={formValue.HasTitaniumRod as boolean}
                onChange={() =>
                  setFormValue(previous => ({
                    ...previous,
                    HasTitaniumRod: !previous.HasTitaniumRod,
                  }))
                }
              >
                {t('metal.Titanium Rod')}
              </Checkbox>
            </div>
            <div className='input-checkbox mb0'>
              <Checkbox
                checked={formValue.HasEyeInjury as boolean}
                onChange={() =>
                  setFormValue(previous => ({
                    ...previous,
                    HasEyeInjury: !previous.HasEyeInjury,
                  }))
                }
              >
                {t('metal.Eye Injury involving Metal')}
              </Checkbox>
            </div>
            <div className='input-checkbox mb0'>
              <Checkbox
                checked={formValue.HasAnheurysmClips as boolean}
                onChange={() =>
                  setFormValue(previous => ({
                    ...previous,
                    HasAnheurysmClips: !previous.HasAnheurysmClips,
                  }))
                }
              >
                {t('metal.Anheurysm Clips')}
              </Checkbox>
            </div>
            <div className='input-checkbox mb0'>
              <Checkbox
                checked={formValue.HasOtherMetalInBody as boolean}
                onChange={() =>
                  setFormValue(previous => ({
                    ...previous,
                    HasOtherMetalInBody: !previous.HasOtherMetalInBody,
                  }))
                }
              >
                {t('metal.Other')}
              </Checkbox>
            </div>
            <div className='input-checkbox mb0'>
              <Checkbox
                checked={formValue.HasNotSure as boolean}
                onChange={() =>
                  setFormValue(previous => ({
                    ...previous,
                    HasNotSure: !previous.HasNotSure,
                  }))
                }
              >
                {t('metal.I am not sure')}
              </Checkbox>
            </div>
          </div>
        </Form>
        <div className='btn-row row justify-content-between full-mob'>
          <button onClick={() => goToPreviousStep(stepName)} className='btn prev btn-white'>
            {t('form.Back', { ns: 'translations' })}
          </button>
          <button className='btn next' disabled={!isConfirmEnabled} onClick={() => selectResponse()}>
            {t('form.Next', { ns: 'translations' })}
          </button>
        </div>
      </div>
      {infoBox ? <InfoBox title={infoBox.title} content={infoBox.content} /> : null}
    </>
  );
};

export default MetalBodyType;
