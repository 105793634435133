import React, { useState } from 'react';
import { useParams } from 'react-router';
import LoaderComponent from '../../components/loader-component';
import { subscribe, unsubscribe } from '../../api/sms/SmsAPI';
import InvalidHandoffExpiredLink from '../../components/invalid-handoff-expired-link';

const SmsUnsubscribeComponent: React.FunctionComponent = () => {
  const { hash } = useParams<{ hash: string }>();
  const [isLoading, setIsLoading] = useState(false);
  const [isUnsubscribe, setIsUnsubscribe] = useState(true);
  const [invitationHasError, setInvitationHasError] = useState(false);

  const click = (): void => {
    setIsLoading(true);
    isUnsubscribe ? unsubscribeClick() : subscribeClick();
  };

  const subscribeClick = (): void => {
    subscribe(hash)
      .then(response => {
        if (response.success) {
          setIsUnsubscribe(true);
        } else {
          setInvitationHasError(true);
        }
      })
      .catch(() => {
        setInvitationHasError(true);
      })
      .finally(() => setIsLoading(false));
  };

  const unsubscribeClick = (): void => {
    unsubscribe(hash)
      .then(response => {
        if (response.success) {
          setIsUnsubscribe(false);
        } else {
          setInvitationHasError(true);
        }
      })
      .catch(() => {
        setInvitationHasError(true);
      })
      .finally(() => setIsLoading(false));
  };

  if (invitationHasError) {
    return <InvalidHandoffExpiredLink />;
  }

  if (isLoading) {
    return <LoaderComponent className='loader-block-center' />;
  }

  return (
    <section className='main-request-section'>
      <div className='container'>
        <div className='row'>
          <div className='request-type'>
            <h2 className='h2'>SMS messaging from Medmo</h2>
            <div className='text-wrap mb32' style={{ minHeight: '40px' }}>
              <p>
                {isUnsubscribe
                  ? 'To unsubscribe from all future SMS messages from Medmo, Inc:'
                  : 'You will no longer receive SMS messages from Medmo, Inc. To subscribe to all future SMS messages from Medmo, Inc:'}
              </p>
            </div>
            <div className='btn-row row justify-content-between'>
              <button className='btn' onClick={() => click()}>
                {isUnsubscribe ? 'Unsubscribe' : 'Subscribe'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SmsUnsubscribeComponent;
