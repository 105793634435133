import React from 'react';
import { Button, Modal } from 'rsuite';
import { InfoModalTexts } from '../../../models/General';

type InfoModalProps = {
  onClose: () => void;
  type?: string;
  texts?: InfoModalTexts;
};

const InfoModalComponent: React.FunctionComponent<InfoModalProps> = props => {
  const { type, onClose, texts } = props;
  return (
    <div>
      <Modal className={'info-modal ' + `${type ? type : ''}-modal`} open onClose={() => onClose()}>
        <Modal.Header>
          <Modal.Title>{texts?.title || `${type ? type : 'default'} modal`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='model-desk'>{texts?.content || `${type ? type : 'default'} text content`}</div>
        </Modal.Body>
        <Modal.Footer>
          <div className='row justify-content-center'>
            <Button classPrefix='btn-model' onClick={() => onClose()}>
              {texts?.buttonText ? texts.buttonText : 'OK'}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default InfoModalComponent;
