import React, { ReactNode } from 'react';
import Logo from '../../assets/medmo-logo-white.svg';
import { HomeUrl } from '../../config/UrlsConfig';

const IntroductoryComponent: React.FunctionComponent<{ children: ReactNode }> = props => {
  return (
    <main className='main'>
      <div className='introductory'>
        <div className='container'>
          <div className='row'>
            <div className='introductory-sidebar'>
              <div className='introductory-header'>
                <a href={HomeUrl}>
                  <img src={Logo} alt='medmo' title='medmo' />
                </a>
              </div>
              <h1>Medical Imaging for Patients</h1>
            </div>
            <div className='introductory-form-wrap'>{props.children}</div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default IntroductoryComponent;
