import React, { useCallback, useEffect, useMemo, useState } from 'react';
import InfoBox from '../info-box';
import { initMammogramGeneralData, MAMMOGRAM_STEP_NAMES, MammogramData, MammogramDatetimesProps, MammogramGeneralData } from './interfaces';
import MammogramTime from './mammogram-time';
import MammogramLocation from './mammogram-location';
import MammogramSymptoms from './mammogram-symptoms';
import MammogramQuestions from './mammogram-questions';
import { Trans, useTranslation } from 'react-i18next';

const MammogramDatetimes: React.FunctionComponent<MammogramDatetimesProps> = ({
  goToPreviousStep,
  scanRequestData,
  completeStepCallback,
}: MammogramDatetimesProps) => {
  const { t } = useTranslation('translations');
  const [mammogramData, setMammogramData] = useState<MammogramGeneralData>(() => {
    const {
      LocationNotes,
      AvailableMammogramDatetime,
      IsFistMammogram,
      IsKnowWhenLastMammogram,
      SymptomsNotes,
      IsBreastPain,
      IsNippleDischarge,
      IsLumpsInYourBreast,
      HaveNotSymptoms,
      HaveAnyBreastImplants,
      HaveAnyFamilyHistoryOfBreastCancer,
      LastMammogramResponseID,
    } = scanRequestData;
    const newData = {
      LocationNotes,
      AvailableMammogramDatetime,
      IsFistMammogram,
      IsKnowWhenLastMammogram,
      SymptomsNotes,
      IsBreastPain,
      IsNippleDischarge,
      IsLumpsInYourBreast,
      HaveNotSymptoms,
      HaveAnyBreastImplants,
      HaveAnyFamilyHistoryOfBreastCancer,
      LastMammogramResponseID,
    };
    return {
      ...initMammogramGeneralData,
      data: { ...initMammogramGeneralData.data, ...newData },
    };
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [mammogramData.currentStep]);

  const previousMammogramStep = useCallback(
    (stepName?: string): void => {
      if (MAMMOGRAM_STEP_NAMES.time === stepName) {
        return goToPreviousStep(MAMMOGRAM_STEP_NAMES.mammogram);
      } else if (MAMMOGRAM_STEP_NAMES.symptoms === stepName && mammogramData.data.IsFistMammogram) {
        return setMammogramData(({ currentStep, data }) => {
          return {
            data,
            currentStep: currentStep - 2,
            errorSubmitting: false,
          };
        });
      }
      setMammogramData(({ currentStep, data }) => {
        return {
          data,
          currentStep: currentStep - 1,
          errorSubmitting: false,
        };
      });
    },
    [mammogramData],
  );

  const completeMammogramStep = useCallback(
    (stepMetadata?: Partial<MammogramData>, stepName?: string): void => {
      setMammogramData(({ currentStep, data }) => {
        if (MAMMOGRAM_STEP_NAMES.time === stepName && stepMetadata && stepMetadata.IsFistMammogram) {
          return {
            data: { ...data, ...stepMetadata, LocationNotes: '' },
            currentStep: currentStep + 2,
            errorSubmitting: false,
          };
        }
        return {
          data: { ...data, ...stepMetadata },
          currentStep: currentStep + 1,
          errorSubmitting: false,
        };
      });
      if (MAMMOGRAM_STEP_NAMES.questions === stepName) {
        const newData = { ...mammogramData.data, ...stepMetadata };
        completeStepCallback(newData, MAMMOGRAM_STEP_NAMES.mammogram);
      }
    },
    [mammogramData],
  );

  const currentStepDisplayed = useMemo<React.ReactNode | null>(() => {
    const { currentStep } = mammogramData;
    if (!mammogramData) {
      return null;
    }

    switch (currentStep) {
      case 1:
        return (
          <MammogramTime
            goToPreviousStep={previousMammogramStep}
            completeStepCallback={completeMammogramStep}
            scanRequestData={mammogramData.data}
          />
        );
      case 2:
        return (
          <MammogramLocation
            goToPreviousStep={previousMammogramStep}
            completeStepCallback={completeMammogramStep}
            scanRequestData={mammogramData.data}
          />
        );
      case 3:
        return (
          <MammogramSymptoms
            goToPreviousStep={previousMammogramStep}
            completeStepCallback={completeMammogramStep}
            scanRequestData={mammogramData.data}
          />
        );
      case 4:
        return (
          <MammogramQuestions
            goToPreviousStep={previousMammogramStep}
            completeStepCallback={completeMammogramStep}
            scanRequestData={mammogramData.data}
          />
        );
      default:
        return null;
    }
  }, [mammogramData]);

  return (
    <>
      <>{currentStepDisplayed}</>
      <InfoBox
        title={t('box.WHY_DO_YOU_NEED_INFOBOX_DATA.title')}
        content={<Trans i18nKey='box.WHY_DO_YOU_NEED_INFOBOX_DATA.content' components={{ br: <br /> }} />}
      />
    </>
  );
};

export default MammogramDatetimes;
