import React, { useCallback, useContext, useEffect, useState } from 'react';
import InfoBox from '../../components/info-box';
import { initData, textsMap } from './utils';
import { PersonalInfoFormData } from './interfaces';
import { UserContext } from '../../contexts/UserContext';
import PreviewPersonalInfo from './PreviewPersonalInfo';
import { useHistory } from 'react-router';
import { EditInfoUrl, EditPasswordUrl, SettingsUrl } from '../../config/UrlsConfig';

const PersonalSettingsComponent: React.FunctionComponent = () => {
  const [personalData, setPersonalData] = useState<PersonalInfoFormData>(initData);
  const user = useContext(UserContext);
  const history = useHistory();

  useEffect(() => setPersonalData({ ...initData, ...user }), []);

  const showEditInfo = useCallback(() => {
    history.push(EditInfoUrl, { pathname: SettingsUrl });
  }, []);
  const showEditPasswordComponent = useCallback(() => {
    history.push(EditPasswordUrl, { pathname: SettingsUrl });
  }, []);

  return (
    <section className='main-request-section'>
      <div className='container'>
        <div className='row'>
          <PreviewPersonalInfo
            showEditInfo={showEditInfo}
            showEditPassword={showEditPasswordComponent}
            data={personalData}
            isLoggedWithPassword={true}
          />
          <InfoBox title={textsMap.safeInfoTitle} content={textsMap.safeInfoContent} />
        </div>
      </div>
    </section>
  );
};

export default PersonalSettingsComponent;
