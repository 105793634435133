import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router';
import {
  APPOINTMENT_REMINDER_ALL_FILES_DOWNLOADED,
  APPOINTMENT_REMINDER_FILE_DOWNLOADED,
  SEND_FILES_ALL_FILES_DOWNLOADED,
  SEND_FILES_FILE_DOWNLOADED,
} from '../../utils/MixpanelEvents';
import { MixpanelContext } from '../../contexts/MixpanelContext';
import { getAppointmentReminderDocuments, getDownloadDocumentsURL } from '../../api/pre-appointment/PreAppointmentAPI';
import { AppointmentReminderType, NewAppointmentReminderType } from './interfaces';
import { getSendFilesDocuments, getDownloadFilesURL } from '../../api/send-files/SendFilesAPI';

const AppointmentDocuments: React.FunctionComponent = () => {
  const { t } = useTranslation('handoff');
  const mixpanel = useContext(MixpanelContext);
  const { invitationID } = useParams<{ invitationID: string }>();
  const [scans, setScans] = useState<NewAppointmentReminderType[]>([]);
  const [isVoucher, setIsVoucher] = useState(false);
  const location = useLocation();

  const isSendFilesProcessUrl = useMemo(() => {
    return location.pathname.includes('/sf/');
  }, [location.pathname]);

  useEffect(() => {
    if (isSendFilesProcessUrl) {
      return getSFDocuments();
    }
    getARDocuments();
  }, []);

  const getSFDocuments = () => {
    getSendFilesDocuments(invitationID).then((data: AppointmentReminderType[]) => {
      const newData: NewAppointmentReminderType[] = [];
      data.forEach(el => {
        const foundEl = newData.find(e => e.TestRequestID === el.TestRequestID);
        if (!foundEl) {
          const newEl = {
            ...el,
            files: [{ ...el }],
          };
          newData.push(newEl);
        } else {
          if (el.FileTypeID === 18) {
            foundEl.files.unshift({ ...el });
            setIsVoucher(true);
          } else {
            foundEl.files.push({ ...el });
          }
        }
      });
      setScans(newData);
    });
  };

  const getARDocuments = () => {
    getAppointmentReminderDocuments(invitationID).then((data: AppointmentReminderType[]) => {
      const newData: NewAppointmentReminderType[] = [];
      data.forEach(el => {
        const foundEl = newData.find(e => e.TestRequestID === el.TestRequestID);
        if (!foundEl) {
          const newEl = {
            ...el,
            files: [{ ...el }],
          };
          newData.push(newEl);
        } else {
          foundEl.files.push({ ...el });
        }
      });
      setScans(newData);
    });
  };

  const openUrl = (file: AppointmentReminderType) => {
    const FileTypeID = file.FileTypeID;
    const trackTitle = isSendFilesProcessUrl ? SEND_FILES_FILE_DOWNLOADED : APPOINTMENT_REMINDER_FILE_DOWNLOADED;
    mixpanel.track(trackTitle, { InvitationID: invitationID, FileTypeID });
    window.open(file.URL, '_blank');
    // window.open(`/file/${FileTypeID}/${file.PathToFile}`, '_blank');
  };

  const downloadAll = () => {
    const URL = isSendFilesProcessUrl ? getDownloadFilesURL(invitationID) : getDownloadDocumentsURL(invitationID);
    window.open(URL, '_blank');
    const trackTitle = isSendFilesProcessUrl ? SEND_FILES_ALL_FILES_DOWNLOADED : APPOINTMENT_REMINDER_ALL_FILES_DOWNLOADED;
    mixpanel.track(trackTitle, { InvitationID: invitationID });
  };

  return (
    <div className='ping-appointment'>
      <div className='ping-appointment-img'></div>
      <h2 className='h2'>{t('pre_app.title')}</h2>
      <div className='text mb24'>
        <p>{t('pre_app.text')}</p>
      </div>
      {isVoucher ? (
        <div className='text mb24'>
          <p>
            <span className='red-text'>*</span>
            {t('pre_app.voucher_text')}
          </p>
        </div>
      ) : null}
      {scans.map(scan => (
        <div key={scan.TestRequestID} className='ping-appointment-files'>
          <h4 className='h4'>
            {t(`${scan.FriendlyName}` as unknown as string | string[], {
              ns: 'scanTypesFNList',
              defaultValue: scan.FriendlyName,
            })}
          </h4>
          <div className='text mb16 gray'>
            <p>
              {t('pre_app.Scan ID')} {scan.TestRequestID}
            </p>
          </div>
          <ul>
            {scan.files.map((file, index) => (
              <li key={file.PathToFile + index}>
                <a onClick={() => openUrl(file)} className='link'>
                  {t(`pre_app.files.${file.FileTypeID}` as unknown as string, { defaultValue: 'Link' })}
                  {file.FileTypeID === 18 ? <span className='red-text'>*</span> : null}
                </a>
              </li>
            ))}
          </ul>
        </div>
      ))}
      <div className='text mb16'>
        <p>{t('pre_app.sub-text')}</p>
      </div>
      <a onClick={downloadAll} className='btn btn-white btn-download'>
        {t('pre_app.Download all files')}
      </a>
    </div>
  );
};

export default AppointmentDocuments;
