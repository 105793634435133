import { DOBForValidation } from '../handoff-process/interfaces';
import { request } from '../Request';
import { SERVER_URL } from '../../config/ServerConfig';

export function validatePatientDateOfBirth(payload: DOBForValidation): Promise<boolean> {
  return request({
    url: `${SERVER_URL}/appointment-reminder/validate-date-of-birth`,
    method: 'POST',
    body: JSON.stringify(payload),
  });
}

export function getAppointmentReminderDocuments(invitationID: string) {
  return request({
    url: `${SERVER_URL}/appointment-reminder/${invitationID}`,
    method: 'GET',
  });
}

export function getDownloadDocumentsURL(invitationID: string): string {
  return `${SERVER_URL}/appointment-reminder/download-all/${invitationID}`;
}

export function getFileURL(FileTypeID: number, PathToFile: string): Promise<{ URL: string }> {
  return request({
    url: `${SERVER_URL}/files/get-signed-url`,
    method: 'POST',
    body: JSON.stringify({ FileTypeID, PathToFile }),
  });
}

export function downloadDocuments(invitationID: string) {
  return request({
    url: `${SERVER_URL}/appointment-reminder/download-all/${invitationID}`,
    method: 'GET',
  });
}
