import React, { useState } from 'react';
import { Form } from 'rsuite';
import TextField from '../../text-field-component';
import Textarea from '../../textarea';
import { useTranslation } from 'react-i18next';
import { AssistanceInnerProps, DetailsDataForm, PING_ASSISTANCE_STEP_NAMES } from '../interfaces';

const AssistanceDetails: React.FunctionComponent<AssistanceInnerProps> = ({ goToPreviousStep, completeStepCallback, scanRequestData }) => {
  const { t } = useTranslation('handoff');
  const { AssistanceDetails = '' } = scanRequestData;

  const [formValue, setFormValue] = useState<DetailsDataForm>({
    AssistanceDetails,
  });

  const selectResponse = (): void => {
    completeStepCallback({ AssistanceDetails: formValue.AssistanceDetails }, PING_ASSISTANCE_STEP_NAMES.assistanceDetails);
  };

  return (
    <>
      <div className='main-request-form'>
        <h2 id='Additional_questions' className='h2'>
          {t('assistance.Please provide details about your assistance needs')}
        </h2>
        <div className='text-form fw600'>
          <p>{t('assistance.If youre not sure, please click Next to continue.')}</p>
        </div>
        <Form onChange={value => setFormValue(value as DetailsDataForm)} formValue={formValue} className='main-form-appointment' fluid>
          <div className='input-wrap textarea-wrap h80 mb32'>
            <TextField
              name='AssistanceDetails'
              value={formValue.AssistanceDetails}
              placeholder={t('assistance.E.g. I need a Spanish interpreter during my appointment')}
              accepter={Textarea}
            />
          </div>
        </Form>
        <div className='btn-row row full-mob justify-content-between'>
          <button onClick={() => goToPreviousStep(PING_ASSISTANCE_STEP_NAMES.assistanceDetails)} className='btn prev btn-white'>
            {t('form.Back', { ns: 'translations' })}
          </button>
          <button className='btn next' onClick={() => selectResponse()}>
            {t('form.Next', { ns: 'translations' })}
          </button>
        </div>
      </div>
    </>
  );
};

export default AssistanceDetails;
