import React from 'react';
import PatientScanRequest from '../patient-scan-request';
import { HealthyrComponentProps } from './interface';

export const HEALTHYR = 'healthyr';

const HealthyrComponent: React.FunctionComponent<HealthyrComponentProps> = ({ authenticate, isAuthenticated }) => {
  return <PatientScanRequest authenticate={authenticate} isAuthenticated={isAuthenticated} partner={HEALTHYR} />;
};

export default HealthyrComponent;
