import { Schema } from 'rsuite';

const { StringType } = Schema.Types;

export const ForgotPasswordSchema = Schema.Model({
  email: StringType().isEmail('Please enter a valid email address.').isRequired('This field is required.'),
});

export const CreatePasswordSchema = Schema.Model({
  newPassword: StringType('Please enter 8-digit identifier')
    .isRequired('This field is required.')
    .minLength(8, 'The field cannot be less than 8 characters')
    .containsNumber('Must contain numbers')
    .containsUppercaseLetter('Must contain 1 capital letter')
    .addRule(value => {
      return /[!%&'()*+./;<=>?\\,/:#@"\\[\]_\u007B-\u00BF-]/.test(value);
    }, 'Must contain 1 special character'),
  confirmPassword: StringType('Please enter 8-digit identifier')
    .isRequired('This field is required.')
    .minLength(8, 'The field cannot be less than 8 characters')
    .containsNumber('Must contain numbers')
    .containsUppercaseLetter('Must contain 1 capital letter')
    .addRule(value => {
      return /[!%&'()*+./;<=>?\\,/:#@"\\[\]_\u007B-\u00BF-]/.test(value);
    }, 'Must contain 1 special character')
    .addRule((value, data) => {
      if (value !== data.newPassword) {
        return false;
      }
      return true;
    }, 'The passwords are not equal'),
});

export const texts = {
  confirm: {
    title: 'Confirm',
    content: 'Link has been sent successfully',
    buttonText: 'Return to Login',
  },
  successAccount: {
    title: `Password Successfully Updated`,
    content: `Your password has been successfully updated!`,
    buttonText: 'Return to Login',
  },
  expiredToken: {
    title: `Expired Token`,
    content: `Password reset token is invalid or has expired.`,
    buttonText: 'Return to Login',
  },
};
