import React, { useContext } from 'react';
import { MixpanelContext } from '../../../contexts/MixpanelContext';
import { replaceWhitespacesForUnderscore } from '../../../utils/GeneralUtil';
import { WILL_TRAVEL_FURTHER } from '../../../utils/MixpanelEvents';
import { NoAvailableICsNearbyProps } from './interfaces';

const NoAvailableICsNearby: React.FunctionComponent<NoAvailableICsNearbyProps> = ({
  scanTypeName,
  shouldShowNoICsNearbyCallback,
  getBackToPreviousStep,
  willTravelFurtherCallback,
}: NoAvailableICsNearbyProps) => {
  const mixpanel = useContext(MixpanelContext);

  const handleWillTravelFurther = (): void => {
    willTravelFurtherCallback(true);
    shouldShowNoICsNearbyCallback(false);
    mixpanel.track(WILL_TRAVEL_FURTHER, { willTravelFurther: true });
  };

  return (
    <div className='request-type'>
      <h2 id={`Sorry_we_cant_find_${replaceWhitespacesForUnderscore(scanTypeName)}_scanning_within_50_miles`} className='h2'>
        Sorry, we can’t find {scanTypeName} scanning within 50 miles
      </h2>
      <div className='request-warning'>
        <span className='warning-icon'></span>
        <div className='title'>No imaging centers near your address</div>
        <div className='text'>
          <p>Would you like to travel to imaging centers further than 50 miles from your location?</p>
        </div>
        <div className='btn-row row justify-content-between'>
          <button className='btn btn-white' onClick={getBackToPreviousStep}>
            No, change address
          </button>
          <button className='btn' onClick={handleWillTravelFurther}>
            Yes, I’ll travel further
          </button>
        </div>
      </div>
    </div>
  );
};

export default NoAvailableICsNearby;
