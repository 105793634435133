import { request } from '../Request';
import {
  CleerlyCheckoutPayload,
  DOBForValidation,
  HandoffProcessCleerlyPayload,
  HandoffProcessPayload,
  InvitationDataResponse,
} from './interfaces';
import { ScanRequest } from '../../models/ScanRequest';
import { SERVER_URL } from '../../config/ServerConfig';
import { CleerlyScanRequest } from '../../views/cleerly/interfaces';

export function getInvitationData(invitationID: string): Promise<InvitationDataResponse> {
  return request({
    url: `${SERVER_URL}/handoff-process/${invitationID}`,
    method: 'GET',
  });
}

export function validateDateOfBirth(payload: DOBForValidation): Promise<boolean> {
  return request({
    url: `${SERVER_URL}/handoff-process/validate-date-of-birth`,
    method: 'POST',
    body: JSON.stringify(payload),
  });
}

export function finishCleerlySelfServiceProcess(data: CleerlyScanRequest): Promise<{ ID: string }> {
  return request({
    url: `${SERVER_URL}/cleerly/self-process`,
    method: 'POST',
    body: JSON.stringify(data),
  });
}

export function finishCleerlyHandoffProcess(invitationID: string, handoffData: HandoffProcessCleerlyPayload): Promise<ScanRequest> {
  return request({
    url: `${SERVER_URL}/handoff-process/${invitationID}/cleerly`,
    method: 'PUT',
    body: JSON.stringify(handoffData),
  });
}

export function finishHandoffProcess(invitationID: string, handoffData: HandoffProcessPayload): Promise<ScanRequest> {
  return request({
    url: `${SERVER_URL}/handoff-process/${invitationID}`,
    method: 'PUT',
    body: JSON.stringify(handoffData),
  });
}

export function checkoutCleerly(payload: CleerlyCheckoutPayload): Promise<{ ChargeId: string; ChargeAmount: number }> {
  return request({
    url: `${SERVER_URL}/checkout/cleerly`,
    method: 'POST',
    body: JSON.stringify(payload),
  });
}
