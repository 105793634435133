const IMPACT_EVENTS = {
  IDENTIFY: 'identify',
  TRACK_CONVERSATION: 'trackConversion',
};

export const CustomerStatus = {
  REGISTER: 'Registration',
  SIGN_IN: 'Sign In',
};

export type RequestedData = {
  orderId: number;
  customerId: string;
  sku: string;
  category: string;
  price: number;
};

export const ireIdentify = (customerId = '', customerEmail = '') => {
  window && window.ire(IMPACT_EVENTS.IDENTIFY, { customerId, customerEmail });
};

export const ireRegistrationSignIn = (customerId: string, customerStatus = CustomerStatus.SIGN_IN) => {
  const data = {
    orderId: customerId + '-' + Date.now(),
    customerId: customerId,
    customerStatus: customerStatus,
  };

  window &&
    window.ire(IMPACT_EVENTS.TRACK_CONVERSATION, 35876, data, {
      verifySiteDefinitionMatch: true,
    });
};

export const ireRequested = (data: RequestedData) => {
  const payload = {
    orderId: data.orderId,
    customerId: data.customerId,
    customerStatus: '',
    currencyCode: 'USD',
    orderPromoCode: '',
    items: [
      {
        subTotal: data.price,
        category: data.category,
        sku: data.sku,
        quantity: 1,
      },
    ],
  };

  window &&
    window.ire(IMPACT_EVENTS.TRACK_CONVERSATION, 35877, payload, {
      verifySiteDefinitionMatch: true,
    });
};
