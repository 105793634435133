import React, { SyntheticEvent, useState } from 'react';
import { Form } from 'rsuite';
import { ScanType } from '../../models/ScanType';
import InfoBox from '../info-box';
import TextField from '../text-field-component';
import {
  CANT_FIND_WHAT_YOU_NEED_INFOBOX_DATA,
  PAYMENT_INFORMATION_WARNING_BOX_DATA,
  STEP_NAMES,
} from '../../views/patient-scan-request/PatientScanRequestUtils';
import { ReviewScanTypeSelectionProps } from './interfaces';
import { NumberBoolean } from '../../models/General';
import WarningBox from '../warning-box';

const ReviewScanTypeSelection: React.FunctionComponent<ReviewScanTypeSelectionProps> = ({
  goToPreviousStep,
  completeStepCallback,
  scanRequestData,
  nonConfirmedScanTypeSelection,
  isEditMode,
}: ReviewScanTypeSelectionProps) => {
  const { title, content, urlText, url } = CANT_FIND_WHAT_YOU_NEED_INFOBOX_DATA;
  const { subtitle, content: warningContent } = PAYMENT_INFORMATION_WARNING_BOX_DATA;
  const { SelfPay, SelectedScan, scanServiceSelected } = scanRequestData || { scanServiceSelected: {} };
  const { MinPrice, Fee, TotalPrice } = (nonConfirmedScanTypeSelection.scanSelected as ScanType) || {};
  const [formValue] = useState<{ scanName: string }>({ scanName: nonConfirmedScanTypeSelection.scanSelected?.FriendlyName as string });

  const handleConfirm = (event: SyntheticEvent): void => {
    event.preventDefault();
    const { scanSelected, willTravelFurther } = nonConfirmedScanTypeSelection;
    const { ScanServiceID, FullName } = scanSelected as ScanType;
    const payload = {
      ScanServiceID,
      WillTravelFurther: (willTravelFurther ? 1 : 0) as NumberBoolean,
      scanServiceSelected: scanSelected as ScanType,
      SelectedScan: FullName,
    };
    completeStepCallback(payload, STEP_NAMES.reviewScanTypeSelection);
  };

  const getBack = (event: SyntheticEvent): void => {
    event.preventDefault();
    goToPreviousStep(STEP_NAMES.reviewScanTypeSelection);
  };

  return (
    <>
      <div className='request-type'>
        <h2 id='Review_your_selection' className='h2'>
          Review your selection
        </h2>
        <div className='text-wrap'>
          <p>Please make sure the information below accurately reflects the imaging you’ve been prescribed.</p>
        </div>
        <Form fluid formValue={formValue}>
          <div className='input-wrap'>
            <TextField readOnly value={formValue.scanName} name='scanName' />
          </div>
          {!isEditMode && (
            <div className='payment-options'>
              <h3 className='payment-options_title'>Accepted payment options for this exam:</h3>
              <ul className='payment-options_list'>
                <li>Insurance</li>
                <li>Self-pay&nbsp;&nbsp;${TotalPrice}</li>
              </ul>
            </div>
          )}
          {isEditMode && SelfPay === 1 && (
            <>
              <div className='request-type-payments_total'>
                <div className='row total'>
                  <div>Total cost of the appointment</div>
                  <div>${TotalPrice}</div>
                </div>
                <div className='row'>
                  <div>Amount due at time of appointment</div>
                  <div>${MinPrice}</div>
                </div>
                <div className='row'>
                  <div>
                    <b>Deposit amount due prior</b>
                  </div>
                  <div>
                    <b>${Fee}</b>
                  </div>
                </div>
              </div>
              <div className='request-type-payments_previous row'>
                <div>
                  <b>Previous cost of the appointment</b>
                  <p>{SelectedScan}</p>
                </div>
                <div className='price'>${(scanServiceSelected as ScanType)?.TotalPrice}</div>
              </div>
              <WarningBox>
                <p>
                  <b>{subtitle}</b>
                  {warningContent}
                </p>
              </WarningBox>
            </>
          )}
          <div className='btn-row row justify-content-between full-mob'>
            <button className='btn btn-white back' onClick={getBack}>
              Back
            </button>
            <button className='btn next' onClick={handleConfirm}>
              Confirm
            </button>
          </div>
        </Form>
      </div>
      <InfoBox title={title} content={content} urlText={urlText} url={url} />
    </>
  );
};

export default ReviewScanTypeSelection;
