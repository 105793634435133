import React from 'react';
import { Link } from 'react-router-dom';
import StepsComponent from '../../components/step-info';
import { NewScanUrl } from '../../config/UrlsConfig';

const NoScansFoundComponent: React.FunctionComponent = () => {
  return (
    <div className='scan-management-nodata'>
      <section className='scan-management_banner'>
        <div className='container'>
          <div className='scan-management_banner_box'>
            <div className='global-title_wrap'>
              <div className='global-title_wrap_box'>
                <div className='sub-title'>Your appointments</div>
                <h1 className='h1'>Manage your appointments</h1>
                <div className='text'>
                  <p>You have no existing orders, press the button below to get started.</p>
                </div>
              </div>
            </div>
            <Link className='btn' to={NewScanUrl}>
              SCHEDULE NOW
            </Link>
          </div>
        </div>
      </section>
      <StepsComponent />
    </div>
  );
};

export default NoScansFoundComponent;
