import React from 'react';

const WarningBox: React.FunctionComponent<{ title?: string }> = ({ title, children }) => (
  <div className='request-warning'>
    <div className='row'>
      <span className='icon-info'>
        <span className='path1'></span>
        <span className='path2'></span>
      </span>
      <div className='request-warning-text'>
        {title && <div className='title'>{title}</div>}
        <div className='text'>{children}</div>
      </div>
    </div>
  </div>
);

export default WarningBox;
