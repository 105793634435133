import { StripeError } from '@stripe/stripe-js';
import React, { SyntheticEvent, useMemo, useState } from 'react';
import { Form } from 'rsuite';
import StripeLogo from '../../assets/stripe.svg';
import { FormError } from '../../models/General';
import StripeFields from '../stripe-fields';
import WarningBox from '../warning-box';
import { PAYMENT_INFORMATION_WARNING_BOX_DATA } from '../../views/patient-scan-request/PatientScanRequestUtils';
import { FastLinkSource, PurchaseInformationForm, PurchaseInformationProps } from './interfaces';
import { PurchaseSchema } from './utils';
import { Link } from 'react-router-dom';
import { PatientProviderAgreementSelfPayUrl } from '../../config/UrlsConfig';
import { CLEERLY_HANDOFF_STEP_NAMES } from '../../views/handoff-process/cleerly/utils';
import { PaymentInformationForm } from '../payment-information/interfaces';

const PurchaseInformation: React.FunctionComponent<PurchaseInformationProps> = ({
  goToPreviousStep,
  scanRequestData,
  completeStepCallback,
  additionalInfo,
  patientData,
}) => {
  const { CardExpirationDate, CardType, CardLast4, PaymentID, PaymentSecret, scanServiceSelected, ScanCost = '750.00' } = scanRequestData;
  const { subtitle, content: warningContent } = PAYMENT_INFORMATION_WARNING_BOX_DATA;
  const [formValue, setFormValue] = useState<PurchaseInformationForm>({
    CardExpirationDate: CardExpirationDate || '',
    CardType: CardType || '',
    CardLast4: CardLast4 || null,
    PaymentID: PaymentID || '',
    PaymentSecret: PaymentSecret || '',
  });
  const [formError, setFormError] = useState<FormError>({});
  const [stripeError, setStripeError] = useState<boolean>(false);
  const [cardOptions] = useState(() => {
    if (patientData && patientData.FirstName) {
      return { owner: { name: patientData.FirstName + ' ' + patientData.LastName }, currency: 'usd' };
    }

    return { currency: 'usd' };
  });
  const [isCardError, setIsCardError] = useState(!!additionalInfo?.isCardErrorMessageShown);

  const isConfirmEnabled = useMemo<boolean>(() => {
    const { CardType, CardLast4, PaymentID, PaymentSecret } = formValue;
    const areRequiredFieldsPresent = !!CardType && !!CardLast4 && !!PaymentID && !!PaymentSecret;
    return areRequiredFieldsPresent && !stripeError;
  }, [formValue, stripeError]);

  const handleOnCompleteStep = (data: PaymentInformationForm) => {
    completeStepCallback(
      {
        ...data,
      },
      CLEERLY_HANDOFF_STEP_NAMES.purchaseInformation,
    );
  };

  const handleStripeChange = (error: StripeError | boolean | null, paymentMethod: FastLinkSource | null): void => {
    if (!paymentMethod || !!error) {
      setStripeError(true);
      return;
    }

    const cardData = {
      CardExpirationDate: `${paymentMethod.card?.exp_month}/${paymentMethod.card?.exp_year?.toString().slice(2)}`,
      CardLast4: Number(paymentMethod.card?.last4),
      CardType: paymentMethod.card?.brand as string,
      PaymentID: paymentMethod.id,
      PaymentSecret: paymentMethod.client_secret,
    };

    setFormValue(previous => {
      return {
        ...previous,
        ...cardData,
      };
    });
    setStripeError(false);
    setIsCardError(false);
    paymentMethod.IsPayFaster && handleOnCompleteStep({ ...formValue, ...cardData, FullNameOnCard: paymentMethod.owner?.name || '' });
  };

  const termsURL = useMemo(() => {
    return PatientProviderAgreementSelfPayUrl.replace(':scanServiceID', scanServiceSelected?.ScanServiceID.toString() || '');
  }, [scanServiceSelected]);

  const handleConfirm = (event: SyntheticEvent): void => {
    event.preventDefault();
    completeStepCallback(
      {
        ...formValue,
      },
      CLEERLY_HANDOFF_STEP_NAMES.purchaseInformation,
    );
  };

  const getBack = (event: SyntheticEvent): void => {
    event.preventDefault();
    goToPreviousStep(CLEERLY_HANDOFF_STEP_NAMES.purchaseInformation);
  };

  return (
    <div className='request-type'>
      <h2 className='h2'>{additionalInfo?.title ? additionalInfo?.title : 'Purchase your Cleerly analysis of your CCTA exam.'}</h2>
      <div className='text-wrap'>
        <p>
          {additionalInfo?.description
            ? additionalInfo?.description
            : 'Your Cleerly Exam includes an interpretation of your results, a consultation and follow up guidance.'}
        </p>
      </div>
      <div className='request-type-payments_total'>
        <div className='row total'>
          <div>Cleerly Exam</div>
          <div>
            <b>${ScanCost}</b>
          </div>
        </div>
      </div>
      {isCardError ? (
        <div className='card-invalid'>
          <span>There was an error processing your credit card.</span>
        </div>
      ) : null}
      <Form
        formValue={formValue}
        onChange={value => setFormValue(value as PurchaseInformationForm)}
        model={PurchaseSchema}
        onCheck={error => setFormError(error)}
        fluid
      >
        <StripeFields
          amount={ScanCost}
          isFullNameShown={false}
          sourceCardOptions={cardOptions}
          callback={handleStripeChange}
          formError={formError}
        />
        <div className='stripe-info mb24 mt0'>
          <img src={StripeLogo} alt='stripe'></img>
        </div>
        {additionalInfo?.isWarningBoxHidden ? null : (
          <WarningBox>
            <p>
              <b>{subtitle}</b>
              {warningContent}
            </p>
          </WarningBox>
        )}
        <div className=' full-mob mb24'>
          <button onClick={getBack} className='btn prev btn-white'>
            Back
          </button>
          <button className='btn next' disabled={!isConfirmEnabled} onClick={handleConfirm}>
            Purchase
          </button>
        </div>
        <div className='fs12 mb32'>
          <p>
            By clicking submit you agree to our&nbsp;
            <Link target='_blank' to={termsURL}>
              <b>patient provider terms</b>
            </Link>
          </p>
        </div>
      </Form>
    </div>
  );
};

export default PurchaseInformation;
