import React, { useCallback, useEffect, useMemo, useState } from 'react';
import InfoBox from '../info-box';
import { Trans, useTranslation } from 'react-i18next';
import {
  initMetalBodyDetailsData,
  initMetalBodyGeneralData,
  initMetalBodyLocationData,
  MetalBodyData,
  MetalBodyGeneralData,
  MetalBodyProps,
  PING_METAL_STEP_NAMES,
  resetMetalBodyTypeData,
} from './interfaces';
import MetalBodyQuestions from './metal-body-question';
import MetalBodyType from './metal-body-type';
import MetalBodyLocation from './metal-body-location';
import MetalBodyDetails from './metal-body-details';

const MetalInBodyWrapper: React.FunctionComponent<MetalBodyProps> = ({
  goToPreviousStep,
  scanRequestData,
  completeStepCallback,
}: MetalBodyProps) => {
  const { t } = useTranslation('translations');
  const {
    HasTitaniumRod,
    HasEyeInjury,
    HasAnheurysmClips,
    HasOtherMetalInBody,
    HasNotSure,
    OtherMetalInBodyDescription,
    HasPacemakerOrDefibrillatorInBody,
    PacemakerFileKey,
    PaceMakerDetails,
    MetalBodyQuestionID,
    MetalInfo,
    MetalLocation,
  } = scanRequestData;
  const [metalBodyData, setMetalBodyData] = useState<MetalBodyGeneralData>(() => {
    const newData = {
      HasTitaniumRod,
      HasEyeInjury,
      HasAnheurysmClips,
      HasOtherMetalInBody,
      HasNotSure,
      OtherMetalInBodyDescription,
      HasPacemakerOrDefibrillatorInBody,
      PacemakerFileKey,
      PaceMakerDetails,
      MetalBodyQuestionID,
      MetalInfo,
      MetalLocation,
    };
    return {
      ...initMetalBodyGeneralData,
      currentStep: 1,
      data: { ...initMetalBodyGeneralData.data, ...newData },
    };
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [metalBodyData.currentStep]);

  const previousStep = useCallback(
    (stepName?: string): void => {
      if (PING_METAL_STEP_NAMES.metalBodyQuestion === stepName) {
        return goToPreviousStep(PING_METAL_STEP_NAMES.metalBodyQuestion);
      }

      setMetalBodyData(({ currentStep, data }) => {
        let prevStep = 1;
        if (PING_METAL_STEP_NAMES.metalBodyLocation === stepName) {
          if (!data.HasNotSure && !data.HasOtherMetalInBody) {
            prevStep = 2;
          }
        }
        return {
          data,
          currentStep: currentStep - prevStep,
          errorSubmitting: false,
        };
      });
    },
    [metalBodyData],
  );

  const completeStep = useCallback(
    (stepMetadata?: Partial<MetalBodyData>, stepName?: string): void => {
      if (PING_METAL_STEP_NAMES.metalBodyType === stepName && stepMetadata?.MetalBodyQuestionID === 0) {
        stepMetadata = { ...stepMetadata, ...initMetalBodyLocationData, ...initMetalBodyDetailsData, ...resetMetalBodyTypeData };
      } else if (PING_METAL_STEP_NAMES.metalBodyQuestion === stepName && stepMetadata?.MetalBodyQuestionID === 0) {
        stepMetadata = { ...stepMetadata, ...initMetalBodyLocationData, ...initMetalBodyDetailsData, ...resetMetalBodyTypeData };
        const newData = { ...metalBodyData.data, ...stepMetadata };
        return completeStepCallback(newData, stepName);
      }

      if (PING_METAL_STEP_NAMES.metalBodyLocation === stepName) {
        const newData = { ...metalBodyData.data, ...stepMetadata };
        return completeStepCallback(newData, stepName);
      }

      setMetalBodyData(({ currentStep, data }) => {
        let nextStep = 1;
        const newData = { ...data, ...stepMetadata };
        if (PING_METAL_STEP_NAMES.metalBodyType === stepName) {
          if (!newData.HasNotSure && !newData.HasOtherMetalInBody) {
            nextStep += 1;
          }
        }
        return {
          data: newData,
          currentStep: currentStep + nextStep,
          errorSubmitting: false,
        };
      });
    },
    [metalBodyData],
  );

  const currentStepDisplayed = useMemo<React.ReactNode | null>(() => {
    const { currentStep, data } = metalBodyData;
    if (!metalBodyData) {
      return null;
    }
    switch (currentStep) {
      case 1: {
        return (
          <MetalBodyQuestions
            goToPreviousStep={previousStep}
            completeStepCallback={completeStep}
            scanRequestData={data}
            stepName={PING_METAL_STEP_NAMES.metalBodyQuestion}
          />
        );
      }
      case 2:
        return (
          <MetalBodyType
            goToPreviousStep={previousStep}
            stepName={PING_METAL_STEP_NAMES.metalBodyType}
            scanRequestData={data}
            completeStepCallback={completeStep}
          />
        );
      case 3:
        return (
          <MetalBodyDetails
            goToPreviousStep={previousStep}
            completeStepCallback={completeStep}
            scanRequestData={data}
            stepName={PING_METAL_STEP_NAMES.metalBodyDetails}
          />
        );
      case 4:
        return (
          <MetalBodyLocation
            goToPreviousStep={previousStep}
            stepName={PING_METAL_STEP_NAMES.metalBodyLocation}
            scanRequestData={data}
            completeStepCallback={completeStep}
          />
        );
      default:
        return null;
    }
  }, [metalBodyData]);

  return (
    <>
      <>{currentStepDisplayed}</>
      <InfoBox
        title={t('box.WHY_DO_YOU_NEED_INFOBOX_DATA.title')}
        content={<Trans i18nKey='box.WHY_DO_YOU_NEED_INFOBOX_DATA.content' components={{ br: <br /> }} />}
      />
    </>
  );
};

export default MetalInBodyWrapper;
