import React, { useMemo, useState } from 'react';
import { Form, Whisper, Tooltip } from 'rsuite';
import { TextFieldProps } from './interfaces';

const TextField: React.FunctionComponent<TextFieldProps> = props => {
  const {
    name,
    label,
    accepter,
    error,
    className,
    value,
    readOnly,
    autoComplete,
    placeholder,
    disabled,
    onFocus,
    onBlur,
    onClean,
    shouldDisplayX,
    tooltipHint,
    errorClassName = '',
    componentClass,
    ...rest
  } = props;
  const [focus, setFocus] = useState<boolean>(false);

  const handleFocus = (event: Event): void => {
    setFocus(true);
    if (onFocus) {
      onFocus(event);
    }
  };

  const handleBlur = (event: Event): void => {
    setFocus(false);
    if (onBlur) {
      onBlur(event);
    }
  };

  const errorMessage = useMemo(() => {
    if (typeof error === 'string') {
      return error;
    }

    return null;
  }, [error]);

  return (
    <div className={`input-wrap ${className ? className : ''} ${onClean ? 'input-has-clear' : ''}`}>
      <Form.Group
        className={`${value || placeholder ? 'show' : ''} ${focus ? 'focus' : ''} ${errorMessage ? 'error' + errorClassName : ''} ${
          tooltipHint ? 'tooltip' : ''
        } anim-placeholder ${componentClass}`}
      >
        <Form.ControlLabel>{label} </Form.ControlLabel>
        <Form.Control
          onFocus={handleFocus}
          onBlur={handleBlur}
          name={name}
          readOnly={!!readOnly}
          disabled={!!disabled}
          accepter={accepter}
          errorMessage=''
          autoComplete={autoComplete}
          placeholder={placeholder}
          {...rest}
        />
        {onClean && shouldDisplayX && <button className='btn-input-clean' onClick={onClean}></button>}
        {tooltipHint && (
          <Whisper placement='top' trigger='hover' speaker={<Tooltip>{tooltipHint}</Tooltip>}>
            <div className='tooltip-mask'></div>
          </Whisper>
        )}
        <Form.ErrorMessage show={!!errorMessage} className='rs-form-control-message-wrapper' placement={'bottomStart'}>
          {errorMessage}
        </Form.ErrorMessage>
      </Form.Group>
    </div>
  );
};

export default TextField;
