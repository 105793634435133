import { StripeError } from '@stripe/stripe-js';
import React, { SyntheticEvent, useMemo, useState } from 'react';
import { Form } from 'rsuite';
import StripeLogo from '../../assets/stripe.svg';
import { FormError } from '../../models/General';
import { ScanType } from '../../models/ScanType';
import StripeFields from '../stripe-fields';
import WarningBox from '../warning-box';
import { STEP_NAMES } from '../../views/patient-scan-request/PatientScanRequestUtils';
import { PaymentInformationForm, PaymentInformationProps, SourceFastLink } from './interfaces';
import { PaymentSchema } from './utils';
import { useTranslation } from 'react-i18next';
import { HEALTHYR } from '../../views/healthyr';

const PaymentInformation: React.FunctionComponent<PaymentInformationProps> = ({
  goToPreviousStep,
  scanRequestData,
  completeStepCallback,
  isEditMode,
  partner,
}: PaymentInformationProps) => {
  const { FullNameOnCard, CardExpirationDate, CardType, CardLast4, PaymentID, PaymentSecret, scanServiceSelected } = scanRequestData;
  const { MinPrice, Fee, TotalPrice } = (scanServiceSelected as ScanType) || {};
  const [formValue, setFormValue] = useState<PaymentInformationForm>({
    FullNameOnCard: FullNameOnCard ? FullNameOnCard : '',
    CardExpirationDate: CardExpirationDate || '',
    CardType: CardType || '',
    CardLast4: CardLast4 || null,
    PaymentID: PaymentID || '',
    PaymentSecret: PaymentSecret || '',
  });
  const [formError, setFormError] = useState<FormError>({});
  const [stripeError, setStripeError] = useState<boolean>(false);
  const [hasSubmittedCardInformation, setHasSubmittedCardInformation] = useState<boolean>(() => !!CardType && !!CardLast4);
  const { t } = useTranslation('handoff');
  const Amount = partner === HEALTHYR && scanRequestData.PrescriptionQuestionResponseID === 0 ? '40.00' : Fee;

  const isConfirmEnabled = useMemo<boolean>(() => {
    const { FullNameOnCard, CardType, CardLast4, PaymentID, PaymentSecret } = formValue;
    const areRequiredFieldsPresent = !!CardType && !!CardLast4 && !!PaymentID && !!PaymentSecret;
    return areRequiredFieldsPresent && !stripeError && (hasSubmittedCardInformation ? true : !!FullNameOnCard);
  }, [formValue, stripeError]);

  const handleOnCompleteStep = (data: PaymentInformationForm) => {
    completeStepCallback(
      {
        ...data,
        SelfPay: 1,
        IsConcierge: null,
        InsurancePlanID: 0,
        InsurancePlan: '',
        PolicyNumber: '',
        FrontOfInsuranceCardFileKey: null,
        BackOfInsuranceCardFileKey: null,
      },
      STEP_NAMES.paymentInformation,
    );
  };

  const handleStripeChange = (error: StripeError | boolean | null, paymentMethod: SourceFastLink | null): void => {
    if (!paymentMethod || !!error) {
      setStripeError(true);
      return;
    }
    const cardData = {
      CardExpirationDate: `${paymentMethod.card?.exp_month}/${paymentMethod.card?.exp_year?.toString().slice(2)}`,
      CardLast4: Number(paymentMethod.card?.last4),
      CardType: paymentMethod.card?.brand as string,
      PaymentID: paymentMethod.id,
      PaymentSecret: paymentMethod.client_secret,
    };

    setFormValue(previous => {
      return {
        ...previous,
        ...cardData,
      };
    });
    setStripeError(false);
    paymentMethod.IsPayFaster &&
      handleOnCompleteStep({
        ...formValue,
        ...cardData,
        FullNameOnCard: paymentMethod.owner?.name || '',
      });
  };

  const changeCardInformation = (): void => {
    setHasSubmittedCardInformation(false);
    setFormValue({
      ...formValue,
      CardLast4: null,
      CardType: '',
      PaymentID: '',
      PaymentSecret: '',
      CardExpirationDate: '',
    });
  };

  const handleConfirm = (event: SyntheticEvent): void => {
    event.preventDefault();
    handleOnCompleteStep(formValue);
  };

  const getBack = (event: SyntheticEvent): void => {
    event.preventDefault();
    goToPreviousStep(STEP_NAMES.paymentInformation);
  };

  const onErrorHandler = (error: any) => {
    if (error) {
      if (error.FullNameOnCard === 'Please add a valid name') {
        const message = t('payment.Please enter a full name');
        return setFormError({ ...error, FullNameOnCard: message });
      } else if (error.FullNameOnCard === 'This field is required.') {
        const message = t('payment.This field is required');
        return setFormError({ ...error, FullNameOnCard: message });
      }
    }
    setFormError(error);
  };

  return (
    <>
      {partner === HEALTHYR && scanRequestData.PrescriptionQuestionResponseID === 0 ? (
        <div className='request-type-payments_total'>
          <div className='row total'>
            <div>Consultation and scheduling services</div>
            <div>$40.00</div>
          </div>
        </div>
      ) : (
        <div className='request-type-payments_total'>
          <div className='row total'>
            <div>{t('payment.Total cost of the appointment')}</div>
            <div>${TotalPrice}</div>
          </div>
          <div className='row pb0'>
            <div>{t('payment.Amount due at time of appointment')}</div>
            <div>${MinPrice}</div>
          </div>
          <div className='row'>
            <div className='opt7base'>
              <b>{t('payment.Amount due today')}</b>
            </div>
            <div>
              <b>${Fee}</b>
            </div>
          </div>
        </div>
      )}
      <Form
        formValue={formValue}
        onChange={value => setFormValue(value as PaymentInformationForm)}
        model={PaymentSchema}
        onCheck={error => onErrorHandler(error)}
        fluid
      >
        {hasSubmittedCardInformation ? (
          <div className='request-type-payments_total mb-16'>
            <div className='row'>
              <b>{t('payment.Card information')}</b>
              <button className='edit-input' onClick={changeCardInformation}>
                {t('payment.Change')}
              </button>
            </div>
            <div className='row'>
              <div>{t('payment.Card number')}:</div>
              <div>
                <b>****-****-****-{CardLast4}</b>
              </div>
            </div>
            <div className='row'>
              <div>{t('payment.Card type')}:</div>
              <div>
                <b>{CardType}</b>
              </div>
            </div>
            {!!CardExpirationDate && !isEditMode && (
              <div className='row'>
                <div>{t('payment.Expires on')}:</div>
                <div>
                  <b>{CardExpirationDate}</b>
                </div>
              </div>
            )}
            {!!FullNameOnCard && !isEditMode && (
              <div className='row payment-row'>
                <div>{t('payment.Name on card')}:</div>
                <div>
                  <b>{FullNameOnCard}</b>
                </div>
              </div>
            )}
          </div>
        ) : (
          <>
            <StripeFields amount={Amount} cardOwner={formValue.FullNameOnCard} callback={handleStripeChange} formError={formError} />
            <div className='stripe-info'>
              <img src={StripeLogo} alt='stripe'></img>
            </div>
          </>
        )}
        <WarningBox>
          <p>
            <b>{t('payment.payment_info.title')}</b>
            {t('payment.payment_info.content')}
          </p>
        </WarningBox>
        <div className='btn-row row justify-content-between full-mob'>
          <button onClick={getBack} className='btn prev btn-white'>
            {t('form.Back', { ns: 'translations' })}
          </button>
          <button className='btn next' disabled={!isConfirmEnabled} onClick={handleConfirm}>
            {partner === HEALTHYR && scanRequestData.PrescriptionQuestionResponseID === 0
              ? 'Order'
              : t('form.Next', { ns: 'translations' })}
          </button>
        </div>
      </Form>
    </>
  );
};

export default PaymentInformation;
