import React, { useState } from 'react';
import { Checkbox, Form } from 'rsuite';
import { MAMMOGRAM_STEP_NAMES, MammogramData, MammogramProps, MammogramSymptomsForm } from '../interfaces';
import TextField from '../../text-field-component';
import Textarea from '../../textarea';
import { useTranslation } from 'react-i18next';

const MammogramSymptoms: React.FunctionComponent<MammogramProps> = ({
  goToPreviousStep,
  scanRequestData,
  completeStepCallback,
}: MammogramProps) => {
  const { t } = useTranslation('handoff');
  const { SymptomsNotes, IsBreastPain, IsNippleDischarge, IsLumpsInYourBreast, HaveNotSymptoms } = scanRequestData;
  const [formValue, setFormValue] = useState<MammogramSymptomsForm>({
    SymptomsNotes,
    HaveNotSymptoms,
    IsBreastPain,
    IsNippleDischarge,
    IsLumpsInYourBreast,
  });

  const onNextHandler = (): void => {
    const { SymptomsNotes, HaveNotSymptoms, IsBreastPain, IsNippleDischarge, IsLumpsInYourBreast } = formValue;
    const payload: Partial<MammogramData> = {
      SymptomsNotes,
      HaveNotSymptoms,
      IsBreastPain,
      IsNippleDischarge,
      IsLumpsInYourBreast,
    };

    completeStepCallback(payload, MAMMOGRAM_STEP_NAMES.symptoms);
  };

  return (
    <div className='main-request-form'>
      <h2 id='When_would_you_like_your_appointment' className='h2'>
        {t('mammogram.symptom_title')}
      </h2>
      <Form onChange={value => setFormValue(value as MammogramSymptomsForm)} formValue={formValue} className='main-form-appointment' fluid>
        <div className='input-checkbox_wrap'>
          <div className='input-checkbox mb8'>
            <Checkbox
              checked={formValue.IsBreastPain}
              onChange={() => setFormValue(previous => ({ ...previous, HaveNotSymptoms: false, IsBreastPain: !previous.IsBreastPain }))}
            >
              {t('mammogram.Breast pain')}
            </Checkbox>
          </div>
          <div className='input-checkbox mb8'>
            <Checkbox
              checked={formValue.IsNippleDischarge}
              onChange={() =>
                setFormValue(previous => ({ ...previous, HaveNotSymptoms: false, IsNippleDischarge: !previous.IsNippleDischarge }))
              }
            >
              {t('mammogram.Nipple discharge')}
            </Checkbox>
          </div>
          <div className='input-checkbox mb8'>
            <Checkbox
              checked={formValue.IsLumpsInYourBreast}
              onChange={() =>
                setFormValue(previous => ({ ...previous, HaveNotSymptoms: false, IsLumpsInYourBreast: !previous.IsLumpsInYourBreast }))
              }
            >
              {t('mammogram.Lumps in your breast')}
            </Checkbox>
          </div>
          <div className='input-checkbox mb0'>
            <Checkbox
              checked={formValue.HaveNotSymptoms}
              onChange={() =>
                setFormValue(previous => ({
                  ...previous,
                  IsBreastPain: false,
                  IsNippleDischarge: false,
                  IsLumpsInYourBreast: false,
                  HaveNotSymptoms: !previous.HaveNotSymptoms,
                }))
              }
            >
              {t('mammogram.None of the Above')}
            </Checkbox>
          </div>
        </div>
        <div className='text-form mb16'>
          <p>{t('contrast.symptoms_text')}</p>
        </div>
        <div className='input-wrap textarea-wrap mb32'>
          <TextField
            label={t('mammogram.Enter your answer here')}
            accepter={Textarea}
            value={formValue.SymptomsNotes}
            name='SymptomsNotes'
          />
        </div>
        <div className='btn-row row full-mob justify-content-between'>
          <button className='btn btn-white back no-arrow' onClick={() => goToPreviousStep(MAMMOGRAM_STEP_NAMES.symptoms)}>
            {t('form.Back', { ns: 'translations' })}
          </button>
          <button className='btn next no-arrow' onClick={onNextHandler}>
            {t('form.Next', { ns: 'translations' })}
          </button>
        </div>
      </Form>
    </div>
  );
};

export default MammogramSymptoms;
