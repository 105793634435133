import React, { ReactElement, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { UserContext } from '../../contexts/UserContext';
import MainFooter from '../main-footer';
import { AppProps } from '../router-component/router-interfaces';
import SideMenu from './SideMenu';
import Logo from '../../assets/medmo-logo.svg';
import LogoСleerly from '../../assets/сleerly-brand.svg';
import LogoSolv from '../../assets/solv_dark.svg';
import { LoginUrl, NewScanUrl, SolvUrl, HealthyrUrl } from '../../config/UrlsConfig';
import InfoModalComponent from '../modals/info-modal';
import { SESSION_STORAGE_KEYS } from '../../views/patient-scan-request/PatientScanRequestUtils';
import ConfirmModal from '../modals/confirm-modal';
import { MODAL_TEXTS } from '../leave-route-prompt/utils';
import { Dropdown } from 'rsuite';
import { HANDOFF_SESSION_STORAGE_KEYS } from '../../views/handoff-process/standard/utils';
import { CommonContext } from '../../contexts/CommonContext';
import { ireIdentify } from '../../utils/impactUtil';
import useLocationLanguage from '../../hooks/useLocationLanguage';
import i18n from 'i18next';

type AuthWrapperProp = {
  children: ReactElement;
};

const DefaultWrapper: React.FunctionComponent<AuthWrapperProp & AppProps> = ({ children, isAuthenticated, logOut, resetUser }) => {
  const [showExpiredModal, setShowExpiredModal] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [language, setLanguage] = useState(useLocationLanguage());
  const currentUser = useContext(UserContext);
  const commonData = useContext(CommonContext);
  const history = useHistory();
  const location = useLocation();
  const path = history.location.pathname;

  const sessionExpiredHandler = (event: PromiseRejectionEvent) => {
    if (event.reason?.status === 401) {
      const currentStepHandOff = sessionStorage.getItem(HANDOFF_SESSION_STORAGE_KEYS.currentStep);
      if (history.location?.pathname.includes('handoff-process') && currentStepHandOff === '0') {
        localStorage.clear();
        resetUser();
      }
      setShowExpiredModal(true);
    }
  };

  const isLoginActive = useMemo(() => {
    return (
      location.pathname.includes('handoff-process') ||
      location.pathname.includes('/form/') ||
      location.pathname.includes('/cleerly') ||
      location.pathname.includes('/file') ||
      location.pathname.includes('/p/') ||
      location.pathname.includes('/sf/') ||
      location.pathname.includes('/pa/')
    );
  }, [location.pathname]);

  const isPatientPingProcessUrl = useMemo(() => {
    return location.pathname.includes('/form/') || location.pathname.includes('/p/');
  }, [location.pathname]);

  const isPreAppointmentProcessUrl = useMemo(() => {
    return location.pathname.includes('/pa/');
  }, [location.pathname]);

  const isSendFilesProcessUrl = useMemo(() => {
    return location.pathname.includes('/sf/');
  }, [location.pathname]);

  const isChangeLangActive = useMemo(() => {
    return isPreAppointmentProcessUrl || isPatientPingProcessUrl || isSendFilesProcessUrl;
  }, [isPreAppointmentProcessUrl, isPatientPingProcessUrl, isSendFilesProcessUrl]);

  const isCleerlyActive = useMemo(() => {
    return location.pathname.includes('/cleerly');
  }, [location.pathname]);

  const onCloseExpiredModal = () => {
    setShowExpiredModal(false);
    localStorage.clear();
    resetUser();
  };

  useEffect(() => {
    window.addEventListener('unhandledrejection', sessionExpiredHandler);
    window.scrollTo(0, 0);
    return () => {
      window.removeEventListener('unhandledrejection', sessionExpiredHandler);
    };
  }, []);

  if (history && history.location) {
    localStorage.setItem('route', history.location.pathname);
  }

  const handleClickLogout = () => {
    const currentStep = sessionStorage.getItem(SESSION_STORAGE_KEYS.currentStep);

    if (currentStep && +currentStep > 0 && +currentStep < 14) {
      setShowLogoutModal(true);
    } else if (currentStep && +currentStep === 14) {
      onCloseLogoutModal(true);
    } else {
      logOut();
    }
  };

  const onCloseLogoutModal = (value: boolean) => {
    setShowLogoutModal(false);

    if (value) {
      sessionStorage.clear();
      logOut();
    }
  };

  const rememberPathBeforeLogin = () => {
    const savedCurrentStep = sessionStorage.getItem(SESSION_STORAGE_KEYS.currentStep);
    if (savedCurrentStep && +savedCurrentStep > 0) {
      localStorage.setItem('nextPath', path === '/' ? NewScanUrl : path);
    }
    if (path === SolvUrl) {
      localStorage.setItem('nextPath', SolvUrl);
    } else if (path === HealthyrUrl) {
      localStorage.setItem('nextPath', HealthyrUrl);
    }
  };

  const changeLanguage = (lang: string) => {
    const nextLang = lang === 'en' ? 'es' : 'en';
    i18n.changeLanguage(nextLang).then(() => {
      window.history.replaceState(null, '', `?hl=${nextLang}`);
      setLanguage(nextLang);
    });
  };

  useEffect(() => {
    currentUser.UserID ? ireIdentify(String(currentUser.UserID)) : ireIdentify();
  }, [location.key, currentUser]);

  return (
    <>
      <header
        className={`header ${path === SolvUrl && isAuthenticated ? 'header-auth header-partner' : isAuthenticated ? 'header-auth' : ''}`}
      >
        <div className='container'>
          <div className='row'>
            <Link className='header-logo' to='/'>
              <img src={Logo} alt='medmo' title='medmo' />
            </Link>
            {path === SolvUrl && (
              <Link className='header-logo solv-logo' to={path}>
                <img src={LogoSolv} alt='Solv' title='solv' />
              </Link>
            )}
            {isAuthenticated && currentUser ? (
              <nav className='header-nav'>
                <Dropdown
                  className='header-nav_link btn btn-white'
                  title={
                    <div>
                      <span>{currentUser.FirstName}</span> <span>{currentUser.LastName}</span>
                    </div>
                  }
                >
                  <Dropdown.Item onClick={logOut}>Logout</Dropdown.Item>
                </Dropdown>
              </nav>
            ) : (
              <>
                {isLoginActive && (commonData.IsCleerly || isCleerlyActive) && (
                  <Link className='сleerly-logo' to='/'>
                    <img src={LogoСleerly} alt='Сleerly' title='Сleerly' />
                  </Link>
                )}
                {!isLoginActive && (
                  <nav className='header-nav'>
                    <Link className='header-nav_link btn btn-white' onClick={rememberPathBeforeLogin} to={LoginUrl}>
                      Log In
                    </Link>
                  </nav>
                )}
                {isLoginActive && isChangeLangActive && (
                  <button className='btn btn-lang btn-white' onClick={() => changeLanguage(language)}>
                    {language === 'es' ? 'English' : 'Español'}
                  </button>
                )}
              </>
            )}
          </div>
        </div>
      </header>
      <main className='main'>
        {isAuthenticated && (
          <div className='row'>
            <SideMenu logOut={handleClickLogout} />
            {React.cloneElement(children, { isAuthenticated: true })}
          </div>
        )}
        {!isAuthenticated && React.cloneElement(children, { isAuthenticated: false })}
      </main>
      <MainFooter />
      {showExpiredModal && (
        <InfoModalComponent
          onClose={onCloseExpiredModal}
          texts={{
            title: 'Your session has expired',
            content: 'You have been logged out due to inactivity. Please log in again.',
          }}
        />
      )}
      {showLogoutModal && <ConfirmModal onClose={onCloseLogoutModal} info={MODAL_TEXTS} />}
    </>
  );
};

export default DefaultWrapper;
