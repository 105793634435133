import React, { useState } from 'react';
import { Form } from 'rsuite';
import { MAMMOGRAM_STEP_NAMES, MammogramData, MammogramLocationForm, MammogramProps } from '../interfaces';
import TextField from '../../text-field-component';
import Textarea from '../../textarea';
import { useTranslation } from 'react-i18next';

const MammogramLocation: React.FunctionComponent<MammogramProps> = ({
  goToPreviousStep,
  scanRequestData,
  completeStepCallback,
}: MammogramProps) => {
  const { t } = useTranslation('handoff');
  const { LocationNotes } = scanRequestData;
  const [formValue, setFormValue] = useState<MammogramLocationForm>({ LocationNotes });

  const onNextHandler = (): void => {
    const { LocationNotes } = formValue;
    const payload: Partial<MammogramData> = {
      LocationNotes,
    };

    completeStepCallback(payload, MAMMOGRAM_STEP_NAMES.location);
  };

  return (
    <div className='main-request-form'>
      <h2 id='When_would_you_like_your_appointment' className='h2'>
        {t('mammogram.Where did you have your last Mammogram?')}
      </h2>
      <Form onChange={value => setFormValue(value as MammogramLocationForm)} formValue={formValue} className='main-form-appointment' fluid>
        <div className='input-wrap textarea-wrap mb32'>
          <TextField
            label={t('mammogram.Enter your answer here')}
            accepter={Textarea}
            value={formValue.LocationNotes}
            name='LocationNotes'
          />
        </div>
        <div className='btn-row row full-mob justify-content-between'>
          <button className='btn btn-white back no-arrow' onClick={() => goToPreviousStep(MAMMOGRAM_STEP_NAMES.location)}>
            {t('form.Back', { ns: 'translations' })}
          </button>
          <button className='btn next no-arrow' onClick={onNextHandler}>
            {t('form.Next', { ns: 'translations' })}
          </button>
        </div>
      </Form>
    </div>
  );
};

export default MammogramLocation;
