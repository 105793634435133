import React from 'react';
import { CovidResponse } from '../models/CovidResponse';
import { DropdownOption } from '../models/DropdownOption';
import { InsurancePlan } from '../models/InsurancePlan';
import { PatientPriorityOption } from '../models/PatientPriorityOption';
import { ScanType } from '../models/ScanType';

export type CommonContextTypes = {
  PatientPrioritises: PatientPriorityOption[];
  CovidResponses: CovidResponse[];
  Genders: DropdownOption[];
  InsurancePlans: InsurancePlan[];
  ScanTypes: ScanType[];
  IsCleerly: boolean;
};

export type CommonContextFunctions = {
  getPrioritiesOptions: () => void;
  getCovidResponses: () => void;
  getGenders: () => void;
  getInsurancePlans: () => void;
  getScanTypes: () => void;
  updateIsCleerly: (bool: boolean) => void;
};

export const CommonContext = React.createContext<CommonContextTypes & CommonContextFunctions>({
  PatientPrioritises: [],
  CovidResponses: [],
  Genders: [],
  InsurancePlans: [],
  ScanTypes: [],
  IsCleerly: false,
  updateIsCleerly: () => {
    return;
  },
  getPrioritiesOptions: () => {
    return;
  },
  getCovidResponses: () => {
    return;
  },
  getGenders: () => {
    return;
  },
  getInsurancePlans: () => {
    return;
  },
  getScanTypes: () => {
    return;
  },
});
