import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { checkAuth } from './api/auth/AuthAPI';
import { login, logout } from './api/login/LoginAPI';
import RouterComponent from './components/router-component';
import { MixpanelContext, mixpanelValue } from './contexts/MixpanelContext';
import { UserContext } from './contexts/UserContext';
import { ServerUserResponse, User } from './models/User';
import { convertToLocalDate, getUtcOffset, transformDate } from './utils/DateUtil';
import { RouteConfig } from './utils/RouterUtil';
import { LoginFormData } from './views/login/interfaces';
import { getVersionAPI } from './api/version/VersionAPI';
import { APP_VERSION } from './config/FrontConfig';
import pkj from '../package.json';
import LoaderComponent from './components/loader-component';
import { createUserSessionLog } from './api/users/UsersAPI';
import { PopulusData, PopulusVisitID } from './components/populus-media/utils';
import moment from 'moment';
import { CommonContext, CommonContextTypes } from './contexts/CommonContext';
import { getCovidResponsesAPI } from './api/covid-response/CovidResponseAPI';
import { DropdownOption } from './models/DropdownOption';
import { getGendersAPI } from './api/genders/GendersAPI';
import { getPrioritiesOptionsAPI } from './api/patient-priorities/PatientPrioritiesAPI';
import { getScanTypesAPI } from './api/scan-types/ScanTypesAPI';
import { REUPLOAD_INTERVAL_IN_MINUTES } from './utils/GeneralUtil';
import { differenceInMinutes } from 'date-fns';
import { getInsurancePlansAPI } from './api/insurance/InsuranceAPI';
import { ireIdentify } from './utils/impactUtil';
import i18n from 'i18next';
import useLocationLanguage from './hooks/useLocationLanguage';

const App: React.FunctionComponent = () => {
  const lang = useLocationLanguage();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(!!localStorage.getItem('isAuth'));
  const [user, setUser] = useState<User | null>(null);
  const [isReloading, setIsReloading] = useState(true);
  const [commonData, setCommonData] = useState<CommonContextTypes>({
    PatientPrioritises: [],
    CovidResponses: [],
    Genders: [],
    InsurancePlans: [],
    ScanTypes: [],
    IsCleerly: false,
  });
  const mixpanel = useContext(MixpanelContext);
  const previousScanTypesTimeReloaded = useRef<Date>(new Date());
  const previousInsurancePlansTimeReloaded = useRef<Date>(new Date());

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang]);

  useEffect(() => {
    const APP_INFO = { APP: APP_VERSION === '0.0.0' ? pkj.version : APP_VERSION, API: '' };
    getVersionAPI()
      .then(r => (APP_INFO.API = r.info))
      .finally(() => (window.medmo_version = JSON.stringify(APP_INFO)));
  }, []);

  useEffect(() => {
    window.addEventListener('beforeunload', addPopulusVisitorID);
    checkPopulusVisitorID();
    return () => {
      window.removeEventListener('beforeunload', addPopulusVisitorID);
    };
  }, []);

  useEffect(() => {
    const isAuth = localStorage.getItem('isAuth');
    if (isAuth === 'true') {
      localStorage.removeItem('isAuth');
      updateUser();
    } else {
      setIsReloading(false);
      ireIdentify();
    }
  }, []);

  const addPopulusVisitorID = () => {
    const visitorId = sessionStorage.getItem(PopulusVisitID);
    const populusData = { visitorId, date: moment().utc() };
    localStorage.setItem(PopulusData, JSON.stringify(populusData));
    return true;
  };

  const checkPopulusVisitorID = () => {
    const populusRawData = localStorage.getItem(PopulusData);
    if (populusRawData) {
      const populusData = JSON.parse(populusRawData);
      const isSessionId = sessionStorage.getItem(PopulusVisitID);
      if (!populusData.visitorId || isSessionId) {
        localStorage.removeItem(PopulusData);
        return;
      }
      const dateofvisit = moment(populusData.date);
      const today = moment().utc();
      const minutes = today.diff(dateofvisit, 'minutes');
      if (minutes <= 30) {
        sessionStorage.setItem(PopulusVisitID, populusData.visitorId);
      }
    }
    localStorage.removeItem(PopulusData);
  };

  const updateUserData = (data: User | null) => {
    if (data) {
      data.UserID ? ireIdentify(String(data.UserID)) : ireIdentify();
      return { ...data, DOB: transformDate(data.DOB, 'MM/DD/YYYY') };
    }

    return null;
  };

  const checkUser = (data: ServerUserResponse): void => {
    if (data?.status && data?.status > 200) {
      ireIdentify();
      localStorage.removeItem('isAuth');
      return;
    }

    setIsAuthenticated(true);
    localStorage.setItem('isAuth', 'true');
    setUser(updateUserData(data));
    setIsReloading(false);
  };

  const updateUser = (): void => {
    checkAuth()
      .then(res => {
        if (res && res.status) {
          localStorage.clear();
          return;
        }
        checkUser(res);
      })
      .catch(error => {
        localStorage.removeItem('isAuth');
        if (error.status && error.status === 404) {
          localStorage.setItem('isSessionExpired', 'true');
        }
      })
      .finally(() => setIsReloading(false));
  };

  const expandUser = (data: Record<string, any> | User) => {
    if (user) {
      const newUser = { ...user, ...data };
      newUser.UserID ? ireIdentify(String(newUser.UserID)) : ireIdentify();
      setUser(newUser);
    } else {
      const newUser = { ...(data as User) };
      newUser.UserID ? ireIdentify(String(newUser.UserID)) : ireIdentify();
      setUser(newUser);
      setIsAuthenticated(true);
    }
  };

  const createSessionLog = () => {
    const sessionStart = convertToLocalDate(new Date());
    const utcOffset = getUtcOffset();
    createUserSessionLog(sessionStart, utcOffset).then();
  };

  const authCheck = useCallback((data: LoginFormData, callbackBeforeSavingAuth?: (d: ServerUserResponse) => void) => {
    return new Promise((resolve, reject) => {
      login(data)
        .then(res => {
          if (res.status && res.status > 200) {
            return reject(res);
          }
          mixpanel.identify(res.UserID.toString());
          callbackBeforeSavingAuth && callbackBeforeSavingAuth(res);
          checkUser(res);
          createSessionLog();
          resolve({ ...res });
        })
        .catch(e => {
          localStorage.removeItem('isAuth');
          reject(e);
        });
    });
  }, []);

  const resetUser = useCallback(() => {
    setIsAuthenticated(false);
    setUser(null);
    ireIdentify();
    mixpanel.reset();
    localStorage.removeItem('isAuth');
    localStorage.removeItem('route');
    isReloading && setIsReloading(false);
  }, [isReloading]);

  const logOut = useCallback(() => {
    logout().finally(() => {
      resetUser();
    });
  }, []);

  const getCovidResponses = (): void => {
    getCovidResponsesAPI().then(covidResponsesFromAPI => {
      const sortedCovidResponses = covidResponsesFromAPI.sort((a, b) => (a.Response < b.Response ? 1 : a.Response > b.Response ? -1 : 0));
      setCommonData({ ...commonData, CovidResponses: sortedCovidResponses });
    });
  };

  const getGenders = (): void => {
    getGendersAPI().then(biologicalSexesFromAPI => {
      const parsedBiologicalSexes: DropdownOption[] = biologicalSexesFromAPI.map(({ ID, BiologicalSex }) => ({
        value: ID,
        label: BiologicalSex,
      }));
      setCommonData({ ...commonData, Genders: parsedBiologicalSexes });
    });
  };

  const getPrioritiesOptions = (): void => {
    getPrioritiesOptionsAPI().then(options => setCommonData({ ...commonData, PatientPrioritises: options }));
  };

  const getScanTypes = (): void => {
    if (
      commonData.ScanTypes.length &&
      differenceInMinutes(new Date(), previousScanTypesTimeReloaded.current) < REUPLOAD_INTERVAL_IN_MINUTES
    ) {
      return;
    }

    getScanTypesAPI().then(scanTypes => {
      previousScanTypesTimeReloaded.current = new Date();
      setCommonData({ ...commonData, ScanTypes: scanTypes });
    });
  };

  const updateIsCleerly = (bool: boolean): void => {
    setCommonData({ ...commonData, IsCleerly: bool });
  };

  const getInsurancePlans = (): void => {
    if (
      commonData.InsurancePlans.length &&
      differenceInMinutes(new Date(), previousInsurancePlansTimeReloaded.current) < REUPLOAD_INTERVAL_IN_MINUTES
    ) {
      return;
    }

    getInsurancePlansAPI().then(insurancePlansFromAPI => {
      previousInsurancePlansTimeReloaded.current = new Date();
      setCommonData({ ...commonData, InsurancePlans: insurancePlansFromAPI });
    });
  };

  return (
    <div className='wrapper'>
      {isReloading ? (
        <main className='main'>
          <section className='page-section'>
            <div className='container'>
              <div className='row'>
                <LoaderComponent />
              </div>
            </div>
          </section>
        </main>
      ) : (
        <UserContext.Provider value={{ ...(user as User), updateUser, expandUser, resetUser }}>
          <CommonContext.Provider
            value={{ ...commonData, getCovidResponses, getPrioritiesOptions, getGenders, getInsurancePlans, getScanTypes, updateIsCleerly }}
          >
            <RouterComponent
              isAuthenticated={isAuthenticated}
              routes={RouteConfig}
              authenticate={authCheck}
              resetUser={resetUser}
              logOut={logOut}
            />
          </CommonContext.Provider>
        </UserContext.Provider>
      )}
    </div>
  );
};

const MixpanelWrapper: React.FunctionComponent = () => (
  <MixpanelContext.Provider value={mixpanelValue}>
    <App />
  </MixpanelContext.Provider>
);

export default MixpanelWrapper;
