import React from 'react';
import { CLEERLY_HANDOFF_STEP_NAMES } from '../../views/handoff-process/cleerly/utils';
import { ScanRequest } from '../../models/ScanRequest';

type PaymentCCTAInformationProps = {
  goToPreviousStep: (stepName: string) => void;
  completeStepCallback: (partialScanData: Partial<ScanRequest>, stepName?: string) => void;
};

const PaymentCCTAInformation: React.FunctionComponent<PaymentCCTAInformationProps> = ({ goToPreviousStep, completeStepCallback }) => {
  return (
    <div className='handoff-process'>
      <div className='how-medmo-works mb32'>
        <p>
          Our Care Team will find you the best center based on your preferences with the best self-pay rate and contact you with these
          details prior to your appointment.
        </p>
      </div>
      <div className='btn-row row justify-content-between full-mob'>
        <button onClick={() => goToPreviousStep(CLEERLY_HANDOFF_STEP_NAMES.paymentInformation)} className='btn prev btn-white'>
          Back
        </button>
        <button className='btn next' onClick={() => completeStepCallback({}, CLEERLY_HANDOFF_STEP_NAMES.paymentInformation)}>
          Next
        </button>
      </div>
    </div>
  );
};

export default PaymentCCTAInformation;
