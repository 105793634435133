import React, { FC, useCallback, useEffect, useState } from 'react';
import {
  CreateSourceData,
  loadStripe,
  Stripe,
  StripeCardElement,
  StripeCardElementChangeEvent,
  StripeElements,
  StripeError,
} from '@stripe/stripe-js';
import { CardElement, Elements, useStripe, useElements, PaymentRequestButtonElement } from '@stripe/react-stripe-js';
import TextField from '../text-field-component';
import { STRIPE_API_KEY } from '../../config/FrontConfig';
import { StripeFieldsProps } from './interfaces';
import CardField from './card-field';
import debounce from 'debounce';
import { useTranslation } from 'react-i18next';
import { PaymentRequestSourceEvent } from '@stripe/stripe-js/types/stripe-js/payment-request';

const stripePromise = loadStripe(STRIPE_API_KEY);

const CheckoutForm: FC<StripeFieldsProps> = ({ formError, cardOwner, callback, isFullNameShown, amount, sourceCardOptions = {} }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isCardComplete, setIsCardComplete] = useState(false);
  const { t } = useTranslation('handoff');
  const [paymentRequest, setPaymentRequest] = useState<any>(null);

  useEffect(() => {
    if (isCardComplete) {
      cardOwner && debounceCheckFields(stripe, elements, cardOwner);
    }
  }, [cardOwner]);

  const onPaymentRequestSourceHandler = (event: PaymentRequestSourceEvent) => {
    event.complete('success');
    callback(null, { ...event.source, IsPayFaster: true });
  };

  useEffect(() => {
    if (stripe && amount) {
      const pr = stripe.paymentRequest({
        country: 'US',
        currency: 'usd',
        total: {
          label: 'Total',
          amount: amount ? Number(amount) * 100 : 0,
          pending: !amount,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      pr.canMakePayment().then(result => {
        if (result) {
          pr.on('source', onPaymentRequestSourceHandler);
          setPaymentRequest(pr);
        }
      });
    }
  }, [stripe, amount]);

  const handleCardChange = (event?: StripeCardElementChangeEvent): void => {
    if (event) {
      setIsCardComplete(event.complete);
    }
    if (isFullNameShown) {
      return checkFields(stripe, elements, cardOwner);
    }
    checkFields(stripe, elements, '');
  };

  const checkFields = (stripe: Stripe | null, elements: StripeElements | null, name = ''): void => {
    if (!stripe || !elements) {
      return;
    }
    const cardOptions = isFullNameShown ? { owner: { name } } : sourceCardOptions;
    const card = elements.getElement(CardElement);
    stripe.createSource(card as StripeCardElement, cardOptions as CreateSourceData).then(stripeSource => {
      if (stripeSource.error !== undefined) {
        const newErrorMessage = getErrorMessage(stripeSource.error);
        setErrorMessage(newErrorMessage);
        callback(stripeSource.error, null);
      } else {
        setErrorMessage('');
        callback(null, stripeSource.source);
      }
    });
  };

  const debounceCheckFields = useCallback(debounce(checkFields, 400), []);

  const getErrorMessage = (error: StripeError): string => {
    if (error.code === 'parameter_invalid_empty') {
      return t('payment.card_name_error');
    }
    return error?.message ?? 'Error on card.';
  };

  if (!isFullNameShown) {
    return (
      <>
        {paymentRequest && (
          <div>
            <div>
              <PaymentRequestButtonElement options={{ paymentRequest }} />
            </div>
            <div className='or-text'>OR</div>
          </div>
        )}
        <div className='form-new-scan-stripe'>
          <CardField onChange={handleCardChange} errorMessage={errorMessage} />
        </div>
      </>
    );
  }

  return (
    <>
      {paymentRequest && (
        <div>
          <div>
            <PaymentRequestButtonElement options={{ paymentRequest }} />
          </div>
          <div className='or-text'>{t('payment.or')}</div>
        </div>
      )}
      <div className='form-new-scan-stripe'>
        <div className='rs-form-group form-new-scan-group width-l'>
          <TextField
            name='FullNameOnCard'
            error={formError.FullNameOnCard}
            autoComplete='off'
            value={cardOwner}
            label={t('payment.Full name on card')}
          />
        </div>
        <CardField onChange={handleCardChange} errorMessage={errorMessage} />
      </div>
    </>
  );
};

const StripeFields: FC<StripeFieldsProps> = props => {
  const { formError, cardOwner, callback, sourceCardOptions = {}, isFullNameShown = true, amount } = props;

  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm
        amount={amount}
        formError={formError}
        cardOwner={cardOwner}
        sourceCardOptions={sourceCardOptions}
        isFullNameShown={isFullNameShown}
        callback={callback}
      />
    </Elements>
  );
};

export default StripeFields;
