import React, { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import LogoWhite from '../../assets/medmo-logo-white.svg';
import { APP_CORP_WEB_URL } from '../../config/FrontConfig';
import { BaaUrl, NewScanUrl } from '../../config/UrlsConfig';

const MainFooter: React.FunctionComponent = () => {
  const history = useHistory();
  const location = useLocation();

  if (history && history.location) {
    localStorage.setItem('route', history.location.pathname);
  }

  const isBookScanActive = useMemo(() => {
    return !location.pathname.includes('/cleerly');
  }, [location.pathname]);

  return (
    <footer className='footer'>
      <div className='container'>
        <div className='row'>
          <div className='footer-logo-wrap'>
            <Link className='footer-logo' to='/'>
              <img src={LogoWhite} alt='medmo' title='medmo' />
            </Link>
            <p>
              {new Date().getFullYear()} Medmo, Inc.
              <br /> All Rights Reserved
              <br /> New York, NY, USA
              <br />
              <a className='support-mail' href='mailto:support@medmo.com'>
                support@medmo.com
              </a>
            </p>
          </div>
          <nav className='footer-nav row'>
            <ul>
              {isBookScanActive ? (
                <li>
                  <Link to={NewScanUrl}>Book a Scan</Link>
                </li>
              ) : null}
              <li>
                <a href={`${APP_CORP_WEB_URL}/terms/patients/`} target='_blank' rel='noreferrer'>
                  Terms of Service
                </a>
              </li>
              <li>
                <a href={APP_CORP_WEB_URL} target='_blank' rel='noreferrer'>
                  Corporate Website
                </a>
              </li>
              <li>
                <a href={`${APP_CORP_WEB_URL}/privacy/`} target='_blank' rel='noreferrer'>
                  Security & Privacy
                </a>
              </li>
              <li>
                <a href={`${APP_CORP_WEB_URL}/accreditation/`} target='_blank' rel='noreferrer'>
                  Quality & Accreditation
                </a>
              </li>
              <li>
                <a href={`${APP_CORP_WEB_URL}/contact-us/`} target='_blank' rel='noreferrer'>
                  Contact Us
                </a>
              </li>
              <li>
                <Link to={BaaUrl}>HIPAA Compliance</Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </footer>
  );
};

export default MainFooter;
