import React, { useContext, useEffect, useMemo, useState } from 'react';
import LoaderComponent from '../../components/loader-component';
import useLocationLanguage from '../../hooks/useLocationLanguage';
import { useLocation, useParams } from 'react-router';
import { MixpanelContext } from '../../contexts/MixpanelContext';
import { Progress } from 'rsuite';
import AppointmentDocuments from '../../components/appointment-documents';
import ConfirmYourIdentity from './confirm-your-identity';
import {
  APPOINTMENT_REMINDER_LANGUAGE_CHANGED,
  APPOINTMENT_REMINDER_OPENED,
  SEND_FILES_LANGUAGE_CHANGED,
  SEND_FILES_OPENED,
} from '../../utils/MixpanelEvents';

const PreAppointmentProcess: React.FunctionComponent = () => {
  const { Line } = Progress;
  const lang = useLocationLanguage();
  const { invitationID } = useParams<{ invitationID: string }>();
  const [isLoading, setIsLoading] = useState(true);
  const [isDOBValid, setIsDOBValid] = useState(false);
  const mixpanel = useContext(MixpanelContext);
  const location = useLocation();

  const isSendFilesProcessUrl = useMemo(() => {
    return location.pathname.includes('/sf/');
  }, [location.pathname]);

  const percentage = useMemo(() => {
    const steps = 2;
    return (0 / steps) * 100;
  }, []);

  useEffect(() => {
    const OriginalLanguage = lang === 'en' ? 'es' : 'en';
    const trackTitle = isSendFilesProcessUrl ? SEND_FILES_LANGUAGE_CHANGED : APPOINTMENT_REMINDER_LANGUAGE_CHANGED;
    mixpanel.track(trackTitle, {
      InvitationID: invitationID,
      OriginalLanguage,
      NewLanguage: lang,
    });
  }, [lang]);

  useEffect(() => {
    const trackTitle = isSendFilesProcessUrl ? SEND_FILES_OPENED : APPOINTMENT_REMINDER_OPENED;
    mixpanel.track(trackTitle, { InvitationID: invitationID });
    setIsLoading(false);
  }, []);

  const confirmDOBCallback = () => {
    setIsDOBValid(true);
    window.scrollTo(0, 0);
  };

  if (isLoading) {
    return <LoaderComponent className='loader-block-center' />;
  }

  return (
    <section className='main-request-section'>
      <Line percent={percentage} strokeColor='#ff8a35' />
      <div className='container'>
        <div className='row'>{isDOBValid ? <AppointmentDocuments /> : <ConfirmYourIdentity completeCallback={confirmDOBCallback} />}</div>
      </div>
    </section>
  );
};

export default PreAppointmentProcess;
