import React from 'react';
import { Modal } from 'rsuite';
import { ConfirmModalProps } from './interfaces';

const ConfirmModal: React.FunctionComponent<ConfirmModalProps> = props => {
  const {
    onClose,
    info: { title, text, cancelButtonText, submitButtonText },
  } = props;

  return (
    <div>
      <Modal size={'sm'} className='modal-info' open onClose={() => onClose(false)}>
        <Modal.Header closeButton={false}>
          <Modal.Title>{title && title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='model-desk'>
            <p>{text}</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className='justify-content-end'>
            <button className='btn btn-white btn-cancel' onClick={() => onClose(false)}>
              {cancelButtonText}
            </button>
            <button className='btn' onClick={() => onClose(true)}>
              {submitButtonText}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ConfirmModal;
