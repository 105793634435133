import { SERVER_URL } from '../../config/ServerConfig';
import { request } from '../Request';
import { ScanType } from '../../models/ScanType';

export function getScanTypesAPI(): Promise<ScanType[]> {
  return request({
    url: `${SERVER_URL}/scan-types/list`,
    method: 'GET',
  });
}
