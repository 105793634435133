import React, { useState } from 'react';
import { Form } from 'rsuite';
import TextField from '../../text-field-component';
import Textarea from '../../textarea';
import InfoBox from '../../info-box';
import { useTranslation } from 'react-i18next';
import { MetalBodyDetailsForm, MetalBodyGeneralProps, PING_METAL_STEP_NAMES } from '../interfaces';

const MetalBodyDetails: React.FunctionComponent<MetalBodyGeneralProps> = ({
  goToPreviousStep,
  completeStepCallback,
  scanRequestData,
  infoBox,
  stepName = PING_METAL_STEP_NAMES.metalBodyDetails,
}) => {
  const { t } = useTranslation('handoff');
  const { MetalInfo = null } = scanRequestData;

  const [formValue, setFormValue] = useState<MetalBodyDetailsForm>({
    MetalInfo,
  });

  const selectResponse = (): void => {
    completeStepCallback({ MetalInfo: formValue.MetalInfo }, PING_METAL_STEP_NAMES.metalBodyDetails);
  };

  return (
    <>
      <div className='main-request-form'>
        <h2 id='Additional_questions' className='h2'>
          {t('metal.Please provide any helpful information about the metal in your body')}
        </h2>
        <div className='text-form fw600'>
          <p>{t('metal.sub_title')}</p>
        </div>
        <Form onChange={value => setFormValue(value as MetalBodyDetailsForm)} formValue={formValue} className='main-form-appointment' fluid>
          <div className='input-wrap textarea-wrap h80 mb32'>
            <TextField name='MetalInfo' value={formValue.MetalInfo} label={t('metal.E.g. I have dental metals')} accepter={Textarea} />
          </div>
        </Form>
        <div className='btn-row row full-mob justify-content-between'>
          <button onClick={() => goToPreviousStep(stepName)} className='btn prev btn-white'>
            {t('form.Back', { ns: 'translations' })}
          </button>
          <button className='btn next' onClick={() => selectResponse()}>
            {t('form.Next', { ns: 'translations' })}
          </button>
        </div>
      </div>
      {infoBox ? <InfoBox title={infoBox.title} content={infoBox.content} /> : null}
    </>
  );
};

export default MetalBodyDetails;
