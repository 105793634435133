import {
  EMPTY_NON_CONFIRMED_SCAN_TYPE_SELECTION,
  SCAN_REQUEST_INITIAL_DATA,
  SESSION_STORAGE_USER_TRAFFIC_INT,
} from './PatientScanRequestUtils';

export const resetScanData = () => {
  sessionStorage.setItem('currentStep', '0');
  sessionStorage.setItem('zipCode', '');
  sessionStorage.setItem('scanRequestData', JSON.stringify(SCAN_REQUEST_INITIAL_DATA));
  sessionStorage.setItem('nonConfirmedScanTypeSelection', JSON.stringify(EMPTY_NON_CONFIRMED_SCAN_TYPE_SELECTION));
  sessionStorage.setItem('scanData', JSON.stringify(SCAN_REQUEST_INITIAL_DATA));
  sessionStorage.setItem('errorSubmitting', 'false');
  sessionStorage.removeItem(SESSION_STORAGE_USER_TRAFFIC_INT);
};
