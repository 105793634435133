import { debounce } from 'debounce';
import React, { useCallback, useEffect, useState } from 'react';
import { MOBILE_SCREEN_WIDTH } from '../../utils/GeneralUtil';
import { InfoBoxProps } from './interfaces';

const InfoBox: React.FunctionComponent<InfoBoxProps> = ({ title, content, urlText, url }) => {
  const [isInfoBoxShown, setInfoBoxShown] = useState<boolean>(false);
  const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', debouncedChangeScreenWidth);
    return () => {
      window.removeEventListener('resize', debouncedChangeScreenWidth);
    };
  }, []);

  const changeScreenWidth = () => setScreenWidth(window.innerWidth);

  const debouncedChangeScreenWidth = useCallback(debounce(changeScreenWidth, 400), []);

  useEffect(() => {
    setInfoBoxShown(screenWidth > MOBILE_SCREEN_WIDTH);
  }, [screenWidth]);

  return (
    <aside className={`main-sidebar ${!isInfoBoxShown && 'close'}`}>
      <div className='main-sidebar_wrap'>
        <button className={`btn-info ${isInfoBoxShown && 'default-cursor'}`} onClick={() => setInfoBoxShown(true)}>
          <span className='icon-error-black'></span>
        </button>
        <button className='btn-minimize' onClick={() => setInfoBoxShown(false)}>
          <span className='icon-minimize-black'></span>
        </button>
        <div className='main-sidebar_title'>{title}</div>
        <div className='main-sidebar_content'>
          <p>{content}</p>
        </div>
        {urlText && url && (
          <a href={url} target='_blank' rel='noreferrer'>
            {urlText}
          </a>
        )}
      </div>
    </aside>
  );
};

export default InfoBox;
