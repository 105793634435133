import React, { SyntheticEvent } from 'react';
import { Tooltip, Whisper } from 'rsuite';
import { EditScanPartProps } from './interfaces';

const EditScanPart: React.FunctionComponent<EditScanPartProps> = props => {
  const { titleClassName, label, className, value, values, disabled, handleEdit, tooltipHint } = props;

  const handleClick = (e: SyntheticEvent) => {
    if (!disabled && handleEdit) {
      handleEdit(e);
    }
  };

  return (
    <div className={`${disabled ? 'disabled' : ''} ${className ? className : ''}`} onClick={handleClick}>
      <div className={titleClassName ? titleClassName : ''}>
        <div className='title'>{label}</div>
      </div>
      <div className='input-wrap'>
        {values ? (
          values.map((val, i) => {
            if (val) {
              return (
                <div key={i} className='input-wrap-field'>
                  {val}
                </div>
              );
            }
          })
        ) : (
          <div className='input-wrap-field'>{value}</div>
        )}
      </div>
      {tooltipHint && (
        <Whisper placement='top' trigger='hover' speaker={<Tooltip>{tooltipHint}</Tooltip>}>
          <div className='tooltip-mask'></div>
        </Whisper>
      )}
    </div>
  );
};

export default EditScanPart;
