import React, { useState } from 'react';
import { Form } from 'rsuite';
import TextField from '../../text-field-component';
import Textarea from '../../textarea';
import InfoBox from '../../info-box';
import { useTranslation } from 'react-i18next';
import { MetalBodyGeneralProps, MetalBodyLocationForm, PING_METAL_STEP_NAMES } from '../interfaces';

const MetalBodyLocation: React.FunctionComponent<MetalBodyGeneralProps> = ({
  goToPreviousStep,
  completeStepCallback,
  scanRequestData,
  infoBox,
  stepName = PING_METAL_STEP_NAMES.metalBodyLocation,
}) => {
  const { t } = useTranslation('handoff');
  const { MetalLocation = null } = scanRequestData;

  const [formValue, setFormValue] = useState<MetalBodyLocationForm>({
    MetalLocation,
  });

  const selectResponse = (): void => {
    completeStepCallback({ MetalLocation: formValue.MetalLocation }, PING_METAL_STEP_NAMES.metalBodyLocation);
  };

  return (
    <>
      <div className='main-request-form'>
        <h2 id='Additional_questions' className='h2'>
          {t('metal.Where is the metal located in your body?')}
        </h2>
        <div className='text-form fw600'>
          <p>{t('metal.sub_title')}</p>
        </div>
        <Form
          onChange={value => setFormValue(value as MetalBodyLocationForm)}
          formValue={formValue}
          className='main-form-appointment'
          fluid
        >
          <TextField name='MetalLocation' value={formValue.MetalLocation} label={t('metal.E.g. Right hip')} accepter={Textarea} />
        </Form>
        <div className='btn-row full-mob row justify-content-between'>
          <button onClick={() => goToPreviousStep(stepName)} className='btn prev btn-white'>
            {t('form.Back', { ns: 'translations' })}
          </button>
          <button className='btn next' onClick={() => selectResponse()}>
            {t('form.Next', { ns: 'translations' })}
          </button>
        </div>
      </div>
      {infoBox ? <InfoBox title={infoBox.title} content={infoBox.content} /> : null}
    </>
  );
};

export default MetalBodyLocation;
