import React, { useContext, useState } from 'react';
import InfoBox from '../info-box';
import LoaderComponent from '../loader-component';
import { AdSectionProps } from './interfaces';
import PopulusMediaComponent from '../populus-media';
import { POPULUS_NO_ADS } from '../../views/patient-scan-request/PatientScanRequestUtils';
import { POPULUS_HANDOFF_AD_HIDE_MS } from '../../config/FrontConfig';
import { PING_STEP_NAMES } from '../../views/patient-ping/utils';
import { MixpanelContext } from '../../contexts/MixpanelContext';
import { PATIENT_PING_POPULUS } from '../../utils/MixpanelEvents';
import { useTranslation } from 'react-i18next';

const AdSection: React.FunctionComponent<AdSectionProps> = ({
  infoBoxData,
  scan,
  stepName = PING_STEP_NAMES.confirmYourIdentityAdSection,
  completeStepCallback,
  patient,
  npi,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const mixpanel = useContext(MixpanelContext);
  const { t } = useTranslation('handoff');

  const handleCallbackToClosePopulus = (text: string) => {
    if (text === POPULUS_NO_ADS) {
      setIsLoading(true);
      completeStepCallback({}, stepName);
      return;
    }
    mixpanel.track(PATIENT_PING_POPULUS);
    setTimeout(() => {
      setIsLoading(true);
      completeStepCallback({}, stepName);
    }, Number(POPULUS_HANDOFF_AD_HIDE_MS));
  };

  if (isLoading) {
    return <LoaderComponent className='loader-block-center' />;
  }

  return (
    <>
      <div className='request-type handoff-process'>
        <div className='scan-service-edit_header'>
          <h2 className='h2'>
            {t('One moment')}&nbsp;
            <span className='dotted-loader'>
              <i></i>
            </span>
          </h2>
        </div>
        <PopulusMediaComponent npi={npi} patient={patient} step={1} reason='' scan={scan} callbackPopulus={handleCallbackToClosePopulus} />
      </div>
      {infoBoxData ? <InfoBox title={infoBoxData.title} content={infoBoxData.content} /> : null}
    </>
  );
};

export default AdSection;
