import { ScanType, ScanTypeDisplayNode } from '../models/ScanType';

export const buildScanTypesTree = (fullScanTypes: ScanType[]): ScanTypeDisplayNode[] => {
  const scanTypesTree: ScanTypeDisplayNode[] = [];
  for (const fullScanType of fullScanTypes) {
    const { ScanTypeID, ScanTypeName } = fullScanType;
    const scanTypeIndex: number = scanTypesTree.findIndex(scanType => ScanTypeID === scanType.id);
    if (scanTypeIndex === -1) {
      const newScanType = { id: ScanTypeID, name: ScanTypeName, children: getFirstChildren([], fullScanType), isLeaf: false };
      scanTypesTree.push(newScanType);
    } else {
      scanTypesTree[scanTypeIndex] = {
        ...scanTypesTree[scanTypeIndex],
        children: getFirstChildren(scanTypesTree[scanTypeIndex].children as ScanTypeDisplayNode[], fullScanType),
      };
    }
  }
  return scanTypesTree;
};

const getFirstChildren = (currentFirstChildren: ScanTypeDisplayNode[], fullScanType: ScanType): ScanTypeDisplayNode[] => {
  const { ScanServiceName, ScanDescription } = fullScanType;
  const scanTypeIndex: number = currentFirstChildren.findIndex(scanType => fullScanType.ScanServiceName === scanType.name);
  if (scanTypeIndex === -1) {
    const newScanType: ScanTypeDisplayNode = { name: ScanServiceName || '', isLeaf: ScanDescription === '' || ScanDescription === null };
    if (newScanType.isLeaf) {
      newScanType.id = fullScanType.ScanServiceID;
    } else {
      newScanType.children = getSecondChildren([], fullScanType);
    }
    return [...currentFirstChildren, newScanType];
  } else {
    currentFirstChildren[scanTypeIndex] = {
      ...currentFirstChildren[scanTypeIndex],
      children: getSecondChildren(currentFirstChildren[scanTypeIndex].children as ScanTypeDisplayNode[], fullScanType),
    };
    return currentFirstChildren;
  }
};

const getSecondChildren = (currentSecondChildren: ScanTypeDisplayNode[], fullScanType: ScanType): ScanTypeDisplayNode[] => {
  const { ScanDescription, ScanExtraOption } = fullScanType;
  const scanTypeIndex: number = currentSecondChildren.findIndex(scanType => fullScanType.ScanDescription === scanType.name);
  if (scanTypeIndex === -1) {
    const newScanType: ScanTypeDisplayNode = { name: ScanDescription || '', isLeaf: ScanExtraOption === '' || ScanExtraOption === null };
    if (newScanType.isLeaf) {
      newScanType.id = fullScanType.ScanServiceID;
    } else {
      newScanType.children = getThirdChildren([], fullScanType);
    }
    return [...currentSecondChildren, newScanType];
  } else {
    currentSecondChildren[scanTypeIndex] = {
      ...currentSecondChildren[scanTypeIndex],
      children: getThirdChildren(currentSecondChildren[scanTypeIndex].children as ScanTypeDisplayNode[], fullScanType),
    };
    return currentSecondChildren;
  }
};

const getThirdChildren = (currentThirdChildren: ScanTypeDisplayNode[], fullScanType: ScanType): ScanTypeDisplayNode[] => {
  return [...(currentThirdChildren || []), { name: fullScanType.ScanExtraOption || '', id: fullScanType.ScanServiceID, isLeaf: true }];
};
