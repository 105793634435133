import React, { useContext, useEffect, useState } from 'react';
import { CovidQuestionsProps } from './interfaces';
import { CommonContext, CommonContextFunctions, CommonContextTypes } from '../../contexts/CommonContext';
import { STEP_NAMES } from '../../views/patient-scan-request/PatientScanRequestUtils';
import LoaderComponent from '../loader-component';
import InfoBox from '../info-box';
import { Trans, useTranslation } from 'react-i18next';

const CovidQuestions: React.FunctionComponent<CovidQuestionsProps> = ({
  scanRequestData,
  goToPreviousStep,
  completeStepCallback,
  agentInfo,
  stepName = STEP_NAMES.additionalQuestions,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { CovidResponses, getCovidResponses } = useContext<CommonContextTypes & CommonContextFunctions>(CommonContext);
  const { t } = useTranslation('handoff');
  const { CovidResponseID } = scanRequestData;

  useEffect(() => {
    if (!CovidResponses.length) {
      getCovidResponses();
    } else {
      setIsLoading(false);
    }
  }, [CovidResponses]);

  const selectCovidResponse = (covidResponseID: number): void => {
    completeStepCallback({ CovidResponseID: covidResponseID }, stepName);
  };

  return (
    <>
      {isLoading ? (
        <LoaderComponent className='loader-block-center' />
      ) : (
        <>
          <div className='main-request-form'>
            <h2 id='Additional_questions' className='h2'>
              {t('covid.title')}
            </h2>
            <div className='text-form'>
              <p>{t('covid.text')}</p>
            </div>
            <div className='main-form-appointment'>
              <div className='mb32'>
                {CovidResponses.map(({ ID, Response }) => (
                  <button
                    key={ID}
                    className={`appointment-field appointment-field_btn ${ID === CovidResponseID ? 'active' : ''}`}
                    onClick={() => selectCovidResponse(ID)}
                  >
                    {t(`metal.${Response}` as unknown as TemplateStringsArray)}
                  </button>
                ))}
              </div>
              <div className='btn-row row justify-content-between full-mob'>
                <button className='btn btn-white back no-arrow' onClick={() => goToPreviousStep(stepName)}>
                  {t('form.Back', { ns: 'translations' })}
                </button>
              </div>
            </div>
          </div>
          {agentInfo ? (
            <InfoBox
              title={t('box.HAVE_QUESTIONS_PING_PATIENT_INFOBOX_DATA.title', { ns: 'translations' })}
              content={
                <Trans
                  i18nKey='box.HAVE_QUESTIONS_PING_PATIENT_INFOBOX_DATA.content'
                  values={{
                    CxName: agentInfo?.CxName || '[CX First Name]',
                    CxPhone: agentInfo?.CxPhone || '[CX phone]',
                    CxEmail: agentInfo?.CxEmail || '[CX email]',
                  }}
                  components={{ linkTo: <a /> }}
                />
              }
            />
          ) : null}
        </>
      )}
    </>
  );
};

export default CovidQuestions;
