import React, { SyntheticEvent, useCallback, useContext, useMemo, useState } from 'react';
import { Form } from 'rsuite';
import { PatientLocationForm, PatientLocationProps } from './interfaces';
import { Checkbox } from 'rsuite';
import { getPlaceLocation, searchAutocomplete } from '../../api/google-maps/GoogleMapsAPI';
import TextField from '../text-field-component';
import InfoBox from '../info-box';
import { HAVE_QUESTIONS_INFOBOX_DATA, STEP_NAMES } from '../../views/patient-scan-request/PatientScanRequestUtils';
import { debounce } from 'debounce';
import { ScanRequest } from '../../models/ScanRequest';
import { PlaceDetails } from '../../api/google-maps/interfaces';
import { Address } from '../../models/Address';
import AutoCompleteInput from '../autocomplete-input';
import { AutocompleteOption } from '../autocomplete-input/interfaces';
import { MixpanelContext } from '../../contexts/MixpanelContext';
import { LOCATION_SELECTED } from '../../utils/MixpanelEvents';
import { EDIT_SOURCE, PATIENT_PING_SOURCE, PXB_SOURCE, SOLV_SOURCE } from '../../utils/GeneralUtil';
import { useTranslation } from 'react-i18next';

const ADDRESS_ERROR_MESSAGE = 'To save address information, please choose an option from the dropdown.';

const PatientLocation: React.FunctionComponent<PatientLocationProps> = ({
  source,
  goToPreviousStep,
  scanRequestData,
  completeStepCallback,
  mixpanelHandoffPayload,
  isOptional = false,
}) => {
  const { HomeAddressString, SecondaryAddressString, ShouldFindNearHomeAddress, HomeAddressData, SecondaryAddressData, HasSecondAddress } =
    scanRequestData;
  const { url } = HAVE_QUESTIONS_INFOBOX_DATA;
  const [formValue, setFormValue] = useState<PatientLocationForm>({
    HomeAddress: HomeAddressString || HomeAddressData?.RawString || '',
    HomeAddressApartmentNumber: HomeAddressData?.Address2 || '',
    SecondaryAddress: (SecondaryAddressString || SecondaryAddressData?.RawString) ?? '',
    SecondaryAddressApartmentNumber: SecondaryAddressData?.Address2 || '',
    ShouldFindNearHomeAddress:
      ShouldFindNearHomeAddress !== undefined && ShouldFindNearHomeAddress !== null ? !!ShouldFindNearHomeAddress : !HasSecondAddress,
  });
  const [homeAddressSearchResults, setHomeAddressSearchResults] = useState<AutocompleteOption[]>([]);
  const [secondaryAddressSearchResults, setSecondaryAddressSearchResults] = useState<AutocompleteOption[]>([]);
  const [homeAddressSelected, setHomeAddressSelected] = useState<Address | null>(HomeAddressData?.RawString ? HomeAddressData : null);
  const mixpanel = useContext(MixpanelContext);
  const { t } = useTranslation('handoff');
  const [secondaryAddressSelected, setSecondaryAddressSelected] = useState<Address | null>(
    SecondaryAddressData?.RawString ? SecondaryAddressData : null,
  );
  const [shouldDisplayHomeAddressWarning, setShouldDisplayHomeAddressWarning] = useState(false);
  const [shouldDisplaySecondaryAddressWarning, setShouldDisplaySecondaryAddressWarning] = useState(false);

  const isConfirmEnabled = useMemo(() => {
    if (isOptional) {
      return true;
    }
    const { HomeAddress, ShouldFindNearHomeAddress, SecondaryAddress } = formValue;
    const homeAddressValuesExist = !!HomeAddress && !!homeAddressSelected;
    const secondaryAddressValuesExist = !!SecondaryAddress && !!secondaryAddressSelected;
    return homeAddressValuesExist && (!ShouldFindNearHomeAddress ? secondaryAddressValuesExist : true);
  }, [formValue, homeAddressSelected, secondaryAddressSelected, isOptional]);

  const handleHomeAddressChange = (value: string): void => {
    setFormValue({ ...formValue, HomeAddress: value });
    handleSearch(value, true);
  };

  const clearHomeAddressValues = () => {
    setFormValue({
      ...formValue,
      HomeAddress: '',
      HomeAddressApartmentNumber: '',
    });
    setHomeAddressSelected(null);
    setShouldDisplayHomeAddressWarning(false);
  };

  const clearSecondaryAddressValues = () => {
    setFormValue({
      ...formValue,
      SecondaryAddress: '',
      SecondaryAddressApartmentNumber: '',
    });
    setSecondaryAddressSelected(null);
    setShouldDisplaySecondaryAddressWarning(false);
  };

  const handleSecondaryAddressChange = (value: string): void => {
    setFormValue({ ...formValue, SecondaryAddress: value });
    handleSearch(value, false);
  };

  const handleSearch = (address: string, isHomeAddress: boolean): void => {
    if (address.length > 3) {
      debouncedSearchAddress(address, isHomeAddress);
    }
  };

  const searchAddress = (address: string, isHomeAddress: boolean): void => {
    searchAutocomplete(address)
      .then(newSearchResults => {
        const parsedResults = newSearchResults.map(({ placeId, description }) => ({
          value: placeId,
          text: description,
        }));
        if (isHomeAddress) {
          setHomeAddressSearchResults(parsedResults);
        } else {
          setSecondaryAddressSearchResults(parsedResults);
        }
      })
      .catch(() => {
        const noResultsOption = { value: null, text: 'No results found' };
        if (isHomeAddress) {
          setHomeAddressSearchResults([noResultsOption]);
        } else {
          setSecondaryAddressSearchResults([noResultsOption]);
        }
      });
  };

  const debouncedSearchAddress = useCallback(debounce(searchAddress, 400), []);

  const selectAddress = (isHomeAddress: boolean, placeId: string, description: string): void => {
    if (isHomeAddress) {
      setFormValue({ ...formValue, HomeAddress: description });
      getPlaceLocation(placeId).then(placeLocation => {
        saveHomeAddressSelected(placeLocation);
      });
    } else {
      setFormValue({ ...formValue, SecondaryAddress: description });
      getPlaceLocation(placeId).then(placeLocation => {
        saveSecondaryAddressSelected(placeLocation);
      });
    }
  };

  const saveHomeAddressSelected = (placeDetails: PlaceDetails): void => {
    const homeAddress = { ...buildAddress(placeDetails), AddressTypeID: 1 };
    // setFormValue({
    //   ...formValue,
    //   HomeAddress: homeAddress.Address || '',
    // });
    setHomeAddressSelected(homeAddress);
    trackLocationSelected(homeAddress);
    setShouldDisplayHomeAddressWarning(false);
  };

  const saveSecondaryAddressSelected = (placeDetails: PlaceDetails): void => {
    const secondaryAddress = { ...buildAddress(placeDetails), AddressTypeID: 2 };
    // setFormValue({
    //   ...formValue,
    //   SecondaryAddress: secondaryAddress.Address || '',
    // });
    setSecondaryAddressSelected(secondaryAddress);
    trackLocationSelected(secondaryAddress);
    setShouldDisplaySecondaryAddressWarning(false);
  };

  const trackLocationSelected = (address: Address) => {
    if (source === PATIENT_PING_SOURCE) {
      const eventName = `PATIENT_PING_${LOCATION_SELECTED}`;
      const payload = { ...address, ...mixpanelHandoffPayload };
      mixpanel.track(eventName, payload);
    } else if (source !== EDIT_SOURCE) {
      const eventName = source === PXB_SOURCE || source === SOLV_SOURCE ? LOCATION_SELECTED : `HANDOFF_${LOCATION_SELECTED}`;
      const payload = source === PXB_SOURCE || source === SOLV_SOURCE ? address : { ...address, ...mixpanelHandoffPayload };
      mixpanel.track(eventName, payload);
    }
  };

  const buildAddress = (placeDetails: PlaceDetails): Address => {
    const { streetNumber, route, city, state, zipCode, rawString, lat, lng, utcOffset, link } = placeDetails;
    return {
      Address1: `${streetNumber} ${route}`,
      City: city,
      State: state,
      Zip: zipCode,
      RawString: rawString || '',
      Latitude: lat,
      Longitude: lng,
      TimeZone: utcOffset?.toString(),
      Link: link || '',
    };
  };

  const handleAddressBlur = (isHomeAddress: boolean): void => {
    const address = isHomeAddress ? formValue.HomeAddress : formValue.SecondaryAddress;
    if (isHomeAddress) {
      setShouldDisplayHomeAddressWarning(Boolean(address.length));
      return;
    }
    setShouldDisplaySecondaryAddressWarning(Boolean(address.length));
  };

  const getBack = (event: SyntheticEvent): void => {
    event.preventDefault();
    goToPreviousStep(STEP_NAMES.patientLocation);
  };

  const handleSubmit = (event: SyntheticEvent): void => {
    event.preventDefault();
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let scanDataToSave: Partial<ScanRequest> = {
      HomeAddressString: formValue.HomeAddress,
      ShouldFindNearHomeAddress: formValue.ShouldFindNearHomeAddress ? 1 : 0,
      HomeAddressData: { ...homeAddressSelected, Address2: formValue.HomeAddressApartmentNumber } as Address,
      UserBrowserTimezone: timezone,
      SecondaryAddressString: !formValue.ShouldFindNearHomeAddress ? formValue.SecondaryAddress : '',
      SecondaryAddressData: !formValue.ShouldFindNearHomeAddress
        ? ({ ...secondaryAddressSelected, Address2: formValue.SecondaryAddressApartmentNumber } as Address)
        : null,
    };
    if (isOptional) {
      const isEmptyAddress = !homeAddressSelected;
      if (!secondaryAddressSelected) {
        scanDataToSave = {
          ...scanDataToSave,
          ShouldFindNearHomeAddress: 1,
          SecondaryAddressString: '',
          SecondaryAddressData: null,
        };
      }
      return isEmptyAddress
        ? completeStepCallback({}, STEP_NAMES.patientLocation)
        : completeStepCallback(scanDataToSave, STEP_NAMES.patientLocation);
    }
    completeStepCallback(scanDataToSave, STEP_NAMES.patientLocation);
  };

  return (
    <>
      <div className='request-type'>
        <h2 id='Where_are_you_located' className='h2'>
          {t('address.What is your mailing address')} <span>{isOptional ? `(${t('upload.optional')})` : ''}</span>
        </h2>
        <div className='text-wrap'>
          <p>{t('address.text')}</p>
        </div>
        <Form fluid formValue={formValue}>
          <AutoCompleteInput
            name='HomeAddress'
            value={formValue.HomeAddress}
            options={homeAddressSearchResults}
            className='mb16'
            onChange={(value: string) => handleHomeAddressChange(value)}
            onSelect={({ value, text }) => selectAddress(true, value as string, text)}
            label={t('form.Address', { ns: 'translations' })}
            shouldDisplayX={!!formValue.HomeAddress.length}
            onClean={clearHomeAddressValues}
            autoComplete='off'
            readOnly={homeAddressSelected !== null}
            onBlur={() => handleAddressBlur(true)}
            error={shouldDisplayHomeAddressWarning ? ADDRESS_ERROR_MESSAGE : null}
          />
          <TextField
            name='HomeAddressApartmentNumber'
            className='mb16'
            value={formValue.HomeAddressApartmentNumber}
            onChange={(value: string) => setFormValue(previous => ({ ...previous, HomeAddressApartmentNumber: value }))}
            label={t('form.ApartmentOrUnitNumberOptional', { ns: 'translations' })}
          />
          <div className='input-checkbox mb24'>
            <Checkbox
              onChange={() =>
                setFormValue(previous => ({
                  ...previous,
                  ShouldFindNearHomeAddress: !previous.ShouldFindNearHomeAddress,
                }))
              }
              checked={formValue.ShouldFindNearHomeAddress}
            >
              {formValue.ShouldFindNearHomeAddress
                ? t('form.Use my home address to find a nearby imaging center', { ns: 'translations' })
                : t('form.Use my home address to find a nearby imaging center', { ns: 'translations' })}
            </Checkbox>
          </div>
          {!formValue.ShouldFindNearHomeAddress && (
            <>
              <p className='mb32 fw600 blue'>{t('form.Find me an imaging center near this address', { ns: 'translations' })}:</p>
              <AutoCompleteInput
                name='SecondaryAddress'
                value={formValue.SecondaryAddress}
                options={secondaryAddressSearchResults}
                onChange={(value: string) => handleSecondaryAddressChange(value)}
                onSelect={({ value, text }) => selectAddress(false, value as string, text)}
                label={t('form.Address', { ns: 'translations' })}
                shouldDisplayX={!!formValue.SecondaryAddress.length}
                onClean={clearSecondaryAddressValues}
                autoComplete='off'
                className='mb16'
                readOnly={secondaryAddressSelected !== null}
                onBlur={() => handleAddressBlur(false)}
                error={shouldDisplaySecondaryAddressWarning ? ADDRESS_ERROR_MESSAGE : null}
              />
              <TextField
                name='SecondaryAddressApartmentNumber'
                className='mb16'
                value={formValue.SecondaryAddressApartmentNumber}
                onChange={(value: string) =>
                  setFormValue(previous => ({
                    ...previous,
                    SecondaryAddressApartmentNumber: value,
                  }))
                }
                label={t('form.ApartmentOrUnitNumberOptional', { ns: 'translations' })}
              />
            </>
          )}
          <div className='btn-row row justify-content-between full-mob'>
            <button className='btn btn-white back no-arrow' onClick={getBack}>
              {t('form.Back', { ns: 'translations' })}
            </button>
            <button className='btn next no-arrow' disabled={!isConfirmEnabled} onClick={handleSubmit}>
              {t('form.Next', { ns: 'translations' })}
            </button>
          </div>
        </Form>
      </div>
      <InfoBox
        title={t('box.HAVE_QUESTIONS_INFOBOX_DATA.title', { ns: 'translations' })}
        content={t('box.HAVE_QUESTIONS_INFOBOX_DATA.text', { ns: 'translations' })}
        urlText={t('box.HAVE_QUESTIONS_INFOBOX_DATA.urlText', { ns: 'translations' })}
        url={url}
      />
    </>
  );
};

export default PatientLocation;
