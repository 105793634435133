import React from 'react';
import { ScanManagment } from '../../../../models/ScanManagment';

const ContactOptionComponent: React.FunctionComponent<{ info: ScanManagment; onCloseCallback: () => void }> = ({
  info,
  onCloseCallback,
}) => {
  const { CXAgentPhone } = info;

  return (
    <div className='control-panel_info-navigation'>
      <div className='title'>Contact options</div>
      <div className='content'>
        <p>
          You can reach us any time during our working hours: M-F 9 AM-5 PM EST. We will respond within 2 business hours. If we receive a
          message from you during non-business hours we will respond the next business day. You can reach us in the following ways:
        </p>
        {CXAgentPhone && (
          <p>
            Dedicated medmo care team member: <a href={`tel:${CXAgentPhone}`}>{CXAgentPhone}</a>
          </p>
        )}
        <p>
          Medmo main line: <a href='tel:8334463366'>(833) 446-3366</a>
        </p>
        <p>
          Email: <a href='mailto:support@medmo.com'>support@medmo.com</a>
        </p>
      </div>
      <div className='btn-list row'>
        <button className='btn' onClick={onCloseCallback}>
          Thanks
        </button>
      </div>
    </div>
  );
};

export default ContactOptionComponent;
