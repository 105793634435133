import React from 'react';
import { RequestMain } from '../api/Request';
import ResponseError from '../errors/ResponseError';

export const EMAIL_REGEXP = /([a-zA-Z0-9+._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/g;

export const REUPLOAD_INTERVAL_IN_MINUTES = 60;

export const GenderSelect = [
  { value: 0, label: 'Prefer not to say' },
  { value: 1, label: 'Female' },
  { value: 2, label: 'Male' },
];

export const replaceEmailToLink = (str: string) => {
  const matches = str.match(EMAIL_REGEXP);

  if (!matches) {
    return str;
  }

  const splittedByEmail: (string | JSX.Element)[] = str.split(EMAIL_REGEXP);

  matches.forEach((email, i) => {
    const indexOfEmail = splittedByEmail.indexOf(email);
    splittedByEmail[indexOfEmail] = (
      <a key={i} href={`mailto:${email}`}>
        {email}
      </a>
    );
  });

  return splittedByEmail;
};

export const catch401 = (error: ResponseError): void => {
  if (error.status === 401) {
    throw error;
  }
};

export const removeFieldsFromObject = (fieldsList: string[], object: Record<string, any>): Record<string, any> => {
  const response = { ...object };
  for (const field of fieldsList) {
    if (response[field]) {
      delete response[field];
    }
  }
  return response;
};

export const removeEmptyFieldsFromObject = (object: Record<string, any>): Record<string, any> => {
  const response = { ...object };
  Object.keys(response).forEach(key => {
    if (response[key] !== 0 && (!response[key] || response[key] === -1)) {
      delete response[key];
    }
  });
  return response;
};

export const prepareGetOptions = (signal: AbortSignal | undefined, url: string): RequestMain => {
  let options: RequestMain = {
    method: 'GET',
    url,
  };

  if (signal) {
    options = { ...options, signal };
  }

  return options;
};

export const getUrlParams = (search = ''): any => {
  const hashes = search.slice(search.indexOf('?') + 1).split('&');
  return hashes.reduce((acc, hash) => {
    const [key, val] = hash.split('=');
    return {
      ...acc,
      [key]: decodeURIComponent(val),
    };
  }, {});
};

export const replaceWhitespacesForUnderscore = (value: string): string => value.replaceAll(' ', '_');

export const groupBy = <T, K extends keyof any>(items: T[], key: K) => {
  return items.reduce(function (rv, x) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {} as Record<K, T[]>);
};

export const MOBILE_SCREEN_WIDTH = 1023;

export const PXB_SOURCE = 'pxb';
export const SOLV_SOURCE = 'solv';
export const HEALTHYR_SOURCE = 'helthyr';
export const HANDOFF_SOURCE = 'handoff';
export const PATIENT_PING_SOURCE = 'ping';
export const VIEW_SOURCE = 'view';
export const EDIT_SOURCE = 'edit';

export const STANDARD_REGISTRATION_TYPE_ID = 1;
export const PXB_REGISTRATION_TYPE_ID = 2;
export const HANDOFF_REGISTRATION_TYPE_ID = 3;
