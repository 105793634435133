import React, { useCallback, useEffect, useContext, useMemo, useState } from 'react';
import { POPULUS_NETWORK_ID, POPULUS_PARTNER_ID } from '../../config/FrontConfig';
import { UserContext } from '../../contexts/UserContext';
import { NODE_ENV } from '../../config/ServerConfig';
import { ContentAdv, PopulusMediaProps } from './interfaces';
import { POPULUS_ADD_INFO, POPULUS_ADS_RENDERED, POPULUS_NO_ADS } from '../../views/patient-scan-request/PatientScanRequestUtils';
import { getVisitorId, getGender, getAgeRange } from './utils';
import useWindowDimensions from '../../hooks/useWindowDimensions';
declare const window: any;

const PopulusMediaComponent: React.FC<PopulusMediaProps> = ({
  step,
  scan,
  callbackPopulus = null,
  patient = null,
  npi = null,
  reason = '',
}) => {
  const currentUser = useContext(UserContext);
  const [isShowContent, setIsShowContent] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const windowSize = useWindowDimensions();

  const isMobile = useMemo(() => {
    if (!windowSize || !windowSize.width) {
      return window.innerWidth < 768;
    }
    return windowSize['width'] < 768;
  }, [windowSize]);

  const runPopulus = useCallback(() => {
    const visitId = getVisitorId(currentUser);
    const devEnvs = ['local', 'development', 'local-test', 'staging'];
    const isDev = devEnvs.includes(NODE_ENV);
    const zone = isDev ? 'dev' : 'prod';
    const gender = patient?.GenderName ? getGender(patient?.GenderName) : getGender(currentUser?.GenderName || null);
    const ageRange = patient?.DOB ? getAgeRange(patient?.DOB) : getAgeRange(currentUser?.DOB);
    const contentObj: ContentAdv = {
      network: POPULUS_NETWORK_ID,
      partner: POPULUS_PARTNER_ID,
      width: '728px',
      height: '90px',
      'visit-id': visitId,
      'inventory-class': 'display',
      keywords: currentKeywords,
      reason: reason,
      zone: zone,
      gender: gender,
      'age-range': ageRange,
    };

    if (isMobile) {
      contentObj.width = '100%';
      contentObj.height = '250px';
    }

    if (['local', 'development', 'local-test'].includes(NODE_ENV)) {
      const testKeywords = 'Migraine, severe headache';
      contentObj.keywords = contentObj.keywords.length ? contentObj.keywords + ', ' + testKeywords : testKeywords;
    }

    if (npi) {
      contentObj.pld = npi;
    }

    if (window['$pop']) {
      const elementId = isMobile ? 'populus-custom-content' : 'populus-custom-content-728';
      const adObject = window['$pop'].render(document.getElementById(elementId), contentObj);
      if (adObject) {
        adObject.addEventListener('noAds', () => {
          console.log('noAds');
          sessionStorage.setItem(POPULUS_ADD_INFO, JSON.stringify({ PopulusAdsRender: false, PopulusAdsNoAds: true }));
          setIsShowContent(false);
          callbackPopulus && callbackPopulus(POPULUS_NO_ADS);
        });
        adObject.addEventListener('error', () => {
          sessionStorage.setItem(POPULUS_ADD_INFO, JSON.stringify({ PopulusAdsRender: true, PopulusAdsNoAds: true }));
          callbackPopulus && callbackPopulus(POPULUS_NO_ADS);
        });
        adObject.addEventListener('rendered', () => {
          sessionStorage.setItem(POPULUS_ADD_INFO, JSON.stringify({ PopulusAdsRender: true, PopulusAdsNoAds: false }));
          callbackPopulus && callbackPopulus(POPULUS_ADS_RENDERED);
        });
      } else {
        callbackPopulus && callbackPopulus(POPULUS_NO_ADS);
      }
    } else {
      callbackPopulus && callbackPopulus(POPULUS_NO_ADS);
    }
  }, [reason, scan, isMobile]);

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      if (!isShowContent) {
        setIsShowContent(true);
      }
      runPopulus();
    }, 100);
  }, [reason, scan, step, isMobile]);

  const currentKeywords = useMemo(() => {
    if (!scan) {
      return '';
    }
    let result = scan.CPTCode ? scan.CPTCode + '' : '';
    if (scan.ScanTypeName && scan.ScanTypeName !== '') {
      result += ', ' + scan.ScanTypeName;
    }
    if (scan.ScanServiceName && scan.ScanServiceName !== '') {
      result += ', ' + scan.ScanServiceName;
    }
    if (scan.ScanDescription && scan.ScanDescription !== '') {
      result += ', ' + scan.ScanDescription;
      if (scan.ScanExtraOption && scan.ScanExtraOption !== '') {
        result += ', ' + scan.ScanExtraOption;
      }
    }

    if (scan.PopulusKeywords && scan.PopulusKeywords !== '') {
      result += ', ' + scan.PopulusKeywords;
    }

    return result;
  }, [scan]);

  if (isLoading) {
    return <></>;
  }
  return (
    <>
      {!isLoading && isShowContent && (
        <div className={`populus-customs populus-customs-1 ${isMobile ? 'mobile-active' : ''}`}>
          <div className='row'>
            {isMobile ? (
              <div className='populus-customs-col'>
                <div className='populus-custom' id='populus-custom-content' />
              </div>
            ) : (
              <div className='populus-customs-col populus-customs-col-728'>
                <div className='populus-custom' id='populus-custom-content-728' />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default PopulusMediaComponent;
