import React from 'react';

const QualityComponent: React.FunctionComponent = () => {
  return (
    <div className='page-content page-content_quality-page'>
      <div className='page-content-wrap'>
        <div className='page-content-h1'>
          <h1>QUALITY AND ACCREDITATION</h1>
        </div>
        <section>
          <p className='page-content-p'>
            All of the imaging centers in our network have accreditations from the American College of Radiology (ACR) or the Intersocietal
            Accreditation Committee (IAC). These organizations set national standards for quality and patient safety.
          </p>

          <p className='page-content-p'>
            We only permit centers to book studies if they have accreditations for that specific study type. For example, a center that
            books MRIs has received MRI-specific accreditation from the ACR and/or IAC.
          </p>

          <p className='page-content-p'>You can learn more about the accrediting bodies at the following links:</p>

          <ul>
            <li>
              <a className='link' href='https://www.acraccreditation.org/' target='_blank' rel='noreferrer noopener'>
                American College of Radiology
              </a>
            </li>
            <li>
              <a className='link' href='https://www.intersocietal.org/' target='_blank' rel='noreferrer noopener'>
                Intersocietal Accreditation Committee
              </a>
            </li>
          </ul>
        </section>
      </div>
    </div>
  );
};

export default QualityComponent;
