import { request } from '../Request';
import { SERVER_URL } from '../../config/ServerConfig';
import { Article } from '../../views/dashboard/interfaces';
import { DashboardResponse } from './interfaces';
import { prepareGetOptions } from '../../utils/GeneralUtil';

export function shareAppointment(): Promise<{ ID: number }> {
  return request({
    method: 'POST',
    url: `${SERVER_URL}/users/share-appointment`,
  });
}

export function getArticleList(): Promise<Article[]> {
  return request({
    method: 'GET',
    url: `${SERVER_URL}/article`,
  });
}

export function getHomePageInfo(signal?: AbortSignal): Promise<DashboardResponse> {
  const options = prepareGetOptions(signal, `${SERVER_URL}/home`);
  return request(options);
}
