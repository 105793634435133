import { convertToLocalDate } from '../../utils/DateUtil';
import { User } from '../../models/User';
import moment from 'moment';

export const PopulusVisitID = 'PopulusVisitID';
export const PopulusData = 'PopulusData';

export const getVisitorId = (user: User) => {
  const visitorId = sessionStorage.getItem(PopulusVisitID);
  if (visitorId) {
    return visitorId;
  }

  return createVisitorId(user);
};

const createVisitorId = (currentUser: User) => {
  const currentDateTime = convertToLocalDate(new Date(), 'MM-DD-YYYY-HH-mm-ss');
  const visitId = currentDateTime + '-' + (currentUser?.UserID ? currentUser?.UserID : '0000');
  sessionStorage.setItem(PopulusVisitID, visitId);
  return visitId;
};

const ageRanges = [
  { min: 18, max: 24, value: 2 },
  { min: 25, max: 34, value: 3 },
  { min: 35, max: 44, value: 4 },
  { min: 45, max: 54, value: 5 },
  { min: 55, max: 64, value: 6 },
  { min: 65, max: Number.MAX_SAFE_INTEGER, value: 7 },
];

export const getGender = (GenderName: string | null): string => {
  if (GenderName) {
    if (GenderName === 'Male') {
      return 'm';
    } else if (GenderName === 'Female') {
      return 'f';
    }
  }
  return 'x';
};

export const getAgeRange = (DOB: string): number => {
  if (DOB) {
    const years = moment().diff(DOB, 'years');
    const inRange = ageRanges.filter(range => years >= range.min && years <= range.max);
    if (inRange.length) {
      return inRange[0].value;
    }
  }
  return 0;
};
