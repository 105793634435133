import React from 'react';

const TermsComponent: React.FunctionComponent = () => {
  return (
    <div className='page-content'>
      <div className='page-content-wrap'>
        <h1>Terms Of Use</h1>
        <h2>
          <ins>1. Parties; Program.</ins>
        </h2>
        <p>
          Reference to “we”, “us”, “our” or the like is deemed to include MEDMO, INC. and its affiliates, parents, subsidiaries, successors
          and assigns. Reference to “you”, “your” or the like is for all purposes deemed to include the individual who accepts these Terms
          and Conditions as well as his/her entity and all individuals in his/her/its practice that are authorized by you to use this
          website/app (also referred to herein as the “service(s)”, “app” or “Program”).
          <strong>
            The individual accepting these terms and conditions represents and warrants that he/she has the power and authority to bind the
            practice and all associated users to these Terms and Conditions. DO NOT USE THIS SERVICE IF YOU DO NOT AGREE TO THESE TERMS.
            YOUR USE OF THE SERVICE CONSTITUTES YOUR ACKNOWLEDGEMENT OF AND AGREEMENT TO THESE TERMS.
          </strong>
          The term “Program” includes our website(s) including Medmo.com (and any additional and successor websites), software applications
          (mobile and Internet-based), instructions, materials (whether housed online, on a server/cloud or downloadable), content, layout,
          tables, templates, report formats, forms, data, databases and any service, product or functionality we offer, as well as all
          associated intellectual property including trademarks, patents and copyrights, all now or hereafter existing. References to
          “including” means “including but not limited to” and “including without limitation”. In the absence of a specified term, this
          Agreement is deemed to remain in effect for one year, with automatic renewal for successive one year periods.
        </p>
        <h2>
          <ins>2. Independent Contractors; Compliance with Law.</ins>
        </h2>
        <p>
          We are independent contracting parties. Each party shall be solely responsible for its (including its owners’, employees’, agents’
          and affiliates’) own acts and omissions (including in rendering any telehealth/telemedicine services via the Program) and breach
          of this Agreement (including any adverse change in any representation and/or warranty hereunder and compliance with Applicable
          Law, as defined below), and, to the extent not covered by insurance proceeds from any source, you shall indemnify and hold us
          (including our owners, managers, employees, agents, affiliates/parents/subsidiaries, assignees and successors) harmless from and
          against all allegations, investigations, audits, claims, actions, proceedings, costs, penalties, fines, losses and liabilities
          associated with the foregoing, your provision of services, practice, operations and business and your covenants and obligations
          herein, including your breach hereof, adverse changes in any representations and warranties hereunder and all other acts and
          omissions of yours (with “yours” for the avoidance of doubt including your owners, employees, agents and
          affiliates/parents/subsidiaries) including reasonable attorneys’ fees, including following termination. You will be solely
          responsible for licensure/permits/registration and the conduct of your business, services and practice as required by all
          applicable Federal and State laws, rules, regulations and third party payor (private and governmental) requirements, including,
          without limitation, HIPAA and state privacy and patient disclosure laws and corresponding regulations (collectively, such laws,
          rules regulations and payor requirements are referred to herein as “Applicable Law”) and are further solely responsible for
          verifying the accuracy of information provided by you, patients and imaging centers including via our Program and violation of
          Applicable Law by you. You assume the sole risk and liability for privacy breaches associated with your violation of Applicable
          Law and transmission of information to inaccurate e-mail addresses, users or other third parties, including via the Program. You
          represent that the execution of this Agreement has been duly authorized (with the accepting individual having the full power and
          authority to execute this Agreement on behalf of the practice, its owners, employees, agents, affiliates/subsidiaries/parents) and
          that you are duly organized and a validly existing entity with such licenses, Board certification(s), registration and permits in
          the states in which you do business and with respect to the state residents you service to assure your compliance with Applicable
          Law. You are under no contractual or other restriction or obligation which is inconsistent with the execution of this Agreement,
          the performance of your duties hereunder, or the rights granted to us hereunder. This Section 2 shall survive termination of this
          Agreement.
        </p>
        <h2>
          <ins>3. Termination.</ins>
        </h2>
        <p>
          This Agreement commences upon acceptance and may be terminated by us at any time upon immediate written notice. It may be
          terminated by you with a 30 days advance notice by emailing support@medmo.com with your request to terminate. We may store
          information for your de-activated portal even after termination by either party for so long as required by law/regulation or
          otherwise permitted by law/regulation.
        </p>
        <h2>
          <ins>4. Program Ownership.</ins>
        </h2>
        <p>
          You acknowledge that our Program whether or not marked as “confidential” is special and unique proprietary assets and trade
          secrets of ours (further constituting our “Confidential Information” proprietary to us). You agree that at all times during the
          term of this Agreement and following its termination that you will not, either directly or indirectly, disclose, share, use,
          reproduce, derive materials from, reverse engineer or in any manner divulge to any person or entity, any such Confidential
          Information, except for purposes of use of the Program for the purposes intended (though patient information for patients treated
          by you shall also belong to you). This Agreement merely constitutes a revocable non-exclusive and non-transferable license to use
          the Program on the terms and conditions herein. The license granted does not include any right to use the Program for purposes
          (other than for use by those individuals authorized by you under this Agreement with respect to services rendered by you) or to
          copy, reproduce, sell, assign, transfer, lease, sublease or sublicense the Program for any purpose or to any third party, in whole
          or in part. The above license rights granted will not affect the exclusive ownership of the Program by us. If you devise any
          revisions, enhancements, or improvements in the Program, you will promptly disclose such improvements to us, and such improvements
          will be our sole property, and you will have no rights whatsoever in same. You will cause each authorized user accessing the
          Program to abide by the terms and conditions of this Agreement as if each were a party hereto. Our sole responsibility for any
          allegation of infringement of a third party’s intellectual property shall be to replace the functionality with equivalent
          functionality so long as we desire to continue to offer such functionality for all such similarly situated users, and this
          Agreement remains subject to your termination rights herein. This Section 4 shall survive termination of this Agreement.
        </p>
        <h2>
          <ins>5. Costs of Connectivity; Downtime</ins>
        </h2>
        <p>
          You are solely responsible, at your own cost and expense, for acquiring, installing and maintaining any connectivity equipment,
          hardware, software and other equipment as may be necessary for you and your authorized users to connect to and use the Program
          functionality or receive the information derived therefrom. We will make commercially reasonable efforts to restore functionality
          when down time is caused by us, and the Agreement remains subject to the termination rights herein without penalty to us.
        </p>
        <h2>
          <ins>6. Data.</ins>
        </h2>
        <p>
          You acknowledge that we retain independent possession and ownership of, and may share with other persons and entities the data and
          information from utilization of the Program (including any patient information which is de-identified if required under HIPAA as
          then-applicable) (e.g., including, without limitation, for statistical purposes, data aggregation, research study, analysis,
          warehousing, sale or otherwise). This Section 6 shall survive termination of this Agreement.
        </p>
        <h2>
          <ins>7. NO WARRANTY.</ins>
        </h2>
        <p>
          MARKETING, ADVERTISING AND PROMOTIONAL OR SALES MATERIALS OR COMMUNICATIONS OF ANY NATURE DO NOT CONSTITUTE ANY REPRESENTATION OR
          WARRANTY AND ARE NOT INCORPORATED INTO THIS AGREEMENT, AND YOU WILL NOT RELY ON SAME. EXCEPT AS OTHERWISE EXPRESSLY PROVIDED
          HEREIN, WE UNDERTAKE NO OBLIGATION TO VERIFY DATA/INFORMATION PROVIDED BY THIRD PARTIES OR TO PROVIDE ERROR-FREE OR FAULT-FREE
          PROGRAM(S), WEBSITE/APP ACTIVITY OR AVAILABILITY, DATA, REPORTS, RECORDS, INFORMATION, ITEMS, SUPPLIES OR EQUIPMENT. ALL PROGRAMS,
          SUPPLIES, ITEMS, SERVICES AND EQUIPMENT PROVIDED OR ARRANGED FOR BY US ARE PROVIDED “AS IS”. WE DISCLAIM ALL REPRESENTATIONS AND
          WARRANTIES OF ANY KIND OR NATURE, EXPRESS OR IMPLIED (EITHER IN FACT OR BY OPERATION OF LAW), WITH RESPECT TO ANY DATA,
          INFORMATION, REPORTS, PROGRAMS, SUPPLIES, EQUIPMENT, SERVICES OR ITEMS PROVIDED HEREUNDER, INCLUDING, BUT NOT LIMITED TO, ANY
          WARRANTY OF MERCHANTABILITY, TITLE, DESIGN, INFRINGEMENT, OR FITNESS FOR A PARTICULAR PURPOSE AND ANY WARRANTY ARISING FROM
          CONDUCT, COURSE OF DEALING, CUSTOM, OR USAGE IN TRADE. This Section 7 shall survive termination of this Agreement.
        </p>
        <h2>
          <ins>8. LIMITATION OF LIABILITY.</ins>
        </h2>
        <p>
          IN NO EVENT SHALL WE BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, INCLUDING LOSS OF
          PROFITS, INCURRED BY YOU OR YOUR OWNERS, EMPLOYEES, AFFILIATES/PARENTS/SUBSIDIARIES, AGENTS OR SUCCESSORS, WHETHER IN AN ACTION IN
          CONTRACT, TORT OR OTHERWISE, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THIS LIMITATION OF LIABILITY IS
          CUMULATIVE AND NOT PER INCIDENT. ADDITIONALLY, IF THE LIMITATION OF LIABILTY IS NOT RECOGNIZED BY A COURT OR OTHER GOVERNING
          AUTHORITY, THEN IN NO EVENT SHALL WE BE LIABLE FOR DAMAGES IN AN AMOUNT MORE THAN THE AMOUNT ACTUALLY PAID BY YOU TO US, IF ANY,
          OVER THE THREE (3) MONTH PERIOD IMMEDIATELY PRECEDING NOTICE OF THE BREACH. THE FOREGOING LIMITATIONS OF THIS SECTION WILL APPLY
          EVEN IF THE ABOVE STATED REMEDY FAILS OF ITS ESSENTIAL PURPOSE. This Section 8 shall survive termination of this Agreement.
        </p>
        <h2>
          <ins>9. Non-Disparagement.</ins>
        </h2>
        <p>
          At all times during the term of this Agreement and following its expiration or termination for any reason, you agree to not,
          directly or indirectly, in public or private, whether in oral, written, electronic or other format, disparage, deprecate, impugn
          or otherwise make any statements or remarks that would tend to or be construed to defame or slander the personal or professional
          reputations, professional qualifications, services, website/app, Program, functionality and/or business of ours, including of our
          owner(s), parents, subsidiaries, affiliates and/or its/his/her/their owners, independent contractors, employees, agents,
          affiliates, subsidiaries, parents and/or successors, nor shall you in any manner assist or encourage any third party in doing so.
          This shall not prohibit the making of claims and the enforcement of rights and remedies arising under this Agreement, testifying
          truthfully under oath, nor shall it or any other provision in this Agreement prohibit or preclude cooperation with investigations
          or inquiries by governmental authorities. This Section 9 shall survive termination of this Agreement.
        </p>
        <h2>
          <ins>10. Name and Likeness.</ins>
        </h2>
        <p>
          Except for purposes of listing your practice and/or practitioners in any directory we may establish including for purposes of
          telehealth/telemedicine, for purposes of displaying a profile of you for purposes of telehealth/telemedicine, for our marketing
          purposes (including materials containing lists of participants or former participants, and issuance of a press release(s)
          concerning your participation) or otherwise as expressly permitted of us herein, neither party shall otherwise use, reproduce,
          license, sell or otherwise make available, the (i) name, (ii) photos, (iii) logos, or (iv) intellectual property of the other
          party (e.g., copyright, trademark, service-mark, or other proprietary items) without such party’s prior written consent. You agree
          that we may issue announcements, publications and press releases indicating your participation in our Program.
        </p>
        <h2>
          <ins>11. Remedies.</ins>
        </h2>
        <p>
          In the event of a breach or threatened breach of any of the covenants herein, in addition to any other rights or remedies which
          the party may have at law and in equity which shall be cumulative and not alternative, such party shall further be entitled to
          such equitable and injunctive relief (including a temporary and permanent injunction, without the requirement of the posting of a
          bond) as may be available from a court of competent jurisdiction to restrain the other party (and their respective owners,
          affiliates and successors) from any violation or continued violation of such covenant. The affected party shall not, as a result
          of the time involved in obtaining such relief, be deprived of the benefit of the full period of the covenant. Accordingly, the
          covenant shall be deemed to have the duration specified herein, computed from the date the relief is granted, but reduced by the
          time between the period when the covenant began to run and the date of the first violation of the covenant. If any restriction or
          any other provision contained in this Agreement shall be deemed to be invalid, illegal or unenforceable by reason of the extent,
          duration or scope thereof, or otherwise, then the court making such determination shall reduce such extent, duration, scope or
          other provision hereof, and in its reduced form, such restriction or provision shall then be enforceable in the manner
          contemplated hereby. This Section 11 shall survive termination of this Agreement.
        </p>
        <h2>
          <ins>12. Telemedicine.</ins>
        </h2>
        <p>
          If you participate in our telehealth/telemedicine facilitation program as ever offered, which may include such functionality as we
          establish from time to time (e.g., without limitation, ordering, referral and information/record/imaging result sharing and other
          communication services) will be scheduled through our Program and promptly attended to when scheduled. You are solely and
          exclusively responsible for your clinical judgment, medical decision making, ordering and referrals, reports, actions, conduct and
          advice in rendering such services. You are further solely responsible for your compliance with all Applicable Law governing the
          conduct of and billing of and/or submission of claims for payment or reimbursement for telehealth/telemedicine. AS SUCH
          REQUIREMENTS ARE EVER-CHANGING AND BEING NEWLY IMPLEMENTED, YOU ASSUME THE SOLE RISK AS TO WHETHER YOUR USE OF OUR PROGRAM
          QUALIFIES FOR TELEHEALTH/TELEMEDICINE PAYMENT/REIMBURSEMENT OF ANY FORM, WHETHER FOR STORE AND FORWARD TECHNOLOGY, REMOTE
          MONITORING, TELEMEDICINE OR OTHERWISE. WE ARE NEVER LIABLE TO YOU FOR ANY INABILITY BY YOU TO OBTAIN OR RETAIN PAYMENT OR
          REIMBURSMENT FOR TELEMEDICINE/TELEHEALTH, AND YOU WILL INDEMNIFY AND HOLD US HARMLESS FROM AND AGAINST ANY AUDIT, INVESTIGATION,
          ADMINISTRATIVE CLAIM, LIABILITY, DAMAGES OR LOSS INCURRED WITH RESPECT TO SAME TO THE EXTENT WE BECOME INVOLVED, INCLUDING FOR ANY
          COSTS AND REASONABLE ATTORNEYS’ FEES WE INCUR AS A RESULT OF SUCH ACTIVITIES RELATING TO YOU. We do not represent, warrant or
          guaranty any patient eligibility or entitlement to payment or reimbursement for any services rendered by you, including, but not
          limited to, for telehealth/telemedicine. You are ultimately solely responsible for ensuring the accuracy of any patient
          eligibility and the conduct of your claims and billing. Further, in rendering such services, you will not engage in any lewd,
          unprofessional, unethical, offensive, discriminatory, tortious and/or criminal acts. You represent and warrant that you and others
          in your practice rendering telemedicine/telehealth services shall at all times maintain malpractice insurance covering acts and
          omissions during the term of your participation with minimum levels of $1million per occurrence and $3million in the annual
          aggregate. The fee structure for such participation as then applicable will be established and be binding upon your agreement to
          participate in the program, the terms of which are deemed incorporated into this agreement and which may be updated by us at any
          time for prospective applicability to future consultations. You hereby agree to any such updates or amendments by us. If you
          object to any terms, then you must alert us in writing and cease using the Program.
        </p>
        <h2>
          <ins>13. Prior Authorization.</ins>
        </h2>
        <p>
          Medmo’s third party imaging subscribers may offer prior authorization services to Medmo users. “Prior authorization” means
          obtaining approval from payors before performing services as a condition of coverage. MEDMO IS MERELY A TECHNOLOGICAL FACILITATION
          PLATFORM AND IS NOT INVOLVED IN OR RESPONSIBLE FOR OBTAINING PRIOR AUTHORIZATIONS OR VERIFYING ANY INFORMATION RELATING THERETO
          AND DOES NOT PROVIDE ANY PROMISE OR GUARANTY OF ACTUAL COVERAGE BY A PAYOR OR CARRIER. Medmo’s third party imaging subscribers
          retain the right to terminate these services at any time for any reason without notice. Medmo’s third party imaging subscribers
          are relying on the information provided by the patient and ordering provider, including you, and do not represent that they will
          verify the accuracy, current nature or completeness of the information. You are solely responsible for medical necessity for any
          patient order. Medmo makes no representation or warranty that the information submitted will not be corrupted or lost. Medmo and
          the third party imaging subscribers will not be liable for damages of any nature attributable to participation in the third party
          imaging subscriber’s prior authorization services or the results thereof, including with respect to any failure to obtain
          reimbursement from and/or refunds or offsets by carriers/payors, or costs associated therewith. You acknowledge that prior
          authorizations are not a guarantee of verification of patient eligibility or payment under the applicable payor plan. Payors
          retain the right to review the medical necessity of services and eligibility for services including exclusions and benefit
          limitations even after a patient receives the services. Participants including you assume the sole and absolute risk of
          participation including of any liability to carriers/payors and patients. You will look solely to the patient and carrier/payor
          for ultimate payment/reimbursement including with respect to any dispute regarding payment and/or care, and will be solely
          responsible for having patients sign any necessary agreements allowing for subscriber to enforce same against such third party. IN
          NO EVENT SHALL MEDMO BE LIABLE TO YOU FOR LOSS OF GOODWILL, OR FOR DIRECT, SPECIAL, INDIRECT, INCIDENTAL, CONSEQUENTIAL OR
          PUNITIVE DAMAGES ARISING OUT OF OR RELATING TO THIS PRIOR AUTHORIZATION PROGRAM, REGARDLESS OF WHETHER SUCH CLAIM ARISES IN TORT
          OR IN CONTRACT. This limitation of liability will survive termination of your participation in the prior authorization program,
          termination of your participation in Medmo and termination of this Agreement.
        </p>
        <h2>
          <ins>14. Miscellaneous.</ins>
        </h2>
        <p>
          We may amend or supplement this agreement at any time, and you may be required to accept or otherwise acknowledge your agreement
          to the changes in order to maintain your access to our Program. If you fail to acknowledge your acceptance of any such change as
          required by us then your access will expire immediately or at any time thereafter at our option. Notices hereunder may be given by
          notification on the website/app/Program, by text message to the registered mobile phone user, by e-mail to the registered contact
          of the party, or by e-mail to us at support@medmo.com. The Agreement is not assignable by you without our written consent, though
          we may delegate our rights and obligations and assign this Agreement to any transferee, assignee or successor to our business or
          assets to which this Agreement relates. If any provision of this Agreement or the application of any provision hereof to any
          person or circumstances is held to be legally invalid, inoperative or unenforceable, the remainder of this Agreement shall not be
          affected unless the invalid provision substantially impairs the benefits of the remaining portions of this Agreement to the other
          party. Any consent or waiver executed in writing by a party shall be binding upon such party from and after the date of execution
          thereof unless a later or earlier date is specified therein. Any delay or failure to exercise any remedy or right under this
          Agreement (whether a default exists or not) shall not be construed as a waiver of such remedy or right, or an acquiescence in such
          default, nor shall it affect any subsequent default of the same or a different nature. Any reference to termination herein shall
          similarly apply to expiration, non-renewal or cancellation of an account or access. This Agreement shall be binding upon and shall
          inure to the benefit of the parties hereto and their respective successors and permitted assigns. This Agreement is governed by
          the laws of the State of New York without reference to its conflicts of law provisions. Each party hereby agrees and consents that
          any legal action or proceedings with respect to this Agreement shall only be brought in the courts of the State of New York in New
          York City County except that we may bring or remove an action to any other venue and jurisdiction in order to enforce the
          covenants herein. By execution and delivery of this Agreement, each such party hereby (i) accepts the jurisdiction of the
          aforesaid courts; (ii) waives, to the fullest extent permitted by law, any objection which it may now or hereafter have to the
          venue set forth above; and (iii) further waives any claim that any such suit, action or proceeding brought in any such court has
          been brought in an inconvenient forum. Each party hereby acknowledges personal jurisdiction in New York for purposes hereof.
          Notwithstanding any other rights a party may have under law or equity, any cause of action you may have arising out of or related
          to this Agreement must commence within one (1) year after the cause of action arises. Otherwise, the cause in of action is
          permanently barred. THE PARTIES WAIVE THE RIGHT TO TRIAL BY JURY. In the event that any party brings litigation at law or in
          equity arising out of this Agreement, if we are the substantially prevailing party, then we shall be entitled to all expenses
          incurred, including but not limited to, reasonable attorneys’ fees all collection, court and other costs incurred in and leading
          up to such proceeding, and the costs of collection on any judgment. Any provision of this Agreement which by its express terms or
          by reasonable implication must survive expiration or termination in order to be effectuated shall so survive expiration or
          termination.
        </p>
        <ul>
          <li>Date: 9/27/2021</li>
          <li>Date Last Updated: 9/27/2021</li>
        </ul>
      </div>
    </div>
  );
};

export default TermsComponent;
