import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { getScanManagment } from '../../api/patient-scan-request/PatientScanRequestAPI';
import LoaderComponent from '../../components/loader-component';
import { NewScanUrl } from '../../config/UrlsConfig';
import { ScanManagment } from '../../models/ScanManagment';
import NoScansFoundComponent from './NoScansFoundComponent';
import ScanBody from './ScanBody';
import { WarningSatuses } from './utils';

const ScanManagementComponent: React.FunctionComponent = () => {
  const [scans, setScans] = useState<ScanManagment[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const getAllScans = () => {
    getScanManagment()
      .then(data => {
        if (!data || !data.Data) {
          setScans([]);
          return;
        }
        setScans(data.Data);
      })
      .catch(() => setScans([]))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    getAllScans();
  }, []);

  const reloadScans = useCallback(() => {
    setIsLoading(true);
    getAllScans();
  }, []);

  const isError = useMemo(() => scans.some(scan => scan.WarningStatusID === WarningSatuses.Error), [scans]);

  const isWarning = useMemo(() => scans.some(scan => scan.WarningStatusID === WarningSatuses.Warning), [scans]);
  if (isLoading) {
    return <LoaderComponent />;
  }

  return (
    <div className='scan-management'>
      {!scans?.length ? (
        <NoScansFoundComponent />
      ) : (
        <div className='scan-management_сontrol-panel'>
          <div className='container'>
            <div className='global-title_wrap'>
              <div className='global-title_wrap_box'>
                <div className='sub-title'>Track your scans</div>
                <h1 className='h1'>Manage your appointments</h1>
              </div>
              <Link className='btn btn-scan' to={NewScanUrl}>
                Book another Scan
              </Link>
            </div>
            <div className='control-panel-tabs'>
              <div className={`scan-status ${isError ? 'error' : 'warning'}`} hidden={!isError && !isWarning}>
                <span className='icon'></span>
              </div>
              <div className='control-panel-tabs_content'>
                {scans.map(scan => (
                  <ScanBody key={scan.TestRequestID} scan={scan} reloadScans={reloadScans} />
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ScanManagementComponent;
