import React, { useMemo, useState } from 'react';
import InsuranceInformation from '../insurance-information';
import PaymentInformation from '../payment-information';
import { INSURANCE_INFORMATION_HEAD_DATA, PAYMENT_INFORMATION_HEAD_DATA } from '../../views/patient-scan-request/PatientScanRequestUtils';
import { PaymentMethodEditProps } from './interfaces';
import InfoBox from '../info-box';
import { ScanRequest } from '../../models/ScanRequest';
import { EDIT_SOURCE, PATIENT_PING_SOURCE, replaceWhitespacesForUnderscore } from '../../utils/GeneralUtil';
import { convertToPriceStr } from '../../utils/PriceUtils';
import { Trans, useTranslation } from 'react-i18next';
import { HEALTHYR } from '../../views/healthyr';

const PaymentMethodComponent: React.FunctionComponent<PaymentMethodEditProps> = ({
  goToPreviousStep,
  scanRequestData,
  completeStepCallback,
  source,
  mixpanelHandoffPayload,
  addInfo,
  partner,
  isInsuranceRequired = false,
}) => {
  const { t } = useTranslation('handoff');
  const { head: paymentHead } = PAYMENT_INFORMATION_HEAD_DATA;
  const { head: insuranceHead } = INSURANCE_INFORMATION_HEAD_DATA;
  const {
    SelfPay,
    scanServiceSelected,
    PolicyNumber,
    InsurancePlan,
    InsuranceCardBack,
    InsuranceCardFront,
    InsurancePlanID,
    FullNameOnCard,
    CardExpirationDate,
    CardType,
    CardLast4,
    PaymentID,
    PaymentSecret,
  } = scanRequestData;
  const [selectedOption, setSelectedOption] = useState<number>(SelfPay as number);
  const [data, setData] = useState<Partial<ScanRequest>>({
    PolicyNumber,
    InsurancePlan,
    InsuranceCardBack,
    InsuranceCardFront,
    InsurancePlanID,
    FullNameOnCard,
    CardExpirationDate,
    CardType,
    CardLast4,
    PaymentID,
    PaymentSecret,
  });

  const selectOption = (value: number) => {
    setData(prev => ({
      ...prev,
      PolicyNumber: value === 0 ? prev.PolicyNumber : '',
      InsurancePlan: value === 0 ? prev.InsurancePlan : '',
      InsuranceCardBack: value === 0 ? prev.InsuranceCardBack : undefined,
      InsuranceCardFront: value === 0 ? prev.InsuranceCardFront : undefined,
      InsurancePlanID: value === 0 ? prev.InsurancePlanID : 0,
      FullNameOnCard: value === 1 ? prev.FullNameOnCard : '',
      CardExpirationDate: value === 1 ? prev.CardExpirationDate : '',
      CardType: value === 1 ? prev.CardType : '',
      CardLast4: value === 1 ? prev.CardLast4 : 0,
      PaymentID: value === 1 ? prev.PaymentID : '',
      PaymentSecret: value === 1 ? prev.PaymentSecret : '',
    }));
    setSelectedOption(value);
  };

  const isContentShown = useMemo(() => {
    return !(source === PATIENT_PING_SOURCE && addInfo && addInfo.isOnlyUploadEnabled);
  }, [addInfo]);

  const parsedAveragePrice = useMemo(() => {
    const averagePrice = scanServiceSelected?.AvgPrice || '';
    if (!averagePrice) {
      return averagePrice;
    }
    const dotPosition = averagePrice.indexOf('.');
    return convertToPriceStr(Number(averagePrice.slice(0, dotPosition)));
  }, [scanServiceSelected?.AvgPrice]);

  return (
    <>
      <div className='request-type'>
        <h2
          id={selectedOption ? replaceWhitespacesForUnderscore(paymentHead) : replaceWhitespacesForUnderscore(insuranceHead)}
          className='h2'
        >
          {addInfo
            ? t(addInfo.title as unknown as TemplateStringsArray)
            : partner === HEALTHYR && scanRequestData.PrescriptionQuestionResponseID === 0
            ? 'Payment'
            : selectedOption
            ? t('payment.title2')
            : t('insurance.title')}
        </h2>
        {isContentShown ? (
          <div className='text-wrap'>
            {partner === HEALTHYR && scanRequestData.PrescriptionQuestionResponseID === 0 ? (
              <p>
                <u>
                  You’ll get a confirmation email with instructions for your consultation shortly after. After your consultation Medmo will
                  schedule your exam for you.
                </u>
              </p>
            ) : selectedOption ? (
              <p>
                <u>{t('payment.text')}</u> {t('payment.text2')}
              </p>
            ) : (
              <p>
                <Trans ns='handoff' i18nKey='insurance.subTitle' components={{ b: <b /> }} />
              </p>
            )}
          </div>
        ) : null}
        <div className='request-type-payments bordered' hidden={source !== EDIT_SOURCE}>
          <a
            className={`request-type-payments_item pointer-cursor ${selectedOption === 0 ? 'active' : ''}`}
            onClick={() => selectOption(0)}
          >
            <div className='row'>
              <p>
                <b>{t('how_to_cover.Insurance')}</b>
              </p>
              <p>{t('how_to_cover.Available')}</p>
            </div>
            <div className='info'>{t('how_to_cover.Insurance coverage')}</div>
          </a>
          <a
            className={`request-type-payments_item pointer-cursor ${selectedOption === 1 ? 'active' : ''}`}
            onClick={() => selectOption(1)}
          >
            {!!parsedAveragePrice && source !== 'edit' && (
              <div className='hint'>
                <p>
                  {t('how_to_cover.Compared to the average market rate')} - <b>${parsedAveragePrice}</b>
                </p>
              </div>
            )}
            <div className='row'>
              <p>
                <b>{t('how_to_cover.Self pay')}</b>
              </p>
              <p>${scanServiceSelected?.TotalPrice}</p>
            </div>
            <div className='info'>{t('how_to_cover.Medmo self pay rate')}</div>
          </a>
        </div>
        {selectedOption ? (
          <PaymentInformation
            goToPreviousStep={goToPreviousStep}
            completeStepCallback={completeStepCallback}
            scanRequestData={{ ...scanRequestData, ...data }}
            isEditMode={source === EDIT_SOURCE}
            partner={partner}
          />
        ) : (
          <InsuranceInformation
            goToPreviousStep={goToPreviousStep}
            completeStepCallback={completeStepCallback}
            scanRequestData={{ ...scanRequestData, ...data }}
            source={source}
            mixpanelHandoffPayload={mixpanelHandoffPayload}
            addInfo={addInfo}
            isInsuranceRequired={isInsuranceRequired}
          />
        )}
      </div>
      <InfoBox
        title={selectedOption ? t('payment.box.title') : t('insurance.box.title')}
        content={
          selectedOption ? (
            partner === HEALTHYR && scanRequestData.PrescriptionQuestionResponseID === 0 ? (
              <span>
                This price includes a consultation to retrieve your prescription for the appointment upon clinical approval, concierge
                scheduling services, and 24/7 customer support.
              </span>
            ) : (
              t('payment.box.content')
            )
          ) : (
            t('insurance.box.content')
          )
        }
      />
    </>
  );
};

export default PaymentMethodComponent;
