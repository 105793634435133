import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { SelectScanTypeProps } from './interfaces';
import LoaderComponent from '../loader-component';
import { getAvailableICsNearbyPerScanType, getAvailableICsNearbyPerScanTypeAndZipCode } from '../../api/imaging-center/ImagingCenterAPI';
import { ScanType, ScanTypeDisplayNode } from '../../models/ScanType';
import InfoBox from '../info-box';
import NoAvailableICsNearby from '../../views/patient-scan-request/no-available-ics-nearby';
import {
  CANT_FIND_WHAT_YOU_NEED_INFOBOX_DATA,
  EMPTY_NON_CONFIRMED_SCAN_TYPE_SELECTION,
  SELECT_BODY_PART_TEXTS,
  SELECT_FINAL_DETAILS_TEXTS,
  SELECT_IMAGING_TYPE_TEXTS,
  STEP_NAMES,
} from '../../views/patient-scan-request/PatientScanRequestUtils';
import { NonConfirmedScanTypeSelection } from '../../views/patient-scan-request/interfaces';
import { Address } from '../../models/Address';
import { MixpanelContext } from '../../contexts/MixpanelContext';
import {
  AVAILABLE_ICS_NEARBY_FOUND,
  AVAILABLE_ICS_NEARBY_NOT_FOUND,
  BACK_FROM_BODY_PART,
  BACK_FROM_SCAN_DETAILS,
  BACK_FROM_SCAN_FINAL_DETAILS,
  BODY_PART_SELECTED,
  SCAN_DETAILS_SELECTED,
  SCAN_TYPE_SELECTED,
} from '../../utils/MixpanelEvents';
import { replaceWhitespacesForUnderscore } from '../../utils/GeneralUtil';
import { CommonContext, CommonContextFunctions, CommonContextTypes } from '../../contexts/CommonContext';
import { buildScanTypesTree } from '../../utils/ScanTypesUtils';

const SelectScanType: React.FunctionComponent<SelectScanTypeProps> = ({
  goToNextStep,
  goToPreviousStep,
  scanRequestData,
  isSolv,
  nonConfirmedScanTypeSelection,
  zipCode,
  nonConfirmedScanTypeSelectionCallback,
}: SelectScanTypeProps) => {
  const { title, content, urlText, url } = CANT_FIND_WHAT_YOU_NEED_INFOBOX_DATA;
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [scanTypesLevelsStored, setScanTypesLevelsStored] = useState<ScanTypeDisplayNode[][]>([]);
  const [scanTypesCurrentlyDisplayed, setScanTypesCurrentlyDisplayed] = useState<ScanTypeDisplayNode[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<string[]>(nonConfirmedScanTypeSelection.selectedOptions);
  const [nameOfScanTypeSelected, setNameOfScanTypeSelected] = useState<string>('');
  const [shouldShowNoICsNearby, setShouldShowNoICsNearby] = useState<boolean>(false);
  const [willTravelFurther, setWillTravelFurther] = useState<boolean>(false);
  const [textsToDisplay, setTextsToDisplay] = useState<{
    title: string;
    description: string;
  }>({ title: '', description: '' });
  const mixpanel = useContext(MixpanelContext);
  const { ScanTypes: allScanTypesFromAPI, getScanTypes } = useContext<CommonContextTypes & CommonContextFunctions>(CommonContext);

  const shouldShowNoICsNearbyCallback = useCallback(
    value => {
      setShouldShowNoICsNearby(value);
    },
    [shouldShowNoICsNearby],
  );

  const willTravelFurtherCallback = useCallback(
    value => {
      setWillTravelFurther(value);
    },
    [willTravelFurther],
  );

  useEffect(() => {
    getScanTypes();
  }, []);

  useEffect(() => {
    if (allScanTypesFromAPI.length) {
      const scanTypesTree: ScanTypeDisplayNode[] = buildScanTypesTree(allScanTypesFromAPI);
      const levelsStored =
        nonConfirmedScanTypeSelection.scanTypesLevelsStored.length > 0
          ? nonConfirmedScanTypeSelection.scanTypesLevelsStored
          : [scanTypesTree];
      setScanTypesLevelsStored(levelsStored);
      setIsLoading(false);
    }
  }, [allScanTypesFromAPI]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (scanTypesLevelsStored.length > 0) {
      setScanTypesCurrentlyDisplayed(scanTypesLevelsStored[scanTypesLevelsStored.length - 1]);
      changeTextsToDisplayByLevel(scanTypesLevelsStored.length);
    }
  }, [scanTypesLevelsStored]);

  const changeTextsToDisplayByLevel = (currentLevelDisplayed: number): void => {
    switch (currentLevelDisplayed) {
      case 1:
        setTextsToDisplay(SELECT_IMAGING_TYPE_TEXTS);
        break;
      case 2:
        setTextsToDisplay(SELECT_BODY_PART_TEXTS);
        break;
      default:
        setTextsToDisplay(SELECT_FINAL_DETAILS_TEXTS);
        break;
    }
  };

  const selectScanType = (scanType: ScanTypeDisplayNode): void => {
    if (scanType.isLeaf) {
      finishScanTypeSelection(scanType);
    } else {
      setSelectedOptions(options => [...options, scanType.name]);
      setScanTypesLevelsStored(levelsStored => [...levelsStored, scanType.children as ScanTypeDisplayNode[]]);
      logMixpanelSelectEvent(scanType.name, scanType.id);
      if (scanTypesLevelsStored.length === 1) {
        getICsNearbyPerScanType(scanType);
      }
    }
  };

  const finishScanTypeSelection = (scanType: ScanTypeDisplayNode): void => {
    const nonConfirmedSelection: NonConfirmedScanTypeSelection = {
      scanSelected: allScanTypesFromAPI.find(({ ScanServiceID }) => ScanServiceID === scanType.id) as ScanType,
      scanTypesLevelsStored,
      selectedOptions,
      willTravelFurther,
    };
    nonConfirmedScanTypeSelectionCallback(nonConfirmedSelection);
    goToNextStep(STEP_NAMES.selectScanType, {
      scanServiceSelected: nonConfirmedSelection.scanSelected as ScanType,
      WillTravelFurther: willTravelFurther ? 1 : 0,
    });
  };

  const logMixpanelSelectEvent = (scanName: string, scanTypeID?: number): void => {
    switch (scanTypesLevelsStored.length) {
      case 1:
        mixpanel.track(SCAN_TYPE_SELECTED, { scanName, scanTypeID });
        break;
      case 2:
        mixpanel.track(BODY_PART_SELECTED, { scanName });
        break;
      case 3:
        mixpanel.track(SCAN_DETAILS_SELECTED, { scanName });
        break;
      default:
        break;
    }
  };

  const getICsNearbyPerScanType = (scanType: ScanTypeDisplayNode): void => {
    if (isSolv || !isSolv) {
      return;
    }

    setIsLoading(true);

    if (zipCode) {
      getAvailableICsNearbyPerScanTypeAndZipCode(zipCode, scanType.id as number)
        .then(distanceOptions => mixpanel.track(AVAILABLE_ICS_NEARBY_FOUND, distanceOptions))
        .catch(error => {
          if (error.status === 404) {
            setNameOfScanTypeSelected(scanType.name);
            shouldShowNoICsNearbyCallback(true);
            mixpanel.track(AVAILABLE_ICS_NEARBY_NOT_FOUND);
          }
        })
        .finally(() => setIsLoading(false));
    } else {
      const { ShouldFindNearHomeAddress, HomeAddressData, SecondaryAddressData, HasSecondAddress } = scanRequestData;
      const findNear = ShouldFindNearHomeAddress !== undefined ? !!ShouldFindNearHomeAddress : !HasSecondAddress;
      const { Latitude, Longitude } = findNear ? (HomeAddressData as Address) : (SecondaryAddressData as Address);
      getAvailableICsNearbyPerScanType(Latitude, Longitude, scanType.id as number)
        .then(distanceOptions => mixpanel.track(AVAILABLE_ICS_NEARBY_FOUND, distanceOptions))
        .catch(error => {
          if (error.status === 404) {
            setNameOfScanTypeSelected(scanType.name);
            shouldShowNoICsNearbyCallback(true);
            mixpanel.track(AVAILABLE_ICS_NEARBY_NOT_FOUND);
          }
        })
        .finally(() => setIsLoading(false));
    }
  };

  const handleBack = (): void => {
    if (selectedOptions.length > 0) {
      logMixpanelBackEvent();
      setSelectedOptions(options => options.slice(0, options.length - 1));
      setScanTypesLevelsStored(levelsStored => levelsStored.slice(0, levelsStored.length - 1));
    } else {
      getBackToPreviousStep();
    }
  };

  const logMixpanelBackEvent = (): void => {
    switch (selectedOptions.length) {
      case 1:
        mixpanel.track(BACK_FROM_BODY_PART);
        break;
      case 2:
        mixpanel.track(BACK_FROM_SCAN_DETAILS);
        break;
      case 3:
        mixpanel.track(BACK_FROM_SCAN_FINAL_DETAILS);
        break;
      default:
        break;
    }
  };

  const isBackActive = useMemo(() => {
    return !(!isSolv && selectedOptions.length < 1);
  }, [isSolv, selectedOptions]);

  const getBackToPreviousStep = (): void => {
    nonConfirmedScanTypeSelectionCallback(EMPTY_NON_CONFIRMED_SCAN_TYPE_SELECTION);
    goToPreviousStep(shouldShowNoICsNearby ? STEP_NAMES.noAvailableICsNearby : STEP_NAMES.selectScanType);
  };

  return (
    <>
      {isLoading ? (
        <LoaderComponent className='loader-block-center' />
      ) : (
        <>
          {shouldShowNoICsNearby ? (
            <NoAvailableICsNearby
              scanTypeName={nameOfScanTypeSelected}
              shouldShowNoICsNearbyCallback={shouldShowNoICsNearbyCallback}
              getBackToPreviousStep={getBackToPreviousStep}
              willTravelFurtherCallback={willTravelFurtherCallback}
            />
          ) : (
            <div className='request-type'>
              <h2 id={replaceWhitespacesForUnderscore(textsToDisplay.title)} className='h2'>
                {textsToDisplay.title}
              </h2>
              {selectedOptions.length > 0 && (
                <div className='request-type-selects'>
                  <p>{textsToDisplay.description}</p>
                  <ul>
                    {selectedOptions.map((selectedOption, index) => (
                      <li key={index}>{selectedOption}</li>
                    ))}
                  </ul>
                </div>
              )}
              <ul className='request-type_list'>
                {scanTypesCurrentlyDisplayed.map((scanType, index) => (
                  <li key={index}>
                    <a onClick={() => selectScanType(scanType)}>{scanType.name}</a>
                  </li>
                ))}
              </ul>
              <div className='btn-row row justify-content-between full-mob'>
                {isBackActive && (
                  <button className='btn btn-white back' onClick={handleBack}>
                    Back
                  </button>
                )}
              </div>
            </div>
          )}
          <InfoBox title={title} content={content} urlText={urlText} url={url} />
        </>
      )}
    </>
  );
};

export default SelectScanType;
