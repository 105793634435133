import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { initUserInformationData, UserInformationFormData, UserInformationFormDataKeys, UserInformationProps } from './interfaces';
import { CLEERLY_HANDOFF_STEP_NAMES } from '../utils';
import { transformDate } from '../../../../utils/DateUtil';
import { FormError } from '../../../../models/General';
import { FormInstance } from 'rsuite/Form';
import { CommonContext, CommonContextFunctions, CommonContextTypes } from '../../../../contexts/CommonContext';
import { Checkbox, Form, SelectPicker } from 'rsuite';
import { UserInformationSchema } from './utils';
import TextField from '../../../../components/text-field-component';
import InputMask from '../../../../components/mask-field';
import { Link } from 'react-router-dom';
import { PrivacyUrl } from '../../../../config/UrlsConfig';
import InfoBox from '../../../../components/info-box';
import LoaderComponent from '../../../../components/loader-component';

const UserInformation: React.FunctionComponent<UserInformationProps> = ({
  infoBoxData,
  completeStepCallback,
  patientDataToPrepopulate,
  isDobActive = false,
}) => {
  const [formData, setFormData] = useState<UserInformationFormData>(() =>
    patientDataToPrepopulate
      ? {
          TermsAgreed: 0,
          ...patientDataToPrepopulate,
          DOB: transformDate(patientDataToPrepopulate.DOB, 'MM/DD/YYYY'),
        }
      : initUserInformationData,
  );
  const [formError, setFormError] = useState<FormError>({});
  const [isLoading, setLoading] = useState<boolean>(true);
  const formRef = useRef<FormInstance | null>(null);
  const { Genders: biologicalSexes, getGenders } = useContext<CommonContextTypes & CommonContextFunctions>(CommonContext);

  useEffect(() => {
    if (!biologicalSexes.length) {
      getGenders();
    } else {
      setLoading(false);
    }
  }, [biologicalSexes]);

  const onSubmit = () => {
    if (!formRef.current || !formRef.current.check()) {
      return;
    }

    completeStepCallback(formData, CLEERLY_HANDOFF_STEP_NAMES.userInformation);
  };

  const handleChange = (formValue: UserInformationFormData) => {
    setFormData(formValue);
  };

  const areRequiredFieldsFilled = useMemo(
    () =>
      (Object.keys(formData) as UserInformationFormDataKeys).every(key => {
        if (key === 'GenderId' && typeof formData[key] === 'number') {
          return true;
        }

        return !!formData[key];
      }),
    [formData],
  );

  if (isLoading) {
    return <LoaderComponent />;
  }

  return (
    <>
      <div className={`introductory-form create-acc-form main-request-form`}>
        <div id='Create_a_Medmo_Account' className='h2'>
          Please complete below
        </div>
        <div className='introductory-form_text'>
          <p>We need this information to schedule your exams.</p>
        </div>
        <Form
          model={UserInformationSchema}
          onChange={(formValue: Record<string, any>) => handleChange(formValue as UserInformationFormData)}
          onCheck={(formError: FormError) => setFormError(formError)}
          formValue={formData}
          ref={formRef}
          fluid
        >
          <div className='row row-wrap'>
            <TextField
              className='w50'
              name='FirstName'
              label='First name'
              autoComplete='off'
              error={formError.FirstName}
              value={formData.FirstName}
              placeholder='Enter First name'
            />
            <TextField
              className='w50'
              name='LastName'
              label='Last name'
              autoComplete='off'
              error={formError.LastName}
              value={formData.LastName}
              placeholder='Enter Last name'
            />
            <TextField
              className={`select-wrap ${formError.GenderId && 'error-border'} w50`}
              accepter={SelectPicker}
              name='GenderId'
              label='Biological Sex'
              error={formError.GenderId}
              value={formData.GenderId}
              data={biologicalSexes}
              placeholder='Choose Biological Sex'
              appearance='subtle'
              placement='bottomStart'
              searchable={false}
            />
            <TextField
              className='w50'
              type='tel'
              name='DOB'
              readOnly={!isDobActive}
              accepter={InputMask}
              label='Date of birth'
              error={formError.DOB}
              value={formData.DOB}
              mask={[/[0-9]/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
              format='MM/DD/YYYY'
              autoComplete='off'
              placeholder='Enter Date of birth'
            />
            <TextField
              className='w50'
              name='Email'
              type='email'
              label='Email'
              autoComplete='off'
              error={formError.Email}
              value={formData.Email}
              placeholder='Enter Email'
            />
            <TextField
              className='w50'
              type='tel'
              accepter={InputMask}
              error={formError.Phone}
              value={formData.Phone}
              mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
              name='Phone'
              autoComplete='off'
              label='Phone'
              placeholder='Enter Phone'
            />
          </div>
          <div className='row justify-content-between row-wrap mb24'>
            <div className='input-checkbox mb0'>
              <Checkbox
                name='TermsAgreed'
                checked={formData.TermsAgreed === 1}
                onChange={(value: any, checked: boolean) => setFormData({ ...formData, TermsAgreed: checked ? 1 : 0 })}
              >
                I agree to Medmo’s&nbsp;
                <Link to={PrivacyUrl} target='_blank'>
                  Privacy Policy
                </Link>
              </Checkbox>
            </div>
          </div>
          <button className='btn w100' onClick={onSubmit} disabled={!areRequiredFieldsFilled}>
            Save & Continue
          </button>
        </Form>
      </div>
      {infoBoxData && <InfoBox title={infoBoxData.title} content={infoBoxData.content} />}
    </>
  );
};

export default UserInformation;
