import React, { useState } from 'react';
import { Form } from 'rsuite';
import TextField from '../../text-field-component';
import Textarea from '../../textarea';
import InfoBox from '../../info-box';
import { useTranslation } from 'react-i18next';
import { BodyGeneralProps, PING_CANCER_STEP_NAMES, HistoryOfCancerDetailsForm } from '../interfaces';

const CancerDetails: React.FunctionComponent<BodyGeneralProps> = ({
  goToPreviousStep,
  completeStepCallback,
  scanRequestData,
  infoBox,
  stepName = PING_CANCER_STEP_NAMES.cancerDetails,
}) => {
  const { t } = useTranslation('handoff');
  const { HistoryOfCancerDetails = '' } = scanRequestData;

  const [formValue, setFormValue] = useState<HistoryOfCancerDetailsForm>({
    HistoryOfCancerDetails,
  });

  const selectResponse = (): void => {
    completeStepCallback({ HistoryOfCancerDetails: formValue.HistoryOfCancerDetails }, PING_CANCER_STEP_NAMES.cancerDetails);
  };

  return (
    <>
      <div className='main-request-form'>
        <h2 id='Additional_questions' className='h2'>
          {t('cancer.title2')}
        </h2>
        <div className='text-form fw600'>
          <p>{t('cancer.If youre not sure, please click Next to continue.')}</p>
        </div>
        <Form
          onChange={value => setFormValue(value as HistoryOfCancerDetailsForm)}
          formValue={formValue}
          className='main-form-appointment'
          fluid
        >
          <div className='input-wrap textarea-wrap h80 mb32'>
            <TextField
              name='HistoryOfCancerDetails'
              value={formValue.HistoryOfCancerDetails}
              placeholder={t('cancer.E.g. I have a family history of lung cancer')}
              accepter={Textarea}
            />
          </div>
        </Form>
        <div className='btn-row row full-mob justify-content-between'>
          <button onClick={() => goToPreviousStep(stepName)} className='btn prev btn-white'>
            {t('form.Back', { ns: 'translations' })}
          </button>
          <button className='btn next' onClick={() => selectResponse()}>
            {t('form.Next', { ns: 'translations' })}
          </button>
        </div>
      </div>
      {infoBox ? <InfoBox title={infoBox.title} content={infoBox.content} /> : null}
    </>
  );
};

export default CancerDetails;
