import React, { KeyboardEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Form } from 'rsuite';
import { FormInstance } from 'rsuite/Form';
import { updatePassword } from '../../api/users/UsersAPI';
import TextFieldPassword from '../../components/text-field-password';
import { FormError } from '../../models/General';
import { EditPasswordFormData } from './interfaces';
import { editPasswordInitData } from './utils';
import InfoModalComponent from '../../components/modals/info-modal';
import { modalTexts } from './utils';
import { InfoModalTexts } from '../../models/General';
import { EditPasswordSchema } from './Schemas';
import { useHistory } from 'react-router';
import { useError } from '../../utils/ErrorUtil';
import { SettingsUrl } from '../../config/UrlsConfig';

const EditPasswordComponent: React.FunctionComponent = () => {
  const [formData, setFormData] = useState<EditPasswordFormData>(editPasswordInitData);
  const [formError, setFormError] = useState<FormError>({});
  const [successText, setSuccessText] = useState<null | InfoModalTexts>(null);
  const [errorText, setErrorText] = useError('');
  const formRef = useRef<FormInstance | null>(null);
  const history = useHistory<{ pathname: string }>();
  const locationFrom = history.location?.state?.pathname;

  useEffect(() => {
    if (formRef.current && formData.newPassword && formData.confirmPassword) {
      formRef.current.checkForField('confirmPassword');
    }
  }, [formData.newPassword]);

  const changePassword = (): void => {
    const { oldPassword, newPassword } = formData;

    updatePassword({ oldPassword, newPassword })
      .then(() => setSuccessText(modalTexts.success.passwordUpdate))
      .catch(e => setErrorText(e));
  };

  const onSubmit = () => {
    if (isSubmitDisabled || !formRef.current || !formRef.current.check()) {
      return;
    }

    changePassword();
  };

  const onCloseSuccessModal = (): void => {
    setFormData(editPasswordInitData);
    history.push(SettingsUrl);
  };

  const isSubmitDisabled = useMemo(() => {
    return !formData.confirmPassword || !formData.newPassword || !formData.oldPassword;
  }, [formData]);

  const checkPasswordsEquality = useCallback(() => {
    return formData.confirmPassword === formData.newPassword;
  }, [formData.confirmPassword, formData.newPassword]);

  const onCancel = () => {
    if (locationFrom) {
      history.push(locationFrom);
      return;
    }
    history.push(SettingsUrl);
  };

  const onKeyDown = (event: KeyboardEvent<HTMLFormElement>): void => {
    if (event.key === 'Enter') {
      event.preventDefault();
      onSubmit();
    }
  };

  return (
    <section className='main-request-section'>
      <div className='main-request-form main-setting-form main-setting-form-into'>
        <h2 className='h2'>Change your password</h2>
        <div className={`text-wrap ${errorText ? 'error' : ''} mb24`}>
          <p>
            {errorText
              ? errorText
              : 'We strongly recommend that you keep your password in a safe place and don’t share it with third parties.'}
          </p>
        </div>
        <Form
          formValue={formData}
          onCheck={formError => setFormError(formError)}
          onChange={formValue => {
            setFormData(formValue as EditPasswordFormData);
            setErrorText('');
          }}
          onKeyDown={event => onKeyDown(event as KeyboardEvent<HTMLFormElement>)}
          model={EditPasswordSchema}
          ref={formRef}
          fluid
        >
          <div className='row row-wrap'>
            <TextFieldPassword
              className='mb24'
              name='oldPassword'
              value={formData.oldPassword}
              placeholder='Enter Password'
              label='Current Password'
              error={formError.oldPassword}
            />
            <TextFieldPassword
              className='mb24'
              name='newPassword'
              rules
              value={formData.newPassword}
              label='New Password'
              placeholder='Enter Password'
              error={formError.newPassword}
              showRulesPermanently
            />
            <TextFieldPassword
              className='mb24'
              name='confirmPassword'
              value={formData.confirmPassword}
              label='Confirm Password'
              placeholder='Enter Password'
              error={formError.confirmPassword}
              rulesList={[{ text: 'New Password must match', check: checkPasswordsEquality }]}
              showRulesPermanently
            />
          </div>
          <div className='btn-row row justify-content-between pt0'>
            <button className='btn btn-white back' onClick={onCancel}>
              Cancel
            </button>
            <button className='btn next' onClick={onSubmit} disabled={isSubmitDisabled}>
              Save
            </button>
          </div>
        </Form>
        {successText && <InfoModalComponent onClose={onCloseSuccessModal} type='success' texts={successText} />}
      </div>
    </section>
  );
};

export default EditPasswordComponent;
