import React, { SyntheticEvent, useCallback, useContext, useState } from 'react';
import InfoBox from '../info-box';
import { UploadPrescriptionProps } from './interfaces';
import { STEP_NAMES } from '../../views/patient-scan-request/PatientScanRequestUtils';
import { FileType } from 'rsuite/Uploader';
import { uploadPrescriptionFile } from '../../api/prescription/PrescriptionAPI';
import LoaderComponent from '../loader-component';
import FilesUploader from '../files-uploader';
import InfoModalComponent from '../modals/info-modal';
import { InfoModalTexts } from '../../models/General';
import { UPLOADING_PRESCRIPTION_ERROR } from './utils';
import { MixpanelContext } from '../../contexts/MixpanelContext';
import { PRESCRIPTION_FILE_SELECTED, PRESCRIPTION_UPLOADED } from '../../utils/MixpanelEvents';
import { FileResponse } from '../../models/FileResponse';
import { Trans, useTranslation } from 'react-i18next';

const UploadPrescription: React.FunctionComponent<UploadPrescriptionProps> = ({
  goToPreviousStep,
  scanRequestData,
  completeStepCallback,
  isUploadOptional = false,
}) => {
  const [prescriptionFiles, setPrescriptionFiles] = useState<FileType[]>(
    scanRequestData.prescriptionFile ? [scanRequestData.prescriptionFile] : [],
  );
  const [isLoading, setIsLoading] = useState(false);
  const [errorDataForModal, setErrorDataForModal] = useState<InfoModalTexts | null>(null);
  const mixpanel = useContext(MixpanelContext);
  const { t } = useTranslation('handoff');

  const isConfirmEnabled = true;

  const filesSelectedCallback = useCallback(
    (files: FileType[]) => {
      setPrescriptionFiles(files);
      mixpanel.track(PRESCRIPTION_FILE_SELECTED);
    },
    [prescriptionFiles],
  );

  const handleConfirm = (event: SyntheticEvent): void => {
    event.preventDefault();
    if (isUploadOptional || isConfirmEnabled) {
      if (
        prescriptionFiles.length ||
        (prescriptionFiles.length &&
          scanRequestData.prescriptionFile &&
          scanRequestData.prescriptionFile.fileKey !== prescriptionFiles[0].fileKey)
      ) {
        if (!scanRequestData.prescriptionFile) {
          uploadFile();
          return;
        }
      } else if (!prescriptionFiles.length && scanRequestData.prescriptionFile && scanRequestData.prescriptionFile.fileKey) {
        const payload = {
          PrescriptionFileKey: '',
          prescriptionFile: undefined,
          RXFile: undefined,
        };
        return completeStepCallback(payload, STEP_NAMES.uploadPrescription);
      } else if (!isUploadOptional) {
        const payload = {
          PrescriptionFileKey: '',
          prescriptionFile: undefined,
          RXFile: undefined,
        };
        return completeStepCallback(payload, STEP_NAMES.uploadPrescription);
      }
      completeStepCallback({}, STEP_NAMES.uploadPrescription);
    } else {
      if (
        !scanRequestData.prescriptionFile ||
        (scanRequestData.prescriptionFile && scanRequestData.prescriptionFile.fileKey !== prescriptionFiles[0].fileKey)
      ) {
        uploadFile();
        return;
      }
      completeStepCallback({}, STEP_NAMES.uploadPrescription);
    }
  };

  const uploadFile = async (): Promise<void> => {
    const fileData = new FormData();
    setIsLoading(true);
    const { blobFile } = prescriptionFiles[0];
    if (!blobFile) {
      return;
    }
    fileData.append('file', blobFile);
    uploadPrescriptionFile(fileData)
      .then(fileUploaded => handleFileUploaded(fileUploaded))
      .catch(() => {
        setErrorDataForModal(UPLOADING_PRESCRIPTION_ERROR);
        setIsLoading(false);
      });
  };

  const handleFileUploaded = (fileUploaded: FileResponse): void => {
    const payload = {
      PrescriptionFileKey: fileUploaded.key,
      prescriptionFile: prescriptionFiles[0],
      RXFile: fileUploaded,
    };
    mixpanel.track(PRESCRIPTION_UPLOADED, fileUploaded);
    completeStepCallback(payload, STEP_NAMES.uploadPrescription);
  };

  if (isLoading) {
    return <LoaderComponent className='loader-block-center' />;
  }

  return (
    <>
      <div className='main-request-form'>
        <h2 id='Please_upload_your_imaging_prescription_referral' className='h2'>
          {t('upload.title')} {isUploadOptional ? t('upload.optional') : t('upload.referral')}
        </h2>
        <div className='main-request-file'>
          <div className='text-form'>
            <p>
              <Trans ns='handoff' i18nKey='upload.text' components={{ b: <strong /> }} />
            </p>
          </div>
          <FilesUploader onChange={filesSelectedCallback} files={prescriptionFiles} multiple={false} className='mb32'>
            {<Trans ns='handoff' i18nKey='upload.drag' components={{ span: <strong /> }} />}
          </FilesUploader>
          {!isUploadOptional && (
            <div className='request-type-payments row'>
              <span className='icon-secure'></span>
              {t('upload.sub_text')}
            </div>
          )}
          <div className='btn-row row justify-content-between full-mob'>
            <button className='btn btn-white back no-arrow' onClick={() => goToPreviousStep(STEP_NAMES.uploadPrescription)}>
              {t('form.Back', { ns: 'translations' })}
            </button>
            <button className='btn next no-arrow' onClick={handleConfirm} disabled={isUploadOptional ? false : !isConfirmEnabled}>
              {t('form.Next', { ns: 'translations' })}
            </button>
          </div>
        </div>
      </div>
      <InfoBox
        title={t('box.SELECT_PHYSICIAN_INFOBOX_DATA.title', { ns: 'translations' })}
        content={t('box.SELECT_PHYSICIAN_INFOBOX_DATA.content', { ns: 'translations' })}
      />
      {errorDataForModal && <InfoModalComponent type='error' texts={errorDataForModal} onClose={() => setErrorDataForModal(null)} />}
    </>
  );
};

export default UploadPrescription;
