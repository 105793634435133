import { PingScanRequest } from '../../views/patient-ping/utils';
import React from 'react';
import { FileType } from 'rsuite/Uploader';

export const PING_METAL_STEP_NAMES = {
  metalBodyPacemaker: 'METAL_BODY_PACEMAKER',
  metalBodyQuestion: 'METAL_BODY_QUESTION',
  metalBodyType: 'METAL_BODY_TYPE',
  metalBodyLocation: 'METAL_BODY_LOCATION',
  metalBodyDetails: 'METAL_BODY_DETAILS',
  metalBodyUploadPacemaker: 'METAL_BODY_UPLOAD_PACEMAKER',
};

export type MetalBodyProps = {
  goToPreviousStep: (stepName?: string) => void;
  scanRequestData: PingScanRequest;
  completeStepCallback: (partialScanData: Partial<PingScanRequest>, stepName?: string) => void;
};

export const initMetalBodyTypeData = {
  HasTitaniumRod: false,
  HasEyeInjury: false,
  HasAnheurysmClips: false,
  HasOtherMetalInBody: false,
  HasNotSure: false,
  OtherMetalInBodyDescription: '',
};

export const resetMetalBodyTypeData = {
  HasTitaniumRod: false,
  HasEyeInjury: false,
  HasAnheurysmClips: false,
  HasOtherMetalInBody: false,
  HasNotSure: false,
  OtherMetalInBodyDescription: '',
};

export const initMetalBodyPacemakerData = {
  HasPacemakerOrDefibrillatorInBody: null,
};

export const initMetalBodyQuestionData = {
  MetalBodyQuestionID: null,
};

export const initMetalBodyUploadPacemakerData = {
  PacemakerFileKey: '',
  pacemakerFile: null,
};

export const initMetalBodyDetailsData = {
  MetalInfo: '',
};

export const initMetalBodyLocationData = {
  MetalLocation: '',
};

export const initMetalBodyData = {
  ...initMetalBodyUploadPacemakerData,
  ...initMetalBodyPacemakerData,
  ...initMetalBodyLocationData,
  ...initMetalBodyDetailsData,
  ...initMetalBodyTypeData,
  ...initMetalBodyQuestionData,
};

export const initMetalBodyGeneralData = {
  data: initMetalBodyData,
  currentStep: 1,
  errorSubmitting: false,
};

export type MetalBodyDetailsForm = {
  MetalInfo: string | null;
};

export type MetalBodyLocationForm = {
  MetalLocation: string | null;
};

export type MetalBodyData = MetalBodyDetailsForm &
  MetalBodyLocationForm &
  MetalBodyTypesForm & {
    MetalBodyQuestionID: number | null;
    HasPacemakerOrDefibrillatorInBody: number | null;
    PacemakerFileKey: string;
    pacemakerFile?: FileType | null;
  };

export type MetalBodyGeneralData = {
  data: MetalBodyData;
  currentStep: number;
  errorSubmitting: boolean;
};

export type MetalBodyGeneralProps = {
  goToPreviousStep: (stepName?: string) => void;
  completeStepCallback: (partialScanData: Partial<PingScanRequest>, stepName?: string) => void;
  infoBox?: { title: string; content: React.ReactNode };
  stepName: string;
  scanRequestData: Partial<PingScanRequest>;
};

export const Questions = [
  { ID: 1, Response: 'Yes' },
  { ID: 0, Response: 'No' },
  { ID: 2, Response: 'Not sure' },
];

export type MetalBodyTypesForm = {
  HasTitaniumRod: boolean | null;
  HasEyeInjury: boolean | null;
  HasAnheurysmClips: boolean | null;
  HasOtherMetalInBody: boolean | null;
  HasNotSure: boolean | null;
  OtherMetalInBodyDescription: string;
};
