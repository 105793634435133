import React from 'react';
import { Props, State } from './interfaces';

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      hasError: false,
      stack: undefined,
      message: undefined,
    };
  }

  private promiseRejectionHandler = (event: PromiseRejectionEvent) => {
    if (event.reason?.status === 401 || event.reason?.message === 'Worker was terminated') {
      return;
    }

    this.setState({
      hasError: true,
      stack: undefined,
      message: event.reason,
    });
  };

  public static getDerivedStateFromError(error: Error): State {
    return { hasError: true, stack: error.stack, message: error.message };
  }

  componentDidMount(): void {
    window.addEventListener('unhandledrejection', this.promiseRejectionHandler);
  }

  componentWillUnmount(): void {
    window.removeEventListener('unhandledrejection', this.promiseRejectionHandler);
  }

  public render(): React.ReactNode {
    if (this.state.hasError) {
      return (
        <div className='wrapper'>
          <main className='main'>
            <section className='page-section'>
              <div className='container center'>
                <h1>Something went wrong. Please try again later.</h1>
              </div>
            </section>
          </main>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
