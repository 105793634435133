import React, { useContext, useState } from 'react';
import InfoBox from '../../../../components/info-box';
import LoaderComponent from '../../../../components/loader-component';
import { AdSectionProps } from './interfaces';
import { HANDOFF_STEP_NAMES } from '../utils';
import { MixpanelContext } from '../../../../contexts/MixpanelContext';
import PopulusMediaComponent from '../../../../components/populus-media';
import { POPULUS_NO_ADS } from '../../../patient-scan-request/PatientScanRequestUtils';
import { HANDOFF_POPULUS } from '../../../../utils/MixpanelEvents';
import { POPULUS_HANDOFF_AD_HIDE_MS } from '../../../../config/FrontConfig';
import { Trans, useTranslation } from 'react-i18next';

const AdSection: React.FunctionComponent<AdSectionProps> = ({
  scanRequestID,
  scan,
  completeStepCallback,
  mixpanelHandoffPayload,
  npi,
  patient,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const mixpanel = useContext(MixpanelContext);
  const { t } = useTranslation('handoff');

  const handleCallbackToClosePopulus = (text: string) => {
    if (text === POPULUS_NO_ADS) {
      setIsLoading(true);
      completeStepCallback({}, HANDOFF_STEP_NAMES.confirmYourIdentityAdSection);
      return;
    }
    mixpanelHandoffPayload && mixpanel.track(HANDOFF_POPULUS, mixpanelHandoffPayload);
    setTimeout(() => {
      setIsLoading(true);
      completeStepCallback({}, HANDOFF_STEP_NAMES.confirmYourIdentityAdSection);
    }, Number(POPULUS_HANDOFF_AD_HIDE_MS));
  };

  if (isLoading) {
    return <LoaderComponent className='loader-block-center' />;
  }

  return (
    <>
      <div className='request-type handoff-process'>
        <div className='scan-service-edit_header'>
          <h2 className='h2'>
            {t('One moment')}&nbsp;
            <span className='dotted-loader'>
              <i></i>
            </span>
          </h2>
          {scanRequestID && scanRequestID > 0 && (
            <div className='row request-id'>
              <div className='box'>
                <div className='title'>{t('Request ID')}</div>
                <div className='num semi-bold'>{scanRequestID}</div>
              </div>
            </div>
          )}
        </div>
        <PopulusMediaComponent npi={npi} patient={patient} step={1} reason='' scan={scan} callbackPopulus={handleCallbackToClosePopulus} />
      </div>
      <InfoBox
        title={t('question.title', { ns: 'translations' })}
        content={
          <Trans
            i18nKey='question.text'
            values={{ email: 'support@medmo.com', phone: '8334463366', phone_format: '(833) 446-3366' }}
            components={{ linkTo: <a /> }}
          />
        }
      />
    </>
  );
};

export default AdSection;
