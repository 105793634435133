import React, { SyntheticEvent, useCallback, useContext, useMemo, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { UploadPrescriptionProps } from '../../../components/upload-prescription/interfaces';
import { STEP_NAMES } from '../../patient-scan-request/PatientScanRequestUtils';
import FilesUploader from '../../../components/files-uploader';
import InfoModalComponent from '../../../components/modals/info-modal';
import LoaderComponent from '../../../components/loader-component';
import { PRESCRIPTION_FILE_SELECTED, PRESCRIPTION_UPLOADED } from '../../../utils/MixpanelEvents';
import { FileResponse } from '../../../models/FileResponse';
import { UPLOADING_PRESCRIPTION_ERROR } from '../../../components/upload-prescription/utils';
import { uploadPrescriptionFile } from '../../../api/prescription/PrescriptionAPI';
import { FileType } from 'rsuite/Uploader';
import { MixpanelContext } from '../../../contexts/MixpanelContext';
import { InfoModalTexts } from '../../../models/General';

const UploadPrescription: React.FunctionComponent<UploadPrescriptionProps> = ({
  goToPreviousStep,
  scanRequestData,
  completeStepCallback,
}) => {
  const [prescriptionFiles, setPrescriptionFiles] = useState<FileType[]>(
    scanRequestData.prescriptionFile ? [scanRequestData.prescriptionFile] : [],
  );
  const [isLoading, setIsLoading] = useState(false);
  const [errorDataForModal, setErrorDataForModal] = useState<InfoModalTexts | null>(null);
  const mixpanel = useContext(MixpanelContext);

  const uploadFileLabel = useMemo(() => {
    return isMobile ? (
      <>Upload from phone</>
    ) : (
      <>
        Drag file here or <span>Browse file</span>
      </>
    );
  }, []);

  const filesSelectedCallback = useCallback(
    (files: FileType[]) => {
      setPrescriptionFiles(files);
      mixpanel.track(PRESCRIPTION_FILE_SELECTED);
    },
    [prescriptionFiles],
  );

  const handleConfirm = (event: SyntheticEvent): void => {
    event.preventDefault();
    if (
      prescriptionFiles.length ||
      (prescriptionFiles.length &&
        scanRequestData.prescriptionFile &&
        scanRequestData.prescriptionFile.fileKey !== prescriptionFiles[0].fileKey)
    ) {
      if (!scanRequestData.prescriptionFile) {
        uploadFile();
        return;
      }
    } else if (!prescriptionFiles.length && scanRequestData.prescriptionFile && scanRequestData.prescriptionFile.fileKey) {
      const payload = {
        PrescriptionFileKey: '',
        prescriptionFile: undefined,
        RXFile: undefined,
      };
      return completeStepCallback(payload, STEP_NAMES.uploadPrescription);
    }
    completeStepCallback({}, STEP_NAMES.uploadPrescription);
  };

  const uploadFile = async (): Promise<void> => {
    const fileData = new FormData();
    setIsLoading(true);
    const { blobFile } = prescriptionFiles[0];
    if (!blobFile) {
      return;
    }
    fileData.append('file', blobFile);
    uploadPrescriptionFile(fileData)
      .then(fileUploaded => handleFileUploaded(fileUploaded))
      .catch(() => {
        setErrorDataForModal(UPLOADING_PRESCRIPTION_ERROR);
        setIsLoading(false);
      });
  };

  const handleFileUploaded = (fileUploaded: FileResponse): void => {
    const payload = {
      PrescriptionFileKey: fileUploaded.key,
      prescriptionFile: prescriptionFiles[0],
      RXFile: fileUploaded,
    };
    mixpanel.track(PRESCRIPTION_UPLOADED, fileUploaded);
    completeStepCallback(payload, STEP_NAMES.uploadPrescription);
  };

  if (isLoading) {
    return <LoaderComponent className='loader-block-center' />;
  }

  return (
    <>
      <div className='main-request-form'>
        <h2 id='Please_upload_your_imaging_prescription_referral' className='h2'>
          Please upload your imaging referral prescription <span>(optional)</span>
        </h2>
        <div className='main-request-file'>
          <div className='text-wrap'>
            <p>
              Please upload the prescription you received from your ordering physician. It can be a picture of the prescription from your
              phone.
            </p>
          </div>
          <FilesUploader onChange={filesSelectedCallback} files={prescriptionFiles} multiple={false}>
            {uploadFileLabel}
          </FilesUploader>
          <div className='btn-row row justify-content-between full-mob'>
            <button className='btn btn-white back' onClick={() => goToPreviousStep(STEP_NAMES.uploadPrescription)}>
              Back
            </button>
            <button className='btn next' onClick={handleConfirm}>
              Next
            </button>
          </div>
        </div>
      </div>
      {errorDataForModal && <InfoModalComponent type='error' texts={errorDataForModal} onClose={() => setErrorDataForModal(null)} />}
    </>
  );
};

export default UploadPrescription;
