import { SERVER_URL } from '../../config/ServerConfig';
import { request } from '../Request';
import { CovidResponse } from '../../models/CovidResponse';

export function getCovidResponsesAPI(): Promise<CovidResponse[]> {
  return request({
    url: `${SERVER_URL}/covid-responses`,
    method: 'GET',
  });
}
