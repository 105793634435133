import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { Progress } from 'rsuite';
import { finishHandoffProcess } from '../../../api/handoff-process/HandoffProcessAPI';
import { HandoffProcessPayload, HandoffPatientInfo, InvitationDataResponse } from '../../../api/handoff-process/interfaces';
import { MixpanelContext } from '../../../contexts/MixpanelContext';
import PatientLocation from '../../../components/patient-location';
import PaymentMethodComponent from '../../../components/payment-method-component';
import SelectAppointmentDatetimes from '../../../components/select-appointment-datetimes';
import { UserContext } from '../../../contexts/UserContext';
import { Address } from '../../../models/Address';
import { NumberBoolean } from '../../../models/General';
import { ScanRequest } from '../../../models/ScanRequest';
import { ALREADY_LOGGED_IN, HANDOFF_PROCESS_SESSION_START, STEP_BACK, STEP_COMPLETED } from '../../../utils/MixpanelEvents';
import Account from '../../../components/account';
import GeneralError from '../../../components/general-error';
import HowToCoverAppointment from '../../../components/how-to-cover-appointment';
import { SCAN_REQUEST_INITIAL_DATA } from '../../patient-scan-request/PatientScanRequestUtils';
import ReviewScanRequest from '../../patient-scan-request/review-scan-request';
import ScanRequestCreated from '../../patient-scan-request/scan-request-created';
import ConfirmYourIdentity from '../components/confirm-your-identity';
import HowMedmoWorks from './how-medmo-works';
import { HandoffProcessData, HandoffProcessProps, MixpanelHandoffPayload, PhysicianDataToDisplay } from './interfaces';
import { HANDOFF_SESSION_STORAGE_KEYS, HANDOFF_STEP_NAMES, HANDOFF_TOTAL_STEPS } from './utils';
import { HANDOFF_SOURCE } from '../../../utils/GeneralUtil';
import AdSection from './ad-show';

const HandoffProcess: React.FunctionComponent<HandoffProcessProps> = ({ authenticate, isAuthenticated, invitationData }) => {
  const { Line } = Progress;
  const { UserID: patientID } = useContext(UserContext);
  const [handoffData, setHandoffData] = useState<HandoffProcessData>(() => {
    const savedCurrentStep = sessionStorage.getItem(HANDOFF_SESSION_STORAGE_KEYS.currentStep);
    const savedScanData = sessionStorage.getItem(HANDOFF_SESSION_STORAGE_KEYS.scanData);
    const savedErrorSubmitting = sessionStorage.getItem(HANDOFF_SESSION_STORAGE_KEYS.errorSubmitting);
    const isFirstLoad = sessionStorage.getItem(HANDOFF_SESSION_STORAGE_KEYS.isFirstLoad);
    return {
      currentStep: savedCurrentStep !== null ? parseInt(savedCurrentStep) : 0,
      scanRequestData: savedScanData !== null ? JSON.parse(savedScanData) : SCAN_REQUEST_INITIAL_DATA,
      errorSubmitting: savedErrorSubmitting !== null ? savedErrorSubmitting === 'true' : false,
      isFirstLoad: isFirstLoad !== null ? isFirstLoad === 'true' : true,
    };
  });
  const [physicianDataToDisplay, setPhysicianDataToDisplay] = useState<PhysicianDataToDisplay>({
    prescriberName: '',
    prescriberPhone: '',
    physicianOrganizationName: '',
  });

  const [attemptsSubmitted, setAttemptsSubmitted] = useState(0);
  const [patientDataToPrepopulate, setPatientDataToPrepopulate] = useState<HandoffPatientInfo | null>(null);
  const [mixpanelHandoffPayload, setMixpanelHandoffPayload] = useState<MixpanelHandoffPayload | null>(null);
  const { invitationID } = useParams<{ invitationID: string }>();
  const mixpanel = useContext(MixpanelContext);

  const percentage = useMemo(() => (handoffData.currentStep / HANDOFF_TOTAL_STEPS) * 100, [handoffData.currentStep]);

  useEffect(() => {
    invitationData && validateInvitation();
  }, [invitationData]);

  useEffect(() => {
    sessionStorage.setItem(HANDOFF_SESSION_STORAGE_KEYS.currentStep, handoffData.currentStep.toString());
    window.scrollTo(0, 0);
  }, [handoffData.currentStep]);

  useEffect(() => {
    sessionStorage.setItem(HANDOFF_SESSION_STORAGE_KEYS.scanData, JSON.stringify(handoffData.scanRequestData));
  }, [handoffData.scanRequestData]);

  useEffect(() => {
    sessionStorage.setItem(HANDOFF_SESSION_STORAGE_KEYS.errorSubmitting, handoffData.errorSubmitting.toString());
  }, [handoffData.errorSubmitting]);

  useEffect(() => {
    sessionStorage.setItem(HANDOFF_SESSION_STORAGE_KEYS.isFirstLoad, handoffData.isFirstLoad.toString());
  }, [handoffData.isFirstLoad]);

  const validateInvitation = (): void => {
    if (invitationData) {
      const mixpanelPayload = {
        POID: invitationData.PhysicianOrganization.ID,
        scanRequestID: invitationData.TestRequestID,
        POUserID: invitationData.POUserID,
      };
      if (handoffData.isFirstLoad) {
        mixpanel.track(HANDOFF_PROCESS_SESSION_START, { invitationID, ...mixpanelPayload });
      }

      setAttemptsSubmitted(invitationData.AttemptCount);
      setHandoffValues(invitationData);
      setPhysicianValues(invitationData);
      setPatientDataToPrepopulate(invitationData.PatientInfo);
      setMixpanelHandoffPayload(mixpanelPayload);
    }
  };

  const setHandoffValues = (invitationResponse: InvitationDataResponse): void => {
    const scanRequestObtainedData = getScanRequestObtainedData(invitationResponse);
    setHandoffData(current => {
      return {
        ...current,
        scanRequestData: { ...current.scanRequestData, ...scanRequestObtainedData },
        isFirstLoad: false,
      };
    });
  };

  const getScanRequestObtainedData = (invitationResponse: InvitationDataResponse): Partial<ScanRequest> => {
    const { ScanServiceID, ScanService, Prescriber, TestRequestID, PatientID } = invitationResponse;
    return {
      ScanServiceID,
      scanServiceSelected: ScanService,
      PhysicianID: Prescriber.ID,
      ID: TestRequestID,
      PatientID,
    };
  };

  const setPhysicianValues = (invitationResponse: InvitationDataResponse): void => {
    const { Prescriber, PhysicianOrganization } = invitationResponse;
    const { FirstName, LastName, Phone, NPI } = Prescriber;
    setPhysicianDataToDisplay({
      prescriberName: `${FirstName} ${LastName}`,
      physicianOrganizationName: PhysicianOrganization.Name,
      prescriberPhone: Phone,
      NPI: NPI,
    });
  };

  const goToPreviousStep = useCallback((stepName?: string): void => {
    setHandoffData(({ currentStep, ...rest }) => ({
      ...rest,
      currentStep: currentStep === 3 ? (isAuthenticated ? currentStep - 2 : currentStep - 1) : currentStep - 1,
      errorSubmitting: false,
    }));
    mixpanel.track(`HANDOFF_${STEP_BACK}_${stepName}`, mixpanelHandoffPayload as MixpanelHandoffPayload);
  }, []);

  const completeStepCallback = useCallback(
    (stepMetadata?: Partial<ScanRequest>, stepName?: string) => {
      const stepDataToSave = stepMetadata || {};
      if (handoffData.currentStep === 1 && isAuthenticated) {
        mixpanel.track(`HANDOFF_${ALREADY_LOGGED_IN}`, { UserID: patientID, ...mixpanelHandoffPayload });
      }
      setHandoffData(({ currentStep, ...rest }) => ({
        ...rest,
        currentStep:
          currentStep === 0
            ? currentStep + 0.5
            : currentStep === 0.5
            ? 1
            : currentStep === 1
            ? isAuthenticated
              ? currentStep + 2
              : currentStep + 1
            : currentStep + 1,
        scanRequestData: { ...rest.scanRequestData, ...stepDataToSave },
      }));
      mixpanel.track(`HANDOFF_${STEP_COMPLETED}_${stepName}`, { ...stepMetadata, ...mixpanelHandoffPayload });
    },
    [handoffData],
  );

  const submitHandoffProcess = async () => {
    const payload = buildHandoffDataPayload(handoffData.scanRequestData);
    try {
      await finishHandoffProcess(invitationID, payload);
    } catch (error) {
      setHandoffData(previous => ({ ...previous, errorSubmitting: true }));
    }
    completeStepCallback({}, HANDOFF_STEP_NAMES.reviewScanRequest);
  };

  const buildHandoffDataPayload = (handoffScanRequestData: ScanRequest): HandoffProcessPayload => {
    let handoffPayload = buildBasePayload(handoffScanRequestData);
    if (!handoffScanRequestData.ShouldFindNearHomeAddress) {
      const { SecondaryAddressData } = handoffScanRequestData;
      handoffPayload = { ...handoffPayload, SecondaryAddressData };
    }
    if (!handoffScanRequestData.SelfPay) {
      handoffPayload = addInsuranceFields(handoffPayload, handoffScanRequestData);
    }
    return handoffPayload;
  };

  const buildBasePayload = (handoffScanRequestData: ScanRequest): HandoffProcessPayload => {
    return {
      TestRequestID: handoffScanRequestData.ID as number,
      PatientID: patientID || null,
      HomeAddressData: handoffScanRequestData.HomeAddressData as Address,
      ShouldFindNearHomeAddress: handoffScanRequestData.ShouldFindNearHomeAddress as NumberBoolean,
      UserBrowserTimezone: handoffScanRequestData.UserBrowserTimezone,
      IsAvailableAnytime: handoffScanRequestData.IsAvailableAnytime as NumberBoolean,
      AvailableDatetimes: handoffScanRequestData.AvailableDatetimes,
      LogisticalNotes: handoffScanRequestData.LogisticalNotes,
      SelfPay: handoffScanRequestData.SelfPay as NumberBoolean,
      CardLast4: handoffScanRequestData.CardLast4 as number,
      CardType: handoffScanRequestData.CardType as string,
      PaymentID: handoffScanRequestData.PaymentID as string,
      PaymentSecret: handoffScanRequestData.PaymentSecret as string,
    };
  };

  const addInsuranceFields = (handoffPayload: HandoffProcessPayload, scanRequestData: ScanRequest): HandoffProcessPayload => {
    const { InsurancePlanID, PolicyNumber, InsuranceCardFront, InsuranceCardBack, GroupNumber } = scanRequestData;
    handoffPayload = {
      ...handoffPayload,
      InsurancePlanID,
      PolicyNumber,
      GroupNumber,
      FrontOfInsuranceCardFileKey: InsuranceCardFront?.key || null,
      BackOfInsuranceCardFileKey: InsuranceCardBack?.key || null,
    };
    return handoffPayload;
  };

  const clearHandoffData = () => {
    sessionStorage.clear();
  };

  const currentStepDisplayed = useMemo<React.ReactNode | null>(() => {
    const { currentStep, scanRequestData, errorSubmitting } = handoffData;
    switch (currentStep) {
      case 0:
        return (
          <ConfirmYourIdentity
            physicianDataToDisplay={physicianDataToDisplay}
            scanRequestID={scanRequestData.ID as number}
            attemptsSubmitted={attemptsSubmitted}
            completeStepCallback={completeStepCallback}
            mixpanelHandoffPayload={mixpanelHandoffPayload as MixpanelHandoffPayload}
          />
        );
      case 0.5: {
        const patient = {
          GenderName: 'Unknown',
          DOB: patientDataToPrepopulate?.DOB ? patientDataToPrepopulate?.DOB : null,
        };
        if (patientDataToPrepopulate?.GenderId) {
          patient.GenderName =
            patientDataToPrepopulate?.GenderId === 1 ? 'Female' : patientDataToPrepopulate?.GenderId === 2 ? 'Male' : 'Unknown';
        }
        return (
          <AdSection
            npi={physicianDataToDisplay.NPI}
            patient={patient}
            scan={handoffData?.scanRequestData?.scanServiceSelected}
            scanRequestID={scanRequestData.ID as number}
            completeStepCallback={completeStepCallback}
            mixpanelHandoffPayload={mixpanelHandoffPayload as MixpanelHandoffPayload}
          />
        );
      }
      case 1:
        return <HowMedmoWorks scanRequestID={scanRequestData.ID as number} completeStepCallback={completeStepCallback} />;
      case 2:
        return (
          <Account
            source={HANDOFF_SOURCE}
            completeStepCallback={completeStepCallback}
            goToPreviousStep={goToPreviousStep}
            authenticate={authenticate}
            defaultHasMedmoAccount={false}
            patientDataToPrepopulate={{ ...patientDataToPrepopulate, DOB: scanRequestData.PatientDOBValidated } as HandoffPatientInfo}
            mixpanelHandoffPayload={mixpanelHandoffPayload as MixpanelHandoffPayload}
          />
        );
      case 3:
        return (
          <PatientLocation
            source={HANDOFF_SOURCE}
            goToPreviousStep={goToPreviousStep}
            scanRequestData={handoffData.scanRequestData}
            completeStepCallback={completeStepCallback}
            mixpanelHandoffPayload={mixpanelHandoffPayload as MixpanelHandoffPayload}
          />
        );
      case 4:
        return (
          <SelectAppointmentDatetimes
            goToPreviousStep={goToPreviousStep}
            scanRequestData={handoffData.scanRequestData}
            completeStepCallback={completeStepCallback}
          />
        );
      case 5:
        return (
          <HowToCoverAppointment
            goToPreviousStep={goToPreviousStep}
            scanRequestData={handoffData.scanRequestData}
            completeStepCallback={completeStepCallback}
          />
        );
      case 6:
        return (
          <PaymentMethodComponent
            goToPreviousStep={goToPreviousStep}
            scanRequestData={scanRequestData}
            completeStepCallback={completeStepCallback}
            source={HANDOFF_SOURCE}
            mixpanelHandoffPayload={mixpanelHandoffPayload as MixpanelHandoffPayload}
          />
        );
      case 7:
        return (
          <ReviewScanRequest goToPreviousStep={goToPreviousStep} submitCallback={submitHandoffProcess} scanRequestData={scanRequestData} />
        );
      case 8:
        return errorSubmitting ? (
          <GeneralError
            goToPreviousStep={goToPreviousStep}
            isFromPXB={false}
            mixpanelHandoffPayload={mixpanelHandoffPayload as MixpanelHandoffPayload}
          />
        ) : (
          <ScanRequestCreated
            scanRequestCreatedID={scanRequestData.ID as number}
            clearProcessData={clearHandoffData}
            isFromPXB={false}
            scanRequestData={scanRequestData}
            isAuthenticated={isAuthenticated}
            mixpanelHandoffPayload={mixpanelHandoffPayload as MixpanelHandoffPayload}
          />
        );
      default:
        return null;
    }
  }, [handoffData, physicianDataToDisplay, patientDataToPrepopulate]);

  return (
    <section className='main-request-section'>
      <Line percent={percentage} strokeColor='#ff8a35' />
      <div className='container'>
        <div className='row'>{currentStepDisplayed}</div>
      </div>
    </section>
  );
};

export default HandoffProcess;
