import React from 'react';

const PatientProviderAgreementInsurance: React.FunctionComponent = () => {
  return (
    <div className='container page-content document'>
      <div className='page-content-wrap'>
        <h1>Patient provider agreement</h1>
        <p>This constitutes a valid and binding agreement among the parties (&quot;Agreement&quot;).</p>
        <p>The Patient or his/her authorized representative used Medmo.com to find and book the services he/she needs.</p>
        <p>Patient warrants that a practicing physician has prescribed the booked service.</p>
        <p>
          Provider represents and warrants that it is fully qualified, credentialed, registered and otherwise licensed to render all
          Services agreed upon herein for the PatTient.
        </p>
        <p>
          Provider attests that the center and all associated staff are in-network with patient&apos;s insurance carrier (if applicable),
          and that no out-of-network charges will be submitted.
        </p>
        <p>
          Provider further attests that it will facilitate, within reasonable limits, the process of obtaining authorization from the
          Patient&apos;s insurance carrier in order to perform the test, if needed. Provider cannot guarantee that Patient&apos;s insurer
          will provide pre-authorization prior to the scheduled test. If prior authorization is delayed by insurer, the test will need to be
          rescheduled at the convenience of both Provider and Patient.
        </p>
        <h2>FURTHER ACKNOWLEDGMENTS AND AGREEMENTS</h2>
        <ol>
          <li>
            &quot;MEDMO, INC.&quot; (&quot;Medmo&quot;; sometimes &quot;we&quot;, &quot;our&quot; or the like) is inclusive of Medmo.com and
            all associated websites and mobile applications (apps).
          </li>
          <li>
            Medmo is acting solely as an intermediary facilitating the scheduling of services provided by third party providers, through
            technological means.
          </li>
          <li>
            Medmo will not be performing any claims adjustment services or auditing the performance of Services. Medmo will not be pursuing
            any collection against the Patient on behalf of Provider. Payments are due in advance through mechanisms arranged for by Medmo.
            Medmo will not be handling any disputes between the Patient and the Provider, whether regarding payment, the provision of the
            Service or otherwise. The Patient and Provider are solely responsible for ensuring/seeking/settling as between themselves any
            issues and disputes including those relating to the provision of a Service and quality of care. BOTH OF SUCH
          </li>
          <li>
            Each of Patient and Provider hereby expressly acknowledge and agree that we are: (i) a technology development and platform
            company; (ii) not an insurance company, benefit plan or third party administrator and are not determining any patient&apos;s
            eligibility to receive benefits under any third party payor program or plan (private or governmental); and (iii) for purposes of
            the Employee Retirement Income Security Act of 1974 (&quot;ERISA&quot;) or any equivalent or similar other state or federal
            laws/regulations that may be applicable, we shall not be deemed the &quot;administrator&quot; or &quot;named fiduciary&quot; of
            any benefit program or plan.
          </li>
          <li>
            Patients shall look solely to Provider for refunds owed of all or any portion of the Service Charge in all instances and
            circumstances. Patients shall look solely to Medmo for refunds owed of all or any portion of the Convenience Charge in all
            instances and circumstances, subject to the terms hereof.
          </li>
          <li>
            MEDMO HAS NO LIABILITY WHATSOVER FOR ANY INJURY, DAMAGE, DEATH, LOSS, ACCIDENT OR DELAY DUE TO ANY FAULT, ACT OF NEGLIGENCE,
            OMISSION OR DEFAULT OF THE CONTRACTING PROVIDER OR PATIENT. IN NO EVENT SHALL MEDMO BE LIABLE TO EITHER PATIENT OR PROVIDER FOR
            LOSS OF GOODWILL, OR FOR SPECIAL, INDIRECT, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES ARISING OUT OF OR RELATING TO THIS
            AGREEMENT, THE PRICING AGREED UPON AND THE SERVICES SOUGHT OR RENDERED, REGARDLESS OF WHETHER SUCH CLAIM ARISES IN TORT OR IN
            CONTRACT.
          </li>
          <li>
            Each of Patient and Provider hereby agree that they are subject to the Terms of Use they agreed to upon registering or
            activating their account. The Terms of Use for Patients are accessible here: [insert web address which is freely accessible
            without log-in], and Providers can access their Terms of Use here: [insert web address, which should be accessible only upon
            log-in by the provider], which terms are hereby deemed incorporated herein as if fully a part hereof and supplement hereto. Each
            party represents and warrants it has reviewed and agreed to such Terms of Use, respectively.
          </li>
          <li>We may assign this Agreement to any successor to our business or assets to which this Agreement relates.</li>
          <li>
            We are deemed a party to this Agreement for purposes of enforcing our rights, the agreements and protections favoring us herein.
            If an applicable jurisdiction determines we are not a direct party, then we shall instead be deemed a third party beneficiary of
            this Agreement for the same purposes.
          </li>
          <li>
            Each party represents and warrants that it is duly authorized and has full power, capacity and authority to accept this
            Agreement on behalf of such party, including the above named Patient or Provider. If the person agreeing hereto is the legal
            representative of the Patient, such legal representative represents and warrants to the parties hereto that he/she/it is fully
            authorized to enter into this Agreement on behalf of the Patient and such legal representative hereby further becomes a party
            hereto and fully bound hereby as if the Patient party.
          </li>
          <li>
            This Agreement as it pertains to Patient and Provider shall be governed by the laws of the State in which the applicable
            Provider is rendering the Service, without regard to the conflicts of law provisions thereof. However, the governing law,
            jurisdiction and venue for any claims with respect to and involving Medmo shall be in accordance with the provisions of the
            parties&apos; respective Terms of Use.
          </li>
          <li>
            By clicking below, entering into this agreement and otherwise using the Medmo website/app, each of Patient and Provider accept
            these terms and conditions as a fully enforceable agreement.
          </li>
        </ol>
        <h2>Key points:</h2>
        <ul>
          <li>You must have a doctor&apos;s prescription for this test.</li>
          <li>
            The imaging center will help obtain pre-authorization for this scan, if needed. If your insurer is slow to respond, the test may
            need to be rescheduled.
          </li>
          <li>The imaging center will be in-network and not submit an out-of-network charge.</li>
        </ul>
      </div>
    </div>
  );
};

export default PatientProviderAgreementInsurance;
