import React, { ChangeEvent, KeyboardEvent, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { PersonalInfoFormData } from './interfaces';
import { initData, modalTexts } from './utils';
import TextField from '../../components/text-field-component';
import { Checkbox, Form, SelectPicker } from 'rsuite';
import { FormError } from '../../models/General';
import { FormInstance } from 'rsuite/Form';
import InputMask from '../../components/mask-field';
import { transformDate } from '../../utils/DateUtil';
import { updatePatientInfo } from '../../api/users/UsersAPI';
import InfoModalComponent from '../../components/modals/info-modal';
import { GenderSelect } from '../../utils/GeneralUtil';
import { InfoModalTexts } from '../../models/General';
import { EditPersonalInfoSchema } from './Schemas';
import { UserContext } from '../../contexts/UserContext';
import { useHistory } from 'react-router';
import { useError } from '../../utils/ErrorUtil';
import { SettingsUrl } from '../../config/UrlsConfig';

const EditPersonalInfoComponent: React.FC = () => {
  const [formData, setFormData] = useState<PersonalInfoFormData>(initData);
  const [formError, setFormError] = useState<FormError>({});
  const [errorText, setErrorText, dataErrors, setDataErrors] = useError('');
  const [successText, setSuccessText] = useState<null | InfoModalTexts>(null);
  const formRef = useRef<FormInstance | null>(null);
  const currentUser = useContext(UserContext);
  const { expandUser, Email, FirstName, LastName, DOB, Phone, GenderId, AllowTexts } = currentUser;
  const history = useHistory<{ pathname: string }>();
  const locationFrom = history.location?.state?.pathname;

  const updatedData = useMemo(
    () => ({
      Email,
      FirstName,
      LastName,
      DOB,
      Phone,
      GenderId,
      AllowTexts,
    }),
    [currentUser],
  );

  useEffect(() => setFormData(updatedData), [currentUser]);

  const updatePatient = (): void => {
    const updatedFormData = { ...formData, DOB: transformDate(formData.DOB, 'YYYY-MM-DD') };

    updatePatientInfo(updatedFormData)
      .then(() => setSuccessText(modalTexts.success.personalInfoUpdate))
      .catch(err => setErrorText(err, formData));
  };

  const onSubmit = () => {
    if (isSubmitDisabled || !formRef.current || !formRef.current.check()) {
      return;
    }

    updatePatient();
  };

  const onCloseErrorModal = (): void => setErrorText(null);

  const onCloseSuccessModal = (): void => {
    setSuccessText(null);

    expandUser({
      FirstName: formData.FirstName,
      LastName: formData.LastName,
      DOB: formData.DOB,
      Phone: formData.Phone,
      GenderId: formData.GenderId,
      AllowTexts: formData.AllowTexts,
    });

    history.push(SettingsUrl);
  };

  const handleChange = (formValue: PersonalInfoFormData, event: ChangeEvent<HTMLInputElement>) => {
    errorText && setErrorText(null);
    const fieldName = event?.target?.name;

    if (fieldName && dataErrors[fieldName]) {
      setDataErrors({ ...dataErrors, [fieldName]: '' });
    }

    setFormData(formValue);
  };

  const isSubmitDisabled = useMemo(() => {
    return (
      FirstName === formData.FirstName &&
      LastName === formData.LastName &&
      Phone === formData.Phone &&
      DOB === formData.DOB &&
      GenderId === formData.GenderId &&
      AllowTexts === formData.AllowTexts
    );
  }, [formData]);

  const onCancel = () => {
    if (locationFrom) {
      history.push(locationFrom);
      return;
    }
    history.push(SettingsUrl);
  };

  const onKeyDown = (event: KeyboardEvent<HTMLFormElement>): void => {
    if (event.key === 'Enter') {
      event.preventDefault();
      onSubmit();
    }
  };

  return (
    <section className='main-request-section'>
      <div className='main-request-form main-setting-form main-setting-form-into'>
        <h2 className='h2'>Your Basic info</h2>
        <div className='text-wrap mb24'>
          <p>Edit your personal information if you have the need to change it.</p>
        </div>
        <Form
          formValue={formData}
          onChange={(formValue, event) => handleChange(formValue as PersonalInfoFormData, event as ChangeEvent<HTMLInputElement>)}
          model={EditPersonalInfoSchema}
          onCheck={formError => setFormError(formError)}
          onKeyDown={event => onKeyDown(event as KeyboardEvent<HTMLFormElement>)}
          ref={formRef}
          fluid
        >
          <div className='row row-wrap'>
            <TextField
              className='w50'
              name='FirstName'
              value={formData.FirstName}
              placeholder='Enter first name'
              error={formError.FirstName || dataErrors.FirstName}
              label='First name'
            />
            <TextField
              className='w50'
              name='LastName'
              value={formData.LastName}
              error={formError.LastName || dataErrors.LastName}
              placeholder='Enter last name'
              label='Last name'
            />
            <TextField
              accepter={SelectPicker}
              name='GenderId'
              data={GenderSelect}
              onSelect={val => setFormData({ ...formData, GenderId: val })}
              value={formData.GenderId}
              appearance='subtle'
              label='Biological Sex'
              placeholder='Choose Biological Sex'
              searchable={false}
              className='select-wrap w50'
            />
            <TextField
              className='w50 birth-wrap'
              name='DOB'
              accepter={InputMask}
              label='Date of birth'
              placeholder='Enter birth date'
              autoComplete='off'
              error={formError.DOB || dataErrors.DOB}
              value={formData.DOB && transformDate(formData.DOB)}
              mask={[/[0-9]/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
              format='MM/DD/YYYY'
            />
            <TextField
              accepter={InputMask}
              error={formError.Phone || dataErrors.Phone}
              value={formData.Phone}
              mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
              name='Phone'
              autoComplete='off'
              label='Phone'
              placeholder='Enter phone number'
            />

            <div className='input-checkbox'>
              <Checkbox
                checked={formData.AllowTexts}
                onChange={() => setFormData(previous => ({ ...previous, AllowTexts: !previous.AllowTexts }))}
              >
                Enable SMS notifications from Medmo, Inc.
              </Checkbox>
            </div>
          </div>
          <div className='btn-row justify-content-between row'>
            <>
              <button className='btn btn-white back' onClick={onCancel}>
                Cancel
              </button>
              <button className='btn next' onClick={onSubmit} disabled={isSubmitDisabled}>
                Save
              </button>
            </>
          </div>
        </Form>
        {(errorText || successText) && (
          <InfoModalComponent
            onClose={errorText ? onCloseErrorModal : onCloseSuccessModal}
            type={errorText ? 'error' : 'success'}
            texts={errorText ? modalTexts.error.personalInfoUpdate : (successText as InfoModalTexts)}
          />
        )}
      </div>
    </section>
  );
};

export default EditPersonalInfoComponent;
