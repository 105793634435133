import { SERVER_URL } from '../../config/ServerConfig';
import { FileResponse } from '../../models/FileResponse';
import { request } from '../Request';

export function uploadPrescriptionFile(data: FormData): Promise<FileResponse> {
  return request({
    url: `${SERVER_URL}/prescription`,
    method: 'POST',
    body: data,
    headers: {
      Expires: '-1',
      'Cache-Control': 'no-cache',
    },
  });
}
