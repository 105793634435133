import React from 'react';
import { STEP_NAMES } from '../../views/patient-scan-request/PatientScanRequestUtils';
import { PrescriptionQuestionProps, Questions } from './interfaces';
import WarningBox from '../warning-box';

const PrescriptionQuestion: React.FunctionComponent<PrescriptionQuestionProps> = ({
  goToPreviousStep,
  completeStepCallback,
  scanRequestData,
}) => {
  const { PrescriptionQuestionResponseID } = scanRequestData;

  const selectResponse = (questionID: number): void => {
    completeStepCallback({ PrescriptionQuestionResponseID: questionID }, STEP_NAMES.prescriptionQuestion);
  };

  return (
    <>
      <div className='main-request-form'>
        <h2 id='Additional_questions' className='h2'>
          Do you have a prescription?
        </h2>
        <div className='main-form-appointment'>
          <div className='mb32'>
            {Questions.map(({ ID, Response }) => (
              <button
                className={`appointment-field ${ID === PrescriptionQuestionResponseID ? 'active' : ''}`}
                key={ID}
                onClick={() => selectResponse(ID)}
              >
                {Response}
              </button>
            ))}
          </div>
          <WarningBox>
            <p>
              You&apos;ll need a prescription from an ordering provider to schedule your appointment. If you don&apos;t have one we&apos;ll
              help you obtain one through our partner provider program. We&apos;ll schedule you once you obtain the prescription.
            </p>
          </WarningBox>
          <div className='btn-row row justify-content-between full-mob'>
            <button className='btn btn-white back' onClick={() => goToPreviousStep(STEP_NAMES.prescriptionQuestion)}>
              Back
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrescriptionQuestion;
