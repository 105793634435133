import React, { useState, useMemo } from 'react';
import { Checkbox, Form } from 'rsuite';
import {
  MAMMOGRAM_STEP_NAMES,
  MammogramBreastSymptomsProps,
  MammogramData,
  MammogramQuestionsForm,
  MammogramSymptomsForm,
} from '../../mammogram-breast/interfaces';
import TextField from '../../text-field-component';
import Textarea from '../../textarea';
import { useTranslation } from 'react-i18next';

const MammogramSymptoms: React.FunctionComponent<MammogramBreastSymptomsProps> = ({
  goToPreviousStep,
  scanRequestData,
  completeStepCallback,
}: MammogramBreastSymptomsProps) => {
  const { t } = useTranslation('handoff');
  const {
    SymptomsNotes,
    IsBreastPain,
    IsNippleDischarge,
    IsLumpsInYourBreast,
    HaveNotSymptoms,
    HaveAnyBreastImplants,
    OtherSymptoms,
    HaveAnyFamilyHistoryOfBreastCancer,
  } = scanRequestData;
  const [formValue, setFormValue] = useState<MammogramSymptomsForm & MammogramQuestionsForm>({
    SymptomsNotes,
    HaveNotSymptoms,
    IsBreastPain,
    IsNippleDischarge,
    IsLumpsInYourBreast,
    HaveAnyBreastImplants,
    OtherSymptoms,
    HaveAnyFamilyHistoryOfBreastCancer,
  });

  const onNextHandler = (): void => {
    const {
      SymptomsNotes,
      HaveNotSymptoms,
      IsBreastPain,
      IsNippleDischarge,
      IsLumpsInYourBreast,
      HaveAnyBreastImplants,
      OtherSymptoms,
      HaveAnyFamilyHistoryOfBreastCancer,
    } = formValue;
    const payload: Partial<MammogramData> = {
      SymptomsNotes,
      HaveNotSymptoms,
      IsBreastPain,
      IsNippleDischarge,
      IsLumpsInYourBreast,
      HaveAnyBreastImplants,
      OtherSymptoms,
      HaveAnyFamilyHistoryOfBreastCancer,
    };

    completeStepCallback(payload, MAMMOGRAM_STEP_NAMES.symptoms);
  };

  const isBtnEnabled = useMemo(() => {
    const { SymptomsNotes, ...rest } = formValue;
    if (SymptomsNotes !== null) {
      return Object.values(rest).some(Boolean);
    }
    return false;
  }, [formValue]);

  return (
    <div className='main-request-form'>
      <h2 id='When_would_you_like_your_appointment' className='h2'>
        {t('mammogram.symptom_title')}
      </h2>
      <Form
        onChange={value => setFormValue(value as MammogramSymptomsForm & MammogramQuestionsForm)}
        formValue={formValue}
        className='main-form-appointment'
        fluid
      >
        <div className='input-checkbox_wrap'>
          <div className='input-checkbox mb8'>
            <Checkbox
              checked={formValue.IsBreastPain}
              onChange={() =>
                setFormValue(previous => ({
                  ...previous,
                  HaveNotSymptoms: false,
                  IsBreastPain: !previous.IsBreastPain,
                }))
              }
            >
              {t('mammogram.Breast pain')}
            </Checkbox>
          </div>
          <div className='input-checkbox mb8'>
            <Checkbox
              checked={formValue.IsNippleDischarge}
              onChange={() =>
                setFormValue(previous => ({
                  ...previous,
                  HaveNotSymptoms: false,
                  IsNippleDischarge: !previous.IsNippleDischarge,
                }))
              }
            >
              {t('mammogram.Nipple discharge')}
            </Checkbox>
          </div>
          <div className='input-checkbox mb8'>
            <Checkbox
              checked={formValue.IsLumpsInYourBreast}
              onChange={() =>
                setFormValue(previous => ({
                  ...previous,
                  HaveNotSymptoms: false,
                  IsLumpsInYourBreast: !previous.IsLumpsInYourBreast,
                }))
              }
            >
              {t('mammogram.Lumps in your breast')}
            </Checkbox>
          </div>
          <div className='input-checkbox mb8'>
            <Checkbox
              checked={formValue.HaveAnyBreastImplants}
              onChange={() =>
                setFormValue(previous => ({
                  ...previous,
                  HaveNotSymptoms: false,
                  HaveAnyBreastImplants: !previous.HaveAnyBreastImplants,
                }))
              }
            >
              {t('mammogram.Do you have any breast implants?')}
            </Checkbox>
          </div>
          <div className='input-checkbox mb8'>
            <Checkbox
              checked={formValue.HaveAnyFamilyHistoryOfBreastCancer}
              onChange={() =>
                setFormValue(previous => ({
                  ...previous,
                  HaveNotSymptoms: false,
                  HaveAnyFamilyHistoryOfBreastCancer: !previous.HaveAnyFamilyHistoryOfBreastCancer,
                }))
              }
            >
              {t('mammogram.Do you have any family history of breast cancer?')}
            </Checkbox>
          </div>
          <div className='input-checkbox mb8'>
            <Checkbox
              checked={formValue.OtherSymptoms}
              onChange={() =>
                setFormValue(previous => ({
                  ...previous,
                  HaveNotSymptoms: false,
                  OtherSymptoms: !previous.OtherSymptoms,
                }))
              }
            >
              {t('mammogram.Other')}
            </Checkbox>
          </div>
          <div className='input-checkbox mb0'>
            <Checkbox
              checked={formValue.HaveNotSymptoms}
              onChange={() =>
                setFormValue(previous => ({
                  ...previous,
                  IsBreastPain: false,
                  IsNippleDischarge: false,
                  IsLumpsInYourBreast: false,
                  HaveAnyBreastImplants: false,
                  OtherSymptoms: false,
                  HaveAnyFamilyHistoryOfBreastCancer: false,
                  HaveNotSymptoms: !previous.HaveNotSymptoms,
                }))
              }
            >
              {t('mammogram.None of the Above')}
            </Checkbox>
          </div>
        </div>
        <div className='text-form mb16'>
          <p>{t('contrast.symptoms_text')}</p>
        </div>
        <div className='input-wrap textarea-wrap h80 mb32'>
          <TextField
            placeholder={t('mammogram.symptoms_placeholder')}
            accepter={Textarea}
            value={formValue.SymptomsNotes}
            name='SymptomsNotes'
          />
        </div>
        <div className='btn-row full-mob row justify-content-between'>
          <button className='btn btn-white back' onClick={() => goToPreviousStep(MAMMOGRAM_STEP_NAMES.symptoms)}>
            {t('form.Back', { ns: 'translations' })}
          </button>
          <button className='btn next' disabled={!isBtnEnabled} onClick={onNextHandler}>
            {t('form.Next', { ns: 'translations' })}
          </button>
        </div>
      </Form>
    </div>
  );
};

export default MammogramSymptoms;
