import { ResponseCustomError } from '../errors/interfaces';
import ResponseError from '../errors/ResponseError';
import { ServerError } from '../models/General';

export interface RequestMain extends RequestInit {
  url: string;
}

const mergeFetchOpt = (options: RequestMain): RequestMain => {
  const headers =
    options.headers ||
    new Headers({
      Expires: '-1',
      'Cache-Control': 'no-cache',
      'Content-Type': 'application/json',
    });

  options.mode = 'cors';
  options.credentials = 'include';
  const defaults = { headers };
  return { ...defaults, ...options };
};

const handleErrors = (res: ServerError | ResponseCustomError): Error | ResponseError | Record<string, undefined> => {
  let json = {};

  if (res.status && res.status >= 500) {
    throw new Error(res.message);
  } else if (res.status && res.status !== 200 && res.message) {
    throw new ResponseError(res);
  }

  try {
    json = res;
  } catch (e) {
    throw Error('Response is not in json format');
  }

  return json;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const request = (options: RequestMain): Promise<any> => {
  const shouldStop = localStorage.getItem('isSessionExpired');

  if (shouldStop) {
    return new Promise((resolve, reject) => reject);
  }

  const opts = mergeFetchOpt(options);

  return fetch(opts.url, opts)
    .then(response => response.json())
    .then(response => handleErrors(response));
};
