import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Form } from 'rsuite';
import TextField from '../../components/text-field-component';
import { FormError } from '../../models/General';
import { ForgotPasswordFormData } from './interfaces';
import { ForgotPasswordSchema } from './utils';
import { LoginUrl } from '../../config/UrlsConfig';
import { forgotPassword } from '../../api/login/LoginAPI';
import LoaderComponent from '../../components/loader-component';
import { FormInstance } from 'rsuite/Form';

const ForgotPasswordComponent: React.FunctionComponent = () => {
  const [formData, setFormData] = useState<ForgotPasswordFormData>({
    email: '',
  });
  const [formError, setFormError] = useState<FormError>({});
  const [isLoading, setLoading] = useState<boolean>(false);
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const formRef = useRef<FormInstance | null>(null);
  const emailRef = useRef('');

  const forgotPassQuery = () => {
    forgotPassword(formData.email)
      .then(() => {
        emailRef.current = formData.email;
        setShowConfirm(true);
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => setLoading(false));
  };

  const handleSubmitResetPass = (): void => {
    if (!emailRef.current) {
      if (!formRef.current || !formRef.current.check()) {
        return;
      }
    }

    setLoading(true);

    forgotPassQuery();
  };

  if (isLoading) {
    return <LoaderComponent />;
  }

  return (
    <div className='introductory-form'>
      <div className='introductory-form_title text-center'>Forgot Password?</div>
      {!showConfirm ? (
        <Form
          className='mb24'
          model={ForgotPasswordSchema}
          onChange={formValue => setFormData(formValue as ForgotPasswordFormData)}
          onCheck={formError => setFormError(formError)}
          formValue={formData}
          ref={formRef}
          fluid
        >
          <div className='mb36'>
            <p>Please enter the Email Address for your account below, and we will send you instructions on how to reset your password.</p>
          </div>
          <TextField name='email' type='email' label='Email Address' error={formError.email} value={formData.email} />
          <button className='btn w100 mb0' onClick={handleSubmitResetPass} disabled={!formData.email}>
            Send Reset Link
          </button>
        </Form>
      ) : (
        <div className='conform-form-block'>
          <span className='conform-form-icon'></span>
          <p>Thanks! If there’s an account associated with this email, we’ll send the password reset instructions immediately.</p>
        </div>
      )}
      <div className='row justify-content-between mb24'>
        <p>
          Didn’t receive the e-mail? <a onClick={handleSubmitResetPass}>Resend it</a> or just
          <Link to={LoginUrl}> return to sign in.</Link>
        </p>
      </div>
    </div>
  );
};

export default ForgotPasswordComponent;
