import React, { useState } from 'react';
import { Form } from 'rsuite';
import { CustomCleerlyProps } from '../interfaces';
import TextField from '../../../components/text-field-component';
import { CLEERLY_SELF_SERVICE_STEP_NAMES } from '../utils';
import { Schema } from 'rsuite';
const { StringType } = Schema.Types;

export const ProviderSchema = Schema.Model({
  ProviderName: StringType().isRequired('This field is required.'),
  ProviderPhone: StringType(),
});

const ProviderComponent: React.FunctionComponent<CustomCleerlyProps> = ({ goToPreviousStep, scanRequestData, completeStepCallback }) => {
  const [formData, setFormData] = useState<{ ProviderName: string; ProviderPhone: string }>({
    ProviderName: scanRequestData?.ProviderName ? scanRequestData.ProviderName : '',
    ProviderPhone: scanRequestData?.ProviderPhone ? scanRequestData.ProviderPhone : '',
  });

  const handleSubmit = (): void => {
    completeStepCallback(formData, CLEERLY_SELF_SERVICE_STEP_NAMES.provider);
  };

  return (
    <div className='main-request-form'>
      <h2 id='When_would_you_like_your_appointment' className='h2'>
        Please provide the name of your prescribing doctor
      </h2>
      <div className='text-form opt7'>
        <p>We’ll make sure your results are sent to your provider when ready</p>
      </div>
      <Form
        model={ProviderSchema}
        onChange={value => setFormData(value as { ProviderName: string; ProviderPhone: string })}
        formValue={formData}
        className='main-form-appointment'
        fluid
      >
        <div className='input-wrap textarea-wrap'>
          <TextField label='Provider name' value={formData.ProviderName} name='ProviderName' />
        </div>
        <div className='input-wrap textarea-wrap'>
          <TextField value={formData.ProviderPhone} name='ProviderPhone' autoComplete='off' label='Provider phone number (optional)' />
        </div>
        <div className='btn-row row justify-content-between full-mob'>
          <button
            className='btn btn-white back'
            onClick={() => goToPreviousStep && goToPreviousStep(CLEERLY_SELF_SERVICE_STEP_NAMES.provider)}
          >
            Back
          </button>
          <button className='btn next' disabled={!formData.ProviderName} onClick={handleSubmit}>
            Confirm
          </button>
        </div>
      </Form>
    </div>
  );
};

export default ProviderComponent;
