import React from 'react';
import { CleerlyScanRequest } from '../interfaces';
import { FINISHING_WARNING_BOX_DATA } from '../utils';
import WarningBox from '../../../components/warning-box';

const ReviewProcess: React.FunctionComponent<{ scanRequestData: Partial<CleerlyScanRequest>; orderId: string }> = ({
  scanRequestData,
  orderId,
}) => {
  const { ImagingCenterName, ProviderName, FirstName, LastName } = scanRequestData;
  const { title, content } = FINISHING_WARNING_BOX_DATA;

  return (
    <div className='request-type'>
      <h2 id='Review_and_submit_your_exam_order' className='h2'>
        We’ve succesfully recieved your order!
      </h2>
      <div className='handoff-process'>
        <div className='row request-id'>
          <div className='box'>
            <div className='title'>Order ID</div>
            <div className='num semi-bold'>{orderId}</div>
          </div>
        </div>
      </div>
      <div className='text-wrap'>
        <p>Below are the details of your order. You will receive a confirmation text and email shortly.</p>
      </div>
      <div className='exam-order'>
        <div className='row'>
          <div>Name:</div>
          <div>
            <b>
              {FirstName} {LastName}
            </b>
          </div>
        </div>
        <div className='row'>
          <div>Imaging Center:</div>
          <div>
            <b>{ImagingCenterName}</b>
          </div>
        </div>
        <div className='row'>
          <div>Provider:</div>
          <div>
            <b>{ProviderName}</b>
          </div>
        </div>
        <div className='row payment-row'>
          <div>Cleerly Payment:</div>
          <div>
            <div className='row'>
              <b>$750</b>
              <div className='total paid-full'>Paid in full</div>
            </div>
          </div>
        </div>
      </div>
      <WarningBox title={title}>
        <p>{content}</p>
      </WarningBox>
    </div>
  );
};

export default ReviewProcess;
