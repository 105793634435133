import { PingScanRequest } from '../../views/patient-ping/utils';

export type ContrastComponentProps = {
  goToPreviousStep: (stepName?: string) => void;
  scanRequestData: PingScanRequest;
  completeStepCallback: (partialScanData: Partial<PingScanRequest>, stepName?: string) => void;
};

export const initAllergiesData = {
  HaveNotSymptomsOfContrast: false,
  SymptomsNotesOfContrast: '',
  HasAllergicToIodine: false,
  HasHighBloodPressure: false,
  IsDiabetic: false,
  HasHistoryOfCancer: false,
  HasHistoryOfKidney: false,
  OtherAllergies: false,
};

export type AllergiesData = {
  HaveNotSymptomsOfContrast: boolean;
  SymptomsNotesOfContrast: string;
  HasAllergicToIodine: boolean;
  HasHighBloodPressure: boolean;
  IsDiabetic: boolean;
  HasHistoryOfKidney: boolean;
  OtherAllergies: boolean;
};

export type AllergiesForm = {
  HaveNotSymptomsOfContrast: boolean;
  SymptomsNotesOfContrast: string;
  HasAllergicToIodine: boolean;
  HasHighBloodPressure: boolean;
  IsDiabetic: boolean;
  HasHistoryOfKidney: boolean;
  OtherAllergies: boolean;
};
