import debounce from 'debounce';
import React, { ChangeEvent, SyntheticEvent, useCallback, useContext, useMemo, useRef, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { Form } from 'rsuite';
import { DoctorSearchResult } from '../../api/search/interfaces';
import { Physician } from '../../models/Physician';
import { FormError } from '../../models/General';
import { formatPhone } from '../../utils/PhoneUtil';
import InfoBox from '../info-box';
import InputMasked from '../input-masked';
import LoaderComponent from '../loader-component';
import TextField from '../text-field-component';
import { SELECT_PHYSICIAN_INFOBOX_DATA, STEP_NAMES } from '../../views/patient-scan-request/PatientScanRequestUtils';
import { PhysicianForm, SelectPhysicianSearchResults, SelectPhysicianLoading, SelectPhysicianProps } from './interfaces';
import { ERROR_MESSAGES, PhysicianSchema } from './utils';
import { searchDoctor } from '../../api/search/SearchAPI';
import { MixpanelContext } from '../../contexts/MixpanelContext';
import { PHYSICIAN_SELECTED } from '../../utils/MixpanelEvents';
import { FormInstance } from 'rsuite/Form';

const SelectPhysician: React.FunctionComponent<SelectPhysicianProps> = ({ goToPreviousStep, scanRequestData, completeStepCallback }) => {
  const { title, content } = SELECT_PHYSICIAN_INFOBOX_DATA;
  const { generalError, noResultsFound } = ERROR_MESSAGES;
  const [formValue, setFormValue] = useState<PhysicianForm>({
    FirstName: scanRequestData?.FirstName ?? '',
    LastName: scanRequestData?.LastName ?? '',
    Phone: scanRequestData?.Phone ?? '',
  });
  const [formError, setFormError] = useState<FormError>({});
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [shouldDisplaySearchResults, setShouldDisplaySearchResults] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<SelectPhysicianSearchResults>({ physicians: [], total: 0, error: false });
  const [isLoading, setIsLoading] = useState<SelectPhysicianLoading>({
    physician: false,
    search: false,
    isFirstTimeSearch: true,
  });
  const mixpanel = useContext(MixpanelContext);
  const formRef = useRef<FormInstance>(null);

  const areFieldsValid = useMemo<boolean>(() => !Object.keys(formError).length, [formError]);

  const areRequiredFieldsFilled = useMemo(() => {
    const { FirstName, LastName } = formValue;
    return !!FirstName && !!LastName;
  }, [formValue]);

  const wordsForSearch = useMemo(() => {
    let words = searchQuery.split(' ');
    words = words.map(word => (isNaN(+word) ? word : formatPhone(word)));
    return words;
  }, [searchQuery]);

  const handleSearchPhysician = (event: ChangeEvent<HTMLInputElement>): void => {
    const query = (event.target as HTMLButtonElement).value;
    setSearchQuery(query);
    if (query.length > 1) {
      debouncedSearchPhysician(query);
    }
  };

  const searchPhysician = (query: string): void => {
    setIsLoading(previous => ({ ...previous, search: true, isFirstTimeSearch: false }));
    searchDoctor(query)
      .then(searchResponse => {
        const parsedPhysicians = parsePhysicians(searchResponse.result);
        setSearchResults({ physicians: parsedPhysicians, total: searchResponse.total, error: false });
      })
      .catch(() => setSearchResults({ physicians: [], total: 0, error: true }))
      .finally(() => setIsLoading(previous => ({ ...previous, search: false })));
  };

  const parsePhysicians = (physicians: DoctorSearchResult[]): Physician[] => {
    const parsedPhysicians: Physician[] = physicians.map(({ id, FirstName, LastName, StateName, Phone, NPI, City, Fax }) => ({
      FirstName,
      LastName,
      State: StateName,
      Phone: Phone ? formatPhone(Phone.toString()) : '',
      LookupID: id,
      Npi: NPI?.toString() ?? null,
      City,
      Fax: Fax?.toString() ?? null,
    }));
    return parsedPhysicians;
  };

  const debouncedSearchPhysician = useCallback(debounce(searchPhysician, 400), []);

  const selectPhysician = (physician: Physician): void => {
    const { FirstName, LastName, Phone } = physician;
    const physicianSelected = { FirstName, LastName, Phone: Phone.toString() };
    setFormValue(physicianSelected);
    mixpanel.track(PHYSICIAN_SELECTED, physicianSelected);
    formRef.current?.cleanErrors();
    setFormError({});
  };

  const handleConfirm = (event: SyntheticEvent): void => {
    event.preventDefault();
    completeStepCallback(formValue, STEP_NAMES.selectPhysician);
  };

  const getBack = (event: SyntheticEvent): void => {
    event.preventDefault();
    goToPreviousStep(STEP_NAMES.selectPhysician);
  };

  if (isLoading.physician) {
    return <LoaderComponent className='loader-block-center' />;
  }

  return (
    <>
      <div className='main-request-form'>
        <h2 id='Who_ordered_your_exam' className='h2'>
          Who ordered your exam?
        </h2>
        <div className='text-form opt7'>
          <p>Your ordering physician information is on your referral order / prescription.</p>
        </div>
        <Form
          className='main-form'
          formValue={formValue}
          onChange={value => setFormValue(value as PhysicianForm)}
          model={PhysicianSchema}
          ref={formRef}
          onCheck={error => setFormError(error)}
          fluid
        >
          <div className='input-wrap mb32'>
            <div className={`rs-form-group anim-placeholder${shouldDisplaySearchResults || searchQuery ? ' show' : ''}`}>
              <label className='rs-form-control-label'>You can search for your physician here</label>
              <div className='rs-form-control-wrapper'>
                <input
                  className='rs-input'
                  type='text'
                  value={searchQuery}
                  onFocus={() => setShouldDisplaySearchResults(true)}
                  onBlur={() => setShouldDisplaySearchResults(false)}
                  onChange={handleSearchPhysician}
                ></input>
              </div>
            </div>
            {shouldDisplaySearchResults && searchQuery.length > 1 && !isLoading.isFirstTimeSearch && (
              <div className='dropdown-physician'>
                <div className='dropdown-physician_list'>
                  <div className='title'>
                    {isLoading.search ? (
                      <span>Searching for physicians, please wait...</span>
                    ) : (
                      <>
                        {searchResults.total > 0 ? (
                          <span>Physicians found ({searchResults.total})</span>
                        ) : (
                          <>{searchResults.error ? <span>{generalError}</span> : <span>{noResultsFound}</span>}</>
                        )}
                      </>
                    )}
                  </div>
                  {searchResults.total > 0 && (
                    <div className='scroll'>
                      <table>
                        <tbody>
                          {searchResults.physicians.map(physician => (
                            <tr className='pointer-cursor' key={physician.LookupID} onMouseDown={() => selectPhysician(physician)}>
                              <td>
                                <Highlighter
                                  highlightClassName='highlighted'
                                  searchWords={wordsForSearch}
                                  autoEscape={true}
                                  textToHighlight={`${physician.FirstName} ${physician.LastName}`}
                                />
                              </td>
                              <td>{physician.State}</td>
                              <td>
                                <Highlighter
                                  highlightClassName='highlighted'
                                  searchWords={wordsForSearch}
                                  autoEscape={true}
                                  textToHighlight={physician.Phone ? `(${physician.Phone})` : ''}
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
          <div className='text-form opt7 mb16'>
            <p>Can’t find your physician information in the drop down or want to add your own?</p>
          </div>
          <div className='row'>
            <TextField
              className='w50'
              name='FirstName'
              value={formValue.FirstName}
              error={formError.FirstName}
              label='Physician first name'
            />
            <TextField className='w50' name='LastName' value={formValue.LastName} error={formError.LastName} label='Physician last name' />
          </div>
          <TextField
            accepter={InputMasked}
            value={formValue.Phone}
            error={formError.Phone}
            mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
            name='Phone'
            autoComplete='off'
            label='Phone'
            type='tel'
          />
          <div className='btn-row row justify-content-between full-mob'>
            <button className='btn btn-white back' onClick={getBack}>
              Back
            </button>
            <button className='btn next' onClick={handleConfirm} disabled={!areFieldsValid || !areRequiredFieldsFilled}>
              Confirm
            </button>
          </div>
        </Form>
      </div>
      <InfoBox title={title} content={content} />
    </>
  );
};

export default SelectPhysician;
