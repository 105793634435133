import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { NODE_ENV } from '../config/ServerConfig';
import * as common from './locales/en/common.json';
import * as handoff from './locales/en/handoff.json';
import * as account from './locales/en/account.json';
import * as scanTypesFNList from './locales/en/scanTypesList.json';
import * as commonEs from './locales/es/common.json';
import * as handoffEs from './locales/es/handoff.json';
import * as accountEs from './locales/es/account.json';
import * as scanTypesFNListEs from './locales/es/scanTypesList.json';

export const defaultNS = 'translations';
export const resources = {
  en: {
    translations: common,
    handoff: handoff,
    account: account,
    scanTypesFNList: scanTypesFNList,
  },
  es: {
    translations: commonEs,
    handoff: handoffEs,
    account: accountEs,
    scanTypesFNList: scanTypesFNListEs,
  },
} as const;

i18n.use(initReactI18next).init({
  fallbackLng: 'en',
  lng: 'en',
  ns: ['translations', 'handoff'],
  debug: NODE_ENV === 'local-test',
  resources,
  defaultNS,
  keySeparator: '.', // we use content as keys
});

export const LANGUAGE: Record<string, { name: string; id: number }> = {
  en: {
    name: 'en',
    id: 1,
  },
  es: {
    name: 'es',
    id: 2,
  },
};

i18n.languages = [LANGUAGE.en.name, LANGUAGE.es.name];

export default i18n;
