import React, { useState } from 'react';
import { ConciergeProps } from '../interfaces';
import { STEP_NAMES } from '../../../views/patient-scan-request/PatientScanRequestUtils';
import { CONCIERGE_COST } from '../../../config/FrontConfig';

const ConciergeAssistOption: React.FunctionComponent<ConciergeProps> = ({ goToPreviousStep, scanRequestData, completeStepCallback }) => {
  const { IsConcierge = null } = scanRequestData;
  const [isConcierge, setIsConcierge] = useState<boolean | null>(IsConcierge);

  const handleConfirm = (): void => {
    if (!isConcierge) {
      const data = {
        PaymentID: '',
        PaymentSecret: '',
        CardType: '',
        CardLast4: 0,
        IsConcierge: isConcierge,
      };
      return completeStepCallback(data, STEP_NAMES.conciergeServiceAssist);
    }
    completeStepCallback({ IsConcierge: isConcierge }, STEP_NAMES.conciergeServiceAssist);
  };

  return (
    <div className='request-type'>
      <h2 id='How_would_you_like_to_cover_your_appointment' className='h2'>
        Choose what type of service you’d like
      </h2>
      <div className='text-wrap mb16'>
        <p>Please select your coverage type below.</p>
      </div>
      <div className='request-type-payments request-type-payments-coverage'>
        <a
          className={`request-type-payments_item pointer-cursor ${isConcierge === false ? 'active' : ''}`}
          onClick={() => setIsConcierge(false)}
        >
          <div className='row mb8'>
            <p className='row title'>
              <span className='icon-asist'></span>
              <b>Medmo Assist</b>
            </p>
            <p>
              <b>Free</b>
            </p>
          </div>
          <div className='info'>Our team will coordinate the best appointment for you based on your preferences</div>
        </a>
        <a
          className={`request-type-payments_item pointer-cursor ${isConcierge === true ? 'active' : ''}`}
          onClick={() => setIsConcierge(true)}
        >
          <div className='row mb8'>
            <p className='row title'>
              <span className='icon-ctm'></span>
              <b>Medmo Concierge</b>
            </p>
            <p>
              <b>${CONCIERGE_COST}</b>
            </p>
          </div>
          <div className='info'>
            Personalized care team expert, includes consultation to examine your coverage options, access to preferred times & rescheduling
            services
          </div>
        </a>
      </div>
      <div className='btn-row row justify-content-between full-mob'>
        <button className='btn prev btn-white' onClick={() => goToPreviousStep(STEP_NAMES.howToCoverAppointment)}>
          Back
        </button>
        <button className='btn next' onClick={handleConfirm} disabled={isConcierge === null}>
          Next
        </button>
      </div>
    </div>
  );
};

export default ConciergeAssistOption;
