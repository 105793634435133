import React, { Suspense, SyntheticEvent, useMemo, useState } from 'react';
import { Form } from 'rsuite';
import { MAMMOGRAM_STEP_NAMES, MammogramData, MammogramTimeForm, MammogramTimeProps } from '../interfaces';
import { format } from 'date-fns';
import { transformDate } from '../../../utils/DateUtil';

const SingleDatePicker = React.lazy(() => import('../../date-picker'));
const DATE_FORMAT_FOR_DATETIMES_DATA = 'MM/dd/yyyy';
import { useTranslation } from 'react-i18next';
import useLocationLanguage from '../../../hooks/useLocationLanguage';
import { enUS, es } from 'date-fns/locale';

const MammogramTime: React.FunctionComponent<MammogramTimeProps> = ({
  goToPreviousStep,
  scanRequestData,
  completeStepCallback,
}: MammogramTimeProps) => {
  const { AvailableMammogramDatetime, IsFistMammogram, IsKnowWhenLastMammogram, LastMammogramResponseID } = scanRequestData;
  const [shouldDisplayDatetimePicker, setShouldDisplayDatetimePicker] = useState<boolean>(false);
  const [formValue, setFormValue] = useState<MammogramTimeForm>({
    AvailableMammogramDatetime,
    IsFistMammogram,
    IsKnowWhenLastMammogram,
    LastMammogramResponseID,
  });
  const { t } = useTranslation('handoff');
  const lang = useLocationLanguage();

  const isConfirmEnabled = useMemo(() => {
    const { AvailableMammogramDatetime, IsFistMammogram, IsKnowWhenLastMammogram } = formValue;
    return AvailableMammogramDatetime !== null || IsFistMammogram || IsKnowWhenLastMammogram;
  }, [formValue]);

  const confirmDatetimesSelection = (): void => {
    const { AvailableMammogramDatetime, IsFistMammogram, IsKnowWhenLastMammogram } = formValue;
    const payload: Partial<MammogramData> = {
      AvailableMammogramDatetime,
      IsFistMammogram,
      IsKnowWhenLastMammogram,
      LastMammogramResponseID: IsFistMammogram ? 2 : IsKnowWhenLastMammogram ? 3 : 1,
    };

    completeStepCallback(payload, MAMMOGRAM_STEP_NAMES.time);
  };

  const handleCloseDateTimePicker = (isCanceled: boolean, dateSelected?: Date | string | null): void => {
    setFormValue({
      AvailableMammogramDatetime: (dateSelected && transformDate(dateSelected, 'YYYY-MM-DD HH:mm:ss')) || null,
      IsFistMammogram: false,
      IsKnowWhenLastMammogram: false,
      LastMammogramResponseID: 1,
    });
    setShouldDisplayDatetimePicker(false);
  };

  const handleSpecificDatesSelection = ({ target }: SyntheticEvent): void => {
    if ((target as HTMLElement).localName !== 'button') {
      if (formValue.AvailableMammogramDatetime !== null && !formValue.AvailableMammogramDatetime) {
        return;
      }
      if (formValue.AvailableMammogramDatetime) {
        setFormValue({ ...formValue, IsFistMammogram: false, IsKnowWhenLastMammogram: false });
      } else {
        setShouldDisplayDatetimePicker(true);
      }
    } else {
      setShouldDisplayDatetimePicker(true);
    }
  };

  return (
    <>
      <Suspense fallback={<div className='loading-placeholder'>Loading...</div>}>
        <SingleDatePicker
          shouldShow={shouldDisplayDatetimePicker}
          title={t('mammogram.date_title')}
          onClose={handleCloseDateTimePicker}
          dateFormat={DATE_FORMAT_FOR_DATETIMES_DATA}
          selectedDate={formValue.AvailableMammogramDatetime}
        />
      </Suspense>
      <div className='main-request-form'>
        <h2 id='When_would_you_like_your_appointment' className='h2'>
          {t('mammogram.title')}
        </h2>
        <Form onChange={value => setFormValue(value as MammogramTimeForm)} formValue={formValue} className='main-form-appointment' fluid>
          <div
            onClick={handleSpecificDatesSelection}
            className={`appointment-field appointment-field_btn${
              formValue.AvailableMammogramDatetime !== null && !formValue.IsKnowWhenLastMammogram && !formValue.IsFistMammogram
                ? ' active'
                : ' pointer-cursor'
            }`}
          >
            <div className='appointment-field_placeholder'>{t('mammogram.I know when my last Mammogram was')}</div>
            {formValue.AvailableMammogramDatetime !== null && !formValue.IsKnowWhenLastMammogram && !formValue.IsFistMammogram && (
              <>
                <button onClick={handleSpecificDatesSelection} className='edit-dates'>
                  {t('mammogram.Change date')}
                </button>
                <ul className='appointment-field_data'>
                  <li key='appointment-field_data-1'>
                    {format(new Date(formValue.AvailableMammogramDatetime), DATE_FORMAT_FOR_DATETIMES_DATA, {
                      locale: lang === 'es' ? es : enUS,
                    })}
                  </li>
                </ul>
              </>
            )}
          </div>
          <button
            onClick={() => setFormValue({ ...formValue, IsKnowWhenLastMammogram: false, IsFistMammogram: true })}
            className={`appointment-field appointment-field_btn mb16${formValue.IsFistMammogram ? ' active' : ''}`}
          >
            {t('mammogram.I am scheduling my first Mammogram')}
          </button>
          <button
            onClick={() => setFormValue({ ...formValue, IsFistMammogram: false, IsKnowWhenLastMammogram: true })}
            className={`appointment-field appointment-field_btn mb32${formValue.IsKnowWhenLastMammogram ? ' active' : ''}`}
          >
            {t('mammogram.I cant recall when my last Mammogram was')}
          </button>
          <div className='btn-row row justify-content-between full-mob'>
            <button className='btn btn-white back no-arrow' onClick={() => goToPreviousStep(MAMMOGRAM_STEP_NAMES.time)}>
              {t('form.Back', { ns: 'translations' })}
            </button>
            <button className='btn next no-arrow' disabled={!isConfirmEnabled} onClick={confirmDatetimesSelection}>
              {t('form.Next', { ns: 'translations' })}
            </button>
          </div>
        </Form>
      </div>
    </>
  );
};

export default MammogramTime;
