import React, { useCallback, useEffect, useMemo, useState } from 'react';
import InfoBox from '../info-box';
import { Trans, useTranslation } from 'react-i18next';
import { initSmokerGeneralData, PING_SMOKER_HABITS_STEP_NAMES, SmokerData, SmokerGeneralData, WrapperProps } from './interfaces';
import SmokerHabits from './body';
import SmokerSigsDetails from './details';
import SmokerYearDetails from './year';

const SmokingHabitsWrapper: React.FunctionComponent<WrapperProps> = ({
  goToPreviousStep,
  scanRequestData,
  completeStepCallback,
}: WrapperProps) => {
  const { t } = useTranslation('translations');
  const { IsSmoker, CigarettesPerDay, ActiveSmokerYears } = scanRequestData;
  const [smokerData, setSmokerData] = useState<SmokerGeneralData>(() => {
    const newData = {
      IsSmoker,
      CigarettesPerDay,
      ActiveSmokerYears,
    };
    return {
      ...initSmokerGeneralData,
      currentStep: 1,
      data: { ...initSmokerGeneralData.data, ...newData },
    };
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [smokerData.currentStep]);

  const previousStep = useCallback(
    (stepName?: string): void => {
      if (PING_SMOKER_HABITS_STEP_NAMES.smoker === stepName) {
        return goToPreviousStep(PING_SMOKER_HABITS_STEP_NAMES.smoker);
      }

      setSmokerData(({ currentStep, data }) => {
        const prevStep = 1;
        return {
          data,
          currentStep: currentStep - prevStep,
          errorSubmitting: false,
        };
      });
    },
    [smokerData],
  );

  const completeStep = useCallback(
    (stepMetadata?: Partial<SmokerData>, stepName?: string): void => {
      if (PING_SMOKER_HABITS_STEP_NAMES.smoker === stepName && stepMetadata?.IsSmoker === 3) {
        stepMetadata = { ...stepMetadata, CigarettesPerDay: null, ActiveSmokerYears: null };
        const newData = { ...smokerData.data, ...stepMetadata };
        return completeStepCallback(newData, stepName);
      } else if (PING_SMOKER_HABITS_STEP_NAMES.smokerYear === stepName) {
        stepMetadata = { ...stepMetadata };
        const newData = { ...smokerData.data, ...stepMetadata };
        return completeStepCallback(newData, stepName);
      }

      setSmokerData(({ currentStep, data }) => {
        const nextStep = 1;
        const newData = { ...data, ...stepMetadata };
        return {
          data: newData,
          currentStep: currentStep + nextStep,
          errorSubmitting: false,
        };
      });
    },
    [smokerData],
  );

  const currentStepDisplayed = useMemo<React.ReactNode | null>(() => {
    const { currentStep, data } = smokerData;
    if (!smokerData) {
      return null;
    }
    switch (currentStep) {
      case 1: {
        return (
          <SmokerHabits
            goToPreviousStep={previousStep}
            completeStepCallback={completeStep}
            scanRequestData={data}
            stepName={PING_SMOKER_HABITS_STEP_NAMES.smoker}
          />
        );
      }
      case 2:
        return (
          <SmokerSigsDetails
            goToPreviousStep={previousStep}
            completeStepCallback={completeStep}
            scanRequestData={data}
            stepName={PING_SMOKER_HABITS_STEP_NAMES.smokerCigs}
          />
        );
      case 3:
        return (
          <SmokerYearDetails
            goToPreviousStep={previousStep}
            completeStepCallback={completeStep}
            scanRequestData={data}
            stepName={PING_SMOKER_HABITS_STEP_NAMES.smokerYear}
          />
        );
      default:
        return null;
    }
  }, [smokerData]);

  return (
    <>
      <>{currentStepDisplayed}</>
      <InfoBox
        title={t('box.WHY_DO_YOU_NEED_INFOBOX_DATA.title')}
        content={<Trans i18nKey='box.WHY_DO_YOU_NEED_INFOBOX_DATA.content' components={{ br: <br /> }} />}
      />
    </>
  );
};

export default SmokingHabitsWrapper;
