import React, { useEffect, useState } from 'react';
import InfoBox from '../info-box';
import { RoiProps } from './interfaces';
import { Form, Radio, RadioGroup } from 'rsuite';
import { PING_STEP_NAMES } from '../../views/patient-ping/utils';
import { Trans, useTranslation } from 'react-i18next';

const Roi: React.FunctionComponent<RoiProps> = ({
  scanRequestData,
  goToPreviousStep,
  completeStepCallback,
  infoBox,
  agentInfo,
  stepName = PING_STEP_NAMES.roiConsent,
}) => {
  const [roiFormValue, setRoiFormValue] = useState<string | number>('');
  const { t } = useTranslation('handoff');

  useEffect(() => {
    if (scanRequestData && (scanRequestData.ConsentAccept === 1 || scanRequestData.ConsentAccept === 0)) {
      const value = scanRequestData?.ConsentAccept === 1 ? 'accept' : 'decline';
      setRoiFormValue(value);
    }
  }, [scanRequestData?.ConsentAccept]);

  const onHandleSubmit = () => {
    const result = { ConsentAccept: roiFormValue === 'accept' ? 1 : 0 };
    completeStepCallback(result, stepName);
  };

  return (
    <>
      <div className='main-request-form'>
        <h2 id='Additional_questions' className='h2'>
          {t('roi.title')}
        </h2>
        <div className='main-form-appointment roi-form'>
          <div className='text-form'>
            <p>{t('roi.text')}</p>
          </div>
          <Form formValue={{ roi: roiFormValue }} fluid>
            <RadioGroup
              name='radioList'
              value={roiFormValue}
              onChange={value => {
                setRoiFormValue(value);
              }}
              inline
            >
              <Radio value='accept'>{t('roi.I accept')}</Radio>
              <Radio value='decline'>{t('roi.I decline')}</Radio>
            </RadioGroup>
            <div className='btn-row row justify-content-between full-mob'>
              <button className='btn btn-white back no-arrow' onClick={() => goToPreviousStep(PING_STEP_NAMES.roiConsent)}>
                {t('form.Back', { ns: 'translations' })}
              </button>
              <button className='btn next no-arrow' disabled={roiFormValue === ''} onClick={onHandleSubmit}>
                {t('form.Next', { ns: 'translations' })}
              </button>
            </div>
          </Form>
        </div>
      </div>
      {infoBox ? <InfoBox title={infoBox.title} content={infoBox.content} /> : null}
      {agentInfo ? (
        <InfoBox
          title={t('box.HAVE_QUESTIONS_PING_PATIENT_INFOBOX_DATA.title', { ns: 'translations' })}
          content={
            <Trans
              i18nKey='box.HAVE_QUESTIONS_PING_PATIENT_INFOBOX_DATA.content'
              values={{
                CxName: agentInfo?.CxName || '[CX First Name]',
                CxPhone: agentInfo?.CxPhone || '[CX phone]',
                CxEmail: agentInfo?.CxEmail || '[CX email]',
              }}
              components={{ linkTo: <a /> }}
            />
          }
        />
      ) : null}
    </>
  );
};

export default Roi;
