import { SERVER_URL } from '../../config/ServerConfig';
import { request } from '../Request';
import { ImagingCenter } from '../../models/ImagingCenter';

export function getAvailableICsNearbyPerScanType(latitude: number, longitude: number, scanTypeId: number): Promise<ImagingCenter[]> {
  return request({
    url: `${SERVER_URL}/imaging-centers/distance?latitude=${latitude}&longitude=${longitude}&scanTypeId=${scanTypeId}`,
    method: 'GET',
  });
}

export function getAvailableICsNearbyPerScanTypeAndZipCode(zipCode: string, scanTypeId: number): Promise<ImagingCenter[]> {
  return request({
    url: `${SERVER_URL}/imaging-centers/zip-distance?zipCode=${zipCode}&scanTypeId=${scanTypeId}`,
    method: 'GET',
  });
}
