import { DashboardUrl, NewScanUrl, ScanManagmentUrl, SettingsUrl } from '../../config/UrlsConfig';
export const menuList = [
  {
    text: 'Dashboard',
    link: DashboardUrl,
    icon: 'icon-dashboard',
  },
  {
    text: 'My Appointments',
    link: ScanManagmentUrl,
    icon: 'icon-scan-management',
  },
  {
    text: 'Order a Scan',
    link: NewScanUrl,
    icon: 'icon-order-scan',
  },
  {
    text: 'Settings',
    link: SettingsUrl,
    icon: 'icon-settings',
  },
];
