import { PingScanRequest } from '../../views/patient-ping/utils';

export type ContrastComponentProps = {
  goToPreviousStep: (stepName?: string) => void;
  scanRequestData: PingScanRequest;
  completeStepCallback: (partialScanData: Partial<PingScanRequest>, stepName?: string) => void;
};

export const initContrastData = {
  HaveNotSymptomsOfContrast: false,
  SymptomsNotesOfContrast: '',
  HasAllergicToIodine: false,
  HasHighBloodPressure: false,
  IsDiabetic: false,
  HasHistoryOfCancer: false,
  HasHistoryOfKidney: false,
};

export type ContrastData = {
  HaveNotSymptomsOfContrast: boolean;
  SymptomsNotesOfContrast: string;
  HasAllergicToIodine: boolean;
  HasHighBloodPressure: boolean;
  IsDiabetic: boolean;
  HasHistoryOfCancer: boolean;
  HasHistoryOfKidney: boolean;
};

export type ContrastForm = {
  HaveNotSymptomsOfContrast: boolean;
  SymptomsNotesOfContrast: string;
  HasAllergicToIodine: boolean;
  HasHighBloodPressure: boolean;
  IsDiabetic: boolean;
  HasHistoryOfCancer: boolean;
  HasHistoryOfKidney: boolean;
};
