import React from 'react';
import { ScanRequest } from '../../models/ScanRequest';
import { Schema } from 'rsuite';
import { NonConfirmedScanTypeSelection } from './interfaces';
import { APP_CORP_WEB_URL, POPULUS_USER_TRAFFIC } from '../../config/FrontConfig';

const { StringType, DateType, NumberType, BooleanType } = Schema.Types;

export const PatientSchema = Schema.Model({
  FirstName: StringType().isRequired('This field is required.').containsLetter('Please add a valid name'),
  LastName: StringType().isRequired('This field is required.').containsLetter('Please add a valid last name'),
  GenderId: NumberType().isRequired('This field is required.'),
  DOB: DateType().isRequired('This field is required.').max(new Date(), 'Date of birth cannot be a future date'),
  Email: StringType().isEmail('Please enter a valid email address.'),
  Phone: StringType()
    .pattern(/^\d{3}-\d{3}-\d{4}$/, 'Please enter a valid phone number.')
    .isRequired('This field is required.'),
  Password: StringType().isRequired('This field is required.'),
  TermsAgreed: BooleanType().isRequired('This field is required.'),
});

export const SignInSchema = Schema.Model({
  email: StringType().isEmail('Please enter a valid email address.'),
  password: StringType(),
});

export enum PXB_STEPS {
  getStarted,
  prescriptionQuestion,
  // zipCode,
  selectScanType,
  reviewScanTypeSelection,
  reasonForExam,
  selectAppointmentDatetimes,
  // additionalQuestions,
  // selectPhysician,
  account,
  patientLocation,
  uploadPrescription,
  howToCoverAppointment,
  paymentMethod,
  conciergeAssist,
  conciergePayment,
  reviewScanRequest,
  scanRequestCreated,
}
export const PXB_TOTAL_STEPS = Object.keys(PXB_STEPS).length / 2 - 1;

export const EMPTY_DATETIMES = {
  dates: [],
  datetimes: {},
};

export const SCAN_REQUEST_INITIAL_DATA: ScanRequest = {
  ID: -1,
  PatientPriorityID: null,
  HomeAddressString: '',
  HomeAddressData: null,
  ShouldFindNearHomeAddress: null,
  SecondaryAddressString: '',
  SecondaryAddressData: null,
  UserBrowserTimezone: '',
  WillTravelFurther: null,
  ScanServiceID: -1,
  Reason: '',
  IsAvailableAnytime: null,
  AvailableDatetimes: '',
  LogisticalNotes: '',
  CovidResponseID: -1,
  PreferredLanguageID: null,
  PatientID: -1,
  PhysicianID: -1,
  InsurancePlan: '',
  SelfPay: null,
  FirstName: '',
  LastName: '',
  State: '',
  Phone: '',
  PhysicianData: null,
  PrescriptionFileKey: '',
  CardExpirationDate: '',
  CardLast4: null,
  CardType: '',
  PaymentID: '',
  PaymentSecret: '',
  PrescriptionQuestionResponseID: null,
};

export const EMPTY_NON_CONFIRMED_SCAN_TYPE_SELECTION: NonConfirmedScanTypeSelection = {
  scanSelected: null,
  scanTypesLevelsStored: [],
  selectedOptions: [],
  willTravelFurther: false,
};

export const SESSION_STORAGE_KEYS = {
  currentStep: 'currentStep',
  scanData: 'scanData',
  zipCode: 'zipCode',
  nonConfirmedScanTypeSelection: 'nonConfirmedScanTypeSelection',
  errorSubmitting: 'errorSubmitting',
  wentThroughAuthentication: 'wentThroughAuthentication',
};

export const SESSION_STORAGE_USER_TRAFFIC_INT = 'userScanRandomInt';

export const HAVE_QUESTIONS_INFOBOX_DATA = {
  title: 'Have questions?',
  content:
    'We’re here every step of the way. If you have questions about how to submit a request and/or how we work, contact our Care team!',
  urlText: 'Contact support',
  url: `${APP_CORP_WEB_URL}/contact-us`,
};

export const CANT_FIND_WHAT_YOU_NEED_INFOBOX_DATA = {
  title: 'Can’t find what you need?',
  content:
    'Your prescription should specify what type of imaging you need. Sometimes there is a CPT code that you can search online as well.',
  urlText: 'Contact support',
  url: `${APP_CORP_WEB_URL}/contact-us`,
};

export const SELECT_DATETIMES_INFOBOX_DATA = {
  title: 'Why do you need this?',
  content:
    'In order to find the best appointment, we ask for a few time slots that work for you first. This saves you from any back and forth later on.',
};
/**
 * @todo Replace these placeholder texts with the actual texts once Alex, Barak and the stakeholders define which should be the texts
 */
export const ADDITIONAL_QUESTIONS_INFOBOX_DATA = {
  title: 'Placeholder title',
  content: 'Placeholder content for additional questions infobox data.',
};

export const PRIVACY_INFOBOX_DATA = {
  title: 'Your information is safe.',
  content:
    'Security and privacy protection is critically important to us. Your information is protected using best in class security protocols and procedures.',
};

export const SELECT_PHYSICIAN_INFOBOX_DATA = {
  title: 'Why do you need this?',
  content:
    'Imaging centers need this information so they can contact your physician if necessary.  We’ll also need this information if you’re using your insurance.',
};

export const INSURANCE_INFORMATION_INFOBOX_DATA = {
  title: 'Stay in-network.',
  content: 'We make sure you’re scheduled in-network.',
};

export const PAYMENT_INFORMATION_INFOBOX_DATA = {
  title: 'What’s included in this price?',
  content:
    'An interpretation of the images, a full report, and a copy of the images are included in the price shown.  All appointments are at high-quality imaging centers accredited by ACR and our panel of physicians.',
};

export const INSURANCE_INFORMATION_HEAD_DATA = {
  head: 'Please enter your insurance information',
  getContent: () => <p>We’ll make sure you are scheduled at an in-network facility.</p>,
};

export const PAYMENT_INFORMATION_HEAD_DATA = {
  head: 'We require a small deposit to hold appointments at special rates',
  getContent: () => (
    <p>
      <u>Your deposit won’t be charged until your appointment is confirmed.</u> You’ll pay the remaining cost at the time of your
      appointment directly with the center.
    </p>
  ),
};

export const CCTA_PAYMENT_INFORMATION_HEAD_DATA = {
  head: 'For self-pay appointments, you will pay the imaging center directly at the time of your appointment.',
  getContent: () => <p>Please select your coverage type below.</p>,
};

export const SELECT_IMAGING_TYPE_TEXTS = { title: 'What type of imaging do you need?', description: '' };

export const SELECT_BODY_PART_TEXTS = {
  title: 'Body part',
  description: 'Please select the body part that will require imaging.',
};

export const SELECT_FINAL_DETAILS_TEXTS = {
  title: 'Final details',
  description: 'Let us know if your referral specified any additional details about your scan.',
};

export const COVERAGE_METHODS_WARNING_BOX_DATA = {
  insuranceSubtitle: 'Insurance coverage',
  insuranceContent: ' - we accept all major insurance carriers and make sure you’re only scheduled at an in-network center.',
  selfPaySubtitle: 'Self pay option',
  selfPayContent:
    ' - If you don’t have insurance, you have a high-deductible, or you can’t get your appointment approved by insurance, we offer exclusive self-pay rates that are discounted to help you save. If you choose this option, however, you can’t apply the expense towards your deductible.',
};

export const PAYMENT_INFORMATION_WARNING_BOX_DATA = {
  subtitle: 'Cancelation policy',
  content:
    ' - we understand that sometimes life happens and you’ll need to cancel your appointment. We ask you cancel at least 24 hours prior to your appointment for a refund of your deposit.',
};

export const FINISHING_SCAN_REQUEST_WARNING_BOX_DATA = {
  title: 'What happens next?',
  content:
    'After submitting your scan request, our customer experience team will schedule you the best appointment based on the criteria you requested. You’ll hear from us within a few business hours with details of your appointment via email and text.',
};

export const ONLY_ONE_FILE_ERROR = {
  title: 'Error',
  content: 'Please, select only one file.',
};

export const INCORRECT_FILE_TYPE_ERROR = {
  title: 'Error',
  content: 'Incorrect file type. Please, select a file with a type supported. (Supported types: jpeg, jpg, png, pdf)',
};

export const STEP_NAMES = {
  getStarted: 'GET_STARTED',
  patientPriority: 'PATIENT_PRIORITY',
  patientLocation: 'PATIENT_LOCATION',
  selectZipCode: 'SELECT_ZIP_CODE',
  selectScanType: 'SELECT_SCAN_TYPE',
  noAvailableICsNearby: 'NO_AVAILABLE_ICS_NEARBY',
  reviewScanTypeSelection: 'REVIEW_SCAN_TYPE_SELECTION',
  reasonForExam: 'REASON_FOR_EXAM',
  selectAppointmentDatetimes: 'SELECT_APPOINTMENT_DATETIMES',
  additionalQuestions: 'ADDITIONAL_QUESTIONS',
  login: 'LOGIN',
  createAccount: 'CREATE_ACCOUNT',
  selectPhysician: 'SELECT_PHYSICIAN',
  uploadPrescription: 'UPLOAD_PRESCRIPTION',
  howToCoverAppointment: 'HOW_TO_COVER_APPOINTMENT',
  insuranceInformation: 'INSURANCE_INFORMATION',
  paymentInformation: 'PAYMENT_INFORMATION',
  purchaseInformation: 'PURCHASE_INFORMATION',
  reviewScanRequest: 'REVIEW_SCAN_REQUEST',
  generalError: 'GENERAL_ERROR',
  howItWorks: 'HOW_IT_WORKS',
  conciergeServiceAssist: 'CONCIERGE_SERVICE_ASSIST',
  conciergeServicePayment: 'CONCIERGE_SERVICE_PAYMENT',
  prescriptionQuestion: 'PRESCRIPTION_QUESTION',
};

const POPULUS_USER_TRAFFIC_INT = Number(POPULUS_USER_TRAFFIC);
export const PERCENT_OF_TRAFFIC_USERS =
  POPULUS_USER_TRAFFIC_INT >= 100 ? 100 : POPULUS_USER_TRAFFIC_INT <= 0 ? 0 : POPULUS_USER_TRAFFIC_INT;

export const getRandomIntInclusive = (min: number, max: number) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const POPULUS_ADD_INFO = 'populusAddInfo';
export const POPULUS_NO_ADS = 'No Ads';
export const POPULUS_ADS_RENDERED = 'Ads rendered';
