import React, { SyntheticEvent, useContext, useEffect, useMemo, useState } from 'react';
import { Form } from 'rsuite';
import { uploadInsuranceCardFile, uploadInsurancePlan } from '../../api/insurance/InsuranceAPI';
import ResponseError from '../../errors/ResponseError';
import { FileResponse } from '../../models/FileResponse';
import { ScanRequest } from '../../models/ScanRequest';
import { catch401, EDIT_SOURCE, HANDOFF_SOURCE, PATIENT_PING_SOURCE } from '../../utils/GeneralUtil';
import FilesUploader from '../files-uploader';
import LoaderComponent from '../loader-component';
import TextField from '../text-field-component';
import { COVERAGE_METHODS_WARNING_BOX_DATA, STEP_NAMES } from '../../views/patient-scan-request/PatientScanRequestUtils';
import { InsuranceForm, InsuranceInformationProps } from './interfaces';
import { BACK_CARD_TYPE, FRONT_CARD_TYPE, UPLOADING_CARD_FILES_ERROR } from './utils';
import { InfoModalTexts } from '../../models/General';
import InfoModalComponent from '../modals/info-modal';
import { FileType } from 'rsuite/Uploader';
import { MixpanelContext } from '../../contexts/MixpanelContext';
import {
  BACK_OF_INSURANCE_CARD_FILE_SELECTED,
  BACK_OF_INSURANCE_CARD_FILE_UPLOADED,
  FRONT_OF_INSURANCE_CARD_FILE_SELECTED,
  FRONT_OF_INSURANCE_CARD_FILE_UPLOADED,
  INSURANCE_PLAN_SELECTED,
} from '../../utils/MixpanelEvents';
import AutoCompleteInput from '../autocomplete-input';
import { AutocompleteOption } from '../autocomplete-input/interfaces';
import { isMobile } from 'react-device-detect';
import WarningBox from '../warning-box';
import { CommonContext, CommonContextFunctions, CommonContextTypes } from '../../contexts/CommonContext';
import { Trans, useTranslation } from 'react-i18next';
import { Dict } from 'mixpanel-browser';

const InsuranceInformation: React.FunctionComponent<InsuranceInformationProps> = ({
  goToPreviousStep,
  scanRequestData,
  completeStepCallback,
  source,
  mixpanelHandoffPayload,
  addInfo,
  isInsuranceRequired = false,
}) => {
  const { PolicyNumber, InsurancePlan, InsuranceCardBack, InsuranceCardFront, InsurancePlanID, GroupNumber } = scanRequestData;
  const { t } = useTranslation('handoff');
  const { insuranceSubtitle, insuranceContent, selfPaySubtitle, selfPayContent } = COVERAGE_METHODS_WARNING_BOX_DATA;
  const [formValue, setFormValue] = useState<InsuranceForm>({
    InsuranceProvider: InsurancePlan ?? '',
    PolicyNumber: PolicyNumber ?? '',
    GroupID: GroupNumber ?? '',
    FrontOfInsuranceCardFiles: InsuranceCardFront
      ? [
          {
            ...InsuranceCardFront,
            name: InsuranceCardFront.fileTitle || InsuranceCardFront.originalName,
            fileKey: InsuranceCardFront.key,
            localFileKey: InsuranceCardFront.localFileKey,
          },
        ]
      : [],
    BackOfInsuranceCardFiles: InsuranceCardBack
      ? [
          {
            ...InsuranceCardBack,
            name: InsuranceCardBack.fileTitle || InsuranceCardBack.originalName,
            fileKey: InsuranceCardBack.key,
            localFileKey: InsuranceCardBack.localFileKey,
          },
        ]
      : [],
  });
  const [insurancePlanSelectedID, setInsurancePlanSelectedID] = useState<number | null>(InsurancePlanID || null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [errorDataForModal, setErrorDataForModal] = useState<InfoModalTexts | null>(null);
  const mixpanel = useContext(MixpanelContext);
  const { InsurancePlans, getInsurancePlans } = useContext<CommonContextTypes & CommonContextFunctions>(CommonContext);

  const isOnlyUploadShown = useMemo(() => {
    return !!(source === PATIENT_PING_SOURCE && addInfo && addInfo.isOnlyUploadEnabled);
  }, [addInfo]);

  const isConfirmEnabled = useMemo<boolean>(() => {
    if (source === PATIENT_PING_SOURCE && isOnlyUploadShown) {
      return true;
    } else if (isInsuranceRequired) {
      return !!formValue.InsuranceProvider && !!formValue.PolicyNumber;
    }
    return true;
  }, [formValue.InsuranceProvider, formValue, addInfo, isOnlyUploadShown]);

  const frontOfInsuranceCardLabel = isMobile ? (
    <>
      <Trans ns='handoff' i18nKey='insurance.upload' components={{ b: <b />, span: <span /> }} />
    </>
  ) : (
    <>
      <Trans ns='handoff' i18nKey='insurance.drag' components={{ b: <b />, span: <span /> }} />
    </>
  );

  const backOfInsuranceCardLabel = isMobile ? (
    <>
      <Trans ns='handoff' i18nKey='insurance.upload back' components={{ b: <b />, span: <span /> }} />
    </>
  ) : (
    <>
      <Trans ns='handoff' i18nKey='insurance.drag back' components={{ b: <b />, span: <span /> }} />
    </>
  );

  const filteredInsurancePlans = useMemo<AutocompleteOption[]>(() => {
    const filteredOptions = InsurancePlans.filter(plan =>
      plan.PolicyName.toLowerCase().includes(formValue.InsuranceProvider.toLowerCase()),
    ).map(plan => ({ ...plan, value: plan.ID, text: plan.PolicyName }));
    const optionsToDisplay = filteredOptions.length ? filteredOptions : [{ value: null, text: 'No results found' }];
    return formValue.InsuranceProvider ? optionsToDisplay : [];
  }, [formValue.InsuranceProvider]);

  useEffect(() => {
    getInsurancePlans();
  }, []);

  useEffect(() => {
    if (InsurancePlans.length) {
      if (InsurancePlan) {
        const currPlan = InsurancePlans.find(plan => plan.PolicyName.trim() === InsurancePlan.trim());
        setInsurancePlanSelectedID(currPlan ? currPlan.ID : null);
      }
      setIsLoading(false);
    }
  }, [InsurancePlans]);

  const selectInsurancePlan = (plan: AutocompleteOption): void => {
    setFormValue({ ...formValue, InsuranceProvider: plan.text });
    setInsurancePlanSelectedID(plan.value as number);
    trackMixpanelEvent<{ insurancePlanID: string | number | null }>(INSURANCE_PLAN_SELECTED, { insurancePlanID: plan.value });
  };

  function trackMixpanelEvent<T>(event: string, data?: T): void {
    if (source === PATIENT_PING_SOURCE) {
      const eventName = `PATIENT_PING_${event}`;
      const payload = { ...data, ...mixpanelHandoffPayload };
      mixpanel.track(eventName, payload as Dict);
    } else if (source !== EDIT_SOURCE) {
      const eventName = source === HANDOFF_SOURCE ? `HANDOFF_${event}` : event;
      const payload = source === HANDOFF_SOURCE ? { ...data, ...mixpanelHandoffPayload } : data;
      mixpanel.track(eventName, payload as Dict);
    }
  }

  const clearInsuranceTextFields = () => {
    setFormValue({ ...formValue, InsuranceProvider: '', PolicyNumber: '', GroupID: '' });
    setInsurancePlanSelectedID(null);
  };

  const selectFrontOfCardFile = (files: FileType[]): void => {
    setFormValue({ ...formValue, FrontOfInsuranceCardFiles: files });
    files.length && trackMixpanelEvent(FRONT_OF_INSURANCE_CARD_FILE_SELECTED);
  };

  const selectBackOfCardFile = (files: FileType[]): void => {
    setFormValue({ ...formValue, BackOfInsuranceCardFiles: files });
    files.length && trackMixpanelEvent(BACK_OF_INSURANCE_CARD_FILE_SELECTED);
  };

  const handleConfirm = async (event: SyntheticEvent) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      await handleSavingData();
    } catch (error) {
      catch401(error as ResponseError);
      setErrorDataForModal(UPLOADING_CARD_FILES_ERROR);
      setIsLoading(false);
    }
  };

  const handleSavingData = async () => {
    let partialScanData: Partial<ScanRequest> = buildBasePartialScanData();
    partialScanData = await handleCreateNewInsurancePlan(partialScanData);
    partialScanData = await handleInsuranceCardFiles(partialScanData);
    if (
      !partialScanData.FrontOfInsuranceCardFileKey &&
      !partialScanData.BackOfInsuranceCardFileKey &&
      !partialScanData.InsurancePlanID &&
      !partialScanData.InsurancePlan &&
      !partialScanData.PolicyNumber &&
      !partialScanData.GroupNumber &&
      !insurancePlanSelectedID
    ) {
      completeStepCallback({}, STEP_NAMES.insuranceInformation);
    } else {
      completeStepCallback(partialScanData, STEP_NAMES.insuranceInformation);
    }
  };

  const buildBasePartialScanData = (): Partial<ScanRequest> => {
    return {
      SelfPay: 0,
      InsurancePlanID: insurancePlanSelectedID as number,
      InsurancePlan: formValue.InsuranceProvider,
      PolicyNumber: formValue.PolicyNumber,
      GroupNumber: formValue.GroupID,
      FrontOfInsuranceCardFileKey: InsuranceCardFront?.key,
      BackOfInsuranceCardFileKey: InsuranceCardBack?.key,
      PaymentID: '',
      PaymentSecret: '',
      CardType: '',
      CardLast4: 0,
    };
  };

  const handleInsuranceCardFiles = async (partialScanData: Partial<ScanRequest>) => {
    if (shouldUploadFrontOfCard()) {
      partialScanData = await addFrontOfCardToPayload(partialScanData);
    }
    if (!formValue.FrontOfInsuranceCardFiles.length) {
      partialScanData = removeFrontOfInsuranceCard(partialScanData);
    }
    if (shouldUploadBackOfCard()) {
      partialScanData = await addBackOfCardToPayload(partialScanData);
    }
    if (!formValue.BackOfInsuranceCardFiles.length) {
      partialScanData = removeBackOfInsuranceCard(partialScanData);
    }
    return partialScanData;
  };

  const handleCreateNewInsurancePlan = async (partialScanData: Partial<ScanRequest>) => {
    if (insurancePlanSelectedID) {
      return partialScanData;
    }
    if (formValue.InsuranceProvider) {
      partialScanData.InsurancePlanID = (await uploadInsurancePlan(formValue.InsuranceProvider)).ID;
    }
    return partialScanData;
  };

  const shouldUploadFrontOfCard = (): boolean => {
    return (
      formValue.FrontOfInsuranceCardFiles.length > 0 &&
      (!InsuranceCardFront ||
        (InsuranceCardFront && InsuranceCardFront.localFileKey !== formValue.FrontOfInsuranceCardFiles[0]?.localFileKey))
    );
  };

  const addFrontOfCardToPayload = async (partialPayload: Partial<ScanRequest>) => {
    const frontOfCardUploaded = await uploadFrontOfCard();
    trackMixpanelEvent<FileResponse>(FRONT_OF_INSURANCE_CARD_FILE_UPLOADED, frontOfCardUploaded);
    partialPayload.InsuranceCardFront = {
      ...frontOfCardUploaded,
      localFileKey: formValue.FrontOfInsuranceCardFiles[0].fileKey,
    };
    partialPayload.FrontOfInsuranceCardFileKey = frontOfCardUploaded.key;
    return partialPayload;
  };

  const uploadFrontOfCard = async (): Promise<FileResponse> => {
    const frontOfCardFileData = new FormData();
    const fileToUpload = formValue.FrontOfInsuranceCardFiles[0].blobFile as File;
    frontOfCardFileData.append('cardType', FRONT_CARD_TYPE);
    frontOfCardFileData.append('file', fileToUpload);
    return uploadInsuranceCardFile(frontOfCardFileData, source);
  };

  const removeFrontOfInsuranceCard = (partialPayload: Partial<ScanRequest>): Partial<ScanRequest> => {
    partialPayload.InsuranceCardFront = undefined;
    partialPayload.FrontOfInsuranceCardFileKey = null;
    return partialPayload;
  };

  const shouldUploadBackOfCard = (): boolean => {
    return (
      formValue.BackOfInsuranceCardFiles.length > 0 &&
      (!InsuranceCardBack || (InsuranceCardBack && InsuranceCardBack.localFileKey !== formValue.BackOfInsuranceCardFiles[0]?.localFileKey))
    );
  };

  const addBackOfCardToPayload = async (partialPayload: Partial<ScanRequest>) => {
    const backOfCardUploaded = await uploadBackOfCard();
    trackMixpanelEvent<FileResponse>(BACK_OF_INSURANCE_CARD_FILE_UPLOADED, backOfCardUploaded);
    partialPayload.InsuranceCardBack = {
      ...backOfCardUploaded,
      localFileKey: formValue.BackOfInsuranceCardFiles[0].fileKey,
    };
    partialPayload.BackOfInsuranceCardFileKey = backOfCardUploaded.key;
    return partialPayload;
  };

  const uploadBackOfCard = async (): Promise<FileResponse> => {
    const backOfCardFileData = new FormData();
    const fileToUpload = formValue.BackOfInsuranceCardFiles[0].blobFile as File;
    backOfCardFileData.append('cardType', BACK_CARD_TYPE);
    backOfCardFileData.append('file', fileToUpload);
    return uploadInsuranceCardFile(backOfCardFileData, source);
  };

  const removeBackOfInsuranceCard = (partialPayload: Partial<ScanRequest>): Partial<ScanRequest> => {
    partialPayload.InsuranceCardBack = undefined;
    partialPayload.BackOfInsuranceCardFileKey = null;
    return partialPayload;
  };

  const getBack = (event: SyntheticEvent): void => {
    event.preventDefault();
    goToPreviousStep(STEP_NAMES.insuranceInformation);
  };

  if (isLoading) {
    return <LoaderComponent className='loader-block-center' />;
  }

  return (
    <>
      <Form formValue={formValue} onChange={value => setFormValue(value as InsuranceForm)} fluid>
        <div className='request-type-payments'>
          {!isOnlyUploadShown ? (
            <>
              <AutoCompleteInput
                name='InsuranceProvider'
                value={formValue.InsuranceProvider}
                options={filteredInsurancePlans}
                onSelect={insurancePlan => selectInsurancePlan(insurancePlan)}
                label={t('insurance.Insurance provider')}
                shouldDisplayX={!!formValue.InsuranceProvider.length}
                onClean={clearInsuranceTextFields}
                autoComplete='off'
                readOnly={insurancePlanSelectedID !== null}
              />
              <TextField name='PolicyNumber' value={formValue.PolicyNumber} label={t('insurance.Member ID')} />
              <TextField
                name='GroupID'
                value={formValue.GroupID}
                label={t('insurance.Group ID')}
                placeholder={t('insurance.optionalPlaceholder')}
              />
            </>
          ) : null}
          <div className='text-wrap opt100'>
            <p>
              {addInfo ? (
                <Trans
                  ns='handoff'
                  i18nKey='insurance.Upload your insurance card to speed up the booking process'
                  components={{ b: <b /> }}
                />
              ) : (
                <Trans ns='handoff' i18nKey='insurance.optional' components={{ bold: <b /> }} />
              )}
            </p>
          </div>
          <FilesUploader onChange={selectFrontOfCardFile} files={formValue.FrontOfInsuranceCardFiles} multiple={false}>
            {frontOfInsuranceCardLabel}
          </FilesUploader>
          <FilesUploader onChange={selectBackOfCardFile} files={formValue.BackOfInsuranceCardFiles} multiple={false}>
            {backOfInsuranceCardLabel}
          </FilesUploader>
        </div>
        {source === EDIT_SOURCE && (
          <WarningBox>
            <p>
              <b>{insuranceSubtitle}</b>
              {insuranceContent}
            </p>
            <p>
              <b>{selfPaySubtitle}</b>
              {selfPayContent}
            </p>
          </WarningBox>
        )}
        <div className='request-type-payments row'>
          <span className='icon-secure'></span>
          {t('insurance.protectedText')}
        </div>
        <div className='btn-row row justify-content-between full-mob'>
          <button className='btn prev btn-white' onClick={getBack}>
            {t('form.Back', { ns: 'translations' })}
          </button>
          <button className='btn next no-arrow' onClick={handleConfirm} disabled={!isConfirmEnabled}>
            {t('form.Next', { ns: 'translations' })}
          </button>
        </div>
      </Form>
      {errorDataForModal && <InfoModalComponent type='error' texts={errorDataForModal} onClose={() => setErrorDataForModal(null)} />}
    </>
  );
};

export default InsuranceInformation;
