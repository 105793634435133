import React from 'react';
import { User } from '../models/User';

export type UserContextType = User & {
  updateUser: () => void;
  expandUser: (data: Record<string, any>) => void;
  resetUser: () => void;
};

export const UserContext = React.createContext<UserContextType>({
  Email: '',
  FirstName: '',
  ID: 0,
  UserID: 0,
  LastName: '',
  Phone: '',
  DOB: '',
  GenderId: 0,
  AllowTexts: true,
  SocialAccounts: [],
  updateUser: () => {
    return;
  },
  expandUser: () => {
    return;
  },
  resetUser: () => {
    return;
  },
});
