import React, { useEffect, useRef, useState } from 'react';
import { Button, Form } from 'rsuite';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { LoginUrl } from '../../config/UrlsConfig';
import { FormInstance } from 'rsuite/Form';
import { checkToken, createNewPassword } from '../../api/login/LoginAPI';
import LoaderComponent from '../../components/loader-component';
import InfoModalComponent from '../../components/modals/info-modal';
import TextFieldPassword from '../../components/text-field-password';
import { CreatePasswordSchema, texts } from './utils';
import { NewPasswordFormData } from './interfaces';
import { FormError } from '../../models/General';

const CreateNewPasswordComponent: React.FunctionComponent = () => {
  const [formValue, setFormValue] = useState<NewPasswordFormData>({
    newPassword: '',
    confirmPassword: '',
  });
  const { token } = useParams<{ token: string }>();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);
  const [showExpired, setShowExpired] = useState<boolean>(false);
  const [formError, setFormError] = useState<FormError>({});
  const [text, setText] = useState<Record<string, string>>(texts.successAccount);
  const formRef = useRef<FormInstance | null>(null);
  const history = useHistory();

  useEffect(() => {
    if (token) {
      setLoading(true);
      checkToken(token)
        .then(data => {
          if (!data.success) {
            setText(texts.expiredToken);
            setShowExpired(true);
          }
        })
        .catch(() => {
          setText(texts.expiredToken);
          setShowExpired(true);
        })
        .finally(() => setLoading(false));
    }
  }, []);

  useEffect(() => {
    if (formRef.current && formValue.newPassword && formValue.confirmPassword) {
      formRef.current.checkForField('confirmPassword');
    }
  }, [formValue.newPassword]);

  const handleSubmit = () => {
    if (!formRef.current || !formRef.current.check()) {
      return;
    }

    setLoading(true);

    if (formValue.newPassword) {
      createNewPassword(formValue.newPassword, token)
        .then(() => {
          setText(texts.successAccount);
          setShowConfirm(true);
        })
        .catch(err => {
          setText({
            title: `Can't confirm`,
            content: err.message,
          });
          setShowError(true);
        })
        .finally(() => setLoading(false));
    }
  };

  const onCloseModal = (): void => {
    if (showConfirm || showExpired) {
      setShowConfirm(false);
      history.push(LoginUrl);
      return;
    }
    setShowError(false);
  };

  if (isLoading) {
    return <LoaderComponent />;
  }

  return (
    <div className='introductory-form'>
      <div className='introductory-form_title text-center'>Create new password</div>
      <Form
        className='mb24'
        model={CreatePasswordSchema}
        onChange={formValue => setFormValue(formValue as NewPasswordFormData)}
        onCheck={formError => setFormError(formError)}
        formValue={formValue}
        ref={formRef}
        fluid
      >
        <TextFieldPassword
          name='newPassword'
          rules
          showRulesPermanently
          value={formValue.newPassword}
          label='New Password'
          placeholder='Enter Password'
          error={formError.newPassword}
        />
        <TextFieldPassword
          name='confirmPassword'
          value={formValue.confirmPassword}
          label='Confirm Password'
          placeholder='Enter Password'
          error={formError.confirmPassword}
        />
        <Button className='btn w100 mb0' onClick={handleSubmit} disabled={!formValue.newPassword || !formValue.confirmPassword}>
          {isLoading && <LoaderComponent />}Create new password
        </Button>
      </Form>
      {(showConfirm || showError || showExpired) && (
        <InfoModalComponent onClose={onCloseModal} type={showConfirm ? 'success' : 'error'} texts={text} />
      )}
    </div>
  );
};

export default CreateNewPasswordComponent;
