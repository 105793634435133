import React from 'react';
import { Link } from 'react-router-dom';
import { NewScanUrl } from '../../../config/UrlsConfig';
import { ScanManagment } from '../../../models/ScanManagment';
import ScanBody from '../../scan-management/ScanBody';

const ScansComponent: React.FunctionComponent<{ scans: ScanManagment[]; reloadScans: () => void }> = ({ scans, reloadScans }) => {
  return (
    <div className='scan-management'>
      <div className='scan-management_сontrol-panel'>
        <div className='container'>
          <div className='global-title_wrap'>
            <div className='global-title_wrap_box'>
              <div className='sub-title'>Your appointments</div>
              <h1 className='h1'>Manage your appointments</h1>
              <div className='text'>
                <p>You can see your current appointments below.</p>
              </div>
            </div>
            <Link className='btn btn-scan' to={NewScanUrl}>
              Book another Scan
            </Link>
          </div>
          <div className='control-panel-tabs'>
            <h2 className='h2'>In-progress appointments</h2>
            <div className='control-panel-tabs_content'>
              {scans.map(scan => (
                <ScanBody key={scan.TestRequestID} scan={scan} reloadScans={reloadScans} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScansComponent;
