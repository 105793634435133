import { SERVER_URL } from '../../config/ServerConfig';
import { request } from '../Request';
import { DoctorsSearchResults } from './interfaces';

export function searchDoctor(searchQuery: string): Promise<DoctorsSearchResults> {
  return request({
    url: `${SERVER_URL}/search/doctor?searchQuery=${searchQuery}`,
    method: 'GET',
  });
}
