import React from 'react';
import { MedicationProps, Questions } from './interfaces';
import InfoBox from '../info-box';
import { Trans, useTranslation } from 'react-i18next';
import { PING_STEP_NAMES } from '../../views/patient-ping/utils';

const Medications: React.FunctionComponent<MedicationProps> = ({
  goToPreviousStep,
  completeStepCallback,
  scanRequestData,
  agentInfo,
  stepName = PING_STEP_NAMES.medications,
}) => {
  const { Medications } = scanRequestData;
  const { t } = useTranslation('handoff');

  const selectResponse = (responseID: number): void => {
    completeStepCallback({ Medications: responseID }, stepName);
  };

  return (
    <>
      <div className='main-request-form'>
        <h2 id='Additional_questions' className='h2'>
          {t('medications.Are you currently on any medication?')}
        </h2>
        <div className='main-form-appointment'>
          <div className='mb32'>
            {Questions.map(({ ID, Response }) => (
              <button
                key={ID}
                className={`appointment-field appointment-field_btn ${ID === Medications ? 'active' : ''}`}
                onClick={() => selectResponse(ID)}
              >
                {t(`metal.${Response}` as unknown as TemplateStringsArray)}
              </button>
            ))}
          </div>
          <div className='btn-row row justify-content-between full-mob'>
            <button className='btn btn-white back no-arrow' onClick={() => goToPreviousStep(stepName)}>
              {t('form.Back', { ns: 'translations' })}
            </button>
          </div>
        </div>
      </div>
      {agentInfo ? (
        <InfoBox
          title={t('box.HAVE_QUESTIONS_PING_PATIENT_INFOBOX_DATA.title', { ns: 'translations' })}
          content={
            <Trans
              i18nKey='box.HAVE_QUESTIONS_PING_PATIENT_INFOBOX_DATA.content'
              values={{
                CxName: agentInfo?.CxName || '[CX First Name]',
                CxPhone: agentInfo?.CxPhone || '[CX phone]',
                CxEmail: agentInfo?.CxEmail || '[CX email]',
              }}
              components={{ linkTo: <a /> }}
            />
          }
        />
      ) : null}
    </>
  );
};

export default Medications;
