import React, { useContext, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import { Form } from 'rsuite';
import LoaderComponent from '../../../components/loader-component';
import InputMask from '../../../components/mask-field';
import TextField from '../../../components/text-field-component';
import { FormError } from '../../../models/General';
import { ConfirmYourIdentitySchema } from './utils';
import { transformDate } from '../../../utils/DateUtil';
import { validatePatientDateOfBirth } from '../../../api/pre-appointment/PreAppointmentAPI';
import { validatePatientDOB } from '../../../api/send-files/SendFilesAPI';
import { MixpanelContext } from '../../../contexts/MixpanelContext';
import {
  APPOINTMENT_REMINDER_AUTHENTICATION_FAILED,
  APPOINTMENT_REMINDER_SUCCESSFUL_AUTHENTICATION,
  SEND_FILES_AUTHENTICATION_FAILED,
  SEND_FILES_SUCCESSFUL_AUTHENTICATION,
} from '../../../utils/MixpanelEvents';
import { useTranslation } from 'react-i18next';
import { DOBInvalidMessage } from '../../handoff-process/components/confirm-your-identity/utils';

type ConfirmYourIdentityProps = {
  completeCallback: () => void;
};

const ConfirmYourIdentity: React.FunctionComponent<ConfirmYourIdentityProps> = ({ completeCallback }) => {
  const [formValue, setFormValue] = useState<{ DOB: string }>({ DOB: '' });
  const [formError, setFormError] = useState<FormError>({});
  const [isLoading, setIsLoading] = useState(false);
  const { invitationID } = useParams<{ invitationID: string }>();
  const mixpanel = useContext(MixpanelContext);
  const { t } = useTranslation('handoff');
  const location = useLocation();

  const isSendFilesProcessUrl = useMemo(() => {
    return location.pathname.includes('/sf/');
  }, [location.pathname]);

  const isConfirmEnabled = useMemo(() => !!formValue.DOB && !Object.keys(formError).length, [formValue.DOB, formError]);

  const handleConfirm = (): void => {
    setIsLoading(true);
    if (isSendFilesProcessUrl) {
      validatePatientDOB({ DOB: transformDate(formValue.DOB, 'YYYY/M/D'), InvitationID: invitationID })
        .then(handleDOBvalidationResponse)
        .catch(handleMaximumAttemptsExceeded);
    } else {
      validatePatientDateOfBirth({ DOB: transformDate(formValue.DOB, 'YYYY/M/D'), InvitationID: invitationID })
        .then(handleDOBvalidationResponse)
        .catch(handleMaximumAttemptsExceeded);
    }
  };

  const handleDOBvalidationResponse = (response: boolean): void => {
    if (!response) {
      const trackTitle = isSendFilesProcessUrl ? SEND_FILES_AUTHENTICATION_FAILED : APPOINTMENT_REMINDER_AUTHENTICATION_FAILED;
      mixpanel.track(trackTitle, { InvitationID: invitationID });
      const message = t('Please enter a valid date of birth');
      setFormError({ DOB: message });
      setIsLoading(false);
      return;
    }
    const trackTitle = isSendFilesProcessUrl ? SEND_FILES_SUCCESSFUL_AUTHENTICATION : APPOINTMENT_REMINDER_SUCCESSFUL_AUTHENTICATION;
    mixpanel.track(trackTitle, { InvitationID: invitationID });
    completeCallback();
  };

  const handleMaximumAttemptsExceeded = (): void => {
    setIsLoading(false);
  };

  const onErrorHandler = (error: any) => {
    if (error) {
      if (error.DOB === DOBInvalidMessage) {
        const message = t(DOBInvalidMessage);
        return setFormError({ ...error, DOB: message });
      } else if (error.DOB === 'DOB must be a date') {
        const message = t('DOB must be a date');
        return setFormError({ ...error, DOB: message });
      } else if (error.DOB === 'Date of birth cannot be a future date') {
        const message = t('Date of birth cannot be a future date');
        return setFormError({ ...error, DOB: message });
      } else if (error.DOB === 'Please enter a valid date of birth (i.e. mm/dd/yyyy)') {
        const message = t('Please enter a valid date of birth');
        return setFormError({ ...error, DOB: message });
      }
    }
    setFormError(error);
  };

  if (isLoading) {
    return <LoaderComponent className='loader-block-center' />;
  }

  return (
    <>
      <div className='request-type handoff-process'>
        <div className='scan-service-edit_header'>
          <h2 className='h2'>{t('Confirm your identity')}</h2>
        </div>
        <div className='text-wrap'>
          <p>{t('identity_text_add')}</p>
        </div>
        <Form
          model={ConfirmYourIdentitySchema}
          formValue={formValue}
          onChange={formValue => setFormValue(formValue as { DOB: string })}
          onCheck={error => onErrorHandler(error)}
          fluid
        >
          <TextField
            type='tel'
            name='DOB'
            accepter={InputMask}
            label={'MM/DD/YYYY'}
            error={formError.DOB}
            value={formValue.DOB}
            mask={[/[0-9]/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
            format='MM/DD/YYYY'
            autoComplete='off'
          />
          <div className='btn-row row justify-content-between'>
            <button className='btn next no-arrow' onClick={handleConfirm} disabled={!isConfirmEnabled}>
              {t('Confirm', { ns: 'translations' })}
            </button>
          </div>
        </Form>
      </div>
    </>
  );
};

export default ConfirmYourIdentity;
