import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Progress } from 'rsuite';
import GetStarted from './get-started';
import SelectScanType from '../../components/select-scan-type';
import ReviewScanTypeSelection from '../../components/review-scan-type-selection';
import SelectAppointmentDatetimes from '../../components/select-appointment-datetimes';
import Account from '../../components/account';
import UploadPrescription from '../../components/upload-prescription';
import HowToCoverAppointment from '../../components/how-to-cover-appointment';
import ReviewScanRequest from './review-scan-request';
import ScanRequestCreated from './scan-request-created';
import { ScanRequest } from '../../models/ScanRequest';
import {
  PXB_TOTAL_STEPS,
  EMPTY_NON_CONFIRMED_SCAN_TYPE_SELECTION,
  SCAN_REQUEST_INITIAL_DATA,
  SESSION_STORAGE_KEYS,
  STEP_NAMES,
  getRandomIntInclusive,
  SESSION_STORAGE_USER_TRAFFIC_INT,
  POPULUS_ADD_INFO,
  PXB_STEPS,
} from './PatientScanRequestUtils';
import { createHealthyrScanRequest, createScanRequest } from '../../api/patient-scan-request/PatientScanRequestAPI';
import { UserContext } from '../../contexts/UserContext';
import { PatientScanRequestProps, ScanRequestCreationData } from './interfaces';
import GeneralError from '../../components/general-error';
import LeaveRoutePrompt from '../../components/leave-route-prompt';
import { catch401, HEALTHYR_SOURCE, PXB_SOURCE, SOLV_SOURCE } from '../../utils/GeneralUtil';
import { MixpanelContext } from '../../contexts/MixpanelContext';
import { ALREADY_LOGGED_IN, STEP_BACK, STEP_COMPLETED } from '../../utils/MixpanelEvents';
import { resetScanData } from './utils';
import PaymentMethodComponent from '../../components/payment-method-component';
import { preparePopulusDataForMixpanel, prepareScanRequestDataForMixpanel } from '../../utils/MixpanelUtils';
import InfoModalComponent from '../../components/modals/info-modal';
import ResponseError from '../../errors/ResponseError';
import { useHistory } from 'react-router';
import { LoginUrl } from '../../config/UrlsConfig';
import HowItWorks from './how-it-works';
import AdSection from '../handoff-process/standard/ad-show';
import ConciergeAssistOption from '../../components/concierge/assist-option';
import ConciergeAssistPaymentInformation from '../../components/concierge/assist-payment';
import { CONCIERGE_COST } from '../../config/FrontConfig';
import { ireRequested } from '../../utils/impactUtil';
import PatientLocation from '../../components/patient-location';
import { HEALTHYR } from '../healthyr';
import HowItWorksHealthyr from '../healthyr/components/how-it-works';
import PrescriptionQuestion from '../../components/prescription';
import HealthyrCreated from '../healthyr/components/healthyr-created';

const PatientScanRequest: React.FunctionComponent<PatientScanRequestProps> = ({ authenticate, isAuthenticated, isSolv, partner }) => {
  const { Line } = Progress;
  const { UserID: patientID, resetUser } = useContext(UserContext);
  const [percentage, setPercentage] = useState<number>(0);
  const [randomInt, setRandomInt] = useState<number>(-1);
  const preSavedCurrentStep = sessionStorage.getItem(SESSION_STORAGE_KEYS.currentStep);
  const isPartner = isSolv ? isSolv : partner;
  const savedCurrentStep =
    preSavedCurrentStep !== null
      ? parseInt(preSavedCurrentStep) === 0 && !isPartner
        ? 2
        : parseInt(preSavedCurrentStep)
      : isPartner
      ? 0
      : 2;
  const savedScanData = sessionStorage.getItem(SESSION_STORAGE_KEYS.scanData);
  const savedZipCode = sessionStorage.getItem(SESSION_STORAGE_KEYS.zipCode);
  const savedNonConfirmedScanTypeSelection = sessionStorage.getItem(SESSION_STORAGE_KEYS.nonConfirmedScanTypeSelection);
  const savedErrorSubmitting = sessionStorage.getItem(SESSION_STORAGE_KEYS.errorSubmitting);
  const savedWentThroughAuthentication = sessionStorage.getItem(SESSION_STORAGE_KEYS.wentThroughAuthentication);
  const [zipCode, setZipCode] = useState<string>(savedCurrentStep !== null && savedZipCode ? savedZipCode : '');
  const [purchaseAddInfo, setPurchaseAddInfo] = useState({ isCardErrorMessageShown: false });
  const [scanRequestCreationData, setScanRequestCreationData] = useState<ScanRequestCreationData>({
    currentStep: savedCurrentStep !== null ? (savedCurrentStep === 0 && !isPartner ? 1 : savedCurrentStep) : isPartner ? 0 : 1,
    scanRequestData: savedScanData !== null ? JSON.parse(savedScanData) : SCAN_REQUEST_INITIAL_DATA,
    nonConfirmedScanTypeSelection:
      savedNonConfirmedScanTypeSelection !== null
        ? JSON.parse(savedNonConfirmedScanTypeSelection)
        : EMPTY_NON_CONFIRMED_SCAN_TYPE_SELECTION,
    errorSubmitting: savedErrorSubmitting !== null ? savedErrorSubmitting === 'true' : false,
    wentThroughAuthentication: savedWentThroughAuthentication !== null ? savedWentThroughAuthentication === 'true' : false,
  });
  const [showZeroStep, setShowZeroStep] = useState(false);
  const [shouldDisplayExpiredModal, setShouldDisplayExpiredModal] = useState(false);
  const mixpanel = useContext(MixpanelContext);
  const history = useHistory();

  useEffect(() => {
    window.addEventListener('unhandledrejection', handleSessionExpired);
    window.scrollTo(0, 0);
    return () => {
      const savedCurrentStep = sessionStorage.getItem(SESSION_STORAGE_KEYS.currentStep);
      if (savedCurrentStep && parseInt(savedCurrentStep) === PXB_STEPS.scanRequestCreated) {
        resetScanData();
      }
      window.removeEventListener('unhandledrejection', handleSessionExpired);
    };
  }, []);

  const createRandomInt = () => {
    sessionStorage.setItem(POPULUS_ADD_INFO, JSON.stringify({ PopulusAdsRender: null, PopulusAdsNoAds: null }));
    const sessionUserInt = sessionStorage.getItem(SESSION_STORAGE_USER_TRAFFIC_INT);
    if (sessionUserInt) {
      setRandomInt(Number(sessionUserInt));
      return;
    }

    const random = getRandomIntInclusive(0, 100);
    sessionStorage.setItem(SESSION_STORAGE_USER_TRAFFIC_INT, String(random));
    setRandomInt(random);
  };

  useEffect(() => {
    createRandomInt();
  }, []);

  const handleSessionExpired = (event: PromiseRejectionEvent): void => {
    event.stopImmediatePropagation();
    if (event.reason?.status === 401) {
      setShouldDisplayExpiredModal(true);
    }
  };

  const closeExpiredModal = (): void => {
    setShouldDisplayExpiredModal(false);
    if (scanRequestCreationData.currentStep > PXB_STEPS.account && scanRequestCreationData.currentStep < PXB_STEPS.scanRequestCreated) {
      setScanRequestCreationData(creationData => ({ ...creationData, currentStep: PXB_STEPS.account }));
      handleStepChange(PXB_STEPS.account);
    }
    if (isAuthenticated) {
      resetUser();
    }
    if (scanRequestCreationData.currentStep === PXB_STEPS.scanRequestCreated) {
      history.push(LoginUrl);
    }
  };

  useEffect(() => {
    const preSavedCurrentStep = sessionStorage.getItem(SESSION_STORAGE_KEYS.currentStep);
    const savedCurrentStep =
      preSavedCurrentStep !== null
        ? parseInt(preSavedCurrentStep) === 0 && !isPartner
          ? 2
          : parseInt(preSavedCurrentStep)
        : isPartner
        ? 0
        : 2;
    const savedScanData = sessionStorage.getItem(SESSION_STORAGE_KEYS.scanData);
    const savedNonConfirmedScanTypeSelection = sessionStorage.getItem(SESSION_STORAGE_KEYS.nonConfirmedScanTypeSelection);
    const savedErrorSubmitting = sessionStorage.getItem(SESSION_STORAGE_KEYS.errorSubmitting);
    const savedWentThroughAuthentication = sessionStorage.getItem(SESSION_STORAGE_KEYS.wentThroughAuthentication);
    const savedZipCode = sessionStorage.getItem(SESSION_STORAGE_KEYS.zipCode);
    setScanRequestCreationData(creationData => {
      const currentStepValue =
        savedCurrentStep !== null
          ? savedCurrentStep === 2 && partner === HEALTHYR
            ? 0
            : savedCurrentStep < 2 && !partner
            ? 2
            : savedCurrentStep === 7 && partner === HEALTHYR && creationData.scanRequestData.PrescriptionQuestionResponseID === 0
            ? 10
            : savedCurrentStep === PXB_STEPS.account &&
              partner === HEALTHYR &&
              creationData.scanRequestData.PrescriptionQuestionResponseID === 0
            ? PXB_STEPS.paymentMethod
            : savedCurrentStep
          : creationData.currentStep;
      return {
        currentStep:
          isAuthenticated && currentStepValue === PXB_STEPS.account
            ? currentStepValue + 1
            : !isAuthenticated && currentStepValue > PXB_STEPS.account
            ? PXB_STEPS.account
            : currentStepValue,
        scanRequestData: savedScanData !== null ? JSON.parse(savedScanData) : creationData.scanRequestData,
        nonConfirmedScanTypeSelection:
          savedNonConfirmedScanTypeSelection !== null
            ? JSON.parse(savedNonConfirmedScanTypeSelection)
            : creationData.nonConfirmedScanTypeSelection,
        errorSubmitting: savedErrorSubmitting !== null ? savedErrorSubmitting === 'true' : creationData.errorSubmitting,
        wentThroughAuthentication:
          savedWentThroughAuthentication !== null ? savedWentThroughAuthentication === 'true' : creationData.wentThroughAuthentication,
      };
    });
    setZipCode(savedCurrentStep !== null && savedZipCode ? savedZipCode : '');
    setShowZeroStep(false);
  }, [showZeroStep]);

  const shouldDisplayPreventLeavePrompt = useMemo<boolean>(() => {
    const { currentStep, errorSubmitting } = scanRequestCreationData;
    if (partner === HEALTHYR && scanRequestCreationData.scanRequestData.PrescriptionQuestionResponseID === 0) {
      return false;
    }
    return currentStep > PXB_STEPS.selectScanType && currentStep < PXB_STEPS.scanRequestCreated && !errorSubmitting;
  }, [scanRequestCreationData.currentStep, scanRequestCreationData.errorSubmitting]);

  const handleStepChange = (step: number): void => {
    setPercentage((step / PXB_TOTAL_STEPS) * 100);
    sessionStorage.setItem(SESSION_STORAGE_KEYS.currentStep, step.toString());
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    handleStepChange(scanRequestCreationData.currentStep);
  }, [scanRequestCreationData.currentStep]);

  useEffect(() => {
    sessionStorage.setItem(SESSION_STORAGE_KEYS.scanData, JSON.stringify(scanRequestCreationData.scanRequestData));
  }, [scanRequestCreationData.scanRequestData]);

  useEffect(() => {
    sessionStorage.setItem(SESSION_STORAGE_KEYS.errorSubmitting, scanRequestCreationData.errorSubmitting.toString());
  }, [scanRequestCreationData.errorSubmitting]);

  useEffect(() => {
    sessionStorage.setItem(SESSION_STORAGE_KEYS.wentThroughAuthentication, scanRequestCreationData.wentThroughAuthentication.toString());
  }, [scanRequestCreationData.wentThroughAuthentication]);

  useEffect(() => {
    sessionStorage.setItem(
      SESSION_STORAGE_KEYS.nonConfirmedScanTypeSelection,
      JSON.stringify(scanRequestCreationData.nonConfirmedScanTypeSelection),
    );
  }, [scanRequestCreationData.nonConfirmedScanTypeSelection]);

  const goToNextStep = (stepName: string, stepMetadata?: Partial<ScanRequest>): void => {
    handleNextStepOnMixpanel(stepName, stepMetadata);
    setScanRequestCreationData(({ currentStep, ...rest }) => {
      let newCurrentStep = currentStep + 1;
      if (currentStep === PXB_STEPS.conciergeAssist && stepMetadata?.IsConcierge === false) {
        newCurrentStep = currentStep + 2;
      } else if (currentStep === PXB_STEPS.paymentMethod && stepMetadata?.SelfPay === 1) {
        newCurrentStep = currentStep + 3;
      } else if (partner === HEALTHYR) {
        if (stepName === STEP_NAMES.insuranceInformation) {
          newCurrentStep = currentStep + 2;
        } else if (stepMetadata?.PrescriptionQuestionResponseID === 0) {
          if (stepName === STEP_NAMES.prescriptionQuestion) {
            if (isAuthenticated) {
              newCurrentStep = currentStep + 9;
            } else {
              newCurrentStep = currentStep + 5;
            }
          } else if (stepName === STEP_NAMES.paymentInformation) {
            newCurrentStep = currentStep + 3;
          }
        }
      } else if (isSolv && STEP_NAMES.howItWorks === stepName) {
        newCurrentStep = currentStep + 2;
      }

      if (currentStep === PXB_STEPS.selectAppointmentDatetimes) {
        newCurrentStep = isAuthenticated ? currentStep + 2 : currentStep + 1;
      }

      return {
        ...rest,
        currentStep: newCurrentStep,
        wentThroughAuthentication: newCurrentStep > PXB_STEPS.account,
      };
    });
  };

  const handleNextStepOnMixpanel = (stepName: string, stepMetadata?: Partial<ScanRequest>): void => {
    const currentStepSaved = sessionStorage.getItem(SESSION_STORAGE_KEYS.currentStep);
    const currentStepState = currentStepSaved !== null ? parseInt(currentStepSaved) : scanRequestCreationData.currentStep;
    if (currentStepState === PXB_STEPS.selectAppointmentDatetimes && isAuthenticated) {
      mixpanel.track(ALREADY_LOGGED_IN, { UserID: patientID });
    }
    const scanRequestSaved = sessionStorage.getItem(SESSION_STORAGE_KEYS.scanData);
    const scanRequestState = scanRequestSaved !== null ? JSON.parse(scanRequestSaved) : scanRequestCreationData.scanRequestData;
    const mixpanelPayload = prepareScanRequestDataForMixpanel({ ...scanRequestState, ...stepMetadata });
    const mixpanelAddPayload = preparePopulusDataForMixpanel(mixpanelPayload, randomInt);
    mixpanel.track(`${STEP_COMPLETED}_${stepName}`, mixpanelAddPayload);
  };

  const goToPreviousStep = (stepName?: string, stepMetaData?: Partial<ScanRequest>): void => {
    stepMetaData?.IsConcierge && setPartialScanData(stepMetaData);
    setScanRequestCreationData(({ currentStep, ...rest }) => {
      let newCurrentStep = currentStep - 1;
      if (currentStep === PXB_STEPS.selectAppointmentDatetimes) {
        newCurrentStep = currentStep - 2;
      } else if (currentStep === PXB_STEPS.reviewScanRequest) {
        if (stepMetaData?.IsConcierge) {
          newCurrentStep = currentStep - 1;
        } else if (rest.scanRequestData.SelfPay === 1) {
          newCurrentStep = currentStep - 3;
        } else if (rest.scanRequestData.IsConcierge === false) {
          newCurrentStep = currentStep - 2;
        }
      } else if (partner === HEALTHYR) {
        if (stepName === STEP_NAMES.conciergeServicePayment) {
          newCurrentStep = currentStep - 2;
        } else if (rest.scanRequestData.PrescriptionQuestionResponseID === 0) {
          if (stepName === STEP_NAMES.paymentInformation) {
            if (isAuthenticated) {
              newCurrentStep = currentStep - 9;
            } else {
              newCurrentStep = currentStep - 5;
            }
          } else if (stepName === STEP_NAMES.login || stepName === STEP_NAMES.createAccount) {
            newCurrentStep = PXB_STEPS.prescriptionQuestion;
          }
        }
      } else if (isSolv && STEP_NAMES.selectScanType === stepName) {
        newCurrentStep = currentStep - 2;
      }

      if (currentStep === PXB_STEPS.patientLocation) {
        newCurrentStep = isAuthenticated ? currentStep - 2 : currentStep - 1;
      }
      return {
        ...rest,
        currentStep: newCurrentStep,
        errorSubmitting: false,
      };
    });
    mixpanel.track(`${STEP_BACK}_${stepName}`);
  };

  const setPartialScanData = useCallback(
    (partialScanData: Partial<ScanRequest>) => {
      setScanRequestCreationData(creationData => ({
        ...creationData,
        scanRequestData: { ...creationData.scanRequestData, ...partialScanData },
      }));
    },
    [scanRequestCreationData.scanRequestData],
  );

  const nonConfirmedScanTypeSelectionCallback = useCallback(
    nonConfirmedSelection => {
      setScanRequestCreationData(creationData => ({
        ...creationData,
        nonConfirmedScanTypeSelection: nonConfirmedSelection,
      }));
    },
    [scanRequestCreationData.nonConfirmedScanTypeSelection],
  );

  const submitHealthyrScanRequest = async () => {
    const scanRequestPayload: ScanRequest = buildScanRequestPayload(scanRequestCreationData.scanRequestData);
    try {
      await createHealthyrScanRequest(scanRequestPayload);
    } catch (error: any) {
      catch401(error as ResponseError);
      const message = error.message ? error.message.toLowerCase() : error.message;
      if (
        scanRequestCreationData.scanRequestData.IsConcierge &&
        error.status &&
        error.status === 400 &&
        message &&
        message.indexOf('payment message') > -1
      ) {
        setPurchaseAddInfo(prevState => ({ ...prevState, isCardErrorMessageShown: true }));
        const resetPayment = {
          PaymentID: '',
          PaymentSecret: '',
          CardType: '',
          CardLast4: 0,
        };
        setScanRequestCreationData(prevState => {
          return {
            ...prevState,
            scanRequestData: { ...prevState.scanRequestData, ...resetPayment },
            currentStep: PXB_STEPS.conciergePayment,
          };
        });
        return;
      }

      setScanRequestCreationData(previous => ({ ...previous, errorSubmitting: true }));
    }
    goToNextStep(STEP_NAMES.reviewScanRequest, {});
  };

  const submitScanRequest = async () => {
    const scanRequestPayload: ScanRequest = buildScanRequestPayload(scanRequestCreationData.scanRequestData);
    try {
      const scanRequestCreated = await createScanRequest(scanRequestPayload);
      setPartialScanData({ ID: scanRequestCreated.ID });
      if (scanRequestCreated.ID) {
        const Fee = scanRequestCreationData.scanRequestData?.scanServiceSelected?.Fee;
        const trackData = {
          orderId: scanRequestCreated.ID,
          customerId: patientID.toString(),
          sku: scanRequestPayload.ScanServiceID.toString(),
          category: scanRequestPayload.SelfPay ? 'Self-pay' : 'Insurance',
          price: scanRequestPayload.SelfPay ? (Fee ? Number(Fee) : 0) : 0,
        };
        ireRequested(trackData);
      }
    } catch (error: any) {
      catch401(error as ResponseError);
      const message = error.message ? error.message.toLowerCase() : error.message;
      if (
        scanRequestCreationData.scanRequestData.IsConcierge &&
        error.status &&
        error.status === 400 &&
        message &&
        message.indexOf('payment message') > -1
      ) {
        setPurchaseAddInfo(prevState => ({ ...prevState, isCardErrorMessageShown: true }));
        const resetPayment = {
          PaymentID: '',
          PaymentSecret: '',
          CardType: '',
          CardLast4: 0,
        };
        setScanRequestCreationData(prevState => {
          return {
            ...prevState,
            scanRequestData: { ...prevState.scanRequestData, ...resetPayment },
            currentStep: PXB_STEPS.conciergePayment,
          };
        });
        return;
      }

      setScanRequestCreationData(previous => ({ ...previous, errorSubmitting: true }));
    }
    goToNextStep(STEP_NAMES.reviewScanRequest, {});
  };

  const buildScanRequestPayload = (scanRequestData: ScanRequest): ScanRequest => {
    let scanRequestPayload: ScanRequest = buildBasePayload(scanRequestData);
    if (!scanRequestData.ShouldFindNearHomeAddress) {
      const { SecondaryAddressData } = scanRequestData;
      scanRequestPayload = { ...scanRequestPayload, SecondaryAddressData };
    }
    if (!scanRequestData.SelfPay) {
      scanRequestPayload = addInsuranceFields(scanRequestPayload, scanRequestData);
    }
    const isPopulusAdsShown = 1;
    // PERCENT_OF_TRAFFIC_USERS <= 0 ? 0 : PERCENT_OF_TRAFFIC_USERS >= 100 ? 1 : randomInt < PERCENT_OF_TRAFFIC_USERS ? 1 : 0;
    const Origin = isSolv
      ? scanRequestData.IsConcierge
        ? 'Solv-Concierge'
        : 'SOLV'
      : partner === HEALTHYR
      ? scanRequestData.IsConcierge
        ? 'Healthyr'
        : 'Healthyr'
      : scanRequestData.IsConcierge
      ? 'Concierge'
      : null;
    const IsConcierge = scanRequestData.IsConcierge;
    const ConciergeCost = IsConcierge ? CONCIERGE_COST : '0';
    const ZipCode = zipCode;
    scanRequestPayload = { ...scanRequestPayload, isPopulusAdsShown, Origin, IsConcierge, ConciergeCost, ZipCode };
    return scanRequestPayload;
  };

  const buildBasePayload = (scanRequestData: ScanRequest): ScanRequest => {
    return {
      PatientPriorityID: scanRequestData.PatientPriorityID,
      HomeAddressData: scanRequestData.HomeAddressData,
      ShouldFindNearHomeAddress: scanRequestData.ShouldFindNearHomeAddress,
      UserBrowserTimezone: scanRequestData.UserBrowserTimezone,
      WillTravelFurther: scanRequestData.WillTravelFurther,
      ScanServiceID: scanRequestData.ScanServiceID,
      Reason: scanRequestData.Reason,
      IsAvailableAnytime: scanRequestData.IsAvailableAnytime,
      AvailableDatetimes: scanRequestData.AvailableDatetimes,
      LogisticalNotes: scanRequestData.LogisticalNotes,
      CovidResponseID: scanRequestData.CovidResponseID,
      PreferredLanguageID: scanRequestData.PreferredLanguageID,
      PatientID: patientID,
      FirstName: scanRequestData.FirstName,
      LastName: scanRequestData.LastName,
      Phone: scanRequestData.Phone,
      PrescriptionFileKey: scanRequestData.PrescriptionFileKey,
      SelfPay: scanRequestData.SelfPay,
      CardLast4: scanRequestData.CardLast4,
      CardType: scanRequestData.CardType,
      PaymentID: scanRequestData.PaymentID,
      PaymentSecret: scanRequestData.PaymentSecret,
    };
  };

  const addInsuranceFields = (scanRequestPayload: ScanRequest, scanRequestData: ScanRequest): ScanRequest => {
    const { InsurancePlanID, PolicyNumber, InsuranceCardFront, InsuranceCardBack, GroupNumber } = scanRequestData;
    scanRequestPayload = {
      ...scanRequestPayload,
      InsurancePlanID,
      PolicyNumber,
      GroupNumber,
      FrontOfInsuranceCardFileKey: InsuranceCardFront?.key || null,
      BackOfInsuranceCardFileKey: InsuranceCardBack?.key || null,
    };
    return scanRequestPayload;
  };

  const completeStepCallback = useCallback((stepData: Partial<ScanRequest>, stepName?: string) => {
    if (partner === HEALTHYR) {
      if (stepData.SelfPay === 0) {
        stepData = Object.assign(stepData, { IsConcierge: true });
      } else if (stepData.PrescriptionQuestionResponseID === 0) {
        const newData = {
          CardType: '',
          CardLast4: 0,
          PaymentID: '',
          PaymentSecret: '',
          CardExpirationDate: '',
          FullNameOnCard: '',
          SelfPay: 1,
        };
        stepData = Object.assign(stepData, newData);
      }
    }

    if (Object.keys(stepData).length) {
      setPartialScanData(stepData);
    }
    if (purchaseAddInfo.isCardErrorMessageShown) {
      setPurchaseAddInfo(prevState => ({ ...prevState, isCardErrorMessageShown: false }));
    }
    if (stepName) {
      goToNextStep(stepName, stepData);
    }
  }, []);

  const setToDefaultScanRequestData = () => {
    resetScanData();
    setShowZeroStep(true);
    setPercentage(0);
    setZipCode('');
    createRandomInt();
  };

  const clearScanRequestData = () => sessionStorage.clear();

  // const isPopulusShown = useMemo(() => {
  //   const isShown =
  //     scanRequestCreationData && scanRequestCreationData.currentStep === PXB_STEPS.reasonForExam && scanRequestCreationData.scanRequestData;
  //   if (isShown) {
  //     return true;
  //   }
  //
  //   return isShown;
  // }, [scanRequestCreationData.currentStep]);

  const CurrentStep = useMemo((): React.ReactNode | null => {
    const { currentStep, scanRequestData, nonConfirmedScanTypeSelection, errorSubmitting, wentThroughAuthentication } =
      scanRequestCreationData;
    switch (currentStep) {
      case PXB_STEPS.getStarted:
        if (isSolv) {
          return <HowItWorks completeStepCallback={completeStepCallback} />;
        } else if (partner === HEALTHYR) {
          return <HowItWorksHealthyr completeStepCallback={completeStepCallback} />;
        }
        return <GetStarted completeStepCallback={completeStepCallback} />;
      // case PXB_STEPS.zipCode:
      //   if (isSolv) {
      //     return <SelectZipCode goToPreviousStep={goToPreviousStep} completeStepCallback={updateZipCodeCallback} initZipCode={zipCode} />;
      //   }
      //   return <SelectZipCode completeStepCallback={updateZipCodeCallback} initZipCode={zipCode} />;
      case PXB_STEPS.prescriptionQuestion:
        return (
          <PrescriptionQuestion
            completeStepCallback={completeStepCallback}
            goToPreviousStep={goToPreviousStep}
            scanRequestData={scanRequestData}
          />
        );
      case PXB_STEPS.selectScanType:
        return (
          <SelectScanType
            goToNextStep={goToNextStep}
            goToPreviousStep={goToPreviousStep}
            scanRequestData={scanRequestData}
            zipCode={zipCode}
            isSolv={!!isPartner}
            nonConfirmedScanTypeSelection={nonConfirmedScanTypeSelection}
            nonConfirmedScanTypeSelectionCallback={nonConfirmedScanTypeSelectionCallback}
          />
        );
      case PXB_STEPS.reviewScanTypeSelection:
        return (
          <ReviewScanTypeSelection
            goToPreviousStep={goToPreviousStep}
            completeStepCallback={completeStepCallback}
            nonConfirmedScanTypeSelection={nonConfirmedScanTypeSelection}
          />
        );
      case PXB_STEPS.reasonForExam:
        return (
          <AdSection
            scan={scanRequestData?.scanServiceSelected}
            scanRequestID={scanRequestData.ID as number}
            completeStepCallback={completeStepCallback}
          />
        );
      //   return (
      //     <ReasonForExam
      //       goToPreviousStep={goToPreviousStep}
      //       scanRequestData={scanRequestData}
      //       completeStepCallback={completeStepCallback}
      //     />
      //   );
      case PXB_STEPS.selectAppointmentDatetimes:
        return (
          <SelectAppointmentDatetimes
            goToPreviousStep={goToPreviousStep}
            scanRequestData={scanRequestData}
            completeStepCallback={completeStepCallback}
          />
        );
      // case PXB_STEPS.additionalQuestions:
      //   return <AdditionalQuestions goToPreviousStep={goToPreviousStep} completeStepCallback={completeStepCallback} />;
      // case PXB_STEPS.selectPhysician:
      //   return (
      //     <SelectPhysician
      //       goToPreviousStep={goToPreviousStep}
      //       scanRequestData={scanRequestData}
      //       completeStepCallback={completeStepCallback}
      //     />
      //   );
      case PXB_STEPS.account:
        return (
          <Account
            source={isSolv ? SOLV_SOURCE : partner === HEALTHYR ? HEALTHYR_SOURCE : PXB_SOURCE}
            completeStepCallback={completeStepCallback}
            goToPreviousStep={goToPreviousStep}
            authenticate={authenticate}
            defaultHasMedmoAccount={wentThroughAuthentication}
          />
        );
      case PXB_STEPS.patientLocation:
        return (
          <PatientLocation
            source={isSolv ? SOLV_SOURCE : PXB_SOURCE}
            goToPreviousStep={goToPreviousStep}
            scanRequestData={scanRequestData}
            completeStepCallback={completeStepCallback}
            isOptional={true}
          />
        );
      case PXB_STEPS.uploadPrescription:
        return (
          <UploadPrescription
            goToPreviousStep={goToPreviousStep}
            scanRequestData={scanRequestData}
            completeStepCallback={completeStepCallback}
            isUploadOptional={isSolv}
          />
        );
      case PXB_STEPS.howToCoverAppointment:
        return (
          <HowToCoverAppointment
            goToPreviousStep={goToPreviousStep}
            scanRequestData={scanRequestData}
            completeStepCallback={completeStepCallback}
          />
        );
      case PXB_STEPS.paymentMethod:
        return (
          <PaymentMethodComponent
            goToPreviousStep={goToPreviousStep}
            scanRequestData={scanRequestData}
            completeStepCallback={completeStepCallback}
            source={isSolv ? SOLV_SOURCE : PXB_SOURCE}
            partner={partner}
            isInsuranceRequired={true}
          />
        );
      case PXB_STEPS.conciergeAssist:
        return (
          <ConciergeAssistOption
            goToPreviousStep={goToPreviousStep}
            scanRequestData={scanRequestData}
            completeStepCallback={completeStepCallback}
          />
        );
      case PXB_STEPS.conciergePayment:
        return (
          <ConciergeAssistPaymentInformation
            goToPreviousStep={goToPreviousStep}
            scanRequestData={scanRequestData}
            completeStepCallback={completeStepCallback}
            additionalInfo={purchaseAddInfo}
            partner={partner}
          />
        );
      case PXB_STEPS.reviewScanRequest:
        return partner === HEALTHYR && scanRequestData.PrescriptionQuestionResponseID === 0 ? (
          <HealthyrCreated submitCallback={submitHealthyrScanRequest} partner={partner} />
        ) : (
          <ReviewScanRequest goToPreviousStep={goToPreviousStep} submitCallback={submitScanRequest} scanRequestData={scanRequestData} />
        );
      case PXB_STEPS.scanRequestCreated:
        return errorSubmitting ? (
          <GeneralError goToPreviousStep={goToPreviousStep} isFromPXB />
        ) : partner === HEALTHYR && scanRequestData.PrescriptionQuestionResponseID === 0 ? (
          <HealthyrCreated clearProcessData={clearScanRequestData} />
        ) : (
          <ScanRequestCreated
            scanRequestCreatedID={scanRequestData.ID as number}
            clearProcessData={clearScanRequestData}
            isFromPXB
            scanRequestData={scanRequestData}
            isAuthenticated={isAuthenticated}
          />
        );
      default:
        return null;
    }
  }, [scanRequestCreationData.currentStep]);

  // const isAdsShownForUserTraffic = useMemo(() => {
  //   if (PERCENT_OF_TRAFFIC_USERS <= 0) {
  //     return false;
  //   } else if (PERCENT_OF_TRAFFIC_USERS >= 100) {
  //     return true;
  //   }
  //   return randomInt < PERCENT_OF_TRAFFIC_USERS;
  // }, [randomInt]);

  return (
    <>
      <LeaveRoutePrompt
        when={shouldDisplayPreventLeavePrompt}
        onLeave={setToDefaultScanRequestData}
        currentStep={scanRequestCreationData.currentStep}
      />
      <section className='main-request-section'>
        <Line percent={percentage} strokeColor='#ff8a35' />
        <div className='container'>
          <div className='row'>{CurrentStep}</div>
          {/*{isAdsShownForUserTraffic && isPopulusShown && (*/}
          {/*  <div className='row'>*/}
          {/*    <PopulusMedia*/}
          {/*      step={scanRequestCreationData.currentStep}*/}
          {/*      scan={scanRequestCreationData.scanRequestData.scanServiceSelected}*/}
          {/*      reason={scanRequestCreationData.scanRequestData.Reason}*/}
          {/*    />*/}
          {/*  </div>*/}
          {/*)}*/}
        </div>
      </section>
      {shouldDisplayExpiredModal && (
        <InfoModalComponent
          onClose={closeExpiredModal}
          texts={{
            title: 'Your session has expired',
            content: 'You have been logged out due to inactivity. Please log in again.',
          }}
        />
      )}
    </>
  );
};

export default PatientScanRequest;
