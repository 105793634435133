import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { ScanType } from '../../../models/ScanType';
import WarningBox from '../../../components/warning-box';
import { STEP_NAMES } from '../PatientScanRequestUtils';
import { ReviewScanRequestProps } from './interfaces';
import LoaderComponent from '../../../components/loader-component';
import { PatientProviderAgreementInsuranceUrl, PatientProviderAgreementSelfPayUrl } from '../../../config/UrlsConfig';
import { CONCIERGE_COST } from '../../../config/FrontConfig';
import { Trans, useTranslation } from 'react-i18next';

const ReviewScanRequest: React.FunctionComponent<ReviewScanRequestProps> = ({
  goToPreviousStep,
  scanRequestData,
  submitCallback,
}: ReviewScanRequestProps) => {
  const {
    HomeAddressData,
    SecondaryAddressData,
    SelfPay,
    IsAvailableAnytime,
    readableAvailableDatetimes,
    scanServiceSelected,
    InsurancePlan,
    IsConcierge,
  } = scanRequestData;
  const { FullName = '', MinPrice, TotalPrice } = scanServiceSelected as ScanType;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { t } = useTranslation('handoff');

  const termsURL = useMemo(() => {
    return SelfPay
      ? PatientProviderAgreementSelfPayUrl.replace(':scanServiceID', scanServiceSelected?.ScanServiceID.toString() || '')
      : PatientProviderAgreementInsuranceUrl;
  }, [SelfPay, scanServiceSelected]);

  const renderSelectedDates = (): JSX.Element[] | undefined => {
    return readableAvailableDatetimes?.map((readableDatetime, index) => <li key={index}>{readableDatetime}</li>);
  };

  const handleConfirm = (): void => {
    setIsLoading(true);
    submitCallback();
  };

  if (isLoading) {
    return <LoaderComponent className='loader-block-center' />;
  }

  return (
    <div className='request-type'>
      <h2 id='Review_and_submit_your_exam_order' className='h2'>
        {t('review.title')}
      </h2>
      <div className='text-wrap'>
        <p>{t('review.text')}</p>
      </div>
      <div className='exam-order'>
        <div className='row'>
          <div>{t('review.Imaging Order')}:</div>
          <div>
            <b>{t(`${FullName}` as unknown as string | string[], { ns: 'scanTypesFNList', defaultValue: FullName })}</b>
          </div>
        </div>
        <div className='row'>
          <div>{t('review.Availability')}:</div>
          <div>
            <b>{IsAvailableAnytime ? t('review.Flexible over the next few weeks') : t('review.Specific dates')}</b>
            <ul className='appointment-field_data'>{renderSelectedDates()}</ul>
          </div>
        </div>
        {HomeAddressData?.RawString && (
          <div className='row'>
            <div>{t('review.Home address OR Additional Address')}:</div>
            <div>
              <b>
                {HomeAddressData?.RawString}
                {!!SecondaryAddressData && ` / ${SecondaryAddressData.RawString}`}
              </b>
            </div>
          </div>
        )}
        {SelfPay ? (
          <>
            <div className='row payment-row'>
              <div>{t('review.Payment Type')}</div>
              <div>
                <b>{t('review.Self-pay')}</b>
              </div>
            </div>
            <div className='row'>
              <div>{t('review.Remaining payment')}</div>
              <div>
                <div className='row'>
                  <b>${MinPrice}&nbsp;</b>
                  <div className='total'>
                    {t('review.of total')} ${TotalPrice}
                  </div>
                </div>
                <p>
                  <Trans ns='handoff' i18nKey='review.payment_note' components={{ b: <b /> }} />
                </p>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className='row'>
              <div>{t('review.Coverage type')}:</div>
              <div>
                <b>{InsurancePlan}</b>
              </div>
            </div>
            {IsConcierge !== undefined && IsConcierge !== null ? (
              <>
                <div className='row row-nowrap'>
                  <div>Service type:</div>
                  <div className='row row-wrap flex1'>
                    {IsConcierge ? (
                      <>
                        <b>{t('Medmo Concierge')}</b>
                        <span className='icon-ctm'></span>
                      </>
                    ) : (
                      <>
                        <b>{t('Medmo Assist')}</b>
                        <span className='icon-list'></span>
                        <div className='col'>
                          <button
                            className='mt16 btn btn-upgrade'
                            onClick={() => goToPreviousStep(STEP_NAMES.reviewScanRequest, { IsConcierge: true })}
                          >
                            {t('Upgrade to Medmo Concierge')}
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                {IsConcierge ? (
                  <div className='row payment-row'>
                    <div>{t('Service Cost')}:</div>
                    <div>
                      <div className='row'>
                        <b>${CONCIERGE_COST}</b>
                      </div>
                    </div>
                  </div>
                ) : null}
              </>
            ) : null}
          </>
        )}
      </div>
      <WarningBox title={t('review.box.title')}>
        <p>{t('review.box.content')}</p>
      </WarningBox>
      <div className='btn-row row justify-content-between full-mob mb32'>
        <button className='btn back btn-white' onClick={() => goToPreviousStep(STEP_NAMES.reviewScanRequest)}>
          {t('form.Back', { ns: 'translations' })}
        </button>
        <button className='btn next' onClick={handleConfirm}>
          {t('form.Submit', { ns: 'translations' })}
        </button>
      </div>
      <div className='text-right fs12'>
        <p>
          <Trans ns='handoff' i18nKey='review.patient_terms' components={{ Link: <Link target='_blank' to={termsURL} />, b: <b /> }} />
        </p>
      </div>
    </div>
  );
};

export default ReviewScanRequest;
