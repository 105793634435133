import React, { useContext } from 'react';
import { ScanRequest } from '../../../models/ScanRequest';
import { STEP_NAMES } from '../PatientScanRequestUtils';
import Logos from '../../../assets/medmo_solv-logo.svg';
import { APP_CORP_WEB_URL } from '../../../config/FrontConfig';
import { MixpanelContext } from '../../../contexts/MixpanelContext';
import { LEARN_MORE_SOLV } from '../../../utils/MixpanelEvents';

type HowItWorksProps = {
  completeStepCallback: (stepData: Partial<ScanRequest>, stepName?: string) => void;
};

const HowItWorks: React.FunctionComponent<HowItWorksProps> = ({ completeStepCallback }) => {
  const mixpanel = useContext(MixpanelContext);
  const handleLearnMore = () => {
    mixpanel.track(LEARN_MORE_SOLV);
    window.open(`${APP_CORP_WEB_URL}/solv`, '_blank');
  };

  return (
    <div className='main-started main-request-form main-request-form-partner'>
      <div className='main-partner-logo'>
        <img src={Logos} alt='Medmo + Solv' />
      </div>
      <div className='text-wrap mb32'>
        <p>
          Medmo is the best way to schedule high-quality imaging appointments near you at the best rates. You can use your insurance or
          self-pay with exclusive rates. A specialized care coordinator is there for you each step of the way.
        </p>
      </div>
      <div className='btn-row mb16'>
        <button onClick={() => completeStepCallback({}, STEP_NAMES.howItWorks)} className='btn next'>
          Get Started
        </button>
      </div>
      <div className='btn-row mb16'>
        <button onClick={handleLearnMore} className='btn btn-link next'>
          Learn More
        </button>
      </div>
      <h2 id='Ready_to_get_started' className='h2 mb24'>
        How it works
      </h2>
      <ul className='main-started-list main-started-list-partner'>
        <li className='row'>
          <div className='img-referral-icon icon'></div>
          <div className='box'>
            <strong className='title'>1. Tell us what you need.</strong>
            <p>
              Tell us what you need, when you’re free, and where you’re located. Use your insurance, or select our special self-pay rates.
            </p>
          </div>
        </li>
        <li className='row'>
          <div className='contact-info-icon icon'></div>
          <div className='box'>
            <strong className='title'>2. Get your appointment details.</strong>
            <p>
              We’ll schedule you the best, high-quality appointment for you. Our care team coordinates with your providers to make sure it
              goes smoothly.
            </p>
          </div>
        </li>
        <li className='row'>
          <div className='insurance-card-icon icon'></div>
          <div className='box'>
            <strong className='title'>3. Get your exam.</strong>
            <p>
              Sit back and relax! Simply show up for your appointment and we’ll handle the rest. You and your physician will get a copy of
              your report and images.
            </p>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default HowItWorks;
