import React, { useState } from 'react';
import InsuranceInformation from '../insurance-information';
import {
  INSURANCE_INFORMATION_INFOBOX_DATA,
  PAYMENT_INFORMATION_INFOBOX_DATA,
  INSURANCE_INFORMATION_HEAD_DATA,
  CCTA_PAYMENT_INFORMATION_HEAD_DATA,
} from '../../views/patient-scan-request/PatientScanRequestUtils';
import InfoBox from '../info-box';
import { ScanRequest } from '../../models/ScanRequest';
import { replaceWhitespacesForUnderscore } from '../../utils/GeneralUtil';
import { CCTAPaymentMethodEditProps } from './interfaces';
import PaymentCCTAInformation from '../payment-ccta-information';
import { CLEERLY_HANDOFF_STEP_NAMES } from '../../views/handoff-process/cleerly/utils';

const CCTAPaymentCMethodComponent: React.FunctionComponent<CCTAPaymentMethodEditProps> = ({
  goToPreviousStep,
  scanRequestData,
  completeStepCallback,
  source,
}) => {
  const { title: paymentTitle, content: paymentContent } = PAYMENT_INFORMATION_INFOBOX_DATA;
  const { title: insuranceTitle, content: insuranceContent } = INSURANCE_INFORMATION_INFOBOX_DATA;
  const { head: paymentHead } = CCTA_PAYMENT_INFORMATION_HEAD_DATA;
  const { head: insuranceHead } = INSURANCE_INFORMATION_HEAD_DATA;
  const {
    SelfPay,
    PolicyNumber,
    InsurancePlan,
    InsuranceCardBack,
    InsuranceCardFront,
    InsurancePlanID,
    FullNameOnCard,
    CardExpirationDate,
    CardType,
    CardLast4,
    PaymentID,
    PaymentSecret,
  } = scanRequestData;
  const [selectedOption] = useState<number>(SelfPay as number);
  const [data] = useState<Partial<ScanRequest>>({
    PolicyNumber,
    InsurancePlan,
    InsuranceCardBack,
    InsuranceCardFront,
    InsurancePlanID,
    FullNameOnCard,
    CardExpirationDate,
    CardType,
    CardLast4,
    PaymentID,
    PaymentSecret,
  });

  return (
    <>
      <div className='request-type'>
        <h2
          id={selectedOption ? replaceWhitespacesForUnderscore(paymentHead) : replaceWhitespacesForUnderscore(insuranceHead)}
          className='h2'
        >
          {selectedOption ? paymentHead : insuranceHead}
        </h2>
        {selectedOption ? (
          <PaymentCCTAInformation goToPreviousStep={goToPreviousStep} completeStepCallback={completeStepCallback} />
        ) : (
          <InsuranceInformation
            goToPreviousStep={() => goToPreviousStep(CLEERLY_HANDOFF_STEP_NAMES.insuranceInformation)}
            completeStepCallback={data => completeStepCallback(data, CLEERLY_HANDOFF_STEP_NAMES.insuranceInformation)}
            scanRequestData={{ ...scanRequestData, ...data }}
            source={source}
          />
        )}
      </div>
      <InfoBox title={selectedOption ? paymentTitle : insuranceTitle} content={selectedOption ? paymentContent : insuranceContent} />
    </>
  );
};

export default CCTAPaymentCMethodComponent;
