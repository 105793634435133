/* eslint-disable no-undef */
export const STRIPE_API_KEY = process.env.REACT_APP_STRIPE_KEY || 'pk_test_cGSlU9Ob0cxny6CCmOUmG8CL';
// eslint-disable-next-line no-undef
export const MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN || 'a922bc3086f86e9f4ee8196b6c0d569d';
export const GOOGLE_APPID = process.env.REACT_APP_GOOGLE_APPID || '819712704888-vphdsoqh5vl9fevs07bkm0h7sbna0hrh';
export const FACEBOOK_APPID = process.env.REACT_APP_FACEBOOK_APPID || '523941759144463';
export const APP_VERSION = process.env.REACT_APP_VERSION || '0.0.0';
export const APP_CORP_WEB_URL = process.env.REACT_APP_CORP_WEB_URL || 'https://www.medmo.com';
export const GTM_ID = process.env.REACT_APP_GTM_ID || 'GTM-WCRK764';
export const POPULUS_PARTNER_ID = process.env.REACT_APP_POPULUS_PARTNER_ID || 'medmo';
export const POPULUS_NETWORK_ID = process.env.REACT_APP_POPULUS_NETWORK_ID || 'thm';
export const POPULUS_USER_TRAFFIC = process.env.REACT_APP_POPULUS_USER_TRAFFIC || '100';
export const POPULUS_HANDOFF_AD_HIDE_MS = process.env.POPULUS_HANDOFF_AD_HIDE_MS || '3000';
export const CONCIERGE_COST = process.env.CONCIERGE_COST || '12.00';
export const API_MFP_URL = process.env.REACT_APP_API_MFP_URL || 'https://mfp-be-dev.medmo.com/api/v1';
