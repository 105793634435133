import React from 'react';
import { FINISHING_SCAN_REQUEST_WARNING_BOX_DATA, ReviewHandoffProcessProps } from './interfaces';
import { ScanType } from '../../../../models/ScanType';
import WarningBox from '../../../../components/warning-box';

const ReviewHandoffProcess: React.FunctionComponent<ReviewHandoffProcessProps> = ({ scanRequestData }) => {
  const {
    HomeAddressData,
    SecondaryAddressData,
    SelfPay,
    IsAvailableAnytime,
    readableAvailableDatetimes,
    scanServiceSelected,
    InsurancePlan,
    ID,
    ScanCost = '750.00',
  } = scanRequestData;
  const { title, content } = FINISHING_SCAN_REQUEST_WARNING_BOX_DATA;
  const { FullName } = scanServiceSelected as ScanType;

  const renderSelectedDates = (): JSX.Element[] | undefined => {
    return readableAvailableDatetimes?.map(
      (
        readableDatetime: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined,
        index: React.Key | null | undefined,
      ) => <li key={index}>{readableDatetime}</li>,
    );
  };

  return (
    <div className='request-type'>
      <h2 id='Review_and_submit_your_exam_order' className='h2'>
        We’ve succesfully recieved your order!
      </h2>
      <div className='handoff-process'>
        <div className='row request-id'>
          <div className='box'>
            <div className='title'>Request ID</div>
            <div className='num semi-bold'>{ID}</div>
          </div>
        </div>
      </div>
      <div className='text-wrap'>
        <p>
          Below are the details of your order. You will recieved a confirmation text and email shortly. Our care team is now working on your
          appointment and will reach out with additional information shortly.
        </p>
      </div>
      <div className='exam-order'>
        <div className='row'>
          <div>Imaging Order:</div>
          <div>
            <b>{FullName}</b>
          </div>
        </div>
        <div className='row'>
          <div>Availability:</div>
          <div>
            <b>{IsAvailableAnytime ? 'Flexible over the next few weeks' : 'Specific dates'}</b>
            <ul className='appointment-field_data'>{renderSelectedDates()}</ul>
          </div>
        </div>
        <div className='row'>
          <div>Home address / Additional Address:</div>
          <div>
            <b>
              {HomeAddressData?.RawString}
              {!!SecondaryAddressData && ` / ${SecondaryAddressData.RawString}`}
            </b>
          </div>
        </div>
        {SelfPay ? (
          <>
            <div className='row payment-row'>
              <div>CCTA Payment:</div>
              <div>
                <b>Self-pay</b>
              </div>
            </div>
          </>
        ) : (
          <div className='row'>
            <div>Coverage type:</div>
            <div>
              <b>{InsurancePlan}</b>
            </div>
          </div>
        )}
        {Number(ScanCost) > 0 ? (
          <div className='row payment-row'>
            <div>Cleerly Payment:</div>
            <div>
              <div className='row'>
                <b>${ScanCost}</b>
                <div className='total paid-full'>Paid in full</div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
      <WarningBox title={title}>
        <p>{content}</p>
      </WarningBox>
    </div>
  );
};

export default ReviewHandoffProcess;
