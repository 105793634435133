import { HandoffPatientInfo } from '../../../../api/handoff-process/interfaces';

export type UserInformationProps = {
  infoBoxData?: { title: string; content: React.ReactNode };
  patientDataToPrepopulate?: HandoffPatientInfo;
  completeStepCallback: (partialData: UserInformationFormData, stepName?: string) => void;
  isDobActive?: boolean;
};

export interface UserInformationFormData {
  FirstName: string;
  LastName: string;
  GenderId: number | null;
  DOB: string;
  Email: string;
  Phone: string;
  TermsAgreed: number;
}

export const initUserInformationData = {
  FirstName: '',
  LastName: '',
  GenderId: null,
  DOB: '',
  Email: '',
  Phone: '',
  TermsAgreed: 0,
};

export type UserInformationFormDataKeys = [keyof UserInformationFormData];
