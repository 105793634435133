import React, { /*useContext*/ useEffect, useState } from 'react';
import { Form } from 'rsuite';
// import { connectSocial, removeSocialAccount } from '../../../api/users/UsersAPI';
import InfoModalComponent from '../../components/modals/info-modal';
// import SocialButton from '../../../components/social-button';
import TextField from '../../components/text-field-component';
// import { FACEBOOK_APPID, GOOGLE_APPID } from '../../../config/FrontConfig';
// import { UserContext } from '../../../contexts/UserContext';
import { InfoModalTexts } from '../../models/General';
// import { SocialAuthResponse } from '../../create-account/interfaces';
import { PersonalInfoFormData, PreviewInfoProps /*Socials*/ } from './interfaces';
import { /*ConnectSocialDataType*/ GenderEnum, initData, /*socialTexts*/ textsMap } from './utils';

const PreviewPersonalInfo: React.FC<PreviewInfoProps> = ({ data, showEditInfo, showEditPassword, isLoggedWithPassword }) => {
  const [formData, setFormData] = useState<PersonalInfoFormData>(initData);
  // const [updatedSocials, setUpdatedSocials] = useState<{ SocialAccounts: string[] } | null>(null);
  const [errorText, setErrorText] = useState<InfoModalTexts | null>(null);
  // const user = useContext(UserContext);
  // const connectedSocials = user.SocialAccounts;
  // const availableSocials = ['facebook', 'google'];

  useEffect(() => {
    setFormData({
      ...initData,
      ...data,
      GenderId: GenderEnum[data.GenderId as number],
      AllowTextsNote: data.AllowTexts ? 'SMS notifications enabled' : 'SMS notifications disabled',
    });
  }, [data]);

  // useEffect(() => {
  //   if (!updatedSocials) {
  //     return;
  //   }

  //   user.expandUser(updatedSocials);
  // }, [updatedSocials]);

  // const unlinkSocial = (social: string) => {
  //   removeSocialAccount(social)
  //     .then(() => setUpdatedSocials({ SocialAccounts: connectedSocials.filter(element => element !== social) }))
  //     .catch(() => setErrorText(socialTexts.error[social as Socials].disconnectionError));
  // };

  // const handleSuccessAuthSocial = (response: SocialAuthResponse) => {
  //   const preparedData: ConnectSocialDataType = { ProviderName: response._provider, ProviderID: response._profile.id, remember: true };
  //   connectSocial(preparedData).then(() => setUpdatedSocials({ SocialAccounts: [...connectedSocials, response._provider] }));
  // };

  // const buttons = {
  //   facebook: (
  //     <SocialButton
  //       provider='facebook'
  //       appId={FACEBOOK_APPID}
  //       onLoginSuccess={handleSuccessAuthSocial}
  //       onLoginFailure={() => setErrorText(socialTexts.error.facebook.connectionError)}
  //       className='btn w100 facebook'
  //     >
  //       <span className='icon-facebook'>
  //         <span className='path1' />
  //         <span className='path2' />
  //       </span>
  //       Connect Facebook
  //     </SocialButton>
  //   ),
  //   google: (
  //     <SocialButton
  //       provider='google'
  //       appId={GOOGLE_APPID}
  //       onLoginSuccess={handleSuccessAuthSocial}
  //       onLoginFailure={() => setErrorText(socialTexts.error.google.connectionError)}
  //       className='btn w100 google'
  //     >
  //       <span className='icon-google'>
  //         <span className='path1' />
  //         <span className='path2' />
  //         <span className='path3' />
  //         <span className='path4' />
  //       </span>
  //       Connect Google
  //     </SocialButton>
  //   ),
  // };

  return (
    <div className='main-request-form main-setting-form'>
      <h2 className='h2'>Your personal information</h2>
      <div className='text-wrap mb24'>
        <p>{isLoggedWithPassword ? textsMap.logWithPasswordInfo : textsMap.editInfo}</p>
      </div>
      <div className='main-setting-form-wrap'>
        {isLoggedWithPassword && (
          <button className='edit-form-data' onClick={showEditInfo}>
            Edit Basic info
            <span className='text'>
              You can change your personal information, anytime you want. <a href='mailto:support@medmo.com'>Need help?</a>
            </span>
            <span className='icon icon-chevron-down'></span>
          </button>
        )}
        <Form formValue={formData} fluid>
          <div className='row row-wrap'>
            <TextField name='FirstName' value={formData.FirstName} label='First name' readOnly />
            <TextField name='LastName' value={formData.LastName} label='Last name' readOnly />
            <TextField name='GenderId' value={formData.GenderId} label='Biological Sex' readOnly />
            <TextField name='DOB' value={formData.DOB} label='Date of birth' readOnly />
            <TextField name='Phone' value={formData.Phone} label='Phone' readOnly />
            <TextField
              name='Email'
              tooltipHint='Cannot update email at this time, please contact support.'
              value={formData.Email}
              label='Email'
              readOnly
            />
            <TextField
              className={formData.AllowTexts ? '' : 'notice-disabled'}
              name='AllowTextsNote'
              value={formData.AllowTextsNote}
              label='SMS Preferences'
              readOnly
            />
          </div>
        </Form>
      </div>
      {isLoggedWithPassword ? (
        <>
          <button className='edit-form-data edit-form-data-password' onClick={showEditPassword}>
            Edit Password
            <span className='text'>A secure password helps protect your Medmo Account</span>
            <span className='text-password'>••••••••</span>
            <span className='icon icon-chevron-down'></span>
          </button>
        </>
      ) : (
        <div className='btn-row justify-content-between btn-row-full'>
          <button className='btn' onClick={showEditInfo}>
            Edit
          </button>
        </div>
      )}
      {/* {availableSocials.map(social => {
        if (connectedSocials) {
          return connectedSocials.includes(social) ? (
            <div className='socials-unlink' key={social}>
              <div className='socials-unlink_item'>
                <div className='head'>
                  <b>{social[0].toUpperCase() + social.slice(1)}</b>Linked
                </div>
                <div className='link'>
                  Would you like to
                  <a onClick={() => unlinkSocial(social)}>Unlink {social} account</a>?
                </div>
              </div>
            </div>
          ) : (
            <div key={social}>{buttons[social as Socials]}</div>
          );
        }
      })} */}
      {errorText && <InfoModalComponent onClose={() => setErrorText(null)} type='error' texts={errorText} />}
    </div>
  );
};

export default PreviewPersonalInfo;
