import React, { Suspense, SyntheticEvent, useMemo, useState } from 'react';
import { Form } from 'rsuite';
import InfoBox from '../info-box';
import { PatientAppointmentDatetimesForm, SelectAppointmentDatetimesProps } from './interfaces';
import { EMPTY_DATETIMES, STEP_NAMES } from '../../views/patient-scan-request/PatientScanRequestUtils';
import TextField from '../text-field-component';
import { format } from 'date-fns';
import { enUS, es } from 'date-fns/locale';
import { convertDatetimesToString, parseStringToDatetimes, literalTimeIntervalsMap } from '../../utils/DateUtil';
import { DatetimesData } from '../date-time-picker/interfaces';
import { ScanRequest } from '../../models/ScanRequest';
import Textarea from '../textarea';
import { useTranslation } from 'react-i18next';
import useLocationLanguage from '../../hooks/useLocationLanguage';

const DateTimePicker = React.lazy(() => import('../date-time-picker'));

const DATE_FORMAT_FOR_DATETIMES_DATA = 'MMM do, yyyy';

const SelectAppointmentDatetimes: React.FunctionComponent<SelectAppointmentDatetimesProps> = ({
  goToPreviousStep,
  scanRequestData,
  completeStepCallback,
  isFlexibleShown = true,
  moduleData = null,
}: SelectAppointmentDatetimesProps) => {
  const lang = useLocationLanguage();
  const { IsAvailableAnytime, AvailableDatetimes, LogisticalNotes } = scanRequestData;
  const [shouldDisplayDatetimePicker, setShouldDisplayDatetimePicker] = useState<boolean>(false);
  const [formValue, setFormValue] = useState<PatientAppointmentDatetimesForm>({
    IsAvailableAnytime: IsAvailableAnytime === null ? null : !!IsAvailableAnytime,
    AvailableDatetimes: parseStringToDatetimes(
      IsAvailableAnytime === 0 && AvailableDatetimes ? AvailableDatetimes : '',
      DATE_FORMAT_FOR_DATETIMES_DATA,
    ),
    LogisticalNotes,
  });
  const { t } = useTranslation('handoff');

  const logisticalNotesError = useMemo(() => {
    const isValid = formValue.IsAvailableAnytime ? !!formValue.LogisticalNotes : true;
    return isValid ? undefined : 'This field is required.';
  }, [formValue]);

  const readableAvailableDatetimes = useMemo<string[]>(() => {
    const { AvailableDatetimes } = formValue;
    return Object.keys(AvailableDatetimes.datetimes).map((date, index) => {
      const dateParsed = format(AvailableDatetimes.dates[index], 'iii MMM do', { locale: lang === 'es' ? es : enUS });
      const parsedTimes = AvailableDatetimes.datetimes[date]
        .map(time => {
          switch (literalTimeIntervalsMap[time]) {
            case 'Morning':
              return t('availability.dateTime.Morning');
            case 'Midday':
              return t('availability.dateTime.Midday');
            case 'Afternoon':
              return t('availability.dateTime.Afternoon');
          }
        })
        .join(', ');
      return `${dateParsed}, ${parsedTimes}`;
    });
  }, [formValue.AvailableDatetimes]);

  const isThereAnyDatetimeSelected = (datetimesData: DatetimesData): boolean => {
    return datetimesData.dates.length > 0 && Object.keys(datetimesData.datetimes).length > 0;
  };

  const isConfirmEnabled = useMemo(() => {
    const { IsAvailableAnytime, AvailableDatetimes, LogisticalNotes } = formValue;
    return IsAvailableAnytime !== null && (!IsAvailableAnytime ? isThereAnyDatetimeSelected(AvailableDatetimes) : !!LogisticalNotes);
  }, [formValue]);

  const confirmDatetimesSelection = (): void => {
    const { IsAvailableAnytime, LogisticalNotes, AvailableDatetimes } = formValue;
    let payload: Partial<ScanRequest> = {
      IsAvailableAnytime: IsAvailableAnytime ? 1 : 0,
      AvailableDatetimes: '',
      LogisticalNotes,
      readableAvailableDatetimes,
    };
    if (!IsAvailableAnytime) {
      const availableDatetimesString = convertDatetimesToString(AvailableDatetimes, DATE_FORMAT_FOR_DATETIMES_DATA);
      payload = { ...payload, AvailableDatetimes: availableDatetimesString };
    }
    completeStepCallback(payload, STEP_NAMES.selectAppointmentDatetimes);
  };

  const handleCloseDateTimePicker = (isCanceled: boolean, datetimesSelected?: DatetimesData): void => {
    if (!isCanceled) {
      setFormValue({ ...formValue, IsAvailableAnytime: false, AvailableDatetimes: datetimesSelected || EMPTY_DATETIMES });
    }
    setShouldDisplayDatetimePicker(false);
  };

  const handleSpecificDatesSelection = ({ target }: SyntheticEvent): void => {
    if ((target as HTMLElement).localName !== 'button') {
      if (formValue.IsAvailableAnytime !== null && !formValue.IsAvailableAnytime) {
        return;
      }
      if (isThereAnyDatetimeSelected(formValue.AvailableDatetimes)) {
        setFormValue({ ...formValue, IsAvailableAnytime: false });
      } else {
        setShouldDisplayDatetimePicker(true);
      }
    } else {
      setShouldDisplayDatetimePicker(true);
    }
  };

  const renderSelectedDates = () => {
    return readableAvailableDatetimes.map((readableDatetime, index) => <li key={index}>{readableDatetime}</li>);
  };

  return (
    <>
      <Suspense fallback={<div className='loading-placeholder'>Loading...</div>}>
        <DateTimePicker
          shouldShow={shouldDisplayDatetimePicker}
          onClose={handleCloseDateTimePicker}
          minDatesSelected={3}
          maxDatesSelected={5}
          dateFormat={DATE_FORMAT_FOR_DATETIMES_DATA}
          selectedDatetimes={formValue.AvailableDatetimes}
          minDateAllowed={moduleData?.ModuleMetadata?.MinDate ? new Date(moduleData.ModuleMetadata.MinDate) : null}
        />
      </Suspense>
      <div className='main-request-form'>
        <h2 id='When_would_you_like_your_appointment' className='h2'>
          {t('availability.title')}
        </h2>
        <div className='text-form'>
          <p>{t('availability.text')}</p>
        </div>
        {moduleData?.ModuleMetadata?.CustomNote ? (
          <div className='request-warning'>
            <span className='icon-info'>
              <span className='path1'></span>
              <span className='path2'></span>
            </span>
            <p>
              <span className='title'>{t('availability.Scheduling notes from Medmo Care Team')} - </span>
              {moduleData.ModuleMetadata.CustomNote}
            </p>
          </div>
        ) : null}
        <Form
          onChange={value => setFormValue(value as PatientAppointmentDatetimesForm)}
          formValue={formValue}
          className='main-form-appointment'
          fluid
        >
          <div
            onClick={handleSpecificDatesSelection}
            className={`appointment-field_btn appointment-field${
              formValue.IsAvailableAnytime !== null && !formValue.IsAvailableAnytime ? ' active' : ' pointer-cursor'
            }`}
          >
            <div className='appointment-field_placeholder'>
              {isFlexibleShown ? t('availability.Specific dates') : t('availability.Select dates')}
            </div>
            {formValue.IsAvailableAnytime !== null && !formValue.IsAvailableAnytime && (
              <>
                <button onClick={handleSpecificDatesSelection} className='edit-dates'>
                  {t('availability.Change dates')}
                </button>
                <ul className='appointment-field_data'>{renderSelectedDates()}</ul>
              </>
            )}
          </div>
          {isFlexibleShown ? (
            <button
              onClick={() => setFormValue({ ...formValue, IsAvailableAnytime: true })}
              className={`appointment-field appointment-field_btn mb32${formValue.IsAvailableAnytime ? ' active' : ''}`}
            >
              {t('availability.flexible')}
            </button>
          ) : null}
          <div className='text-form mb16'>
            <p>{t('availability.sub_text')}</p>
          </div>
          <div className='input-wrap textarea-wrap'>
            <TextField
              placeholder={`${t('availability.Any other logistical notes')}`}
              accepter={Textarea}
              value={formValue.LogisticalNotes}
              error={logisticalNotesError}
              name='LogisticalNotes'
            />
          </div>
          <div className='btn-row row justify-content-between full-mob'>
            <button className='btn btn-white back no-arrow' onClick={() => goToPreviousStep(STEP_NAMES.selectAppointmentDatetimes)}>
              {t('form.Back', { ns: 'translations' })}
            </button>
            <button className='btn next no-arrow' disabled={!isConfirmEnabled} onClick={confirmDatetimesSelection}>
              {t('form.Next', { ns: 'translations' })}
            </button>
          </div>
        </Form>
      </div>
      <InfoBox
        title={t('box.SELECT_DATETIMES_INFOBOX_DATA.title', { ns: 'translations' })}
        content={t('box.SELECT_DATETIMES_INFOBOX_DATA.content', { ns: 'translations' })}
      />
    </>
  );
};

export default SelectAppointmentDatetimes;
