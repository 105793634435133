import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { Checkbox, Form } from 'rsuite';
import { FormInstance } from 'rsuite/Form';
import InfoBox from '../../components/info-box';
import LoaderComponent from '../../components/loader-component';
import InfoModalComponent from '../../components/modals/info-modal';
import TextField from '../../components/text-field-component';
import TextFieldPassword from '../../components/text-field-password';
import { CreateAccountUrl, ForgotPasswordUrl, HealthyrUrl, NewScanUrl } from '../../config/UrlsConfig';
import { MixpanelContext } from '../../contexts/MixpanelContext';
import { FormError, InfoModalTexts } from '../../models/General';
import { HANDOFF_SOURCE, PXB_SOURCE, VIEW_SOURCE } from '../../utils/GeneralUtil';
import { FORGOT_PASSWORD } from '../../utils/MixpanelEvents';
import { LoginFormData, LoginProps } from './interfaces';
import { LoginSchema } from './schema';
import { ireRegistrationSignIn } from '../../utils/impactUtil';
import { useTranslation } from 'react-i18next';

const LoginComponent: React.FunctionComponent<LoginProps> = ({
  authenticate,
  source = VIEW_SOURCE,
  submitCallback,
  getBack,
  goToCreateAccount,
  mixpanelHandoffPayload,
}) => {
  const [formData, setFormData] = useState<LoginFormData>({
    email: '',
    password: '',
    remember: false,
  });
  const [formError, setFormError] = useState<FormError>({});
  const [error, setError] = useState<Error | null>(null);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [socialErrorText, setSocialErrorText] = useState<InfoModalTexts | null>(null);
  const [isAutofillPrevented, setIsAutofillPrevented] = useState(true);
  const formRef = useRef<FormInstance | null>(null);
  const history = useHistory();
  const mixpanel = useContext(MixpanelContext);
  const { t } = useTranslation('account');

  useEffect(() => {
    setTimeout(() => setIsAutofillPrevented(false), 1500);
    window.scroll(0, 0);
  }, []);

  const onSubmit = () => {
    if (!formRef.current || !formRef.current.check()) {
      return;
    }

    setLoading(true);

    localStorage.removeItem('isSessionExpired');

    authenticate(formData, submitCallback)
      .then(res => {
        if (submitCallback) {
          const nextPath = localStorage.getItem('route');
          if (source === PXB_SOURCE && nextPath !== HealthyrUrl) {
            history.push(NewScanUrl);
          }
          if (source !== HANDOFF_SOURCE && res && res.UserID) {
            ireRegistrationSignIn(String(res.UserID));
          }

          return;
        }
        if (source !== HANDOFF_SOURCE && res && res.UserID) {
          ireRegistrationSignIn(String(res.UserID));
        }

        const nextPath = localStorage.getItem('nextPath');

        if (nextPath) {
          history.push(nextPath);
          localStorage.removeItem('nextPath');
          return;
        }
      })
      .catch(err => {
        setError(err);
        setLoading(false);
      });
  };

  const isDisabled = useMemo(() => !formData.email || !formData.password, [formData]);

  const goToForgotPassword = (): void => {
    if (source !== VIEW_SOURCE) {
      trackForgotPasswordEvent();
    }
    history.push(ForgotPasswordUrl);
  };

  const trackForgotPasswordEvent = (): void => {
    if (source === HANDOFF_SOURCE) {
      mixpanel.track(`HANDOFF_${FORGOT_PASSWORD}`, mixpanelHandoffPayload);
      return;
    }
    mixpanel.track(FORGOT_PASSWORD);
  };

  if (isLoading) {
    return <LoaderComponent />;
  }

  return (
    <>
      <div className={`introductory-form ${source === VIEW_SOURCE ? '' : 'main-request-form'}`}>
        {source === VIEW_SOURCE ? (
          <div id='Patients_login_here' className='introductory-form_title text-center'>
            <p>{t('login.Patients login here')}</p>
          </div>
        ) : (
          <h2 id='Sign_in_to_Medmo' className='h2'>
            {t('login.Sign in to Medmo')}
          </h2>
        )}
        <Form
          className='mb32'
          model={LoginSchema}
          onChange={formValue => {
            setError(null);
            setFormData(formValue as LoginFormData);
          }}
          onCheck={formError => setFormError(formError)}
          formValue={formData}
          ref={formRef}
          readOnly={isAutofillPrevented}
          fluid
        >
          <TextField
            name='email'
            label={t('form.Email', { ns: 'translations' }) || 'Email'}
            type='email'
            error={formError.email}
            value={formData.email}
            placeholder={`${t('form.Enter', { ns: 'translations' })} ${t('form.Email', { ns: 'translations' })}`}
          />
          <TextFieldPassword
            name='password'
            label={t('form.Password', { ns: 'translations' }) || 'Password'}
            error={formError.password}
            value={formData.password}
            placeholder={`${t('form.Enter', { ns: 'translations' })} ${t('form.Password', { ns: 'translations' })}`}
          />
          {error && (
            <div className='error-login'>
              <p>{error.message}</p>
            </div>
          )}
          <div className='row justify-content-between row-wrap mb24'>
            <div className='input-checkbox'>
              <Checkbox
                name='remember'
                checked={formData.remember}
                onChange={(value: any, checked: boolean) => setFormData({ ...formData, remember: checked })}
                hidden
              >
                Remember me
              </Checkbox>
            </div>
            <a onClick={goToForgotPassword} className='forgot-password'>
              {t('form.password.Forgot Password', { ns: 'translations' })}
            </a>
          </div>
          <button className='btn w100' onClick={onSubmit} disabled={isDisabled}>
            {source === VIEW_SOURCE ? t('form.Login', { ns: 'translations' }) : t('form.Sign In And Continue', { ns: 'translations' })}
          </button>
        </Form>
        <div className='row justify-content-between mb24'>
          <p>
            {t('form.Dont have a Medmo account', { ns: 'translations' })}&nbsp;
            {source === VIEW_SOURCE ? (
              <Link to={CreateAccountUrl}>{t('form.Create an account', { ns: 'translations' })}</Link>
            ) : (
              <a className='pointer-cursor' onClick={goToCreateAccount}>
                {t('form.Sign Up', { ns: 'translations' })}
              </a>
            )}
          </p>
        </div>
        {source !== VIEW_SOURCE && getBack && (
          <div className='btn-row row justify-content-between'>
            <button onClick={getBack} className='btn btn-white back'>
              {t('form.Back', { ns: 'translations' })}
            </button>
          </div>
        )}
        {socialErrorText && <InfoModalComponent type='error' texts={socialErrorText} onClose={() => setSocialErrorText(null)} />}
      </div>
      {source !== VIEW_SOURCE && (
        <InfoBox
          title={t('box.PRIVACY_INFOBOX_DATA.title', { ns: 'translations' })}
          content={t('box.PRIVACY_INFOBOX_DATA.content', { ns: 'translations' })}
        />
      )}
    </>
  );
};

export default LoginComponent;
