import React, { useState } from 'react';
import { Form } from 'rsuite';
import TextField from '../../text-field-component';
import Textarea from '../../textarea';
import InfoBox from '../../info-box';
import { useTranslation } from 'react-i18next';
import { MetalBodyGeneralProps, PING_SURGERY_STEP_NAMES, SurgeryDetailsForm } from '../interfaces';

const SurgeryGeneralDetails: React.FunctionComponent<MetalBodyGeneralProps> = ({
  goToPreviousStep,
  completeStepCallback,
  scanRequestData,
  infoBox,
  stepName = PING_SURGERY_STEP_NAMES.surgeryDetails,
}) => {
  const { t } = useTranslation('handoff');
  const { SurgeryDetails = '' } = scanRequestData;

  const [formValue, setFormValue] = useState<SurgeryDetailsForm>({
    SurgeryDetails,
  });

  const selectResponse = (): void => {
    completeStepCallback({ SurgeryDetails: formValue.SurgeryDetails }, PING_SURGERY_STEP_NAMES.surgeryDetails);
  };

  return (
    <>
      <div className='main-request-form'>
        <h2 id='Additional_questions' className='h2'>
          {t('surgery.Please provide more information about your surgery')}
        </h2>
        <div className='text-form fw600'>
          <p>{t('surgery.If youre not sure, please click Next to continue.')}</p>
        </div>
        <Form onChange={value => setFormValue(value as SurgeryDetailsForm)} formValue={formValue} className='main-form-appointment' fluid>
          <div className='input-wrap textarea-wrap h80 mb32'>
            <TextField
              name='SurgeryDetails'
              value={formValue.SurgeryDetails}
              placeholder={t('surgery.E.g. I had a arthroplasty on my left hip')}
              accepter={Textarea}
            />
          </div>
        </Form>
        <div className='btn-row row full-mob justify-content-between'>
          <button onClick={() => goToPreviousStep(stepName)} className='btn prev btn-white'>
            {t('form.Back', { ns: 'translations' })}
          </button>
          <button className='btn next' onClick={() => selectResponse()}>
            {t('form.Next', { ns: 'translations' })}
          </button>
        </div>
      </div>
      {infoBox ? <InfoBox title={infoBox.title} content={infoBox.content} /> : null}
    </>
  );
};

export default SurgeryGeneralDetails;
