import React, { useMemo, useState } from 'react';
import { Checkbox, Form } from 'rsuite';
import { Trans, useTranslation } from 'react-i18next';
import { PhysicalSymptomsComponentProps, PhysicalSymptomsData } from './interfaces';
import { PING_STEP_NAMES } from '../../views/patient-ping/utils';
import InfoBox from '../info-box';

const PhysicalSymptoms: React.FunctionComponent<PhysicalSymptomsComponentProps> = ({
  goToPreviousStep,
  scanRequestData,
  completeStepCallback,
}: PhysicalSymptomsComponentProps) => {
  const { t } = useTranslation('handoff');
  const { HasHighFever, HasWeightLoss, HasBloodWhileCoughing, HasNoneOfTheAbovePhysical } = scanRequestData;
  const [formValue, setFormValue] = useState<PhysicalSymptomsData>({
    HasHighFever,
    HasWeightLoss,
    HasBloodWhileCoughing,
    HasNoneOfTheAbovePhysical,
  });

  const onNextHandler = (): void => {
    const { HasHighFever, HasWeightLoss, HasBloodWhileCoughing, HasNoneOfTheAbovePhysical } = formValue;
    const payload: Partial<PhysicalSymptomsData> = {
      HasHighFever,
      HasWeightLoss,
      HasBloodWhileCoughing,
      HasNoneOfTheAbovePhysical,
    };

    completeStepCallback(payload, PING_STEP_NAMES.physicalSymptoms);
  };

  const isConfirmEnabled = useMemo<boolean>(() => {
    const { HasHighFever, HasWeightLoss, HasBloodWhileCoughing, HasNoneOfTheAbovePhysical } = formValue;
    return HasHighFever || HasWeightLoss || HasBloodWhileCoughing || HasNoneOfTheAbovePhysical;
  }, [formValue]);

  return (
    <>
      <div className='main-request-form'>
        <h2 id='When_would_you_like_your_appointment' className='h2'>
          {t('physicalSymptoms.Are you currently experiencing any of the following symptoms?')}
        </h2>
        <Form onChange={value => setFormValue(value as PhysicalSymptomsData)} formValue={formValue} className='main-form-appointment' fluid>
          <div className='input-checkbox_wrap'>
            <div className='input-checkbox mb8'>
              <Checkbox
                checked={formValue.HasHighFever}
                onChange={() =>
                  setFormValue(previous => ({
                    ...previous,
                    HasNoneOfTheAbovePhysical: false,
                    HasHighFever: !previous.HasHighFever,
                  }))
                }
              >
                {t('physicalSymptoms.High fever')}
              </Checkbox>
            </div>
            <div className='input-checkbox mb8'>
              <Checkbox
                checked={formValue.HasWeightLoss}
                onChange={() =>
                  setFormValue(previous => ({
                    ...previous,
                    HasNoneOfTheAbovePhysical: false,
                    HasWeightLoss: !previous.HasWeightLoss,
                  }))
                }
              >
                {t('physicalSymptoms.Weight loss')}
              </Checkbox>
            </div>
            <div className='input-checkbox mb8'>
              <Checkbox
                checked={formValue.HasBloodWhileCoughing}
                onChange={() =>
                  setFormValue(previous => ({
                    ...previous,
                    HasNoneOfTheAbovePhysical: false,
                    HasBloodWhileCoughing: !previous.HasBloodWhileCoughing,
                  }))
                }
              >
                {t('physicalSymptoms.Blood while coughing')}
              </Checkbox>
            </div>
            <div className='input-checkbox mb0'>
              <Checkbox
                checked={formValue.HasNoneOfTheAbovePhysical}
                onChange={() =>
                  setFormValue(previous => ({
                    ...previous,
                    HasHighFever: false,
                    HasWeightLoss: false,
                    HasBloodWhileCoughing: false,
                    HasNoneOfTheAbovePhysical: !previous.HasNoneOfTheAbovePhysical,
                  }))
                }
              >
                {t('physicalSymptoms.None of the above')}
              </Checkbox>
            </div>
          </div>
          <div className='btn-row row justify-content-between full-mob'>
            <button className='btn btn-white back no-arrow' onClick={() => goToPreviousStep(PING_STEP_NAMES.physicalSymptoms)}>
              {t('form.Back', { ns: 'translations' })}
            </button>
            <button disabled={!isConfirmEnabled} className='btn next no-arrow' onClick={onNextHandler}>
              {t('form.Next', { ns: 'translations' })}
            </button>
          </div>
        </Form>
      </div>
      <InfoBox
        title={t('box.WHY_DO_YOU_NEED_INFOBOX_DATA.title', { ns: 'translations' })}
        content={<Trans ns='translations' i18nKey='box.WHY_DO_YOU_NEED_INFOBOX_DATA.content' components={{ br: <br /> }} />}
      />
    </>
  );
};

export default PhysicalSymptoms;
