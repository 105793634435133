import React, { useContext, useState } from 'react';
import { MixpanelContext } from '../../contexts/MixpanelContext';
import { HANDOFF_SOURCE } from '../../utils/GeneralUtil';
import {
  ALREADY_HAVE_AN_ACCOUNT_PXB,
  DONT_HAVE_AN_ACCOUNT_PXB,
  HANDOFF_ALREADY_HAVE_AN_ACCOUNT,
  HANDOFF_DONT_HAVE_AN_ACCOUNT,
} from '../../utils/MixpanelEvents';
import CreateAccountComponent from '../../views/create-account';
import LoginComponent from '../../views/login';
import { STEP_NAMES } from '../../views/patient-scan-request/PatientScanRequestUtils';
import { AccountProps } from './interfaces';

const Account: React.FunctionComponent<AccountProps> = ({
  completeStepCallback,
  goToPreviousStep,
  authenticate,
  defaultHasMedmoAccount,
  source,
  patientDataToPrepopulate,
  mixpanelHandoffPayload,
}) => {
  const [hasMedmoAccount, setHasMedmoAccount] = useState<boolean>(defaultHasMedmoAccount);
  const mixpanel = useContext(MixpanelContext);

  const submitCallback = (): void => {
    completeStepCallback({}, hasMedmoAccount ? STEP_NAMES.login : STEP_NAMES.createAccount);
  };

  const getBack = (): void => {
    goToPreviousStep(hasMedmoAccount ? STEP_NAMES.login : STEP_NAMES.createAccount);
  };

  const goToCreateAccount = (): void => {
    if (source === HANDOFF_SOURCE) {
      mixpanel.track(HANDOFF_DONT_HAVE_AN_ACCOUNT, mixpanelHandoffPayload);
    } else {
      mixpanel.track(DONT_HAVE_AN_ACCOUNT_PXB);
    }
    setHasMedmoAccount(false);
  };

  const goToLogin = (): void => {
    if (source === HANDOFF_SOURCE) {
      mixpanel.track(HANDOFF_ALREADY_HAVE_AN_ACCOUNT, mixpanelHandoffPayload);
    } else {
      mixpanel.track(ALREADY_HAVE_AN_ACCOUNT_PXB);
    }
    setHasMedmoAccount(true);
  };

  return (
    <>
      {hasMedmoAccount ? (
        <LoginComponent
          source={source}
          authenticate={authenticate}
          submitCallback={submitCallback}
          getBack={getBack}
          goToCreateAccount={goToCreateAccount}
          mixpanelHandoffPayload={mixpanelHandoffPayload}
        />
      ) : (
        <CreateAccountComponent
          source={source}
          submitCallback={submitCallback}
          getBack={getBack}
          goToLogin={goToLogin}
          patientDataToPrepopulate={patientDataToPrepopulate}
          mixpanelHandoffPayload={mixpanelHandoffPayload}
        />
      )}
    </>
  );
};

export default Account;
