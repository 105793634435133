import { ScanRequest } from '../../models/ScanRequest';

export type PrescriptionQuestionProps = {
  goToPreviousStep: (stepName?: string) => void;
  scanRequestData: ScanRequest;
  completeStepCallback: (stepData: Partial<ScanRequest>, stepName?: string) => void;
};

export const Questions = [
  { ID: 1, Response: 'Yes, I have a prescription' },
  { ID: 0, Response: 'No, I do NOT have a prescription' },
];
