import { PingScanRequest } from '../../views/patient-ping/utils';

export const PING_ASSISTANCE_STEP_NAMES = {
  assistanceInformation: 'ASSISTANCE_INFORMATION',
  assistanceDetails: 'ASSISTANCE_DETAILS',
};

export type AssistanceWrapperProps = {
  goToPreviousStep: (stepName?: string) => void;
  scanRequestData: PingScanRequest;
  completeStepCallback: (partialScanData: Partial<PingScanRequest>, stepName?: string) => void;
};

export type AssistanceInnerProps = {
  goToPreviousStep: (stepName?: string) => void;
  scanRequestData: AssistanceData;
  completeStepCallback: (partialScanData: Partial<PingScanRequest>, stepName?: string) => void;
};

export const initInformationData = {
  Wheelchair: false,
  Walker: false,
  Interpreter: false,
  NoAssistance: false,
  OtherAssistance: false,
};

export const initDetailsData = {
  AssistanceDetails: '',
};

export const initAssistanceData = {
  ...initInformationData,
  ...initDetailsData,
};

export const initGeneralData = {
  data: initAssistanceData,
  currentStep: 1,
  errorSubmitting: false,
};

export type InformationDataForm = {
  Wheelchair: boolean;
  Walker: boolean;
  Interpreter: boolean;
  NoAssistance: boolean;
  OtherAssistance: boolean;
};

export type DetailsDataForm = {
  AssistanceDetails: string;
};

export type AssistanceData = InformationDataForm & DetailsDataForm;

export type GeneralData = {
  data: AssistanceData;
  currentStep: number;
  errorSubmitting: boolean;
};
