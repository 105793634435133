import { PingScanRequest } from '../../views/patient-ping/utils';
import React from 'react';

export const PING_CANCER_STEP_NAMES = {
  cancer: 'CANCER',
  cancerDetails: 'CANCER_DETAILS',
};

export type WrapperProps = {
  goToPreviousStep: (stepName?: string) => void;
  scanRequestData: PingScanRequest;
  completeStepCallback: (partialScanData: Partial<PingScanRequest>, stepName?: string) => void;
};

export const initCancerData = {
  HistoryOfCancer: null,
};

export const initCancerDetailsData = {
  HistoryOfCancerDetails: '',
};

export const initCancerBodyData = {
  ...initCancerData,
  ...initCancerDetailsData,
};

export const initSurgeryGeneralData = {
  data: initCancerBodyData,
  currentStep: 1,
  errorSubmitting: false,
};

export type HistoryOfCancerBodyForm = {
  HistoryOfCancer: number | null;
};

export type HistoryOfCancerDetailsForm = {
  HistoryOfCancerDetails: string;
};

export type HistoryOfCancerData = HistoryOfCancerBodyForm & HistoryOfCancerDetailsForm;

export type HistoryOfCancerGeneralData = {
  data: HistoryOfCancerData;
  currentStep: number;
  errorSubmitting: boolean;
};

export type BodyGeneralProps = {
  goToPreviousStep: (stepName?: string) => void;
  completeStepCallback: (partialScanData: Partial<PingScanRequest>, stepName?: string) => void;
  infoBox?: { title: string; content: React.ReactNode };
  stepName: string;
  scanRequestData: Partial<PingScanRequest>;
};

export const Questions = [
  { ID: 1, Response: 'Yes' },
  { ID: 0, Response: 'No' },
  { ID: 2, Response: 'Not sure' },
];
