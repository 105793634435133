import { SERVER_URL } from '../../config/ServerConfig';
import { request } from '../Request';
import { PatientPriorityOption } from '../../models/PatientPriorityOption';

export function getPrioritiesOptionsAPI(): Promise<PatientPriorityOption[]> {
  return request({
    url: `${SERVER_URL}/patient-priorities`,
    method: 'GET',
  });
}
