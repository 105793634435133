import { PingScanRequest } from '../../views/patient-ping/utils';
import React from 'react';

export const PING_CHEMOTHERAPY_STEP_NAMES = {
  chemotherapy: 'CHEMOTHERAPY',
  chemotherapyDetails: 'CHEMOTHERAPY_DETAILS',
};

export type WrapperProps = {
  goToPreviousStep: (stepName?: string) => void;
  scanRequestData: PingScanRequest;
  completeStepCallback: (partialScanData: Partial<PingScanRequest>, stepName?: string) => void;
};

export const initChemotherapyData = {
  Chemotherapy: null,
};

export const initChemotherapyDetailsData = {
  CompleteChemotherapy: null,
};

export const initChemotherapyBodyData = {
  ...initChemotherapyData,
  ...initChemotherapyDetailsData,
};

export const initChemotherapyGeneralData = {
  data: initChemotherapyBodyData,
  currentStep: 1,
  errorSubmitting: false,
};

export type ChemotherapyBodyForm = {
  Chemotherapy: number | null;
};

export type ChemotherapyDetailsForm = {
  CompleteChemotherapy: number | null;
};

export type ChemotherapyData = ChemotherapyBodyForm & ChemotherapyDetailsForm;

export type ChemotherapyGeneralData = {
  data: ChemotherapyData;
  currentStep: number;
  errorSubmitting: boolean;
};

export type BodyGeneralProps = {
  goToPreviousStep: (stepName?: string) => void;
  completeStepCallback: (partialScanData: Partial<PingScanRequest>, stepName?: string) => void;
  infoBox?: { title: string; content: React.ReactNode };
  stepName: string;
  scanRequestData: Partial<PingScanRequest>;
};

export const QuestionsChemotherapy = [
  { ID: 1, Response: 'I am currently undergoing Chemotherapy' },
  { ID: 2, Response: 'In the last 12 months' },
  { ID: 3, Response: '2 years ago' },
  { ID: 4, Response: 'Over 3 years ago' },
];

export const Questions = [
  { ID: 1, Response: 'Yes' },
  { ID: 0, Response: 'No' },
  { ID: 2, Response: 'Not sure' },
];
