import React from 'react';
import { APP_CORP_WEB_URL } from '../../config/FrontConfig';
import { stepsTexts } from './utils';

const StepsComponent: React.FunctionComponent<{ isDashboardPage?: boolean }> = ({ isDashboardPage }) => {
  return (
    <section className='trust'>
      <div className='container'>
        <div className='global-title_wrap'>
          <div className='_box'>
            <div className='sub-title'>How it works</div>
            <h2 className='h1'>Get imaging in 3 simple steps</h2>
          </div>
        </div>
        <div className='trust_items row'>
          {stepsTexts.map(({ title, content, icon }) => (
            <div className='trust_item' key={title}>
              <div className={'dashboard-icon ' + icon}></div>
              <div className='wrap'>
                <h3 className='h3'>{title}</h3>
                <p>{content}</p>
              </div>
            </div>
          ))}
        </div>
        {isDashboardPage && (
          <a href={`${APP_CORP_WEB_URL}/how-it-works/`} target='_blank' className='link' rel='noreferrer'>
            Learn more about how it works
          </a>
        )}
      </div>
    </section>
  );
};

export default StepsComponent;
