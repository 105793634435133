import React, { useMemo, useState } from 'react';
import { Checkbox, Form } from 'rsuite';
import { Trans, useTranslation } from 'react-i18next';
import { AllergiesData, AllergiesForm, ContrastComponentProps } from './interfaces';
import TextField from '../text-field-component';
import { PING_STEP_NAMES } from '../../views/patient-ping/utils';
import Textarea from '../textarea';
import InfoBox from '../info-box';

const Allergies: React.FunctionComponent<ContrastComponentProps> = ({
  goToPreviousStep,
  scanRequestData,
  completeStepCallback,
}: ContrastComponentProps) => {
  const { t } = useTranslation('handoff');
  const {
    HaveNotSymptomsOfContrast,
    SymptomsNotesOfContrast,
    HasAllergicToIodine,
    HasHighBloodPressure,
    IsDiabetic,
    HasHistoryOfKidney,
    OtherAllergies,
  } = scanRequestData;
  const [formValue, setFormValue] = useState<AllergiesForm>({
    HaveNotSymptomsOfContrast,
    SymptomsNotesOfContrast,
    HasAllergicToIodine,
    HasHighBloodPressure,
    IsDiabetic,
    HasHistoryOfKidney,
    OtherAllergies,
  });

  const onNextHandler = (): void => {
    const {
      HaveNotSymptomsOfContrast,
      SymptomsNotesOfContrast,
      HasAllergicToIodine,
      HasHighBloodPressure,
      IsDiabetic,
      HasHistoryOfKidney,
      OtherAllergies,
    } = formValue;
    const payload: Partial<AllergiesData> = {
      HaveNotSymptomsOfContrast,
      SymptomsNotesOfContrast,
      HasAllergicToIodine,
      HasHighBloodPressure,
      IsDiabetic,
      HasHistoryOfKidney,
      OtherAllergies,
    };

    completeStepCallback(payload, PING_STEP_NAMES.contrastQuestion);
  };

  const isConfirmEnabled = useMemo<boolean>(() => {
    const { HaveNotSymptomsOfContrast, HasAllergicToIodine, HasHighBloodPressure, OtherAllergies, IsDiabetic, HasHistoryOfKidney } =
      formValue;
    return HaveNotSymptomsOfContrast || HasAllergicToIodine || OtherAllergies || HasHighBloodPressure || IsDiabetic || HasHistoryOfKidney;
  }, [formValue]);

  return (
    <>
      <div className='main-request-form'>
        <h2 id='When_would_you_like_your_appointment' className='h2'>
          {t('contrast.title')}
        </h2>
        <Form onChange={value => setFormValue(value as AllergiesForm)} formValue={formValue} className='main-form-appointment' fluid>
          <div className='input-checkbox_wrap'>
            <div className='input-checkbox mb8'>
              <Checkbox
                checked={formValue.HasAllergicToIodine}
                onChange={() =>
                  setFormValue(previous => ({
                    ...previous,
                    HaveNotSymptomsOfContrast: false,
                    HasAllergicToIodine: !previous.HasAllergicToIodine,
                  }))
                }
              >
                {t('contrast.Are you allergic to Iodine?')}
              </Checkbox>
            </div>
            <div className='input-checkbox mb8'>
              <Checkbox
                checked={formValue.HasHighBloodPressure}
                onChange={() =>
                  setFormValue(previous => ({
                    ...previous,
                    HaveNotSymptomsOfContrast: false,
                    HasHighBloodPressure: !previous.HasHighBloodPressure,
                  }))
                }
              >
                {t('contrast.Do you have high blood pressure?')}
              </Checkbox>
            </div>
            <div className='input-checkbox mb8'>
              <Checkbox
                checked={formValue.HasHistoryOfKidney}
                onChange={() =>
                  setFormValue(previous => ({
                    ...previous,
                    HaveNotSymptomsOfContrast: false,
                    HasHistoryOfKidney: !previous.HasHistoryOfKidney,
                  }))
                }
              >
                {t('contrast.Any history of kidney problem?')}
              </Checkbox>
            </div>
            <div className='input-checkbox mb8'>
              <Checkbox
                checked={formValue.OtherAllergies}
                onChange={() =>
                  setFormValue(previous => ({
                    ...previous,
                    HaveNotSymptomsOfContrast: false,
                    OtherAllergies: !previous.OtherAllergies,
                  }))
                }
              >
                {t('mammogram.Other')}
              </Checkbox>
            </div>
            <div className='input-checkbox mb0'>
              <Checkbox
                checked={formValue.HaveNotSymptomsOfContrast}
                onChange={() =>
                  setFormValue(previous => ({
                    ...previous,
                    HasAllergicToIodine: false,
                    HasHighBloodPressure: false,
                    IsDiabetic: false,
                    HasHistoryOfKidney: false,
                    OtherAllergies: false,
                    HaveNotSymptomsOfContrast: !previous.HaveNotSymptomsOfContrast,
                  }))
                }
              >
                {t('contrast.None of the Above')}
              </Checkbox>
            </div>
          </div>
          <div className='text-form mb16'>
            <p>{t('contrast.symptoms_text')}</p>
          </div>
          <div className='input-wrap textarea-wrap mb32'>
            <TextField
              label={t('contrast.E.g. I had kidney problems in 2018')}
              accepter={Textarea}
              value={formValue.SymptomsNotesOfContrast}
              name='SymptomsNotesOfContrast'
            />
          </div>
          <div className='btn-row row justify-content-between full-mob'>
            <button className='btn btn-white back no-arrow' onClick={() => goToPreviousStep(PING_STEP_NAMES.contrastQuestion)}>
              {t('form.Back', { ns: 'translations' })}
            </button>
            <button disabled={!isConfirmEnabled} className='btn next no-arrow' onClick={onNextHandler}>
              {t('form.Next', { ns: 'translations' })}
            </button>
          </div>
        </Form>
      </div>
      <InfoBox
        title={t('box.WHY_DO_YOU_NEED_INFOBOX_DATA.title', { ns: 'translations' })}
        content={<Trans ns='translations' i18nKey='box.WHY_DO_YOU_NEED_INFOBOX_DATA.content' components={{ br: <br /> }} />}
      />
    </>
  );
};

export default Allergies;
