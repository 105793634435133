import { PingScanRequest } from '../../views/patient-ping/utils';

export type MammogramDatetimesProps = {
  goToPreviousStep: (stepName?: string) => void;
  scanRequestData: PingScanRequest;
  completeStepCallback: (partialScanData: Partial<PingScanRequest>, stepName?: string) => void;
};

export type MammogramBreastSymptomsProps = {
  goToPreviousStep: (stepName?: string) => void;
  scanRequestData: MammogramBreastSymptomsData;
  completeStepCallback: (partialScanData: Partial<MammogramBreastSymptomsData>, stepName?: string) => void;
};

export type MammogramProps = {
  goToPreviousStep: (stepName?: string) => void;
  scanRequestData: MammogramBreastData;
  completeStepCallback: (partialScanData: Partial<MammogramBreastData>, stepName?: string) => void;
};

const initMammogramQuestionsData = {
  HaveAnyBreastImplants: false,
  HaveAnyFamilyHistoryOfBreastCancer: false,
};

const initMammogramSymptomsData = {
  SymptomsNotes: '',
  IsBreastPain: false,
  IsNippleDischarge: false,
  IsLumpsInYourBreast: false,
  HaveNotSymptoms: false,
  OtherSymptoms: false,
};

const initMammogramTimeData = {
  AvailableMammogramDatetime: null,
  IsFistMammogram: false,
  IsKnowWhenLastMammogram: false,
  LastMammogramResponseID: 0,
};

export const initMammogramBreastSymptomsData = {
  ...initMammogramQuestionsData,
  ...initMammogramSymptomsData,
};
export const initMammogramData = {
  ...initMammogramTimeData,
  LocationNotes: '',
};

export const initMammogramGeneralData = {
  data: initMammogramData,
  currentStep: 1,
  errorSubmitting: false,
};

export type MammogramGeneralData = {
  data: MammogramData;
  currentStep: number;
  errorSubmitting: boolean;
};

export type MammogramTimeForm = {
  AvailableMammogramDatetime: Date | null | string;
  IsFistMammogram: boolean;
  IsKnowWhenLastMammogram: boolean;
  LastMammogramResponseID: number;
};

export type MammogramBreastSymptomsData = MammogramSymptomsForm & MammogramQuestionsForm;
export type MammogramBreastData = MammogramTimeForm & {
  LocationNotes: string;
};
export type MammogramData = MammogramBreastData & MammogramBreastSymptomsData;

export const MAMMOGRAM_STEP_NAMES = {
  mammogram: 'MAMMOGRAM',
  time: 'MAMMOGRAM_TIME',
  location: 'MAMMOGRAM_LOCATION',
  symptoms: 'MAMMOGRAM_SYMPTOMS',
  questions: 'MAMMOGRAM_QUESTIONS',
};

export type MammogramQuestionsForm = {
  HaveAnyBreastImplants: boolean;
  HaveAnyFamilyHistoryOfBreastCancer: boolean;
};

export type MammogramSymptomsForm = {
  SymptomsNotes: string;
  IsBreastPain: boolean;
  IsNippleDischarge: boolean;
  IsLumpsInYourBreast: boolean;
  HaveNotSymptoms: boolean;
  OtherSymptoms: boolean;
};

export type MammogramLocationForm = {
  LocationNotes: string;
};

export type MammogramTimeProps = {
  goToPreviousStep: (stepName?: string) => void;
  scanRequestData: MammogramBreastData;
  completeStepCallback: (partialScanData: Partial<MammogramBreastData>, stepName?: string) => void;
};
