import React from 'react';
import { Link } from 'react-router-dom';
import StepsComponent from '../../../components/step-info';
import { APP_CORP_WEB_URL } from '../../../config/FrontConfig';
import { ScanManagmentUrl, NewScanUrl } from '../../../config/UrlsConfig';

const NoScansComponent: React.FunctionComponent<{ isNoScansFound: boolean }> = ({ isNoScansFound }) => {
  return (
    <div className='dashboard-nodata'>
      <section className='dashboard_banner'>
        <div className='container'>
          <div className='row'>
            <div className='dashboard_banner_box'>
              <div className='global-title_wrap'>
                <div className='global-title_wrap_box'>
                  <div className='sub-title'>Your appointments</div>
                  <h1 className='h1'>Manage your appointments</h1>
                  <div className='text'>
                    <p>You have no existing orders, press the button below to get started.</p>
                  </div>
                </div>
              </div>
              <Link className='btn' to={NewScanUrl}>
                SCHEDULE NOW
              </Link>
            </div>
            {isNoScansFound && (
              <div className='dashboard_banner_info'>
                <div className='row'>
                  <span className='dashboard-icon info'></span>
                  <div className='text'>
                    <div className='title'>Need more info?</div>
                    <p>Not sure if you need imaging? Looking for a virtual consultation?</p>
                  </div>
                </div>
                <a href={`${APP_CORP_WEB_URL}/how-it-works/`} target='_blank' rel='noreferrer' className='btn btn-white'>
                  Learn more
                </a>
              </div>
            )}
          </div>
        </div>
        <div className='figure'>
          <span className='circle'></span>
        </div>
      </section>
      {isNoScansFound ? (
        <StepsComponent isDashboardPage={true} />
      ) : (
        <section className='looking-appointments'>
          <div className='container'>
            <div className='looking-appointments-box'>
              <div className='row'>
                <div className='global-title_wrap'>
                  <div className='global-title_wrap_box'>
                    <div className='sub-title'>Our medmo service</div>
                    <h2 className='h2'>Looking for past appointments?</h2>
                  </div>
                </div>
                <div className='dashboard-icon post'></div>
              </div>
              <div className='text-wrap'>
                <p>You can view all your past appointments made through our Medmo service.</p>
              </div>
              <Link className='btn btn-white' to={ScanManagmentUrl}>
                View full list
              </Link>
            </div>
          </div>
        </section>
      )}
    </div>
  );
};

export default NoScansComponent;
