import { PingScanRequest } from '../../views/patient-ping/utils';
import React from 'react';
import { Schema } from 'rsuite';
const { NumberType } = Schema.Types;

export const PING_SMOKER_HABITS_STEP_NAMES = {
  smoker: 'SMOKER',
  smokerCigs: 'SMOKER_CIGS',
  smokerYear: 'SMOKER_YEAR',
};

export type WrapperProps = {
  goToPreviousStep: (stepName?: string) => void;
  scanRequestData: PingScanRequest;
  completeStepCallback: (partialScanData: Partial<PingScanRequest>, stepName?: string) => void;
};

export const initSmokerData = {
  IsSmoker: null,
};

export const initSmokerCigsData = {
  CigarettesPerDay: null,
};

export const initSmokerYearData = {
  ActiveSmokerYears: null,
};

export const initSmokerBodyData = {
  ...initSmokerData,
  ...initSmokerCigsData,
  ...initSmokerYearData,
};

export const initSmokerGeneralData = {
  data: initSmokerBodyData,
  currentStep: 1,
  errorSubmitting: false,
};

export type SmokerBodyForm = {
  IsSmoker: number | null;
};

export type SmokerCigsForm = {
  CigarettesPerDay: number | null | undefined | string;
};

export type SmokerYearForm = {
  ActiveSmokerYears: number | null | undefined | string;
};

export const SmokeCigsSchema = Schema.Model({
  CigarettesPerDay: NumberType()
    .isRequired('This field is required')
    .min(1, 'Please enter a number between 1 to 60.')
    .max(60, 'Please enter a number between 1 to 60.'),
});

export const SmokeYearSchema = Schema.Model({
  ActiveSmokerYears: NumberType()
    .isRequired('This field is required')
    .min(1, 'Please enter a number between 1 to 30.')
    .max(30, 'Please enter a number between 1 to 30.'),
});

export type SmokerData = SmokerBodyForm & SmokerCigsForm & SmokerYearForm;

export type SmokerGeneralData = {
  data: SmokerData;
  currentStep: number;
  errorSubmitting: boolean;
};

export type BodyGeneralProps = {
  goToPreviousStep: (stepName?: string) => void;
  completeStepCallback: (partialScanData: Partial<PingScanRequest>, stepName?: string) => void;
  infoBox?: { title: string; content: React.ReactNode };
  stepName: string;
  scanRequestData: Partial<PingScanRequest>;
};

export const Questions = [
  { ID: 1, Response: 'Current smoker' },
  { ID: 2, Response: 'Past smoker' },
  { ID: 3, Response: 'Im not a smoker' },
];
