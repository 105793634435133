import React, { useMemo, useState } from 'react';
import { Form } from 'rsuite';
import { TextFieldProps } from './interfaces';
import {
  AT_LEAST_8_CHARACTERS,
  AT_LEAST_ONE_CAPITAL_LETTER,
  AT_LEAST_ONE_SPECIAL_CHARACTER,
  AT_LEST_ONE_NUMBER,
} from '../../utils/RegExpUtil';
import { useTranslation } from 'react-i18next';

const TextFieldPassword: React.FunctionComponent<TextFieldProps> = props => {
  const [passwordShown, setPasswordShown] = useState<boolean>(false);
  const {
    name,
    label,
    accepter,
    error,
    className,
    value,
    rules = false,
    placeholder,
    readOnly,
    showEye = true,
    showRulesPermanently = false,
    rulesList,
    ...rest
  } = props;
  const [focus, setFocus] = useState<boolean>(false);
  const [val, setValue] = useState<string>(value);
  const { t } = useTranslation('translations');

  const errorMessage = useMemo(() => {
    if (typeof error === 'string') {
      return error;
    }

    return null;
  }, [error]);

  const validate = (type: string) => {
    let result = false;
    switch (type) {
      case 'length':
        result = AT_LEAST_8_CHARACTERS.test(val || '');
        break;
      case 'capital':
        result = AT_LEAST_ONE_CAPITAL_LETTER.test(val || '');
        break;
      case 'number':
        result = AT_LEST_ONE_NUMBER.test(val || '');
        break;
      case 'special':
        result = AT_LEAST_ONE_SPECIAL_CHARACTER.test(val || '');
        break;
    }

    return result;
  };

  return (
    <div className={`input-wrap ${className ? className : ''} input-password`}>
      <Form.Group
        className={`${val || !!value || placeholder ? 'show' : ''} ${focus ? 'focus' : ''} ${errorMessage ? 'error' : ''} anim-placeholder`}
      >
        <Form.ControlLabel>{label} </Form.ControlLabel>
        <Form.Control
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          onChange={setValue}
          name={name}
          readOnly={readOnly}
          accepter={accepter}
          type={passwordShown ? 'text' : 'password'}
          placeholder={placeholder}
          autoComplete={name}
          {...rest}
          errorPlacement='bottomStart'
        />
        {showEye && <div className={`password-view ${passwordShown ? ' show' : ''}`} onClick={() => setPasswordShown(prev => !prev)}></div>}
        {showRulesPermanently && rules && (
          <ul className='password-valid-list'>
            <li className={validate('length') ? 'show' : ''}>{t('form.password.At least 8 characters')}</li>
            <li className={validate('special') ? 'show' : ''}>{t('form.password.At least 1 special character')}</li>
            <li className={validate('capital') ? 'show' : ''}>{t('form.password.At least 1 capital letter')}</li>
            <li className={validate('number') ? 'show' : ''}>{t('form.password.At least 1 number')}</li>
          </ul>
        )}
        {showRulesPermanently && rulesList && rulesList.length && (
          <ul className='password-valid-list'>
            {rulesList.map((rule, i) => (
              <li className={rule.check() && val !== '' ? 'show' : ''} key={i}>
                {rule.text}
              </li>
            ))}
          </ul>
        )}
      </Form.Group>
    </div>
  );
};

export default TextFieldPassword;
