export const CLEERLY_HANDOFF_TOTAL_STEPS = 8;

export const CLEERLY_HANDOFF_STEP_NAMES = {
  confirmYourIdentity: 'CONFIRM_YOUR_IDENTITY',
  instructions: 'INSTRUCTIONS',
  userInformation: 'USER_INFORMATION',
  patientLocation: 'PATIENT_LOCATION',
  selectAppointmentDatetimes: 'SELECT_APPOINTMENT_DATETIMES',
  howToCoverAppointment: 'HOW_TO_COVER_APPOINTMENT_CLEERLY',
  insuranceInformation: 'INSURANCE_INFORMATION_CLEERLY',
  paymentInformation: 'PAYMENT_INFORMATION_CLEERLY',
  reviewScanRequest: 'REVIEW_SCAN_REQUEST',
  purchaseInformation: 'PURCHASE_INFORMATION_CLEERLY',
  generalError: 'GENERAL_ERROR',
};
