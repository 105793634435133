import React from 'react';
import InfoBox from '../../../components/info-box';
import { PatientPingInfoProps } from './interfaces';
import { PING_STEP_NAMES } from '../utils';
import { Trans, useTranslation } from 'react-i18next';

const PatientPingInfo: React.FunctionComponent<PatientPingInfoProps> = ({ patientPingData, completeStepCallback }) => {
  const { t } = useTranslation('handoff');
  const Ids = patientPingData.ModuleList.map(i => i.ModuleID);
  const list = [
    {
      title: t('get_started.Basic information'),
      ids: [1, 18, 19, 9, 6, 7, 20, 8, 4, 5, 15, 21, 22, 10, 23],
      isShown() {
        return this.ids.some(id => Ids.includes(id));
      },
    },
    {
      title: t('get_started.MRI details'),
      ids: [30, 31, 32, 11],
      isShown() {
        return this.ids.some(id => Ids.includes(id));
      },
    },
    {
      title: t('get_started.Breast imaging details'),
      ids: [24, 25],
      isShown() {
        return this.ids.some(id => Ids.includes(id));
      },
    },
    {
      title: t('get_started.Contrast details'),
      ids: [26, 27, 28, 29, 17],
      isShown() {
        return this.ids.some(id => Ids.includes(id));
      },
    },
    {
      title: t('get_started.General questions'),
      ids: [16],
      isShown() {
        return this.ids.some(id => Ids.includes(id));
      },
    },
    {
      title: t('get_started.Credit card info'),
      ids: [3],
      isShown() {
        return this.ids.some(id => Ids.includes(id));
      },
    },
  ];

  return (
    <>
      <div className='request-type patient-ping'>
        <div className='patient-ping_header'>
          <h2>
            {t('get_started.Welcome')}, {patientPingData.PatientFirstName}
          </h2>
        </div>
        <div className='patient-ping_content'>
          <div className='patient-ping_content_item'>
            <p>{t('get_started.text')}</p>
          </div>
          <div className='patient-ping_content_item'>
            <ul>{list.map(item => (item.isShown() ? <li key={item.title}>{item.title}</li> : null))}</ul>
          </div>
          <div className='patient-ping_content_item'>
            <p>
              <strong>{t('get_started.Scans ordered')}:</strong>
            </p>
            <ul>
              {patientPingData.ScanList.length ? (
                patientPingData.ScanList.map(({ TestRequestID, Scan }) => (
                  <li key={TestRequestID}>
                    {t(`${Scan}` as unknown as string | string[], {
                      ns: 'scanTypesFNList',
                      defaultValue: Scan,
                    })}{' '}
                    -&nbsp;
                    {t('get_started.Scan ID')}: {TestRequestID}
                  </li>
                ))
              ) : (
                <li>
                  <span>FriendlyName</span>
                  {t(`${patientPingData.FriendlyName}` as unknown as string | string[], {
                    ns: 'scanTypesFNList',
                    defaultValue: patientPingData.FriendlyName,
                  })}
                  &nbsp;-&nbsp;
                  {t('get_started.Scan ID')}: {patientPingData.PrimaryTestRequestID}
                </li>
              )}
            </ul>
          </div>
        </div>
        <div className='btn-row row justify-content-between full-mob'>
          <button className='btn next no-arrow' onClick={() => completeStepCallback({}, PING_STEP_NAMES.getStarted)}>
            {t('get_started.Get started')}
          </button>
        </div>
      </div>
      <InfoBox
        title={t('box.HAVE_QUESTIONS_PING_PATIENT_INFOBOX_DATA.title', { ns: 'translations' })}
        content={
          <Trans
            i18nKey='box.HAVE_QUESTIONS_PING_PATIENT_INFOBOX_DATA.content'
            values={{
              CxName: patientPingData?.AgentName || '[CX First Name]',
              CxPhone: patientPingData?.AgentPhone || '[CX phone]',
              CxEmail: patientPingData?.AgentEmail || '[CX email]',
            }}
            components={{ linkTo: <a /> }}
          />
        }
      />
    </>
  );
};

export default PatientPingInfo;
