import { addDays, format } from 'date-fns/esm';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { getScanServiceByID } from '../../api/scan-service/ScanServiceAPI';
import ErrorMessageComponent from '../../components/error-message-component';
import LoaderComponent from '../../components/loader-component';
import { ScanService } from '../../models/ScanService';

const PatientProviderAgreementSelfPay: React.FunctionComponent = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [scanService, setScanService] = useState<ScanService | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [deadline] = useState(() => {
    const futureDate = addDays(new Date(), 90);
    return format(futureDate, 'MMMM do yyyy');
  });
  const { scanServiceID } = useParams<{ scanServiceID: string }>();

  const minPrice = useMemo(() => `$${scanService?.MinPrice}`, [scanService]);

  const fee = useMemo(() => `$${scanService?.Fee}`, [scanService]);

  const totalToPay = useMemo(() => `$${scanService?.TotalToPay}`, [scanService]);

  useEffect(() => {
    getScanServiceFromAPI();
  }, []);

  const getScanServiceFromAPI = (): void => {
    getScanServiceByID(+scanServiceID)
      .then(scanServiceData => setScanService(scanServiceData))
      .catch(() => setError('Error getting scan service in order to display patient provider agreement.'))
      .finally(() => setIsLoading(false));
  };

  if (isLoading) {
    return <LoaderComponent className='loader-block-center' />;
  }

  if (error) {
    return (
      <div className='container'>
        <ErrorMessageComponent errorMessage={error} />
      </div>
    );
  }

  return (
    <div className='container page-content document'>
      <div className='page-content-wrap'>
        <h1>Patient provider agreement</h1>
        <p>This constitutes a valid and binding agreement among the parties (&quot;Agreement&quot;).</p>
        <p>
          The Patient or his/her authorized representative used Medmo.com to find and book the services he/she needs at an agreed upon
          price.
        </p>

        <p>Patient warrants that a practicing physician has prescribed the booked service.</p>
        <p>
          Provider represents and warrants that it is fully qualified, credentialed, registered and otherwise licensed to render all
          Services agreed upon herein for the Patient.
        </p>

        <p>
          The Provider agrees to provide to the Patient the above Service for a total private pay payment price of no more than {minPrice}.
          The Provider accepts such price (the &quot;Service Charge&quot;) due it as payment in full for all technical fees, professional
          fees, and one (1) digital copy of any relevant images furnished to the Patient.
        </p>

        <p>
          Patient further agrees to pay a deposit to Medmo of {fee} for its technological facilitation herein (the &quot;Convenience
          Charge&quot;).
        </p>
        <p>
          Patients are charged the Convenience Charge when the Provider books their scan. The Service Charge is paid directly to the
          Provider at the time of Service. (See Cancellation and Refund Policy below).
        </p>
        <p>
          THE SERVICE MUST BE OBTAINED NO LATER THAN Wednesday, {deadline} OR THIS AGREEMENT WILL EXPIRE AND THE ABOVE PRICE WILL NO LONGER
          BE APPLICABLE FOR RENDERING OF THE SERVICE. UPON EXPIRATION, A NEW PRICE LOCK-IN REQUEST MAY BE SUBMITTED VIA MEDMO.
        </p>
        <p>
          Service Charge includes the total rate for the listed Service above and is inclusive of all costs incurred by the Provider in
          rendering such service.
        </p>
        <p>
          EACH OF PROVIDER AND PATIENT REPRESENT, WARRANT AND COVENANT THAT THEY WILL NOT SUBMIT ANY BILLS OR CLAIMS FOR PAYMENT OR
          REIMBURSEMENT TO ANY PRIVATE OR GOVERNMENTAL PAYOR OR CARRIER FOR THE SERVICE RENDERED HEREIN.
        </p>
        <p>This arrangement is only for the above-named Patient for the stated Service with the above-named Provider.</p>
        <p>
          If the actual service or product required to treat the patient is different from that listed above, then Provider must inform
          Patient what the new/additional service or product is and what the price will be before rendering testing or treatment.
        </p>
        <p>
          Provider will hold Patient harmless from and against any attempt (successful or otherwise) by a third party source to collect any
          excess amounts resulting in additional payment obligations by the Patient beyond the total Service Charge pricing for the Service
          as set forth above (Note: This does not apply to amounts owed Medmo by Patient hereunder).
        </p>

        <h2>CANCELLATION AND REFUND POLICY</h2>
        <h3>Patient Initiation of Cancellation</h3>
        <p>
          Patients initiating a cancellation of a Service for which the bid has already been accepted by Provider, but no Service yet
          performed, are not required to pay any fee to the Provider. Patients may receive a refund of the Convenience Charge from Medmo if
          they can demonstrate that Medmo failed to fulfill its responsibility to perform technological facilitation of the booking
          according to the terms described herein, at the sole discretion of Medmo.
        </p>
        <h3>Provider Initiation of Cancellation</h3>
        <p>
          If a Provider initiates a cancellation of a Service for which the bid has already been accepted by Provider, but no Service yet
          performed, are not required to pay any fee to the Provider and are entitled to a full refund of the Convenience Charge, pending
          notification of Medmo and verification of the Provider cancellation.
        </p>
        <h3>&quot;No Fault&quot; Cancellation</h3>
        <p>
          For &quot;no fault&quot; cancellations, meaning that both the Patient and Provider made a bona fide, sincere and good faith
          attempt to complete the rendering of the Service, but cannot do so for bona fide medical reasons (provided substantiating proof
          and required signed attestations are promptly made available and are accurate), the Patient receives a refund of the Convenience
          charge, pending notification of Medmo and verification of the cancellation.
        </p>
        <h2>FURTHER ACKNOWLEDGMENTS AND AGREEMENTS</h2>
        <ol>
          <li>
            &quot;MEDMO, INC.&quot; (&quot;Medmo&quot;; sometimes &quot;we&quot;, &quot;our&quot; or the like) is inclusive of Medmo.com and
            all associated websites and mobile applications (apps).
          </li>
          <li>
            If Patient cancels or disputes a charge or payment except as otherwise permitted under this Agreement, each of Medmo and
            Provider respectively shall be entitled to reimbursement from Patient and the undersigned (as a legal representative and
            signatory hereto if different than Patient) of all collection costs including attorneys&apos; fees associated with recovering
            payment owed hereunder.
          </li>
          <li>
            Medmo is acting solely as an intermediary facilitating the scheduling of services provided by third party providers, through
            technological means.
          </li>
          <li>
            Medmo has no responsibility whatsoever for any claims against or adverse effect on the Provider or its professionals under this
            private pay arrangement. This includes, without limitation, if the Provider or its professionals are in-network with the
            particular insurance or other payor program or plan (including private and governmental) with which the Patient participates as
            a beneficiary. Similarly, we have no responsibility to the Patient if there is an alternative provider whose qualifications or
            specialties or participation match those required to treat the individual Patient on an in-network and otherwise participating
            provider basis.
          </li>
          <li>
            Medmo makes no assurance at all as to whether or not the agreed upon pricing for the Service is the best possible price or is
            competitive or fair at the time of the rendering of the Service, and the Patient is advised that he/she may possibly pay less
            (taking into account any co-payment and deductible, or co-insurance obligation, as applicable) if he/she sought such service
            through his/her own insurance coverage or other payor program or plan (including governmental).
          </li>
          <li>
            Medmo will not be performing any claims adjustment services or auditing the performance of Services. Medmo will not be pursuing
            any collection against the Patient on behalf of Provider. Payments are due in advance through mechanisms arranged for by Medmo.
            Medmo will not be handling any disputes between the Patient and the Provider, whether regarding payment, the provision of the
            Service or otherwise. The Patient and Provider are solely responsible for ensuring/seeking/settling as between themselves any
            issues and disputes including those relating to the provision of a Service and quality of care. BOTH OF SUCH PARTIES AGREE TO
            COMPLY WITH THE ABOVE CANCELLATION AND REFUND POLICIES.
          </li>
          <li>
            Each of Patient and Provider hereby expressly acknowledge and agree that we are: (i) a technology development and platform
            company; (ii) not an insurance company, benefit plan or third party administrator and are not determining any patient&apos;s
            eligibility to receive benefits under any third party payor program or plan (private or governmental); and (iii) for purposes of
            the Employee Retirement Income Security Act of 1974 (&quot;ERISA&quot;) or any equivalent or similar other state or federal
            laws/regulations that may be applicable, we shall not be deemed the &quot;administrator&quot; or &quot;named fiduciary&quot; of
            any benefit program or plan.
          </li>
          <li>
            Patients shall look solely to Provider for refunds owed of all or any portion of the Service Charge in all instances and
            circumstances. Patients shall look solely to Medmo for refunds owed of all or any portion of the Convenience Charge in all
            instances and circumstances, subject to the terms hereof.
          </li>
          <li>
            MEDMO HAS NO LIABILITY WHATSOVER FOR ANY INJURY, DAMAGE, DEATH, LOSS, ACCIDENT OR DELAY DUE TO ANY FAULT, ACT OF NEGLIGENCE,
            OMISSION OR DEFAULT OF THE CONTRACTING PROVIDER OR PATIENT. IN NO EVENT SHALL MEDMO BE LIABLE TO EITHER PATIENT OR PROVIDER FOR
            LOSS OF GOODWILL, OR FOR SPECIAL, INDIRECT, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES ARISING OUT OF OR RELATING TO THIS
            AGREEMENT, THE PRICING AGREED UPON AND THE SERVICES SOUGHT OR RENDERED, REGARDLESS OF WHETHER SUCH CLAIM ARISES IN TORT OR IN
            CONTRACT.
          </li>
          <li>
            Each of Patient and Provider hereby agree that they are subject to the Terms of Use they agreed to upon registering or
            activating their account. The Terms of Use for Patients are accessible here: [insert web address which is freely accessible
            without log-in], and Providers can access their Terms of Use here: [insert web address, which should be accessible only upon
            log-in by the provider], which terms are hereby deemed incorporated herein as if fully a part hereof and supplement hereto. Each
            party represents and warrants it has reviewed and agreed to such Terms of Use, respectively.
          </li>
          <li>We may assign this Agreement to any successor to our business or assets to which this Agreement relates.</li>
          <li>
            We are deemed a party to this Agreement for purposes of enforcing our rights, the agreements and protections favoring us herein.
            If an applicable jurisdiction determines we are not a direct party, then we shall instead be deemed a third party beneficiary of
            this Agreement for the same purposes.
          </li>
          <li>
            Each party represents and warrants that it is duly authorized and has full power, capacity and authority to accept this
            Agreement on behalf of such party, including the above named Patient or Provider. If the person agreeing hereto is the legal
            representative of the Patient, such legal representative represents and warrants to the parties hereto that he/she/it is fully
            authorized to enter into this Agreement on behalf of the Patient and such legal representative hereby further becomes a party
            hereto and fully bound hereby as if the Patient party.
          </li>
          <li>
            This Agreement as it pertains to Patient and Provider shall be governed by the laws of the State in which the applicable
            Provider is rendering the Service, without regard to the conflicts of law provisions thereof. However, the governing law,
            jurisdiction and venue for any claims with respect to and involving Medmo shall be in accordance with the provisions of the
            parties&apos; respective Terms of Use.
          </li>
          <li>
            By clicking below, entering into this agreement and otherwise using the Medmo website/app, each of Patient and Provider accept
            these terms and conditions as a fully enforceable agreement.
          </li>
        </ol>
        <h2>MEDICARE-RELATED MATTERS</h2>
        <p>
          If the Patient is a Medicare beneficiary or his/her legal representative, he or she agrees to, understands, and expressly
          acknowledges the following:
        </p>
        <ul>
          <li>
            Beneficiary or his/her legal representative accepts full responsibility for payment of the Provider&apos;s charge for all
            Services furnished, as well as any charges for cancellation as set forth herein.
          </li>
          <li>
            Beneficiary or his/her legal representative understands that Medicare limits may or do not apply to what is charged hereunder.
          </li>
          <li>
            Beneficiary or his/her legal representative agrees not to submit a claim to Medicare or to ask the Provider or any third party
            to submit a claim to Medicare.
          </li>
          <li>
            Beneficiary or his/her legal representative understands that Medicare payment and reimbursement will not be made for any items
            or services furnished hereunder even if they would have otherwise been covered by Medicare if there was no private pay contract
            and a proper Medicare claim had been submitted.
          </li>
          <li>
            Beneficiary or his/her legal representative enters into this contract with the knowledge that he/she has the right to obtain
            Medicare-covered items and services from physicians and practitioners, and the beneficiary is not compelled to enter into
            private contracts.
          </li>
          <li>
            Beneficiary or his/her legal representative understands that Medi-Gap plans do not, and that other supplemental plans may not
            make payments for items and services not actually paid for by Medicare. Beneficiary or his/her legal representative agrees never
            to submit such claims or request or cause any third party to do so.
          </li>
          <li>
            Beneficiary or his/her legal representative acknowledges that the beneficiary is not currently in an emergency or urgent health
            care situation.
          </li>
          <li>Beneficiary or his/her legal representative acknowledges that a copy of this contract has been made available to him/her.</li>
        </ul>
        <h2>Key points:</h2>
        <ul>
          <li>You must have a doctor&apos;s prescription for this test.</li>
          <li>
            By paying out of pocket, you’re getting an amazing discount. You can use your HSA, but if you have insurance, you can&apos;t
            submit a claim. If you do, the price will change.
          </li>
          <li>
            There&apos;s no charge if we can&apos;t book your scan for {totalToPay}. If we do, we&apos;ll charge a deposit of {fee}.
          </li>
          <li>
            You&apos;ll pay the imaging center the remaining {minPrice} when you arrive for your scan. The payment covers the test and a
            radiologist&apos;s interpretation, so you won&apos;t get surprise bills later.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default PatientProviderAgreementSelfPay;
