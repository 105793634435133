import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Progress } from 'rsuite';
import { MixpanelContext } from '../../contexts/MixpanelContext';
import { STEP_BACK, STEP_COMPLETED } from '../../utils/MixpanelEvents';
import LoaderComponent from '../../components/loader-component';
import { additionalPurchaseData, CLEERLY_INITIAL_DATA } from './utils';
import { CLEERLY_SCAN_DATA, CleerlyScanRequest } from './interfaces';
import CustomInstructions from './instructions';
import UserInformation from '../handoff-process/cleerly/user-information';
import ImagingCenterComponent from './imaging-center';
import ProviderComponent from './provider';
import PurchaseInformation from '../../components/purchase-information';
import ReviewProcess from './review';
import { finishCleerlySelfServiceProcess } from '../../api/handoff-process/HandoffProcessAPI';
import { HANDOFF_STEP_NAMES } from '../handoff-process/standard/utils';
import GeneralError from '../../components/general-error';
import UploadPrescription from './upload-prescription';

const CleerlyComponent: React.FunctionComponent = () => {
  const { Line } = Progress;
  const [orderId, setOrderId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [scanData, setScanData] = useState<CLEERLY_SCAN_DATA>(CLEERLY_INITIAL_DATA);
  const mixpanel = useContext(MixpanelContext);
  const [purchaseAddInfo, setPurchaseAddInfo] = useState({
    ...additionalPurchaseData,
    isWarningBoxHidden: true,
    isCardErrorMessageShown: false,
  });
  const percentage = useMemo(() => {
    const steps = 6;
    return (scanData.currentStep / steps) * 100;
  }, [scanData.currentStep]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [scanData.currentStep]);

  const goToPreviousStep = useCallback((stepName?: string): void => {
    setScanData(({ currentStep, ...rest }) => {
      return {
        ...rest,
        currentStep: currentStep - 1,
        errorSubmitting: false,
      };
    });
    mixpanel.track(`CLEERLY_SELF_SERVICE_${STEP_BACK}_${stepName}`);
  }, []);

  const completeStepCallback = useCallback(
    (stepMetadata?: Partial<CleerlyScanRequest>, stepName?: string) => {
      const stepDataToSave = stepMetadata || {};

      setScanData(({ currentStep, ...rest }) => {
        return {
          errorSubmitting: false,
          currentStep: currentStep + 1,
          scanRequestData: { ...rest.scanRequestData, ...stepDataToSave },
        };
      });

      if (scanData.currentStep >= 1) {
        mixpanel.track(`CLEERLY_SELF_SERVICE_${STEP_COMPLETED}_${stepName}`, stepMetadata);
      }
    },
    [scanData],
  );

  const submitCallback = async (stepMetadata?: Partial<CleerlyScanRequest>, stepName?: string) => {
    const stepDataToSave = stepMetadata || {};

    const payload = { ...scanData.scanRequestData, ...stepDataToSave };
    try {
      setIsLoading(true);
      const response = await finishCleerlySelfServiceProcess(payload);
      setOrderId(response.ID);
    } catch (error: any) {
      const message = error.message ? error.message.toLowerCase() : error.message;
      setScanData(previous => ({ ...previous, currentStep: 6, errorSubmitting: true }));
      if (error.status && error.status === 400 && message && message.indexOf('payment message') > -1) {
        setPurchaseAddInfo(prevState => ({ ...prevState, isCardErrorMessageShown: true }));
        setScanData(previous => ({ ...previous, currentStep: 5, errorSubmitting: false }));
        setIsLoading(false);
        return;
      }
      purchaseAddInfo.isCardErrorMessageShown && setPurchaseAddInfo(prevState => ({ ...prevState, isCardErrorMessageShown: false }));
      setIsLoading(false);
      return setScanData(previous => ({ ...previous, currentStep: 6, errorSubmitting: true }));
    }
    setIsLoading(false);
    stepName && completeStepCallback(stepDataToSave, HANDOFF_STEP_NAMES.reviewScanRequest);
  };

  const patientData = useMemo(() => {
    const { DOB, Email, FirstName, GenderId, LastName, Phone } = scanData.scanRequestData;
    return {
      DOB,
      Email,
      FirstName,
      LastName,
      Phone,
      GenderId: GenderId === null ? -1 : GenderId,
    };
  }, [scanData.scanRequestData]);

  const currentStepDisplayed = useMemo<React.ReactNode | null>(() => {
    const { currentStep, errorSubmitting } = scanData;
    switch (currentStep) {
      case 0:
        return <CustomInstructions completeStepCallback={completeStepCallback} />;
      case 1:
        return <UserInformation patientDataToPrepopulate={patientData} completeStepCallback={completeStepCallback} isDobActive={true} />;
      case 2:
        return (
          <ImagingCenterComponent
            completeStepCallback={completeStepCallback}
            scanRequestData={scanData.scanRequestData}
            goToPreviousStep={goToPreviousStep}
          />
        );
      case 3:
        return (
          <ProviderComponent
            completeStepCallback={completeStepCallback}
            scanRequestData={scanData.scanRequestData}
            goToPreviousStep={goToPreviousStep}
          />
        );
      case 4:
        return (
          <UploadPrescription
            goToPreviousStep={goToPreviousStep}
            scanRequestData={scanData.scanRequestData}
            completeStepCallback={completeStepCallback}
          />
        );
      case 5:
        return (
          <PurchaseInformation
            patientData={{ FirstName: scanData.scanRequestData.FirstName, LastName: scanData.scanRequestData.LastName }}
            goToPreviousStep={goToPreviousStep}
            additionalInfo={purchaseAddInfo}
            scanRequestData={scanData.scanRequestData}
            completeStepCallback={submitCallback}
          />
        );
      case 6:
        return errorSubmitting ? (
          <GeneralError goToPreviousStep={goToPreviousStep} isFromPXB={false} />
        ) : (
          <ReviewProcess scanRequestData={scanData.scanRequestData} orderId={orderId} />
        );
      default:
        return null;
    }
  }, [scanData]);

  if (isLoading) {
    return <LoaderComponent className='loader-block-center' />;
  }

  return (
    <section className='main-request-section'>
      <Line percent={percentage} strokeColor='#ff8a35' />
      <div className='container'>
        <div className='row'>{currentStepDisplayed}</div>
      </div>
    </section>
  );
};

export default CleerlyComponent;
