import { Schema } from 'rsuite';
const { StringType } = Schema.Types;

export const Statuses = {
  Closed: 0,
  Approving: 1,
  Scheduling: 2,
  Scheduled: 3,
  Completed: 4,
};

export const WarningSatuses = {
  OK: 1,
  Warning: 2,
  Error: 3,
};

export const optionModel = Schema.Model({
  reason: StringType().isRequired('This field is required.').maxLength(250, 'Reason should be less or equal to 250 characters'),
});

export const openDefaultValue = {
  isContactOpen: false,
  isRescheduleOpen: false,
  isCancelOpen: false,
};

export const addSection = {
  isContactOpen: 'isContactOpen',
  isRescheduleOpen: 'isRescheduleOpen',
  isCancelOpen: 'isCancelOpen',
};

export const optionTexts = {
  confirm: {
    title: 'Confirm',
    content: 'Request has been sent successfully',
  },
  error: {
    title: `Error`,
    content: `Something went wrong. Please try again later.`,
  },
};
