import React, { useMemo, useState } from 'react';
import { Checkbox, Form } from 'rsuite';
import { useTranslation } from 'react-i18next';
import { AssistanceInnerProps, InformationDataForm, initInformationData, PING_ASSISTANCE_STEP_NAMES } from '../interfaces';

const AssistanceInformation: React.FunctionComponent<AssistanceInnerProps> = ({
  goToPreviousStep,
  completeStepCallback,
  scanRequestData,
}) => {
  const { t } = useTranslation('handoff');
  const {
    Wheelchair = initInformationData.Wheelchair,
    Walker = initInformationData.Walker,
    Interpreter = initInformationData.Interpreter,
    NoAssistance = initInformationData.NoAssistance,
    OtherAssistance = initInformationData.NoAssistance,
  } = scanRequestData;

  const [formValue, setFormValue] = useState<InformationDataForm>({
    Wheelchair: Boolean(Wheelchair),
    Walker: Boolean(Walker),
    Interpreter: Boolean(Interpreter),
    NoAssistance: Boolean(NoAssistance),
    OtherAssistance: Boolean(OtherAssistance),
  });

  const selectResponse = (): void => {
    completeStepCallback(
      {
        ...formValue,
      },
      PING_ASSISTANCE_STEP_NAMES.assistanceInformation,
    );
  };

  const isConfirmEnabled = useMemo<boolean>(() => {
    const { Wheelchair, Walker, Interpreter, NoAssistance, OtherAssistance } = formValue;
    return Wheelchair || Walker || Interpreter || NoAssistance || OtherAssistance;
  }, [formValue]);

  return (
    <>
      <div className='main-request-form'>
        <h2 id='Additional_questions' className='h2'>
          {t('assistance.Will you require any of the following?')}
        </h2>
        <Form onChange={value => setFormValue(value as InformationDataForm)} formValue={formValue} className='main-form-appointment' fluid>
          <div className='input-checkbox_wrap'>
            <div className='input-checkbox mb0'>
              <Checkbox
                checked={formValue.Wheelchair as boolean}
                onChange={() =>
                  setFormValue(previous => ({
                    ...previous,
                    NoAssistance: false,
                    Wheelchair: !previous.Wheelchair,
                  }))
                }
              >
                {t('assistance.Wheelchair assistance')}
              </Checkbox>
            </div>
            <div className='input-checkbox mb0'>
              <Checkbox
                checked={formValue.Walker as boolean}
                onChange={() =>
                  setFormValue(previous => ({
                    ...previous,
                    NoAssistance: false,
                    Walker: !previous.Walker,
                  }))
                }
              >
                {t('assistance.Walker')}
              </Checkbox>
            </div>
            <div className='input-checkbox mb0'>
              <Checkbox
                checked={formValue.Interpreter as boolean}
                onChange={() =>
                  setFormValue(previous => ({
                    ...previous,
                    NoAssistance: false,
                    Interpreter: !previous.Interpreter,
                  }))
                }
              >
                {t('assistance.Interpreter')}
              </Checkbox>
            </div>
            <div className='input-checkbox mb0'>
              <Checkbox
                checked={formValue.OtherAssistance as boolean}
                onChange={() =>
                  setFormValue(previous => ({
                    ...previous,
                    NoAssistance: false,
                    OtherAssistance: !previous.OtherAssistance,
                  }))
                }
              >
                {t('assistance.OtherAssistance')}
              </Checkbox>
            </div>
            <div className='input-checkbox mb0'>
              <Checkbox
                checked={formValue.NoAssistance as boolean}
                onChange={() =>
                  setFormValue(previous => ({
                    ...previous,
                    Wheelchair: false,
                    Walker: false,
                    Interpreter: false,
                    OtherAssistance: false,
                    NoAssistance: !previous.NoAssistance,
                  }))
                }
              >
                {t('assistance.None of the above')}
              </Checkbox>
            </div>
          </div>
        </Form>
        <div className='btn-row row justify-content-between full-mob'>
          <button onClick={() => goToPreviousStep(PING_ASSISTANCE_STEP_NAMES.assistanceInformation)} className='btn prev btn-white'>
            {t('form.Back', { ns: 'translations' })}
          </button>
          <button className='btn next' disabled={!isConfirmEnabled} onClick={() => selectResponse()}>
            {t('form.Next', { ns: 'translations' })}
          </button>
        </div>
      </div>
    </>
  );
};

export default AssistanceInformation;
