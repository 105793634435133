import React from 'react';
import WarningBox from '../warning-box';
import { STEP_NAMES } from '../../views/patient-scan-request/PatientScanRequestUtils';
import { HowToCoverAppointmentProps } from './interfaces';
import { useTranslation } from 'react-i18next';

const HowToCoverAppointment: React.FunctionComponent<HowToCoverAppointmentProps> = ({
  goToPreviousStep,
  scanRequestData,
  completeStepCallback,
}) => {
  const { scanServiceSelected } = scanRequestData;
  const { t } = useTranslation('handoff');

  const selectOption = (isSelfPay: boolean): void => {
    completeStepCallback({ SelfPay: isSelfPay ? 1 : 0 }, STEP_NAMES.howToCoverAppointment);
  };

  return (
    <div className='request-type'>
      <h2 id='How_would_you_like_to_cover_your_appointment' className='h2'>
        {t('how_to_cover.title')}
      </h2>
      <div className='text-wrap'>
        <p>{t('how_to_cover.sub-title')}</p>
      </div>
      <div className='request-type-payments'>
        <a className='request-type-payments_item pointer-cursor' onClick={() => selectOption(false)}>
          <div className='row'>
            <p>
              <b>{t('how_to_cover.Insurance')}</b>
            </p>
            <p>{t('how_to_cover.Available')}</p>
          </div>
          <div className='info'>{t('how_to_cover.Insurance coverage')}</div>
        </a>
        <a className='request-type-payments_item pointer-cursor' onClick={() => selectOption(true)}>
          {!!scanServiceSelected?.AvgPriceRoundUp && (
            <div className='hint'>
              <p>
                {t('how_to_cover.Compared to the average market rate')} - <b>${scanServiceSelected?.AvgPriceRoundUp}</b>
              </p>
            </div>
          )}
          <div className='row'>
            <p>
              <b>{t('how_to_cover.Self pay')}</b>
            </p>
            <p>${scanServiceSelected?.TotalPrice}</p>
          </div>
          <div className='info'>{t('how_to_cover.Medmo self pay rate')}</div>
        </a>
      </div>
      <WarningBox>
        <p>
          <b>{t('how_to_cover.box.insurance.title')}</b>
          &nbsp;-&nbsp;{t('how_to_cover.box.insurance.text')}
        </p>
        <p>
          <b>{t('how_to_cover.box.self_pay.title')}</b>
          &nbsp;-&nbsp;{t('how_to_cover.box.self_pay.text')}
        </p>
      </WarningBox>
      <div className='btn-row row justify-content-between full-mob'>
        <button className='btn prev btn-white' onClick={() => goToPreviousStep(STEP_NAMES.howToCoverAppointment)}>
          {t('form.Back', { ns: 'translations' })}
        </button>
      </div>
    </div>
  );
};

export default HowToCoverAppointment;
