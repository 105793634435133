import React, { useEffect, useState } from 'react';
import WarningBox from '../../../components/warning-box';
import { HEALTHYR } from '../index';
import LoaderComponent from '../../../components/loader-component';

type HealthyrCreatedCreatedProps = {
  clearProcessData?: () => void;
  submitCallback?: () => void;
  partner?: string;
};

const HealthyrCreated: React.FunctionComponent<HealthyrCreatedCreatedProps> = ({ clearProcessData, submitCallback, partner }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    clearProcessData && setTimeout(() => clearProcessData(), 500);
    isLoading && setIsLoading(false);
  }, [clearProcessData]);

  const handleConfirm = (): void => {
    setIsLoading(true);
    submitCallback && submitCallback();
  };

  useEffect(() => {
    if (partner === HEALTHYR) {
      handleConfirm();
    }
  }, []);

  if (isLoading) {
    return <LoaderComponent className='loader-block-center' />;
  }

  return (
    <>
      <div className='main-request-form main-request-successfully'>
        <h2 id='Your_request_was_successfully_created' className='h2'>
          Success! We’ve sent you an email with your order confirmation and instructions to complete your referral consultation.
        </h2>
        <div className='text-form opt7'>Please check your email.</div>
        <div className='text-form opt7'>
          <p>Once completed, Medmo care team will obtain your referral and contact you to schedule your imaging appointment. </p>
        </div>
        <WarningBox title={'What happens next?'}>
          <p>
            After completing your consultation, our customer experience team will schedule you the best appointment based on the criteria
            you requested.{' '}
          </p>
        </WarningBox>
      </div>
    </>
  );
};

export default HealthyrCreated;
