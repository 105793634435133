import React, { ChangeEvent, SyntheticEvent, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { Checkbox, Form, SelectPicker } from 'rsuite';
import { FormInstance } from 'rsuite/Form';
import { logPatient } from '../../api/login/LoginAPI';
import { registerNewUser /*registerNewUserWithSocial*/ } from '../../api/users/UsersAPI';
import InfoBox from '../../components/info-box';
import LoaderComponent from '../../components/loader-component';
import InputMask from '../../components/mask-field';
import InfoModalComponent from '../../components/modals/info-modal';
import TextField from '../../components/text-field-component';
import TextFieldPassword from '../../components/text-field-password';
import { LoginUrl, NewScanUrl, PrivacyUrl } from '../../config/UrlsConfig';
import { CommonContext, CommonContextFunctions, CommonContextTypes } from '../../contexts/CommonContext';
import { MixpanelContext } from '../../contexts/MixpanelContext';
import { UserContext } from '../../contexts/UserContext';
import { FormError, InfoModalTexts } from '../../models/General';
import { Patient } from '../../models/Patient';
import { User } from '../../models/User';
import { transformDate } from '../../utils/DateUtil';
import { useError } from '../../utils/ErrorUtil';
import {
  HANDOFF_SOURCE,
  PXB_SOURCE,
  VIEW_SOURCE,
  HANDOFF_REGISTRATION_TYPE_ID,
  PXB_REGISTRATION_TYPE_ID,
  STANDARD_REGISTRATION_TYPE_ID,
  HEALTHYR_SOURCE,
} from '../../utils/GeneralUtil';
import { HANDOFF_CONTINUE_AS_GUEST } from '../../utils/MixpanelEvents';
import { CreateAccountFormData, /*SocialAuthResponse*/ FormDataKeys, CreateAccountProps } from './interfaces';
import { CreateAccountSchema, initData, /*prepareSocialData*/ texts } from './utils';
import { CustomerStatus, ireRegistrationSignIn } from '../../utils/impactUtil';
import { useTranslation } from 'react-i18next';

const CreateAccountComponent: React.FunctionComponent<CreateAccountProps> = ({
  source = VIEW_SOURCE,
  submitCallback,
  getBack,
  goToLogin,
  patientDataToPrepopulate,
  mixpanelHandoffPayload,
}) => {
  const [formData, setFormData] = useState<CreateAccountFormData>(() =>
    patientDataToPrepopulate
      ? {
          ...patientDataToPrepopulate,
          Password: '',
          TermsAgreed: 0,
          DOB: transformDate(patientDataToPrepopulate.DOB, 'MM/DD/YYYY'),
        }
      : initData,
  );
  const [formError, setFormError] = useState<FormError>({});
  const [isLoading, setLoading] = useState<boolean>(true);
  const [successText, setSuccessText] = useState<InfoModalTexts | null>(null);
  const [user, setUser] = useState<User | null>(null);
  const [errorText, setErrorText, dataErrors, setDataErrors] = useError('');
  const formRef = useRef<FormInstance | null>(null);
  const currentUser = useContext(UserContext);
  const history = useHistory();
  const mixpanel = useContext(MixpanelContext);
  const { Genders: biologicalSexes, getGenders } = useContext<CommonContextTypes & CommonContextFunctions>(CommonContext);
  const { t } = useTranslation('account');

  useEffect(() => {
    if (!biologicalSexes.length) {
      getGenders();
    } else {
      setLoading(false);
    }
  }, [biologicalSexes]);

  const authAfterAccCreation = (data: Patient): void => {
    const { FirstName, LastName, Phone, Email, DOB, GenderId, UserID, ID, AllowTexts, SocialAccounts } = data;
    const userData = {
      FirstName,
      LastName,
      Phone,
      Email,
      DOB: DOB ? transformDate(DOB, 'MM/DD/YYYY') : '',
      GenderId,
      UserID,
      ID,
      AllowTexts,
      SocialAccounts,
    };
    logPatient(getRegistrationTypeID(), UserID).catch(err => console.error(err));
    mixpanel.identify(UserID.toString());
    if (submitCallback) {
      submitCallback();
      localStorage.setItem('isAuth', 'true');
      if (source !== HANDOFF_SOURCE && UserID) {
        ireRegistrationSignIn(String(UserID), CustomerStatus.REGISTER);
      }
      currentUser.expandUser(userData);
      if (source === PXB_SOURCE) {
        history.push(NewScanUrl);
      }
      return;
    }

    if (source !== HANDOFF_SOURCE && UserID) {
      ireRegistrationSignIn(String(UserID), CustomerStatus.REGISTER);
    }
    setUser(userData);
    localStorage.setItem('isAuth', 'true');
    setSuccessText(texts.accCreationSuccess);
    setLoading(false);
  };

  const continueAsGuest = (): void => {
    mixpanel.track(HANDOFF_CONTINUE_AS_GUEST, mixpanelHandoffPayload);
    submitCallback && submitCallback();
  };

  const getRegistrationTypeID = (): number => {
    return source === VIEW_SOURCE
      ? STANDARD_REGISTRATION_TYPE_ID
      : source === PXB_SOURCE
      ? PXB_REGISTRATION_TYPE_ID
      : source === HEALTHYR_SOURCE
      ? PXB_REGISTRATION_TYPE_ID
      : HANDOFF_REGISTRATION_TYPE_ID;
  };

  const onSubmit = () => {
    if (!formRef.current || !formRef.current.check()) {
      return;
    }

    setLoading(true);

    registerNewUser({ ...formData, DOB: transformDate(formData.DOB, 'YYYY-MM-DD'), AllowTexts: true })
      .then(res => authAfterAccCreation(res as Patient))
      .catch(error => {
        setErrorText(error, formData);
        setLoading(false);
      });
  };

  const onCloseModal = () => {
    setSuccessText(null);

    if (!errorText && user) {
      currentUser.expandUser(user);
      history.push(NewScanUrl);
      return;
    }

    setErrorText(null);
  };

  const handleChange = (formValue: CreateAccountFormData, event: ChangeEvent<HTMLInputElement>) => {
    errorText && setErrorText(null);
    const fieldName = event?.target?.name;

    if (fieldName && dataErrors[fieldName]) {
      setDataErrors({ ...dataErrors, [fieldName]: '' });
    }

    setFormData(formValue);
  };

  const areRequiredFieldsFilled = useMemo(
    () =>
      (Object.keys(formData) as FormDataKeys).every(key => {
        if (key === 'GenderId' && typeof formData[key] === 'number') {
          return true;
        }

        return !!formData[key];
      }),
    [formData],
  );

  if (isLoading) {
    return <LoaderComponent />;
  }

  return (
    <>
      <div className={`introductory-form create-acc-form ${source === VIEW_SOURCE ? '' : 'main-request-form'} mb24`}>
        <div id='Create_a_Medmo_Account' className={source === VIEW_SOURCE ? 'introductory-form_title text-center' : 'h2'}>
          {t('create.Create a Medmo Account')}
        </div>
        <div className='introductory-form_text'>
          <p>{t('create.text')}</p>
        </div>
        <Form
          model={CreateAccountSchema}
          onChange={(formValue: Record<string, any>, event?: SyntheticEvent) =>
            handleChange(formValue as CreateAccountFormData, event as ChangeEvent<HTMLInputElement>)
          }
          onCheck={(formError: FormError) => setFormError(formError)}
          formValue={formData}
          ref={formRef}
          fluid
        >
          <div className='row row-wrap'>
            <TextField
              className='w50'
              name='FirstName'
              label={t('form.First name', { ns: 'translations' }) || 'First name'}
              autoComplete='off'
              error={formError.FirstName || dataErrors.FirstName}
              value={formData.FirstName}
              placeholder={`${t('form.Enter', { ns: 'translations' })} ${t('form.First name', { ns: 'translations' })}`}
            />
            <TextField
              className='w50'
              name='LastName'
              label={t('form.Last name', { ns: 'translations' }) || 'Last name'}
              autoComplete='off'
              error={formError.LastName || dataErrors.LastName}
              value={formData.LastName}
              placeholder={`${t('form.Enter', { ns: 'translations' })} ${t('form.Last name', { ns: 'translations' })}`}
            />
            <TextField
              className={`select-wrap ${formError.GenderId && 'error-border'} w50`}
              accepter={SelectPicker}
              name='GenderId'
              label={t('form.Biological Sex', { ns: 'translations' }) || 'Biological Sex'}
              error={formError.GenderId || dataErrors.GenderId}
              value={formData.GenderId}
              data={biologicalSexes}
              placeholder={`${t('form.Choose', { ns: 'translations' })} ${t('form.Biological Sex', { ns: 'translations' })}`}
              appearance='subtle'
              placement='bottomStart'
              searchable={false}
            />
            <TextField
              className='w50'
              type='tel'
              name='DOB'
              readOnly={source === HANDOFF_SOURCE}
              accepter={InputMask}
              label={t('form.Date of birth', { ns: 'translations' }) || 'Date of birth'}
              error={formError.DOB || dataErrors.DOB}
              value={formData.DOB}
              mask={[/[0-9]/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
              format='MM/DD/YYYY'
              autoComplete='off'
              placeholder={`${t('form.Enter', { ns: 'translations' })} ${t('form.Date of birth', { ns: 'translations' })}`}
            />
            <TextField
              className='w50'
              name='Email'
              type='email'
              label={t('form.Email', { ns: 'translations' }) || 'Email'}
              autoComplete='off'
              error={formError.Email || dataErrors.Email}
              value={formData.Email}
              placeholder={`${t('form.Enter', { ns: 'translations' })} ${t('form.Email', { ns: 'translations' })}`}
            />
            <TextField
              className='w50'
              type='tel'
              accepter={InputMask}
              error={formError.Phone || dataErrors.Phone}
              value={formData.Phone}
              mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
              name='Phone'
              autoComplete='off'
              label={t('form.Phone', { ns: 'translations' }) || 'Phone'}
              placeholder={`${t('form.Enter', { ns: 'translations' })} ${t('form.Phone', { ns: 'translations' })}`}
            />
          </div>
          <TextFieldPassword
            name='Password'
            rules
            showRulesPermanently
            label={t('form.Password', { ns: 'translations' }) || 'Password'}
            error={formError.Password || dataErrors.Password}
            value={formData.Password}
            placeholder={`${t('form.Enter', { ns: 'translations' })} ${t('form.Password', { ns: 'translations' })}`}
          />
          <div className='row justify-content-between row-wrap mb24'>
            <div className='input-checkbox mb0'>
              <Checkbox
                name='TermsAgreed'
                checked={formData.TermsAgreed === 1}
                onChange={(value: any, checked: boolean) => setFormData({ ...formData, TermsAgreed: checked ? 1 : 0 })}
              >
                {t('form.I agree to Medmo', { ns: 'translations' })}&nbsp;
                <Link to={PrivacyUrl} target='_blank'>
                  {t('form.Privacy Policy', { ns: 'translations' })}
                </Link>
              </Checkbox>
            </div>
          </div>
          <button className='btn w100' onClick={onSubmit} disabled={!areRequiredFieldsFilled}>
            {t('form.SaveAndContinue', { ns: 'translations' })}
          </button>
        </Form>
        <div className='row justify-content-between mb24'>
          <p>
            {t('form.Already have an account with us', { ns: 'translations' })}&nbsp;
            {source === VIEW_SOURCE ? (
              <Link to={LoginUrl}>{t('form.Log In', { ns: 'translations' })}</Link>
            ) : (
              <a className='pointer-cursor' onClick={goToLogin}>
                {t('form.Sign In', { ns: 'translations' })}
              </a>
            )}
          </p>
        </div>
        {source === HANDOFF_SOURCE && (
          <>
            <div className='or-container size'>
              <hr className='divider'></hr>
              <span className='text'>{t('form.OR', { ns: 'translations' })}</span>
              <hr className='divider'></hr>
            </div>
            <a className='continue-as-a-guest size' onClick={continueAsGuest}>
              {t('form.Continue as a guest', { ns: 'translations' })}
            </a>
          </>
        )}
        <div className='btn-row row justify-content-between'>
          {source === VIEW_SOURCE ? (
            <a href={LoginUrl} className='btn btn-white back'>
              {t('form.Back', { ns: 'translations' })}
            </a>
          ) : (
            <button onClick={getBack} className='btn btn-white back'>
              {t('form.Back', { ns: 'translations' })}
            </button>
          )}
        </div>
        {(errorText || successText) && (
          <InfoModalComponent
            onClose={onCloseModal}
            type={errorText ? 'error' : 'success'}
            texts={
              successText || {
                title: texts.accCreationError.title,
                content: errorText as string,
                buttonText: texts.accCreationError.buttonText,
              }
            }
          />
        )}
      </div>
      {source !== VIEW_SOURCE && (
        <InfoBox
          title={t('box.PRIVACY_INFOBOX_DATA.title', { ns: 'translations' })}
          content={t('box.PRIVACY_INFOBOX_DATA.content', { ns: 'translations' })}
        />
      )}
    </>
  );
};

export default CreateAccountComponent;
