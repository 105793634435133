import { SERVER_URL } from '../../config/ServerConfig';
import { ScanService } from '../../models/ScanService';
import { request } from '../Request';

export function getScanServiceByID(scanServiceID: number): Promise<ScanService> {
  return request({
    url: `${SERVER_URL}/scan-services/${scanServiceID}`,
    method: 'GET',
  });
}
