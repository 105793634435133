import React, { useEffect } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { FilePreviewerThumbnailProps } from './interfaces';
import { isPDF } from './FileViewerUtils';
import * as pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';

// Get the pdf.js worker from cloudflare content delivery network.
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const FilePreviewerThumbnail: React.FC<FilePreviewerThumbnailProps> = ({
  style = {},
  className,
  file: initFile,
  width,
  height,
  fileLoadFinish,
  handleClick,
}) => {
  const file = initFile;

  const fileSrc = file.url || `data:${file.mimeType};base64,${file.data}`;

  useEffect(() => {
    if (!isPDF(file) && fileLoadFinish) {
      fileLoadFinish();
    }
  }, []);

  return (
    <div style={style} className={`thumbnail-wrapper ${className}`} onClick={handleClick}>
      {isPDF(file) ? (
        <Document file={fileSrc} onLoadSuccess={fileLoadFinish} onLoadError={fileLoadFinish}>
          <Page pageNumber={1} width={width || 64} height={height || 84} />
        </Document>
      ) : (
        <img src={fileSrc} />
      )}
    </div>
  );
};

export default FilePreviewerThumbnail;
