import { ScanRequest } from '../models/ScanRequest';
import { removeEmptyFieldsFromObject, removeFieldsFromObject } from './GeneralUtil';
import {
  PERCENT_OF_TRAFFIC_USERS,
  POPULUS_ADD_INFO,
  SESSION_STORAGE_USER_TRAFFIC_INT,
} from '../views/patient-scan-request/PatientScanRequestUtils';

type MixpanelData = Partial<ScanRequest> & { reasonLength?: number; hasSecondaryAddress?: boolean; ScanRequestID?: number };
type MixpanelPopulusData = MixpanelData & { PopulusAdsCohort: boolean; PopulusAdsRender: boolean | null; PopulusAdsNoAds: boolean | null };

const FIELDS_TO_REMOVE_FOR_MIXPANEL = [
  'HomeAddressData',
  'HomeAddressString',
  'SecondaryAddressData',
  'SecondaryAddressString',
  'PaymentID',
  'PaymentSecret',
  'CardExpirationDate',
  'FullNameOnCard',
  'CardLast4',
  'PolicyNumber',
  'Reason',
  'HomeAddressString',
  'SecondaryAddressString',
  'ID',
  'token',
];

export const prepareScanRequestDataForMixpanel = (stepMetadata: Partial<ScanRequest>): MixpanelData => {
  let payload = { ...removeFieldsFromObject(FIELDS_TO_REMOVE_FOR_MIXPANEL, stepMetadata) };
  payload = removeEmptyFieldsFromObject(payload);
  if (stepMetadata.Reason) {
    payload = { ...payload, reasonLength: stepMetadata.Reason?.length || 0 };
  }
  if (stepMetadata.HomeAddressData) {
    payload = { ...payload, hasSecondaryAddress: !!stepMetadata.SecondaryAddressData };
  }
  if (stepMetadata.ID) {
    payload = { ...payload, ScanRequestID: stepMetadata.ID };
  }
  return payload;
};

export const preparePopulusDataForMixpanel = (data: MixpanelData, number: number): MixpanelPopulusData => {
  if (number === -1) {
    const sessionUserInt = sessionStorage.getItem(SESSION_STORAGE_USER_TRAFFIC_INT);
    number = Number(sessionUserInt);
  }
  if (PERCENT_OF_TRAFFIC_USERS > 0 && number < PERCENT_OF_TRAFFIC_USERS) {
    if (sessionStorage.getItem(POPULUS_ADD_INFO)) {
      const addInfo = JSON.parse(sessionStorage.getItem(POPULUS_ADD_INFO) || '{}');
      sessionStorage.setItem(POPULUS_ADD_INFO, JSON.stringify({ PopulusAdsRender: null, PopulusAdsNoAds: null }));
      return Object.assign({}, data, { PopulusAdsCohort: true }, addInfo);
    }
    return Object.assign({}, data, { PopulusAdsCohort: true, PopulusAdsRender: null, PopulusAdsNoAds: null });
  }
  return Object.assign({}, data, { PopulusAdsCohort: false, PopulusAdsRender: null, PopulusAdsNoAds: null });
};
