import { ModuleItem } from '../../views/patient-ping/interfaces';
import { PingScanRequest } from '../../views/patient-ping/utils';

export type AvailabilityProps = {
  goToPreviousStep: (stepName?: string) => void;
  scanRequestData: PingScanRequest;
  completeStepCallback: (partialScanData: Partial<PingScanRequest>, stepName?: string) => void;
  isFlexibleShown?: boolean;
  moduleData?: null | ModuleItem;
};

export const initAvailability = {
  isMondaySelected: false,
  isTuesdaySelected: false,
  isWednesdaySelected: false,
  isThursdaySelected: false,
  isFridaySelected: false,
  isSaturdaySelected: false,
  isSundaySelected: false,
  isAllDaySelected: false,
  isMorningSelected: false,
  isAfternoonSelected: false,
  isEveningSelected: false,
  AvailableNotes: '',
};

export type AvailableData = {
  isMondaySelected: boolean;
  isTuesdaySelected: boolean;
  isWednesdaySelected: boolean;
  isThursdaySelected: boolean;
  isFridaySelected: boolean;
  isSaturdaySelected: boolean;
  isSundaySelected: boolean;
  isAllDaySelected: boolean;
  isMorningSelected: boolean;
  isAfternoonSelected: boolean;
  isEveningSelected: boolean;
  AvailableNotes: string;
};

export type AvailabilityForm = {
  Mo: boolean;
  Tu: boolean;
  We: boolean;
  Th: boolean;
  Fr: boolean;
  Sa: boolean;
  AllDay: boolean;
  Morning: boolean;
  Afternoon: boolean;
  Evening: boolean;
  AvailableNotes: string;
};
