export const formatPhone = (phone: string): string => {
  const firstDigits = phone.slice(0, 3);
  const middleDigits = phone.slice(3, 6);
  const endDigits = phone.slice(6);
  if (!middleDigits) {
    return firstDigits;
  }
  if (!endDigits) {
    return `${firstDigits}-${middleDigits}`;
  }
  return `${firstDigits}-${middleDigits}-${endDigits}`;
};
