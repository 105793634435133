import React, { useMemo, useState } from 'react';
import InfoBox from '../info-box';
import { HeightAndWeighForm, HeightAndWeightProps } from './interfaces';
import { FormError } from '../../models/General';
import { HeightAndWeightSchema } from './utils';
import { Form } from 'rsuite';
import TextField from '../text-field-component';
import { PING_STEP_NAMES } from '../../views/patient-ping/utils';
import { Trans, useTranslation } from 'react-i18next';

const HeightAndWeight: React.FunctionComponent<HeightAndWeightProps> = ({
  scanRequestData,
  goToPreviousStep,
  completeStepCallback,
  agentInfo,
  stepName = PING_STEP_NAMES.heightAndWeight,
}) => {
  const [formValue, setFormValue] = useState<HeightAndWeighForm>({
    HeightFeet: scanRequestData.HeightFeet,
    HeightInches: scanRequestData.HeightInches,
    WeightPounds: scanRequestData.WeightPounds,
  });
  const [formError, setFormError] = useState<FormError>({});
  const { t } = useTranslation('handoff');

  const isBtnDisabled = useMemo(() => {
    return !!(!formValue.HeightFeet || !formValue.HeightInches || !formValue.WeightPounds || Object.keys(formError).length);
  }, [formValue, formError]);

  const submit = () => {
    const result = {
      HeightFeet: Number(formValue.HeightFeet),
      HeightInches: Number(formValue.HeightInches),
      WeightPounds: Number(formValue.WeightPounds),
    };
    completeStepCallback(result, stepName);
  };

  return (
    <>
      <div className='main-request-form main-request-form_size'>
        <h2 id='Additional_questions' className='h2'>
          {t('width_and_height.title')}
        </h2>
        <Form
          model={HeightAndWeightSchema}
          onChange={formValue => setFormValue(formValue as HeightAndWeighForm)}
          formValue={formValue}
          onCheck={error => setFormError(error)}
          fluid
          noValidate
        >
          <div className='label-bold'>{t('width_and_height.Height')}</div>
          <div className='row row-wrap row-input50'>
            <TextField
              type='tel'
              mask={['[0–9]*']}
              autoComplete='off'
              placeholder={t('width_and_height.Feet')}
              className='w50 input-number'
              value={formValue.HeightFeet}
              name='HeightFeet'
              error={formError.HeightFeet}
              pattern='[0–9]*'
            />
            <TextField
              type='tel'
              mask={['[0–9]*']}
              autoComplete='off'
              placeholder={t('width_and_height.Inches')}
              className='w50 input-number'
              value={formValue.HeightInches}
              name='HeightInches'
              error={formError.HeightInches}
              pattern='[0–9]*'
            />
          </div>
          <div className='label-bold'>{t('width_and_height.Weight')}</div>
          <TextField
            placeholder={t('width_and_height.Pounds')}
            className='input-number'
            type='tel'
            mask={['[0–9]*']}
            autoComplete='off'
            value={formValue.WeightPounds}
            name='WeightPounds'
            error={formError.WeightPounds}
            pattern='[0–9]*'
          />
          <div className='btn-row row justify-content-between full-mob'>
            <button className='btn btn-white back no-arrow' onClick={() => goToPreviousStep(stepName)}>
              {t('form.Back', { ns: 'translations' })}
            </button>
            <button className='btn next no-arrow' disabled={isBtnDisabled} onClick={submit}>
              {t('form.Next', { ns: 'translations' })}
            </button>
          </div>
        </Form>
      </div>
      {agentInfo ? (
        <InfoBox
          title={t('box.HAVE_QUESTIONS_PING_PATIENT_INFOBOX_DATA.title', { ns: 'translations' })}
          content={
            <Trans
              i18nKey='box.HAVE_QUESTIONS_PING_PATIENT_INFOBOX_DATA.content'
              values={{
                CxName: agentInfo?.CxName || '[CX First Name]',
                CxPhone: agentInfo?.CxPhone || '[CX phone]',
                CxEmail: agentInfo?.CxEmail || '[CX email]',
              }}
              components={{ linkTo: <a /> }}
            />
          }
        />
      ) : null}
    </>
  );
};

export default HeightAndWeight;
