import React, { useContext, useEffect, useMemo, useState } from 'react';
import { WrapperHandoffProcessProps } from './standard/interfaces';
import { useParams } from 'react-router';
import { MixpanelContext } from '../../contexts/MixpanelContext';
import { getInvitationData } from '../../api/handoff-process/HandoffProcessAPI';
import { HANDOFF_INVALID_EXPIRED_INVITATION_ID } from '../../utils/MixpanelEvents';
import { InvitationDataResponse } from '../../api/handoff-process/interfaces';
import LoaderComponent from '../../components/loader-component';
import InvalidExpiredLink from './standard/invalid-expired-link';
import HandoffProcess from './standard';
import CleerlyHandoffProcess from './cleerly';
import { CommonContext } from '../../contexts/CommonContext';

const WrapperHandoffProcess: React.FunctionComponent<WrapperHandoffProcessProps> = ({ authenticate, isAuthenticated }) => {
  const { invitationID } = useParams<{ invitationID: string }>();
  const [invitationData, setInvitationData] = useState<InvitationDataResponse | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [invitationHasError, setInvitationHasError] = useState(false);
  const mixpanel = useContext(MixpanelContext);
  const commonData = useContext(CommonContext);

  useEffect(() => {
    getInvitation();
    return () => {
      if (commonData.IsCleerly) {
        commonData.updateIsCleerly(false);
      }
    };
  }, []);

  const getInvitation = (): void => {
    getInvitationData(invitationID)
      .then(response => setInvitationData(response))
      .catch(() => {
        setInvitationHasError(true);
        mixpanel.track(HANDOFF_INVALID_EXPIRED_INVITATION_ID, { invitationID });
      })
      .finally(() => setIsLoading(false));
  };

  const currentHandoffProcess = useMemo(() => {
    if (invitationData) {
      switch (invitationData.HandOffType) {
        case 'Clearly':
          commonData.updateIsCleerly(true);
          return <CleerlyHandoffProcess invitationData={invitationData} />;
        default:
          return <HandoffProcess authenticate={authenticate} isAuthenticated={isAuthenticated} invitationData={invitationData} />;
      }
    }
  }, [invitationData]);

  if (isLoading) {
    return <LoaderComponent className='loader-block-center' />;
  }

  if (invitationHasError) {
    return <InvalidExpiredLink />;
  }

  return <>{currentHandoffProcess}</>;
};

export default WrapperHandoffProcess;
