import { Schema } from 'rsuite';
const { StringType, DateType, NumberType } = Schema.Types;

export const UserInformationSchema = Schema.Model({
  FirstName: StringType().isRequired('This field is required.'),
  LastName: StringType().isRequired('This field is required.'),
  GenderId: NumberType().isRequired('This field is required.'),
  DOB: DateType()
    .isRequired('Please enter a valid date of birth (i.e. mm/dd/yyyy)')
    .max(new Date(), 'Date of birth cannot be a future date'),
  Email: StringType().isEmail('Please enter a valid email address.').isRequired('This field is required.'),
  Phone: StringType()
    .pattern(/^(\()?\d{3}(\))?(-|\s)?\d{3}(-|\s)\d{4}$/, 'Please enter a valid phone number.')
    .isRequired('This field is required.'),
});
