import React, { useState } from 'react';
import { Form } from 'rsuite';
import TextField from '../../text-field-component';
import InfoBox from '../../info-box';
import { useTranslation } from 'react-i18next';
import { BodyGeneralProps, PING_SMOKER_HABITS_STEP_NAMES, SmokerYearForm, SmokeYearSchema } from '../interfaces';
import { FormError } from '../../../models/General';

const SmokerYearDetails: React.FunctionComponent<BodyGeneralProps> = ({
  goToPreviousStep,
  completeStepCallback,
  scanRequestData,
  infoBox,
  stepName = PING_SMOKER_HABITS_STEP_NAMES.smokerYear,
}) => {
  const { t } = useTranslation('handoff');
  const { ActiveSmokerYears } = scanRequestData;
  const [formError, setFormError] = useState<FormError>({});

  const onErrorHandler = (error: any) => {
    if (error && error.ActiveSmokerYears) {
      if (error.ActiveSmokerYears === 'Please enter a number between 1 to 30.') {
        const message = t(`smokerHabits.${error.ActiveSmokerYears}`, { ns: 'handoff', defaultValue: '' });
        return setFormError({ ...error, ActiveSmokerYears: message });
      } else if (error.ActiveSmokerYears === 'This field is required') {
        const message = t(`payment.${error.ActiveSmokerYears}`, { ns: 'handoff', defaultValue: '' });
        return setFormError({ ...error, ActiveSmokerYears: message });
      }
    }
    setFormError(error);
  };

  const [formValue, setFormValue] = useState<SmokerYearForm>({
    ActiveSmokerYears: ActiveSmokerYears || '',
  });

  const selectResponse = (): void => {
    completeStepCallback({ ActiveSmokerYears: Number(formValue.ActiveSmokerYears) }, PING_SMOKER_HABITS_STEP_NAMES.smokerYear);
  };

  return (
    <>
      <div className='main-request-form'>
        <h2 id='Additional_questions' className='h2'>
          {t('smokerHabits.title_year')}
        </h2>
        <div className='text-form fw600'>
          <p>{t('smokerHabits.Please enter a number between 1 to 30.')}</p>
        </div>
        <Form
          model={SmokeYearSchema}
          onCheck={error => onErrorHandler(error)}
          onChange={value => setFormValue(value as SmokerYearForm)}
          formValue={formValue}
          className='main-form-appointment'
          fluid
        >
          <div className='input-wrap textarea-wrap h80 mb32'>
            <TextField
              name='ActiveSmokerYears'
              value={formValue.ActiveSmokerYears}
              placeholder={t('smokerHabits.E.g. 9')}
              error={formError.ActiveSmokerYears}
              type='number'
              min='1'
              max='30'
              inputmode='numeric'
              pattern='[0-9]*'
            />
          </div>
        </Form>
        <div className='btn-row row full-mob justify-content-between'>
          <button onClick={() => goToPreviousStep(stepName)} className='btn prev btn-white'>
            {t('form.Back', { ns: 'translations' })}
          </button>
          <button
            disabled={!formValue.ActiveSmokerYears || !!Object.keys(formError).length}
            className='btn next'
            onClick={() => selectResponse()}
          >
            {t('form.Next', { ns: 'translations' })}
          </button>
        </div>
      </div>
      {infoBox ? <InfoBox title={infoBox.title} content={infoBox.content} /> : null}
    </>
  );
};

export default SmokerYearDetails;
