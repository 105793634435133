import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { Form } from 'rsuite';
import InfoBox from '../../../components/info-box';
import LoaderComponent from '../../../components/loader-component';
import InputMask from '../../../components/mask-field';
import TextField from '../../../components/text-field-component';
import { FormError } from '../../../models/General';
import { ConfirmYourIdentityProps } from './interfaces';
import { ConfirmYourIdentitySchema } from './utils';
import { PING_STEP_NAMES } from '../utils';
import { formatDate, transformDate } from '../../../utils/DateUtil';
import { validatePatientDateOfBirth } from '../../../api/patient-ping/PatientPingAPI';
import { MixpanelContext } from '../../../contexts/MixpanelContext';
import {
  PATIENT_PING_MAXIMUM_NUMBER_OF_ATTEMPTS_EXCEEDED,
  PATIENT_PING_PROCESS_SESSION_START,
  PATIENT_PING_WRONG_DOB,
} from '../../../utils/MixpanelEvents';
import { Trans, useTranslation } from 'react-i18next';
import { DOBInvalidMessage } from '../../handoff-process/components/confirm-your-identity/utils';

const ConfirmYourIdentity: React.FunctionComponent<ConfirmYourIdentityProps> = ({ patientPingData, completeStepCallback }) => {
  const [formValue, setFormValue] = useState<{ DOB: string }>({ DOB: '' });
  const [formError, setFormError] = useState<FormError>({});
  const [isLoading, setIsLoading] = useState(false);
  const [attemptsLeft, setAttemptsLeft] = useState(5 - patientPingData?.DOBAttempts);
  const { invitationID } = useParams<{ invitationID: string }>();
  const mixpanel = useContext(MixpanelContext);
  const { t } = useTranslation('handoff');
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const isConfirmEnabled = useMemo(() => !!formValue.DOB && !Object.keys(formError).length, [formValue.DOB, formError]);

  useEffect(() => {
    if (hasSubmitted) {
      if (attemptsLeft === 0) {
        setFormError(prev => ({
          ...prev,
          DOB: '',
        }));
      }
      setFormError(prev => ({
        ...prev,
        DOB: t('WRONG_DOB_ATTEMPT'),
      }));
    }
  }, [hasSubmitted, attemptsLeft]);

  const handleConfirm = (): void => {
    setIsLoading(true);
    setHasSubmitted(true);
    validatePatientDateOfBirth({ DOB: transformDate(formValue.DOB, 'YYYY/M/D'), InvitationID: invitationID })
      .then(handleDOBvalidationResponse)
      .catch(handleMaximumAttemptsExceeded);
  };

  const handleDOBvalidationResponse = (response: boolean): void => {
    if (!response) {
      mixpanel.track(PATIENT_PING_WRONG_DOB, formValue);
      setAttemptsLeft(current => current - 1);
      setIsLoading(false);
      return;
    }
    mixpanel.track(PATIENT_PING_PROCESS_SESSION_START, { invitationID });
    completeStepCallback({ PatientDOBValidated: formatDate(formValue.DOB, 'YYYY-MM-DD') }, PING_STEP_NAMES.confirmYourIdentity);
  };

  const handleMaximumAttemptsExceeded = (error: Error): void => {
    if (error.message === 'The number of attempts has exceeded 5') {
      mixpanel.track(PATIENT_PING_MAXIMUM_NUMBER_OF_ATTEMPTS_EXCEEDED, formValue);
      setAttemptsLeft(current => current - 1);
    }
    setIsLoading(false);
  };

  const onErrorHandler = (error: any) => {
    if (error) {
      if (error.DOB === DOBInvalidMessage) {
        const message = t(DOBInvalidMessage);
        return setFormError({ ...error, DOB: message });
      } else if (error.DOB === 'DOB must be a date') {
        const message = t('DOB must be a date');
        return setFormError({ ...error, DOB: message });
      } else if (error.DOB === 'Date of birth cannot be a future date') {
        const message = t('Date of birth cannot be a future date');
        return setFormError({ ...error, DOB: message });
      } else if (error.DOB === 'Please enter a valid date of birth (i.e. mm/dd/yyyy)') {
        const message = t('Please enter a valid date of birth');
        return setFormError({ ...error, DOB: message });
      }
    }
    setFormError(error);
  };

  if (isLoading) {
    return <LoaderComponent className='loader-block-center' />;
  }

  return (
    <>
      <div className='request-type handoff-process'>
        <div className='scan-service-edit_header'>
          <h2 className='h2'>{t('Confirm your identity')}</h2>
        </div>
        <Form
          model={ConfirmYourIdentitySchema}
          formValue={formValue}
          onChange={formValue => setFormValue(formValue as { DOB: string })}
          onCheck={error => onErrorHandler(error)}
          fluid
        >
          {attemptsLeft > 0 && (
            <TextField
              type='tel'
              name='DOB'
              accepter={InputMask}
              label={'MM/DD/YYYY'}
              error={formError.DOB}
              value={formValue.DOB}
              mask={[/[0-9]/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
              format='MM/DD/YYYY'
              autoComplete='off'
              // placeholder='MM/DD/YYYY'
            />
          )}
          {attemptsLeft === 0 && (
            <p className='error-text fs16'>
              <Trans ns='handoff' i18nKey='MAXIMUM_ATTEMPTS_ERROR_TEXT' values={{ email: 'support@medmo.com' }} components={{ a: <a /> }} />
            </p>
          )}
          {attemptsLeft > 0 && (
            <div className='btn-row row full-mob mt0 justify-content-between'>
              <button className='btn next no-arrow' onClick={handleConfirm} disabled={!isConfirmEnabled}>
                {t('Confirm', { ns: 'translations' })}
              </button>
            </div>
          )}
        </Form>
      </div>
      <InfoBox
        title={t('box.HAVE_QUESTIONS_PING_PATIENT_INFOBOX_DATA.title', { ns: 'translations' })}
        content={
          <Trans
            i18nKey='box.HAVE_QUESTIONS_PING_PATIENT_INFOBOX_DATA.content'
            values={{
              CxName: patientPingData.AgentName || '[CX First Name]',
              CxPhone: patientPingData.AgentPhone || '[CX phone]',
              CxEmail: patientPingData.AgentEmail || '[CX email]',
            }}
            components={{ linkTo: <a /> }}
          />
        }
      />
    </>
  );
};

export default ConfirmYourIdentity;
