import React, { useEffect, useRef, useState } from 'react';
import { List } from 'rsuite';
import TextField from '../text-field-component';
import { AutoCompleteInputProps, AutocompleteOption } from './interfaces';

const AutoCompleteInput: React.FunctionComponent<AutoCompleteInputProps> = props => {
  const { options, onSelect, onBlur, error, className, ...rest } = props;
  const [shouldDisplayOptions, setShouldDisplayOptions] = useState<boolean>(false);
  const [activeOptionIndex, setActiveOptionIndex] = useState<number>(-1);
  const listRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (activeOptionIndex !== -1) {
      const activeListElement = listRef.current?.children.item(activeOptionIndex) as HTMLElement;
      activeListElement.scrollIntoView({ block: 'nearest' });
    }
  }, [activeOptionIndex]);

  const handleKeyPress = (event: KeyboardEvent): void => {
    switch (event.key) {
      case 'ArrowUp':
        event.preventDefault();
        handleArrowUp();
        break;
      case 'ArrowDown':
        event.preventDefault();
        handleArrowDown();
        break;
      case 'Enter':
        event.preventDefault();
        handleSelectOption();
        break;
      default:
        break;
    }
  };

  const handleArrowUp = (): void => {
    if (activeOptionIndex > 0) {
      setActiveOptionIndex(previousIndex => previousIndex - 1);
    }
  };

  const handleArrowDown = (): void => {
    if (activeOptionIndex < options.length - 1) {
      setActiveOptionIndex(previousIndex => previousIndex + 1);
    }
  };

  const handleBlur = (): void => {
    if (onBlur) {
      onBlur();
    }
    setShouldDisplayOptions(false);
  };

  const handleSelectOption = (): void => {
    if (activeOptionIndex < 0) {
      return;
    }
    onSelect(options[activeOptionIndex]);
    setShouldDisplayOptions(false);
    setActiveOptionIndex(-1);
  };

  const handleSelect = (option: AutocompleteOption): void => {
    onSelect(option);
    setShouldDisplayOptions(false);
    setActiveOptionIndex(-1);
  };

  return (
    // <div className={'input-wrap-autocomplete ' + className}>
    <div className={className ? 'input-wrap-autocomplete ' + className : 'input-wrap-autocomplete'}>
      <TextField error={error} onFocus={() => setShouldDisplayOptions(true)} onBlur={handleBlur} onKeyDown={handleKeyPress} {...rest} />
      {shouldDisplayOptions && (
        <List>
          <div ref={listRef}>
            {options.map((option, index) => (
              <button
                className={activeOptionIndex === index ? 'active' : ''}
                key={option.value}
                disabled={option.value === null}
                onMouseDown={() => handleSelect(option)}
              >
                <List.Item className={activeOptionIndex === index ? 'active' : ''}>{option.text}</List.Item>
              </button>
            ))}
          </div>
        </List>
      )}
    </div>
  );
};

export default AutoCompleteInput;
