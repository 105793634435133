import React, { useCallback, useEffect, useMemo, useState } from 'react';
import InfoBox from '../info-box';
import { Trans, useTranslation } from 'react-i18next';
import {
  HistoryOfCancerData,
  HistoryOfCancerGeneralData,
  initSurgeryGeneralData,
  PING_CANCER_STEP_NAMES,
  WrapperProps,
} from './interfaces';
import CancerBody from './body';
import CancerDetails from './details';

const CancerHistoryWrapper: React.FunctionComponent<WrapperProps> = ({
  goToPreviousStep,
  scanRequestData,
  completeStepCallback,
}: WrapperProps) => {
  const { t } = useTranslation('translations');
  const { HistoryOfCancer, HistoryOfCancerDetails } = scanRequestData;
  const [cancerData, setCancerData] = useState<HistoryOfCancerGeneralData>(() => {
    const newData = {
      HistoryOfCancer,
      HistoryOfCancerDetails,
    };
    return {
      ...initSurgeryGeneralData,
      currentStep: 1,
      data: { ...initSurgeryGeneralData.data, ...newData },
    };
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [cancerData.currentStep]);

  const previousStep = useCallback(
    (stepName?: string): void => {
      if (PING_CANCER_STEP_NAMES.cancer === stepName) {
        return goToPreviousStep(PING_CANCER_STEP_NAMES.cancer);
      }

      setCancerData(({ currentStep, data }) => {
        const prevStep = 1;
        return {
          data,
          currentStep: currentStep - prevStep,
          errorSubmitting: false,
        };
      });
    },
    [cancerData],
  );

  const completeStep = useCallback(
    (stepMetadata?: Partial<HistoryOfCancerData>, stepName?: string): void => {
      if (PING_CANCER_STEP_NAMES.cancer === stepName && stepMetadata?.HistoryOfCancer === 0) {
        stepMetadata = { ...stepMetadata, HistoryOfCancerDetails: '' };
        const newData = { ...cancerData.data, ...stepMetadata };
        return completeStepCallback(newData, stepName);
      } else if (PING_CANCER_STEP_NAMES.cancerDetails === stepName) {
        stepMetadata = { ...stepMetadata };
        const newData = { ...cancerData.data, ...stepMetadata };
        return completeStepCallback(newData, stepName);
      }

      setCancerData(({ currentStep, data }) => {
        const nextStep = 1;
        const newData = { ...data, ...stepMetadata };
        return {
          data: newData,
          currentStep: currentStep + nextStep,
          errorSubmitting: false,
        };
      });
    },
    [cancerData],
  );

  const currentStepDisplayed = useMemo<React.ReactNode | null>(() => {
    const { currentStep, data } = cancerData;
    if (!cancerData) {
      return null;
    }
    switch (currentStep) {
      case 1: {
        return (
          <CancerBody
            goToPreviousStep={previousStep}
            completeStepCallback={completeStep}
            scanRequestData={data}
            stepName={PING_CANCER_STEP_NAMES.cancer}
          />
        );
      }
      case 2:
        return (
          <CancerDetails
            goToPreviousStep={previousStep}
            completeStepCallback={completeStep}
            scanRequestData={data}
            stepName={PING_CANCER_STEP_NAMES.cancerDetails}
          />
        );
      default:
        return null;
    }
  }, [cancerData]);

  return (
    <>
      <>{currentStepDisplayed}</>
      <InfoBox
        title={t('box.WHY_DO_YOU_NEED_INFOBOX_DATA.title')}
        content={<Trans i18nKey='box.WHY_DO_YOU_NEED_INFOBOX_DATA.content' components={{ br: <br /> }} />}
      />
    </>
  );
};

export default CancerHistoryWrapper;
