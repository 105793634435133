import { PingScanRequest } from '../../views/patient-ping/utils';

export type PhysicalSymptomsComponentProps = {
  goToPreviousStep: (stepName?: string) => void;
  scanRequestData: PingScanRequest;
  completeStepCallback: (partialScanData: Partial<PingScanRequest>, stepName?: string) => void;
};

export const initPhysicalSymptomsData = {
  HasHighFever: false,
  HasWeightLoss: false,
  HasBloodWhileCoughing: false,
  HasNoneOfTheAbovePhysical: false,
};

export type PhysicalSymptomsData = {
  HasHighFever: boolean;
  HasWeightLoss: boolean;
  HasBloodWhileCoughing: boolean;
  HasNoneOfTheAbovePhysical: boolean;
};
