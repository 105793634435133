export const getServerUrl = (NODE_ENV: string, SERVER_URL: string): string => {
  if (NODE_ENV) {
    switch (NODE_ENV) {
      case 'local':
        return 'http://localhost:3000/api/v1';
      case 'local-test':
        return 'https://dev-app-api.medmo.com/api/v1';
      default:
        return SERVER_URL;
    }
  }
  return SERVER_URL;
};
