import { DOBForValidation } from '../handoff-process/interfaces';
import { request } from '../Request';
import { SERVER_URL } from '../../config/ServerConfig';

export function validatePatientDOB(payload: DOBForValidation): Promise<boolean> {
  return request({
    url: `${SERVER_URL}/send-files/validate-date-of-birth`,
    method: 'POST',
    body: JSON.stringify(payload),
  });
}

export function getSendFilesDocuments(invitationID: string) {
  return request({
    url: `${SERVER_URL}/send-files/${invitationID}`,
    method: 'GET',
  });
}

export function getDownloadFilesURL(invitationID: string): string {
  return `${SERVER_URL}/send-files/download-all/${invitationID}`;
}
