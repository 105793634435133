import { format, parse } from 'date-fns';
import momentTimezone from 'moment-timezone';
import moment from 'moment';
import { DatetimesData } from '../components/date-time-picker/interfaces';

/* Get date in UTC format */
export const getUTCDate = (format = 'YYYY-MM-DD HH:mm:ss'): string => {
  return moment.utc().format(format);
};

/* Convert to time zone date format */
export const convertToTimeZoneDate = (date: string, timeZone: string, format = 'YYYY-MM-DD HH:mm:ss'): string => {
  return momentTimezone(date).tz(timeZone).format(format);
};

/* Convert to local date format */
export const convertToLocalDate = (date: string | Date, format = 'YYYY-MM-DD HH:mm:ss'): string => {
  return moment.utc(date).local().format(format);
};

export const getUtcOffset = (): number => moment().utcOffset();

export const convertToLocalDatePlusHours = (date: string, hours: number, format = 'YYYY-MM-DD HH:mm:ss'): string => {
  return moment.utc(date).add(hours, 'h').local().format(format);
};

export const timeIntervalsStringsFunctionsMap: Record<string, (date: string) => string> = {
  '8 AM - 11 AM': date => `${date} 08:00:00/${date} 11:00:00`,
  '11 AM - 4 PM': date => `${date} 11:00:00/${date} 16:00:00`,
  '4 PM - 8 PM': date => `${date} 16:00:00/${date} 20:00:00`,
};

export const literalTimeIntervalsMap: Record<string, string> = {
  '8 AM - 11 AM': 'Morning',
  '11 AM - 4 PM': 'Midday',
  '4 PM - 8 PM': 'Afternoon',
};

export const convertDatetimesToString = (datetimesData: DatetimesData, dateFormat: string): string => {
  let datetimesString = '';
  datetimesData.dates.forEach(date => {
    const formattedDate = format(date, dateFormat);
    datetimesData.datetimes[formattedDate].forEach((time: string) => {
      const singleDatetimeString = timeIntervalsStringsFunctionsMap[time](format(date, 'yyyy-MM-dd'));
      datetimesString += singleDatetimeString + ',';
    });
  });
  return datetimesString.slice(0, -1);
};

export const parseStringToDatetimes = (datetimesString: string, dateFormat: string): DatetimesData => {
  const datetimes = datetimesString.split(',').filter(datetime => datetime !== '');
  const newDatetimes = buildDatetimes(datetimes, dateFormat);
  const dates: Date[] = Object.keys(newDatetimes).map(date => parse(date, dateFormat, new Date()));
  return { dates, datetimes: newDatetimes };
};

export const buildReadableAvailableDatetimes = (AvailableDatetimes: DatetimesData): Array<string> => {
  return Object.keys(AvailableDatetimes.datetimes).map((date, index) => {
    const dateParsed = format(AvailableDatetimes.dates[index], 'iii MMM do');
    const parsedTimes = AvailableDatetimes.datetimes[date].map(time => literalTimeIntervalsMap[time]).join(', ');
    return `${dateParsed}, ${parsedTimes}`;
  });
};

const buildDatetimes = (datetimes: string[], dateFormat: string): Record<string, string[]> => {
  let newDatetimes: Record<string, string[]> = {};
  for (const datetime of datetimes) {
    const [startDatetime, endDatetime] = datetime.split('/');
    const [date, startHour] = startDatetime.split(' ');
    const [, endHour] = endDatetime.split(' ');
    const formattedDate = format(parse(date, 'yyyy-MM-dd', new Date()), dateFormat);
    const formattedStartHour = format(parse(startHour, 'HH:mm:ss', new Date()), 'h a');
    const formattedEndHour = format(parse(endHour, 'HH:mm:ss', new Date()), 'h a');
    if (newDatetimes[formattedDate]) {
      const updatedDatetimes = [...newDatetimes[formattedDate], `${formattedStartHour} - ${formattedEndHour}`];
      newDatetimes = { ...newDatetimes, [formattedDate]: updatedDatetimes };
    } else {
      newDatetimes = { ...newDatetimes, [formattedDate]: [`${formattedStartHour} - ${formattedEndHour}`] };
    }
  }
  return newDatetimes;
};

export const formatDate = (date: string | Date, format = 'MM/DD/YYYY'): string => {
  return moment(date).format(format);
};

export const transformDate = (date: string | Date, format = 'MM/DD/YYYY'): string => {
  return moment.utc(date).format(format);
};
