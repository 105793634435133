import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { NODE_ENV } from './config/ServerConfig';

if (!['local-test', 'local'].includes(NODE_ENV)) {
  Sentry.init({
    dsn: 'https://a3bb33ab784ac6bccc00c1b45fb35e48@o725108.ingest.us.sentry.io/4507653235671040',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}
