import { StripeError } from '@stripe/stripe-js';
import React, { SyntheticEvent, useMemo, useState } from 'react';
import { Form } from 'rsuite';
import StripeLogo from '../../../assets/stripe.svg';
import { STEP_NAMES } from '../../../views/patient-scan-request/PatientScanRequestUtils';
import { FormError } from '../../../models/General';
import { ConciergePaymentInformationForm, ConciergePaymentInformationProps, FastLinkSource } from '../interfaces';
import { PaymentSchema } from '../../payment-information/utils';
import StripeFields from '../../stripe-fields';
import { CONCIERGE_COST } from '../../../config/FrontConfig';
import { PaymentInformationForm } from '../../payment-information/interfaces';
import { HEALTHYR } from '../../../views/healthyr';

const ConciergeAssistPaymentInformation: React.FunctionComponent<ConciergePaymentInformationProps> = ({
  goToPreviousStep,
  scanRequestData,
  completeStepCallback,
  additionalInfo,
  isEditMode,
  partner,
}: ConciergePaymentInformationProps) => {
  const { FullNameOnCard, CardExpirationDate, CardType, CardLast4, PaymentID, PaymentSecret } = scanRequestData;
  const [formValue, setFormValue] = useState<ConciergePaymentInformationForm>({
    FullNameOnCard: FullNameOnCard ? FullNameOnCard : '',
    CardExpirationDate: CardExpirationDate || '',
    CardType: CardType || '',
    CardLast4: CardLast4 || null,
    PaymentID: PaymentID || '',
    PaymentSecret: PaymentSecret || '',
  });
  const [formError, setFormError] = useState<FormError>({});
  const [stripeError, setStripeError] = useState<boolean>(false);
  const [isCardError, setIsCardError] = useState(!!additionalInfo?.isCardErrorMessageShown);
  const [hasSubmittedCardInformation, setHasSubmittedCardInformation] = useState<boolean>(() => !!CardType && !!CardLast4);

  const isConfirmEnabled = useMemo<boolean>(() => {
    const { FullNameOnCard, CardType, CardLast4, PaymentID, PaymentSecret } = formValue;
    const areRequiredFieldsPresent = !!CardType && !!CardLast4 && !!PaymentID && !!PaymentSecret;
    return areRequiredFieldsPresent && !stripeError && (hasSubmittedCardInformation ? true : !!FullNameOnCard);
  }, [formValue, stripeError]);

  const handleOnCompleteStep = (data: PaymentInformationForm) => {
    completeStepCallback(
      {
        ...data,
        SelfPay: 0,
      },
      STEP_NAMES.conciergeServicePayment,
    );
  };

  const handleStripeChange = (error: StripeError | boolean | null, paymentMethod: FastLinkSource | null): void => {
    if (!paymentMethod || !!error) {
      setStripeError(true);
      return;
    }

    const cardData = {
      CardExpirationDate: `${paymentMethod.card?.exp_month}/${paymentMethod.card?.exp_year?.toString().slice(2)}`,
      CardLast4: Number(paymentMethod.card?.last4),
      CardType: paymentMethod.card?.brand as string,
      PaymentID: paymentMethod.id,
      PaymentSecret: paymentMethod.client_secret,
    };
    setFormValue(previous => {
      return {
        ...previous,
        ...cardData,
      };
    });
    setIsCardError(false);
    setStripeError(false);
    paymentMethod.IsPayFaster && handleOnCompleteStep({ ...formValue, ...cardData, FullNameOnCard: paymentMethod.owner?.name || '' });
  };

  const changeCardInformation = (): void => {
    setHasSubmittedCardInformation(false);
    setFormValue({ ...formValue, CardLast4: null, CardType: '', PaymentID: '', PaymentSecret: '', CardExpirationDate: '' });
  };

  const handleConfirm = (event: SyntheticEvent): void => {
    event.preventDefault();
    completeStepCallback(
      {
        ...formValue,
        SelfPay: 0,
      },
      STEP_NAMES.conciergeServicePayment,
    );
  };

  const getBack = (event: SyntheticEvent): void => {
    event.preventDefault();
    goToPreviousStep(STEP_NAMES.conciergeServicePayment);
  };

  return (
    <div className='request-type'>
      <div className='request-type-payments_total'>
        <div>
          <h2>{partner === HEALTHYR ? 'Payment' : 'You selected Medmo Concierge!'}</h2>
          <p className='mb24'>
            With Medmo Concierge you’ll be assigned a dedicated care specialist to help you with your appointment. You’ll also have a
            consultation to better understand your options and answer any additional requests you might have.
          </p>
        </div>
        <div className='row total mt16'>
          <div>
            <span className='icon-ctm'></span>
            <b>Medmo Concierge Service Charge</b>
          </div>
          <div>
            <b>${CONCIERGE_COST}</b>
          </div>
        </div>
      </div>
      <Form
        formValue={formValue}
        onChange={value => setFormValue(value as ConciergePaymentInformationForm)}
        model={PaymentSchema}
        onCheck={error => setFormError(error)}
        fluid
      >
        {hasSubmittedCardInformation ? (
          <div className='request-type-payments_total mb-16'>
            <div className='row'>
              <b>Card information</b>
              <button className='edit-input' onClick={changeCardInformation}>
                Change
              </button>
            </div>
            <div className='row'>
              <div>Card number:</div>
              <div>
                <b>****-****-****-{CardLast4}</b>
              </div>
            </div>
            <div className='row'>
              <div>Card type:</div>
              <div>
                <b>{CardType}</b>
              </div>
            </div>
            {!!CardExpirationDate && !isEditMode && (
              <div className='row'>
                <div>Expires on:</div>
                <div>
                  <b>{CardExpirationDate}</b>
                </div>
              </div>
            )}
            {!!FullNameOnCard && !isEditMode && (
              <div className='row payment-row'>
                <div>Name on card:</div>
                <div>
                  <b>{FullNameOnCard}</b>
                </div>
              </div>
            )}
          </div>
        ) : (
          <>
            {isCardError ? (
              <div className='card-invalid'>
                <span>There was an error processing your credit card.</span>
              </div>
            ) : null}
            <StripeFields
              amount={CONCIERGE_COST}
              cardOwner={formValue.FullNameOnCard}
              callback={handleStripeChange}
              formError={formError}
            />
            <div className='stripe-info'>
              <img src={StripeLogo} alt='stripe'></img>
            </div>
          </>
        )}
        <div className='btn-row row justify-content-between full-mob'>
          <button onClick={getBack} className='btn prev btn-white'>
            Back
          </button>
          <button className='btn next' disabled={!isConfirmEnabled} onClick={handleConfirm}>
            Next
          </button>
        </div>
      </Form>
    </div>
  );
};

export default ConciergeAssistPaymentInformation;
