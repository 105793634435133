import React, { useCallback, useEffect, useMemo, useState } from 'react';
import InfoBox from '../info-box';
import { Trans, useTranslation } from 'react-i18next';
import { DiabetesData, DiabetesGeneralData, DiabetesWrapperProps, initDiabetesGeneralData, PING_DIABETES_STEP_NAMES } from './interfaces';
import BodyDiabetes from './body-diabetes';
import DiabetesTypes from './diabetes-type';
import Glucose from './glucose';

const DiabetesWrapper: React.FunctionComponent<DiabetesWrapperProps> = ({
  goToPreviousStep,
  scanRequestData,
  completeStepCallback,
}: DiabetesWrapperProps) => {
  const { t } = useTranslation('translations');
  const { HasDiabetes, DiabetesType, OnBodyGlucoseMonitor } = scanRequestData;
  const [generalData, setGeneralData] = useState<DiabetesGeneralData>(() => {
    const newData = {
      HasDiabetes,
      DiabetesType,
      OnBodyGlucoseMonitor,
    };
    return {
      ...initDiabetesGeneralData,
      currentStep: 1,
      data: { ...initDiabetesGeneralData.data, ...newData },
    };
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [generalData.currentStep]);

  const previousStep = useCallback(
    (stepName?: string): void => {
      if (PING_DIABETES_STEP_NAMES.diabetes === stepName) {
        return goToPreviousStep(PING_DIABETES_STEP_NAMES.diabetes);
      }

      setGeneralData(({ currentStep, data }) => {
        let prevStep = 1;
        if (PING_DIABETES_STEP_NAMES.diabetesDetails === stepName && data?.HasDiabetes === 0) {
          prevStep += 1;
        }
        return {
          data,
          currentStep: currentStep - prevStep,
          errorSubmitting: false,
        };
      });
    },
    [generalData],
  );

  const completeStep = useCallback(
    (stepMetadata?: Partial<DiabetesData>, stepName?: string): void => {
      if (PING_DIABETES_STEP_NAMES.diabetes === stepName && stepMetadata?.HasDiabetes === 2) {
        stepMetadata = { ...stepMetadata };
        const newData = { ...generalData.data, ...stepMetadata };
        return completeStepCallback(newData, stepName);
      } else if (PING_DIABETES_STEP_NAMES.diabetesDetails === stepName) {
        stepMetadata = { ...stepMetadata };
        const newData = { ...generalData.data, ...stepMetadata };
        return completeStepCallback(newData, stepName);
      }

      setGeneralData(({ currentStep, data }) => {
        let nextStep = 1;
        let newData = { ...data, ...stepMetadata };
        if (PING_DIABETES_STEP_NAMES.diabetes === stepName && stepMetadata?.HasDiabetes === 0) {
          nextStep += 1;
          newData = { ...newData, DiabetesType: null };
        }
        return {
          data: newData,
          currentStep: currentStep + nextStep,
          errorSubmitting: false,
        };
      });
    },
    [generalData],
  );

  const currentStepDisplayed = useMemo<React.ReactNode | null>(() => {
    const { currentStep, data } = generalData;
    if (!generalData) {
      return null;
    }
    switch (currentStep) {
      case 1: {
        return (
          <BodyDiabetes
            goToPreviousStep={previousStep}
            completeStepCallback={completeStep}
            scanRequestData={data}
            stepName={PING_DIABETES_STEP_NAMES.diabetes}
          />
        );
      }
      case 2:
        return (
          <DiabetesTypes
            goToPreviousStep={previousStep}
            completeStepCallback={completeStep}
            scanRequestData={data}
            stepName={PING_DIABETES_STEP_NAMES.diabetesTypes}
          />
        );
      case 3:
        return (
          <Glucose
            goToPreviousStep={previousStep}
            completeStepCallback={completeStep}
            scanRequestData={data}
            stepName={PING_DIABETES_STEP_NAMES.diabetesDetails}
          />
        );
      default:
        return null;
    }
  }, [generalData]);

  return (
    <>
      <>{currentStepDisplayed}</>
      <InfoBox
        title={t('box.WHY_DO_YOU_NEED_INFOBOX_DATA.title')}
        content={<Trans i18nKey='box.WHY_DO_YOU_NEED_INFOBOX_DATA.content' components={{ br: <br /> }} />}
      />
    </>
  );
};

export default DiabetesWrapper;
