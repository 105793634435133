import React, { memo, useMemo } from 'react';
import { Redirect, Route } from 'react-router';
import { BrowserRouter, Switch } from 'react-router-dom';
import { DashboardUrl, HomeUrl } from '../../config/UrlsConfig';
import { RoutersComponentProps, Router, RouterList } from './router-interfaces';
import IntroductoryComponent from '../../views/introductory';
import DefaultWrapper from '../default-wrapper';

const RouterComponent: React.FunctionComponent<RoutersComponentProps> = props => {
  const { isAuthenticated, routes, ...rest } = props;

  const routerList: RouterList = useMemo<RouterList>((): RouterList => {
    const generalList: Router[] = [];
    const authList: Router[] = [];
    const notAuthList: Router[] = [];
    const introductoryList: Router[] = [];

    if (!routes) {
      return { authList, notAuthList, generalList, introductoryList };
    }

    routes.forEach(r => {
      if (r.isGeneral()) {
        return generalList.push(r);
      }

      if (r.isActive(true)) {
        return authList.push(r);
      }

      if (r.isIntroductory()) {
        return introductoryList.push(r);
      }

      return notAuthList.push(r);
    });
    return { authList, notAuthList, generalList, introductoryList };
  }, [routes]);

  const renderNotAuthRoutes = useMemo(() => {
    if (!routerList || (routerList && !routerList.notAuthList && !routerList.introductoryList)) {
      return [];
    }

    const nonAuthList = routerList.notAuthList.map((item: Router) => (
      <Route
        key={item.path}
        exact={item.exact}
        path={item.path}
        component={(properties: JSX.IntrinsicAttributes & { children?: React.ReactNode }) => (
          <DefaultWrapper key={item.path} isAuthenticated={false} {...rest}>
            <item.component {...props} {...properties} />
          </DefaultWrapper>
        )}
      />
    ));

    const introductoryList = routerList.introductoryList.map((item: Router) => (
      <Route
        key={item.path}
        exact={item.exact}
        path={item.path}
        component={(properties: JSX.IntrinsicAttributes & { children?: React.ReactNode }) => (
          <IntroductoryComponent key={item.path} {...props}>
            <item.component {...props} {...properties} />
          </IntroductoryComponent>
        )}
      />
    ));

    return [...nonAuthList, ...introductoryList, <Redirect key='*' to={HomeUrl} />];
  }, [routerList]);

  const renderAuthRoutes = useMemo(() => {
    if (!routerList || (routerList && !routerList.authList)) {
      return [];
    }

    const result = routerList.authList.map((item: Router) => (
      <Route
        key={item.path}
        exact={item.exact}
        path={item.path}
        component={(properties: JSX.IntrinsicAttributes & { children?: React.ReactNode }) => (
          <DefaultWrapper key={item.path} isAuthenticated={true} {...rest}>
            <item.component {...props} {...properties} />
          </DefaultWrapper>
        )}
      />
    ));
    return [...result, <Redirect key='*' to={DashboardUrl} />];
  }, [routerList]);

  const renderGeneralRoutes = useMemo(() => {
    if (!routerList || (routerList && !routerList.generalList)) {
      return [];
    }

    return routerList.generalList.map((item: Router) => (
      <Route
        key={item.path}
        exact={item.exact}
        path={item.path}
        component={(properties: JSX.IntrinsicAttributes & { children?: React.ReactNode }) => (
          <DefaultWrapper key={item.path} {...props}>
            <item.component {...props} {...properties} />
          </DefaultWrapper>
        )}
      />
    ));
  }, [routerList, isAuthenticated]);

  return (
    <BrowserRouter>
      <Switch>
        {renderGeneralRoutes}
        {isAuthenticated ? renderAuthRoutes : renderNotAuthRoutes}
      </Switch>
    </BrowserRouter>
  );
};

export default memo(RouterComponent);
