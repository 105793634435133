import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { Form } from 'rsuite';
import { validateDateOfBirth } from '../../../../api/handoff-process/HandoffProcessAPI';
import InfoBox from '../../../../components/info-box';
import LoaderComponent from '../../../../components/loader-component';
import InputMask from '../../../../components/mask-field';
import TextField from '../../../../components/text-field-component';
import { FormError } from '../../../../models/General';
import { ConfirmYourIdentityProps } from './interfaces';
import { ConfirmYourIdentitySchema, DOBInvalidMessage } from './utils';
import { HANDOFF_STEP_NAMES } from '../../standard/utils';
import ErrorBox from '../../../../components/error-box';
import { ErrorBoxProps } from '../../../../components/error-box/interfaces';
import { transformDate } from '../../../../utils/DateUtil';
import { MixpanelContext } from '../../../../contexts/MixpanelContext';
import { HANDOFF_MAXIMUM_NUMBER_OF_ATTEMPTS_EXCEEDED, HANDOFF_WRONG_DOB } from '../../../../utils/MixpanelEvents';
import { Trans, useTranslation } from 'react-i18next';

const ConfirmYourIdentity: React.FunctionComponent<ConfirmYourIdentityProps> = ({
  scanRequestID,
  physicianDataToDisplay,
  attemptsSubmitted,
  completeStepCallback,
  mixpanelHandoffPayload,
}) => {
  const { prescriberName, prescriberPhone, physicianOrganizationName } = physicianDataToDisplay;
  const [formValue, setFormValue] = useState<{ DOB: string }>({ DOB: '' });
  const [formError, setFormError] = useState<FormError>({});
  const [isLoading, setIsLoading] = useState(false);
  const [attemptsLeft, setAttemptsLeft] = useState(5);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const { invitationID } = useParams<{ invitationID: string }>();
  const mixpanel = useContext(MixpanelContext);
  const { t } = useTranslation('handoff');

  const isConfirmEnabled = useMemo(() => !!formValue.DOB && !Object.keys(formError).length, [formValue.DOB, formError]);

  useEffect(() => {
    setAttemptsLeft(5 - attemptsSubmitted);
  }, [attemptsSubmitted]);

  const errorTexts = useMemo<ErrorBoxProps | null>(() => {
    if (!hasSubmitted) {
      return null;
    }
    if (attemptsLeft === 0) {
      return { title: t('MAXIMUM_ATTEMPTS_TITLE'), content: t('MAXIMUM_ATTEMPTS_TEXT') };
    }
    return { title: t('WRONG_DOB_TITLE'), content: t('WRONG_DOB_TEXT') };
  }, [hasSubmitted, attemptsLeft]);

  const handleConfirm = (): void => {
    setIsLoading(true);
    setHasSubmitted(true);
    validateDateOfBirth({ DOB: transformDate(formValue.DOB, 'YYYY/M/D'), InvitationID: invitationID })
      .then(handleDOBvalidationResponse)
      .catch(handleMaximumAttemptsExceeded);
  };

  const handleDOBvalidationResponse = (response: boolean): void => {
    if (!response) {
      mixpanel.track(HANDOFF_WRONG_DOB, { ...formValue, ...mixpanelHandoffPayload });
      setAttemptsLeft(current => current - 1);
      setIsLoading(false);
      return;
    }
    completeStepCallback({ PatientDOBValidated: transformDate(formValue.DOB, 'YYYY-MM-DD') }, HANDOFF_STEP_NAMES.confirmYourIdentity);
  };

  const handleMaximumAttemptsExceeded = (error: Error): void => {
    if (error.message === 'The number of attempts has exceeded 5') {
      mixpanel.track(HANDOFF_MAXIMUM_NUMBER_OF_ATTEMPTS_EXCEEDED, mixpanelHandoffPayload);
      setAttemptsLeft(current => current - 1);
      setIsLoading(false);
    }
  };

  const onErrorHandler = (error: any) => {
    if (error && error.DOB === DOBInvalidMessage) {
      const message = t(DOBInvalidMessage);
      return setFormError({ ...error, DOB: message });
    } else if (error.DOB === 'DOB must be a date') {
      const message = t('DOB must be a date');
      return setFormError({ ...error, DOB: message });
    } else if (error.DOB === 'Date of birth cannot be a future date') {
      const message = t('Date of birth cannot be a future date');
      return setFormError({ ...error, DOB: message });
    } else if (error.DOB === 'Please enter a valid date of birth (i.e. mm/dd/yyyy)') {
      const message = t('Please enter a valid date of birth');
      return setFormError({ ...error, DOB: message });
    }
    setFormError(error);
  };

  if (isLoading) {
    return <LoaderComponent className='loader-block-center' />;
  }

  return (
    <>
      <div className='request-type handoff-process'>
        <div className='scan-service-edit_header'>
          <h2 className='h2'>{t('Confirm your identity')}</h2>
          <div className='row request-id'>
            <div className='box'>
              <div className='title'>{t('Request ID')}</div>
              <div className='num semi-bold'>{scanRequestID}</div>
            </div>
          </div>
        </div>
        <div className='text-wrap semi-bold'>
          <span className='line'>
            {t('From Dr')}. {prescriberName}
          </span>
          <span className='line'>
            {physicianOrganizationName} {prescriberPhone}
          </span>
        </div>
        <div className='text-wrap'>
          <p>{t('identity_text')}</p>
        </div>
        {attemptsLeft > 0 && (
          <div className='text-wrap'>
            <p>
              {t('Attempts left')}: {attemptsLeft}
            </p>
          </div>
        )}
        <Form
          model={ConfirmYourIdentitySchema}
          formValue={formValue}
          onChange={formValue => setFormValue(formValue as { DOB: string })}
          onCheck={error => onErrorHandler(error)}
          fluid
        >
          {attemptsLeft > 0 && (
            <TextField
              type='tel'
              name='DOB'
              accepter={InputMask}
              label={t('Date of birth') || ''}
              error={formError.DOB}
              value={formValue.DOB}
              mask={[/[0-9]/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
              format='MM/DD/YYYY'
              autoComplete='off'
              placeholder='MM/DD/YYYY'
            />
          )}
          {errorTexts && <ErrorBox title={errorTexts.title} content={errorTexts.content} />}
          {attemptsLeft > 0 && (
            <div className='btn-row row justify-content-between'>
              <button className='btn next' onClick={handleConfirm} disabled={!isConfirmEnabled}>
                {t('Confirm', { ns: 'translations' })}
              </button>
            </div>
          )}
        </Form>
      </div>
      <InfoBox
        title={t('question.title', { ns: 'translations' })}
        content={
          <Trans
            i18nKey='question.text'
            values={{ email: 'support@medmo.com', phone: '8334463366', phone_format: '(833) 446-3366' }}
            components={{ linkTo: <a /> }}
          />
        }
      />
    </>
  );
};

export default ConfirmYourIdentity;
