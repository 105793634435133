import { Schema } from 'rsuite';

const { StringType, DateType } = Schema.Types;

export const EditPersonalInfoSchema = Schema.Model({
  FirstName: StringType().isRequired('This field is required.'),
  LastName: StringType().isRequired('This field is required.'),
  DOB: DateType('Invalid date format. Please enter the date in the format “MM/DD/YY”.')
    .isRequired('Please enter a valid date of birth (i.e. mm/dd/yyyy).')
    .max(new Date(), 'Date of birth cannot be a future date'),
  Email: StringType().isEmail('Please enter a valid email address').isRequired('This field is required.'),
  Phone: StringType()
    .pattern(/^(\()?\d{3}(\))?(-|\s)?\d{3}(-|\s)\d{4}$/, 'Please enter a valid phone number.')
    .isRequired('This field is required.'),
});

export const EditPasswordSchema = Schema.Model({
  oldPassword: StringType('Please enter 8-digit identifier').isRequired('This field is required.'),
  newPassword: StringType('Please enter 8-digit identifier')
    .isRequired('This field is required.')
    .minLength(8, 'The field cannot be less than 8 characters')
    .containsNumber('Must contain numbers')
    .containsUppercaseLetter('Must contain 1 capital letter')
    .addRule(value => {
      return /[!%&'()*+./;<=>?\\,/:#@"\\[\]_\u007B-\u00BF-]/.test(value);
    }, 'Must contain 1 special character'),
  confirmPassword: StringType('Please enter 8-digit identifier')
    .isRequired('This field is required.')
    .minLength(8, 'The field cannot be less than 8 characters')
    .containsNumber('Must contain numbers')
    .containsUppercaseLetter('Must contain 1 capital letter')
    .addRule(value => {
      return /[!%&'()*+./;<=>?\\,/:#@"\\[\]_\u007B-\u00BF-]/.test(value);
    }, 'Must contain 1 special character')
    .addRule((value, data) => {
      if (value !== data.newPassword) {
        return false;
      }
      return true;
    }, 'The passwords are not equal'),
});
