import { SERVER_URL } from '../../config/ServerConfig';
import { ScanRequest } from '../../models/ScanRequest';
import { request } from '../Request';
import { ScanRequestContactMethods, ScanManagmentResponse } from './interfaces';
import { ServerSuccess } from '../../models/General';
import { ScanEditType } from '../../views/scan-edit/utils';
import { SuccessResponse } from '../../models/SuccessResponse';
import { ViewSource } from '../../models/ViewSource';

export function createHealthyrScanRequest(scanRequest: ScanRequest): Promise<{ success: boolean }> {
  return request({
    url: `${SERVER_URL}/scan-requests/healthyr`,
    method: 'POST',
    body: JSON.stringify(scanRequest),
  });
}

export function createScanRequest(scanRequest: ScanRequest): Promise<ScanRequest> {
  return request({
    url: `${SERVER_URL}/scan-requests`,
    method: 'POST',
    body: JSON.stringify(scanRequest),
  });
}

export function addContactMethodsToScanRequest(payload: ScanRequestContactMethods, source: ViewSource): Promise<SuccessResponse> {
  return request({
    method: 'POST',
    url: `${SERVER_URL}/scan-requests/add-contact-methods?source=${source}`,
    body: JSON.stringify(payload),
  });
}

export function getScanById(id: string): Promise<ScanRequest> {
  return request({
    url: `${SERVER_URL}/scan-requests/${id}`,
    method: 'GET',
  });
}

export function editScanById(id: string, type: ScanEditType, data: Partial<ScanRequest>): Promise<ServerSuccess> {
  return request({
    url: `${SERVER_URL}/scan-requests/${id}/${type}`,
    method: 'PUT',
    body: JSON.stringify(data),
  });
}

export function getScanManagment(): Promise<ScanManagmentResponse> {
  return request({
    url: `${SERVER_URL}/scan-requests`,
    method: 'GET',
  });
}

export function requestReschedule(id: number, payload: { reason: string | undefined }): Promise<boolean> {
  return request({
    url: `${SERVER_URL}/scan-requests/${id}/reschedule`,
    method: 'POST',
    body: JSON.stringify(payload),
  });
}

export function requestCancel(id: number, payload: { reason: string | undefined }): Promise<boolean> {
  return request({
    url: `${SERVER_URL}/scan-requests/${id}/cancel`,
    method: 'POST',
    body: JSON.stringify(payload),
  });
}
