import { Schema } from 'rsuite';
const { StringType } = Schema.Types;

export type GeneralPingProps = {
  question: string;
  goToPreviousStep: (stepName?: string) => void;
  scanRequestData: GeneralQuestionData;
  completeStepCallback: (partialScanData: Partial<GeneralQuestionData>, stepName?: string) => void;
};

export type GeneralQuestionData = {
  GeneralAnswer: string;
};

export const GENERAL_QUESTION_STEP_NAMES = {
  question: 'GENERAL_QUESTION',
};

export type GeneralQuestionForm = {
  GeneralAnswer: string;
};

export const QuestionInvalidMessage = 'question_invalid_message';
export const QuestionSchema = Schema.Model({
  GeneralAnswer: StringType(QuestionInvalidMessage)
    .isRequired('This field is required')
    .maxLength(4000, 'Text should be less or equal to 4000 characters'),
});
