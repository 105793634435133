import React from 'react';
import { CLEERLY_SELF_SERVICE_STEP_NAMES } from '../utils';
import { CustomCleerlyProps } from '../interfaces';

const CustomInstructions: React.FunctionComponent<CustomCleerlyProps> = ({ completeStepCallback }) => {
  return (
    <div className='request-type handoff-process'>
      <div className='scan-service-edit_header'>
        <h2 className='h2'>Instructions to purchase your Cleerly exam</h2>
      </div>
      <div className='how-medmo-works semi-bold'>
        <p>This form allows you to purchase a Cleerly exam for your CCTA scan.</p>
      </div>
      <div className='how-medmo-works'>
        <div className='row info'>
          <div className='circle-container'>
            <div className='circle'>
              <span className='number semi-bold'>1</span>
            </div>
          </div>
          <div className='texts'>
            <p>Fill out the following form for your Cleerly exam.</p>
          </div>
        </div>
        <div className='row info'>
          <div className='circle-container'>
            <div className='circle'>
              <span className='number semi-bold'>2</span>
            </div>
          </div>
          <div className='texts'>
            <p>Complete payment to purchase your Cleerly exam. You’ll receive a payment voucher.</p>
          </div>
        </div>
        <div className='row info'>
          <div className='circle-container'>
            <div className='circle'>
              <span className='number semi-bold'>3</span>
            </div>
          </div>
          <div className='texts'>
            <p>
              Your CCTA will be shared with Cleerly to run their analysis. Your analysis will be reviewed with you and a provider when it’s
              ready.
            </p>
          </div>
        </div>
      </div>
      <div className='btn-row row justify-content-between full-mob'>
        <button className='btn next' onClick={() => completeStepCallback({}, CLEERLY_SELF_SERVICE_STEP_NAMES.instructions)}>
          Get started
        </button>
      </div>
    </div>
  );
};

export default CustomInstructions;
