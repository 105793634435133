import { request } from '../Request';
import { SERVER_URL } from '../../config/ServerConfig';
import { CreateAccountFormData, CreateAccWithSocial } from '../../views/create-account/interfaces';
import { PersonalInfoFormData } from '../../views/settings/interfaces';
import { ConnectSocialDataType } from '../../views/settings/utils';
import { Patient } from '../../models/Patient';

export function registerNewUser(data: CreateAccountFormData): Promise<Patient> {
  return request({
    method: 'POST',
    url: `${SERVER_URL}/users`,
    body: JSON.stringify(data),
  });
}

export function registerNewUserWithSocial(data: CreateAccWithSocial): Promise<Patient> {
  return request({
    method: 'POST',
    url: `${SERVER_URL}/users/social/register`,
    body: JSON.stringify(data),
  });
}

export function loginWithSocial(data: { ProviderName: string; AccessToken: string }): Promise<Patient> {
  return request({
    method: 'POST',
    url: `${SERVER_URL}/users/social/login`,
    body: JSON.stringify(data),
  });
}

export function removeSocialAccount(social: string): Promise<{ success: boolean; message: string }> {
  return request({
    method: 'DELETE',
    url: `${SERVER_URL}/users/social?ProviderName=${social}`,
  });
}

export function connectSocial(data: ConnectSocialDataType): Promise<{ success: boolean; message: string }> {
  return request({
    method: 'POST',
    url: `${SERVER_URL}/users/social`,
    body: JSON.stringify(data),
  });
}

export function updatePatientInfo(data: PersonalInfoFormData): Promise<{ ID: number }> {
  return request({
    url: `${SERVER_URL}/users`,
    method: 'PUT',
    body: JSON.stringify(data),
  });
}

export function updatePassword(data: { oldPassword: string; newPassword: string }): Promise<{ success: boolean; message: string }> {
  return request({
    url: `${SERVER_URL}/users/password`,
    method: 'PUT',
    body: JSON.stringify(data),
  });
}

export function createUserSessionLog(SessionTime: string, UtcOffset: number): Promise<{ id: number | null }> {
  return request({
    method: 'POST',
    url: `${SERVER_URL}/users/session-log`,
    body: JSON.stringify({ SessionTime, UtcOffset }),
  });
}
