import { request } from '../Request';
import { SERVER_URL } from '../../config/ServerConfig';
import { SuccessResponse } from '../../models/SuccessResponse';

export function subscribe(hash: string): Promise<SuccessResponse> {
  return request({
    url: `${SERVER_URL}/sms/${hash}`,
    method: 'POST',
    body: JSON.stringify({}),
  });
}

export function unsubscribe(hash: string): Promise<SuccessResponse> {
  return request({
    url: `${SERVER_URL}/sms/${hash}`,
    method: 'DELETE',
  });
}
