import React from 'react';
import { APP_CORP_WEB_URL } from '../../config/FrontConfig';
import { useTranslation } from 'react-i18next';

const InvalidHandoffExpiredLink: React.FunctionComponent = () => {
  const { t } = useTranslation('handoff');
  const contactSupport = (): void => {
    window.open(`${APP_CORP_WEB_URL}/contact-us/`);
  };

  return (
    <div className='request-type handoff-process'>
      <h2 className='h2'>{t('invalid_link.title')}</h2>
      <div className='request-warning'>
        <span className='warning-icon'></span>
        <div className='request-warning-text'>
          <div className='title'>{t('invalid_link.sub_title')}</div>
          <div className='text'>
            <p>{t('invalid_link.text')}</p>
          </div>
          <div className='btn-row row justify-content-between'>
            <button className='btn' onClick={contactSupport}>
              {t('invalid_link.button')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvalidHandoffExpiredLink;
