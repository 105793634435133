import { SERVER_URL } from '../../config/ServerConfig';
import { FileResponse } from '../../models/FileResponse';
import { InsurancePlan } from '../../models/InsurancePlan';
import { ViewSource } from '../../models/ViewSource';
import { request } from '../Request';

export function uploadInsuranceCardFile(data: FormData, source: ViewSource): Promise<FileResponse> {
  return request({
    url: `${SERVER_URL}/insurance/upload-card?source=${source}`,
    method: 'POST',
    body: data,
    headers: {
      Expires: '-1',
      'Cache-Control': 'no-cache',
    },
  });
}

export function uploadInsurancePlan(name: string): Promise<InsurancePlan> {
  return request({
    url: `${SERVER_URL}/insurance/plans`,
    method: 'POST',
    body: JSON.stringify({ PolicyName: name }),
  });
}

export function getInsurancePlansAPI(): Promise<InsurancePlan[]> {
  return request({
    url: `${SERVER_URL}/insurance/plans`,
    method: 'GET',
  });
}
