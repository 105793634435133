import { ResponseCustomError, ResponseErrorType } from './interfaces';

export default class ResponseError extends Error implements ResponseErrorType {
  private _name: string;
  private _status: number;
  private _errors: Record<string, string>;

  constructor({ status, message, errors }: ResponseCustomError) {
    super(message);
    this._name = 'ResponseError';
    this._status = status;
    const errorData: Record<string, string> = {};

    errors?.forEach(({ path, message }) => {
      let field = path;
      if (path.includes('.')) {
        const arr = path.split('.');
        field = arr[arr.length - 1];
      }
      errorData[field] = message;
    });

    this._errors = errorData;
  }

  get name(): string {
    return this._name;
  }

  get status(): number {
    return this._status;
  }

  get errors(): Record<string, string> {
    return this._errors;
  }
}
