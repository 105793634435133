import React, { useCallback, useEffect, useMemo, useState } from 'react';
import InfoBox from '../info-box';
import { Trans, useTranslation } from 'react-i18next';
import { initSurgeryGeneralData, PING_SURGERY_STEP_NAMES, SurgeryData, SurgeryGeneralData, SurgeryWrapperProps } from './interfaces';
import BodySurgery from './body';
import SurgeryGeneralDetails from './details-surgery';

const SurgeryWrapper: React.FunctionComponent<SurgeryWrapperProps> = ({
  goToPreviousStep,
  scanRequestData,
  completeStepCallback,
}: SurgeryWrapperProps) => {
  const { t } = useTranslation('translations');
  const { SurgeryPast90Days, SurgeryDetails } = scanRequestData;
  const [surgeryData, setSurgeryData] = useState<SurgeryGeneralData>(() => {
    const newData = {
      SurgeryPast90Days,
      SurgeryDetails,
    };
    return {
      ...initSurgeryGeneralData,
      currentStep: 1,
      data: { ...initSurgeryGeneralData.data, ...newData },
    };
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [surgeryData.currentStep]);

  const previousStep = useCallback(
    (stepName?: string): void => {
      if (PING_SURGERY_STEP_NAMES.surgery === stepName) {
        return goToPreviousStep(PING_SURGERY_STEP_NAMES.surgery);
      }

      setSurgeryData(({ currentStep, data }) => {
        const prevStep = 1;
        return {
          data,
          currentStep: currentStep - prevStep,
          errorSubmitting: false,
        };
      });
    },
    [surgeryData],
  );

  const completeStep = useCallback(
    (stepMetadata?: Partial<SurgeryData>, stepName?: string): void => {
      if (PING_SURGERY_STEP_NAMES.surgery === stepName && stepMetadata?.SurgeryPast90Days === 0) {
        stepMetadata = { ...stepMetadata, SurgeryDetails: '' };
        const newData = { ...surgeryData.data, ...stepMetadata };
        return completeStepCallback(newData, stepName);
      } else if (PING_SURGERY_STEP_NAMES.surgeryDetails === stepName) {
        stepMetadata = { ...stepMetadata };
        const newData = { ...surgeryData.data, ...stepMetadata };
        return completeStepCallback(newData, stepName);
      }

      setSurgeryData(({ currentStep, data }) => {
        const nextStep = 1;
        const newData = { ...data, ...stepMetadata };
        return {
          data: newData,
          currentStep: currentStep + nextStep,
          errorSubmitting: false,
        };
      });
    },
    [surgeryData],
  );

  const currentStepDisplayed = useMemo<React.ReactNode | null>(() => {
    const { currentStep, data } = surgeryData;
    if (!surgeryData) {
      return null;
    }
    switch (currentStep) {
      case 1: {
        return (
          <BodySurgery
            goToPreviousStep={previousStep}
            completeStepCallback={completeStep}
            scanRequestData={data}
            stepName={PING_SURGERY_STEP_NAMES.surgery}
          />
        );
      }
      case 2:
        return (
          <SurgeryGeneralDetails
            goToPreviousStep={previousStep}
            completeStepCallback={completeStep}
            scanRequestData={data}
            stepName={PING_SURGERY_STEP_NAMES.surgeryDetails}
          />
        );
      default:
        return null;
    }
  }, [surgeryData]);

  return (
    <>
      <>{currentStepDisplayed}</>
      <InfoBox
        title={t('box.WHY_DO_YOU_NEED_INFOBOX_DATA.title')}
        content={<Trans i18nKey='box.WHY_DO_YOU_NEED_INFOBOX_DATA.content' components={{ br: <br /> }} />}
      />
    </>
  );
};

export default SurgeryWrapper;
