import { request } from '../Request';
import { SERVER_URL } from '../../config/ServerConfig';
import { DOBForValidation } from '../handoff-process/interfaces';
import { FileResponse } from '../../models/FileResponse';
import { LANGUAGE } from '../../i18n/config';

export function getPingInfoAPI(invitationID: string): Promise<any> {
  return request({
    url: `${SERVER_URL}/patient-ping-process/${invitationID}`,
    method: 'GET',
  });
}

export function getPingProfileInfoAPI(invitationID: string): Promise<any> {
  return request({
    url: `${SERVER_URL}/patient-ping-process/${invitationID}/profile`,
    method: 'GET',
  });
}

export function validatePatientDateOfBirth(payload: DOBForValidation): Promise<boolean> {
  return request({
    url: `${SERVER_URL}/patient-ping-process/validate-date-of-birth`,
    method: 'POST',
    body: JSON.stringify(payload),
  });
}

export function updatePatientPingAPI(invitationID: string, payload: any, lang: string): Promise<boolean> {
  const hl_id = LANGUAGE[lang] && LANGUAGE[lang].id ? LANGUAGE[lang].id : 1;
  return request({
    url: `${SERVER_URL}/patient-ping-process/${invitationID}?hlid=${hl_id}`,
    method: 'POST',
    body: JSON.stringify(payload),
  });
}

export function updatePartialPatientPingAPI(invitationID: string, payload: any, lang: string): Promise<boolean> {
  const hl_id = LANGUAGE[lang] && LANGUAGE[lang].id ? LANGUAGE[lang].id : 1;
  return request({
    url: `${SERVER_URL}/patient-ping-process/${invitationID}/partial?hlid=${hl_id}`,
    method: 'POST',
    body: JSON.stringify(payload),
  });
}

export function uploadPacemakerFile(data: FormData): Promise<FileResponse> {
  return request({
    url: `${SERVER_URL}/patient-ping-process/upload-pacemaker`,
    method: 'POST',
    body: data,
    headers: {
      Expires: '-1',
      'Cache-Control': 'no-cache',
    },
  });
}
