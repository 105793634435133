import React, { useCallback, useEffect, useMemo, useState } from 'react';
import InfoBox from '../info-box';
import { Trans, useTranslation } from 'react-i18next';
import {
  ChemotherapyData,
  ChemotherapyGeneralData,
  initChemotherapyGeneralData,
  PING_CHEMOTHERAPY_STEP_NAMES,
  WrapperProps,
} from './interfaces';
import ChemotherapyBody from './body';
import ChemotherapyDetails from './details';

const ChemotherapyWrapper: React.FunctionComponent<WrapperProps> = ({
  goToPreviousStep,
  scanRequestData,
  completeStepCallback,
}: WrapperProps) => {
  const { t } = useTranslation('translations');
  const { Chemotherapy, CompleteChemotherapy } = scanRequestData;
  const [chemotherapyData, setChemotherapyData] = useState<ChemotherapyGeneralData>(() => {
    const newData = {
      Chemotherapy,
      CompleteChemotherapy,
    };
    return {
      ...initChemotherapyGeneralData,
      currentStep: 1,
      data: { ...initChemotherapyGeneralData.data, ...newData },
    };
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [chemotherapyData.currentStep]);

  const previousStep = useCallback(
    (stepName?: string): void => {
      if (PING_CHEMOTHERAPY_STEP_NAMES.chemotherapy === stepName) {
        return goToPreviousStep(PING_CHEMOTHERAPY_STEP_NAMES.chemotherapy);
      }

      setChemotherapyData(({ currentStep, data }) => {
        const prevStep = 1;
        return {
          data,
          currentStep: currentStep - prevStep,
          errorSubmitting: false,
        };
      });
    },
    [chemotherapyData],
  );

  const completeStep = useCallback(
    (stepMetadata?: Partial<ChemotherapyData>, stepName?: string): void => {
      if (
        PING_CHEMOTHERAPY_STEP_NAMES.chemotherapy === stepName &&
        (stepMetadata?.Chemotherapy === 0 || stepMetadata?.Chemotherapy === 2)
      ) {
        stepMetadata = { ...stepMetadata, CompleteChemotherapy: null };
        const newData = { ...chemotherapyData.data, ...stepMetadata };
        return completeStepCallback(newData, stepName);
      } else if (PING_CHEMOTHERAPY_STEP_NAMES.chemotherapyDetails === stepName) {
        stepMetadata = { ...stepMetadata };
        const newData = { ...chemotherapyData.data, ...stepMetadata };
        return completeStepCallback(newData, stepName);
      }

      setChemotherapyData(({ currentStep, data }) => {
        const nextStep = 1;
        const newData = { ...data, ...stepMetadata };
        return {
          data: newData,
          currentStep: currentStep + nextStep,
          errorSubmitting: false,
        };
      });
    },
    [chemotherapyData],
  );

  const currentStepDisplayed = useMemo<React.ReactNode | null>(() => {
    const { currentStep, data } = chemotherapyData;
    if (!chemotherapyData) {
      return null;
    }
    switch (currentStep) {
      case 1: {
        return (
          <ChemotherapyBody
            goToPreviousStep={previousStep}
            completeStepCallback={completeStep}
            scanRequestData={data}
            stepName={PING_CHEMOTHERAPY_STEP_NAMES.chemotherapy}
          />
        );
      }
      case 2:
        return (
          <ChemotherapyDetails
            goToPreviousStep={previousStep}
            completeStepCallback={completeStep}
            scanRequestData={data}
            stepName={PING_CHEMOTHERAPY_STEP_NAMES.chemotherapyDetails}
          />
        );
      default:
        return null;
    }
  }, [chemotherapyData]);

  return (
    <>
      <>{currentStepDisplayed}</>
      <InfoBox
        title={t('box.WHY_DO_YOU_NEED_INFOBOX_DATA.title')}
        content={<Trans i18nKey='box.WHY_DO_YOU_NEED_INFOBOX_DATA.content' components={{ br: <br /> }} />}
      />
    </>
  );
};

export default ChemotherapyWrapper;
