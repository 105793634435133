import React, { useMemo, useState } from 'react';
import { AvailabilityForm, AvailabilityProps } from './interfaces';
import WarningBox from '../warning-box';
import Checkbox from 'rsuite/esm/Checkbox';
import Textarea from '../textarea';
import TextField from '../text-field-component';
import { Form } from 'rsuite';
import { useTranslation } from 'react-i18next';
import { PING_STEP_NAMES } from '../../views/patient-ping/utils';

const Availability: React.FunctionComponent<AvailabilityProps> = ({
  goToPreviousStep,
  scanRequestData,
  completeStepCallback,
  moduleData = null,
}: AvailabilityProps) => {
  const { t } = useTranslation('handoff');
  const {
    isMondaySelected,
    isTuesdaySelected,
    isWednesdaySelected,
    isThursdaySelected,
    isFridaySelected,
    isSaturdaySelected,
    isSundaySelected,
    isAllDaySelected,
    isMorningSelected,
    isAfternoonSelected,
    isEveningSelected,
    AvailableNotes,
  } = scanRequestData;

  const [formValue, setFormValue] = useState<AvailabilityForm>({
    Mo: isMondaySelected || false,
    Tu: isTuesdaySelected || false,
    We: isWednesdaySelected || false,
    Th: isThursdaySelected || false,
    Fr: isFridaySelected || false,
    Sa: isSaturdaySelected || false,
    AllDay: isAllDaySelected || false,
    Morning: isMorningSelected || false,
    Afternoon: isAfternoonSelected || false,
    Evening: isEveningSelected || false,
    AvailableNotes: AvailableNotes,
  });

  const OnHandleNext = () => {
    const data = {
      isMondaySelected: formValue.Mo,
      isTuesdaySelected: formValue.Tu,
      isWednesdaySelected: formValue.We,
      isThursdaySelected: formValue.Th,
      isFridaySelected: formValue.Fr,
      isSaturdaySelected: formValue.Sa,
      isSundaySelected: isSundaySelected || false,
      isAllDaySelected: formValue.AllDay,
      isMorningSelected: formValue.Morning,
      isAfternoonSelected: formValue.Afternoon,
      isEveningSelected: formValue.Evening,
      AvailableNotes: formValue.AvailableNotes,
    };
    completeStepCallback(data, PING_STEP_NAMES.availability);
  };

  const isDayEnabled = useMemo<boolean>(() => {
    const { Mo, Tu, We, Th, Fr, Sa } = formValue;
    return Mo || Tu || We || Th || Fr || Sa;
  }, [formValue]);

  const isConfirmEnabled = useMemo<boolean>(() => {
    const { AllDay, Morning, Afternoon, Evening, Mo, Tu, We, Th, Fr, Sa } = formValue;
    return (AllDay || Morning || Afternoon || Evening) && (Mo || Tu || We || Th || Fr || Sa);
  }, [formValue]);

  const setNewValueTime = (previous: AvailabilityForm, property: 'AllDay' | 'Morning' | 'Afternoon' | 'Evening') => {
    if (!previous[property]) {
      if (property === 'AllDay') {
        return {
          ...previous,
          ['Morning']: false,
          ['Afternoon']: false,
          ['Evening']: false,
          [property]: !previous[property],
        };
      } else if (property === 'Morning' && previous['Afternoon'] && previous['Evening']) {
        return {
          ...previous,
          ['Morning']: false,
          ['Afternoon']: false,
          ['Evening']: false,
          ['AllDay']: true,
        };
      } else if (property === 'Afternoon' && previous['Morning'] && previous['Evening']) {
        return {
          ...previous,
          ['Morning']: false,
          ['Afternoon']: false,
          ['Evening']: false,
          ['AllDay']: true,
        };
      } else if (property === 'Evening' && previous['Afternoon'] && previous['Morning']) {
        return {
          ...previous,
          ['Morning']: false,
          ['Afternoon']: false,
          ['Evening']: false,
          ['AllDay']: true,
        };
      }
    }

    return {
      ...previous,
      [property]: !previous[property],
    };
  };

  return (
    <div className='main-request-form'>
      <h2>{t('availability.title')}</h2>
      <div className='text-form'>
        <p>{t('availability.please')}</p>
      </div>
      {moduleData?.ModuleMetadata?.CustomNote ? (
        <WarningBox>
          <p>
            <strong>{t('availability.Scheduling notes from Medmo Care')} - </strong>
            {moduleData.ModuleMetadata.CustomNote}
          </p>
        </WarningBox>
      ) : null}
      <h3>{t('availability.Preferred days')}</h3>
      <Form onChange={value => setFormValue(value as AvailabilityForm)} formValue={formValue} className='main-form-appointment' fluid>
        <ul className='preferred-days'>
          <li>
            <Checkbox
              name='pr-day'
              checked={formValue.Mo as boolean}
              onChange={() =>
                setFormValue(previous => ({
                  ...previous,
                  Mo: !previous.Mo,
                }))
              }
            >
              {t('availability.Mo')}
            </Checkbox>
          </li>
          <li>
            <Checkbox
              name='pr-day'
              checked={formValue.Tu as boolean}
              onChange={() =>
                setFormValue(previous => ({
                  ...previous,
                  Tu: !previous.Tu,
                }))
              }
            >
              {t('availability.Tu')}
            </Checkbox>
          </li>
          <li>
            <Checkbox
              name='pr-day'
              checked={formValue.We as boolean}
              onChange={() =>
                setFormValue(previous => ({
                  ...previous,
                  We: !previous.We,
                }))
              }
            >
              {t('availability.We')}
            </Checkbox>
          </li>
          <li>
            <Checkbox
              name='pr-day'
              checked={formValue.Th as boolean}
              onChange={() =>
                setFormValue(previous => ({
                  ...previous,
                  Th: !previous.Th,
                }))
              }
            >
              {t('availability.Th')}
            </Checkbox>
          </li>
          <li>
            <Checkbox
              name='pr-day'
              checked={formValue.Fr as boolean}
              onChange={() =>
                setFormValue(previous => ({
                  ...previous,
                  Fr: !previous.Fr,
                }))
              }
            >
              {t('availability.Fr')}
            </Checkbox>
          </li>
          <li>
            <Checkbox
              name='pr-day'
              checked={formValue.Sa as boolean}
              onChange={() =>
                setFormValue(previous => ({
                  ...previous,
                  Sa: !previous.Sa,
                }))
              }
            >
              {t('availability.Sa')}
            </Checkbox>
          </li>
        </ul>
        {isDayEnabled ? (
          <>
            <h3 className='mb8'>{t('availability.Preferred times')} </h3>
            <div className='text-form gray mb12'>
              <p>{t('availability.Applies to all days selected above. Exceptions can be noted in the box below.')}</p>
            </div>
            <div className='input-checkbox mb6'>
              <Checkbox
                name='times-day'
                checked={formValue.AllDay as boolean}
                onChange={() => setFormValue(previous => setNewValueTime(previous, 'AllDay'))}
              >
                {t('availability.All day')}
              </Checkbox>
            </div>
            <div className='input-checkbox mb6'>
              <Checkbox
                name='times-day'
                checked={formValue.Morning as boolean}
                disabled={formValue.AllDay}
                onChange={() => setFormValue(previous => setNewValueTime(previous, 'Morning'))}
              >
                {t('availability.Morning (8 AM - 11 AM)')}
              </Checkbox>
            </div>
            <div className='input-checkbox mb6'>
              <Checkbox
                name='times-day'
                disabled={formValue.AllDay}
                checked={formValue.Afternoon as boolean}
                onChange={() => setFormValue(previous => setNewValueTime(previous, 'Afternoon'))}
              >
                {t('availability.Afternoon (11 AM - 3 PM)')}
              </Checkbox>
            </div>
            <div className='input-checkbox mb16'>
              <Checkbox
                name='times-day'
                disabled={formValue.AllDay}
                checked={formValue.Evening as boolean}
                onChange={() => setFormValue(previous => setNewValueTime(previous, 'Evening'))}
              >
                {t('availability.Evening (3 PM - 6 PM)')}
              </Checkbox>
            </div>
          </>
        ) : null}
        <div className='text-form mb16'>
          <p>{t('availability.Please provide any additional information to Medmos Care team')}</p>
        </div>
        <div className='input-wrap textarea-wrap h80 mb32'>
          <TextField
            placeholder={`${t('availability.Availability of day of the week, transportation needs, language concerns, etc.')}`}
            accepter={Textarea}
            value={formValue.AvailableNotes}
            name='AvailableNotes'
          />
        </div>
      </Form>
      <div className='btn-row row justify-content-between full-mob'>
        <button onClick={() => goToPreviousStep(PING_STEP_NAMES.availability)} className='btn prev btn-white'>
          {t('form.Back', { ns: 'translations' })}
        </button>
        <button className='btn next' disabled={!isConfirmEnabled} onClick={OnHandleNext}>
          {t('form.Next', { ns: 'translations' })}
        </button>
      </div>
    </div>
  );
};

export default Availability;
