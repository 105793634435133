import React, { useCallback, useEffect, useMemo, useState } from 'react';
import InfoBox from '../info-box';
import { Trans, useTranslation } from 'react-i18next';
import {
  initMetalBodyGeneralData,
  initMetalBodyLocationData,
  MetalBodyData,
  MetalBodyGeneralData,
  MetalBodyProps,
  PING_METAL_STEP_NAMES,
  resetMetalBodyTypeData,
} from './interfaces';
import MetalBodyPaceMaker from './metal-body-pacemaker';
import MetalBodyQuestions from './metal-body-question';
import MetalBodyType from './metal-body-type';
import MetalBodyLocation from './metal-body-location';
import UploadPacemaker from './upload-pacemaker';

const MetalBodyWrapper: React.FunctionComponent<MetalBodyProps> = ({ goToPreviousStep, completeStepCallback }: MetalBodyProps) => {
  const { t } = useTranslation('translations');
  const [metalBodyData, setMetalBodyData] = useState<MetalBodyGeneralData>(() => {
    return {
      ...initMetalBodyGeneralData,
      currentStep: 1,
      data: { ...initMetalBodyGeneralData.data },
    };
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [metalBodyData.currentStep]);

  const previousStep = useCallback(
    (stepName?: string): void => {
      if (PING_METAL_STEP_NAMES.metalBodyQuestion === stepName) {
        return goToPreviousStep(PING_METAL_STEP_NAMES.metalBodyQuestion);
      }

      setMetalBodyData(({ currentStep, data }) => {
        let prevStep = 1;
        if (PING_METAL_STEP_NAMES.metalBodyPacemaker === stepName && !data.MetalBodyQuestionID) {
          prevStep = 3;
        }
        return {
          data,
          currentStep: currentStep - prevStep,
          errorSubmitting: false,
        };
      });
    },
    [metalBodyData],
  );

  const completeStep = useCallback(
    (stepMetadata?: Partial<MetalBodyData>, stepName?: string): void => {
      if (PING_METAL_STEP_NAMES.metalBodyType === stepName && stepMetadata?.MetalBodyQuestionID === 0) {
        stepMetadata = { ...stepMetadata, ...initMetalBodyLocationData, ...resetMetalBodyTypeData };
      } else if (PING_METAL_STEP_NAMES.metalBodyQuestion === stepName && stepMetadata?.MetalBodyQuestionID === 0) {
        stepMetadata = { ...stepMetadata, ...initMetalBodyLocationData, ...resetMetalBodyTypeData };
      }
      if (
        PING_METAL_STEP_NAMES.metalBodyUploadPacemaker === stepName ||
        (PING_METAL_STEP_NAMES.metalBodyPacemaker === stepName && stepMetadata?.HasPacemakerOrDefibrillatorInBody === 0)
      ) {
        const newData = { ...metalBodyData.data, ...stepMetadata };
        return completeStepCallback(newData, stepName);
      }

      setMetalBodyData(({ currentStep, data }) => {
        let nextStep = 1;
        const newData = { ...data, ...stepMetadata };
        if (PING_METAL_STEP_NAMES.metalBodyQuestion === stepName && !newData.MetalBodyQuestionID) {
          nextStep += 2;
        }
        return {
          data: newData,
          currentStep: currentStep + nextStep,
          errorSubmitting: false,
        };
      });
    },
    [metalBodyData],
  );

  const currentStepDisplayed = useMemo<React.ReactNode | null>(() => {
    const { currentStep, data } = metalBodyData;
    if (!metalBodyData) {
      return null;
    }
    switch (currentStep) {
      case 1: {
        return (
          <MetalBodyQuestions
            goToPreviousStep={previousStep}
            completeStepCallback={completeStep}
            scanRequestData={data}
            stepName={PING_METAL_STEP_NAMES.metalBodyQuestion}
          />
        );
      }
      case 2:
        return (
          <MetalBodyType
            goToPreviousStep={previousStep}
            stepName={PING_METAL_STEP_NAMES.metalBodyType}
            scanRequestData={data}
            completeStepCallback={completeStep}
          />
        );
      case 3:
        return (
          <MetalBodyLocation
            goToPreviousStep={previousStep}
            stepName={PING_METAL_STEP_NAMES.metalBodyLocation}
            scanRequestData={data}
            completeStepCallback={completeStep}
          />
        );
      case 4:
        return (
          <MetalBodyPaceMaker
            goToPreviousStep={previousStep}
            completeStepCallback={completeStep}
            scanRequestData={data}
            stepName={PING_METAL_STEP_NAMES.metalBodyPacemaker}
          />
        );
      case 5: {
        return (
          <UploadPacemaker
            goToPreviousStep={previousStep}
            completeStepCallback={completeStep}
            scanRequestData={data}
            stepName={PING_METAL_STEP_NAMES.metalBodyUploadPacemaker}
          />
        );
      }
      default:
        return null;
    }
  }, [metalBodyData]);

  return (
    <>
      <>{currentStepDisplayed}</>
      <InfoBox
        title={t('box.WHY_DO_YOU_NEED_INFOBOX_DATA.title')}
        content={<Trans i18nKey='box.WHY_DO_YOU_NEED_INFOBOX_DATA.content' components={{ br: <br /> }} />}
      />
    </>
  );
};

export default MetalBodyWrapper;
