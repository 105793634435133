export const stepsTexts: Array<{ title: string; content: string; icon: string }> = [
  {
    icon: 'tell',
    title: 'Tell Us What You Need',
    content: `Tell us what you need, when you&apos;re free, and where you&apos;re located. Use your insurance, or select our special
    self-pay rates.`,
  },
  {
    icon: 'scheduled',
    title: 'Get Scheduled',
    content: `We’ll schedule you the best high-quality appointment for you. Our care team coordinates with your providers to make sure
    it goes smoothly.`,
  },
  {
    icon: 'scan',
    title: 'Get Your Scan',
    content: ` Sit back and relax, simply show up for your appointment and we&apos;ll handle the rest. You and your physician will get a
    copy of your report and images.`,
  },
];
