import React, { useCallback, useEffect, useState } from 'react';
import { getHomePageInfo, shareAppointment } from '../../api/dashboard/DashboardApi';
import DoctorUpdated from '../../assets/img/doctor-updated.png';
import LoaderComponent from '../../components/loader-component';
import { APP_CORP_WEB_URL } from '../../config/FrontConfig';
import { ScanManagment } from '../../models/ScanManagment';
// import DoctorModalComponent from './components/DoctorModalComponent';
import NoScansComponent from './components/NoScansComponent';
import ScansComponent from './components/ScansComponent';
import { Article } from './interfaces';
import { useSignal } from '../../utils/UseSignal';
import InfoModalComponent from '../../components/modals/info-modal';

const DashboardComponent: React.FunctionComponent = () => {
  const [isDoctorModalOpen, setIsDoctorModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isNoScansFound, setIsNoScansFound] = useState(true);
  const [articles, setArticles] = useState<Article[]>([]);
  const [scans, setScans] = useState<ScanManagment[]>([]);
  const signal = useSignal();

  const getScansInfo = () => {
    getHomePageInfo(signal)
      .then(data => {
        if (signal.aborted) {
          return;
        }
        if (!data?.Data?.length) {
          setScans([]);
          setIsNoScansFound(data?.Info.HomePageStage === 1);
          return;
        }
        setScans(data.Data);
      })
      .catch(() => {
        if (signal.aborted) {
          return;
        }
        setScans([]);
        setIsNoScansFound(true);
      })
      .finally(() => {
        if (!signal.aborted) {
          setIsLoading(false);
        }
      });
  };

  // const getArticles = () => {
  //   getArticleList()
  //     .then(data => {
  //       if (!data) {
  //         setArticles([]);
  //         return;
  //       }
  //       setArticles(data);
  //     })
  //     .catch(() => setArticles([]))
  //     .finally(() => setIsLoading(false));
  // };

  const shareWithDoctor = () => {
    shareAppointment().then(() => {
      setIsDoctorModalOpen(true);
    });
  };

  useEffect(() => {
    getScansInfo();
    setArticles([]);
    // getArticles();
  }, []);

  const reloadScans = useCallback(() => {
    setIsLoading(true);
    getScansInfo();
  }, []);

  if (isLoading) {
    return <LoaderComponent className='loader-block-center' />;
  }

  return (
    <div className='dashboard'>
      {!scans?.length ? <NoScansComponent isNoScansFound={isNoScansFound} /> : <ScansComponent scans={scans} reloadScans={reloadScans} />}
      <section className='doctor-section'>
        <div className='container'>
          <div className='row'>
            <div className='doctor-info'>
              <h2 className='h2'>Keep your doctor updated</h2>
              <div className='text'>
                <p>Tell your physician about your appointment here.</p>
              </div>
              <button className='btn btn-white' onClick={shareWithDoctor}>
                Share my appointment with my doctor
              </button>
            </div>
            <div className='doctor-img'>
              <img src={DoctorUpdated} alt='doctor' />
            </div>
          </div>
        </div>
      </section>
      {articles.length > 0 && (
        <section className='blog-section'>
          <div className='container'>
            <div className='global-title_wrap'>
              <div className='global-title_wrap_box'>
                <div className='sub-title'>Medmo Blogs</div>
                <h2 className='h2'>Content You Might Like</h2>
              </div>
            </div>
            <div className='blog-list row'>
              <div className='blog-list_item contact'>
                <div className='blog-list_item-wrap'>
                  <div className='title'>Need Help of Need to Get in Touch</div>
                  <a href={`${APP_CORP_WEB_URL}/contact-us/`} target='_blank' rel='noreferrer' className='btn btn-white'>
                    Contact Us
                  </a>
                </div>
              </div>
              {articles.map(({ title, text, link, img }, i) => {
                return (
                  <div key={i} className='blog-list_item'>
                    <div className='blog-list_item-wrap'>
                      <a href='#' className='img'>
                        <img src={img} alt='blog img' hidden />
                      </a>
                      <div className='title'>{title}</div>
                      <div className='text'>
                        <p>{text}</p>
                      </div>
                      <a href='#' className='link'>
                        {link}
                      </a>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      )}
      {/* {isDoctorModalOpen && <DoctorModalComponent onClose={() => setIsDoctorModalOpen(false)} />} */}
      {isDoctorModalOpen && (
        <InfoModalComponent
          onClose={() => setIsDoctorModalOpen(false)}
          type='success'
          texts={{
            title: 'Success',
            content: 'Our care team has received your request and will be reaching out to your doctor',
            buttonText: 'Close',
          }}
        />
      )}
    </div>
  );
};

export default DashboardComponent;
