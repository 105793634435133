import React, { useRef, useState } from 'react';
import { Form } from 'rsuite';
import { FormError, OptionFormStructure, OptionProps } from '../interfaces';
import { FormInstance } from 'rsuite/Form';
import TextField from '../../../../components/text-field-component';
import { optionModel, optionTexts } from '../../utils';
import InfoModalComponent from '../../../../components/modals/info-modal';
import Textarea from '../../../../components/textarea';

const OptionComponent: React.FunctionComponent<OptionProps> = props => {
  const { info, children, btnSubmitText, apiCall, onCloseCallback } = props;
  const { TestRequestID } = info;

  const formRef = useRef<FormInstance | null>(null);
  const [formValue, setFormValue] = useState<OptionFormStructure>({ reason: '' });
  const [formError, setFormError] = useState<FormError>({});
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalShow, setIsModalShow] = useState(false);

  const onCloseModal = () => {
    setIsModalShow(false);
    if (!error) {
      onCloseCallback(true);
      return;
    }
    setError(null);
  };

  const onRequest = () => {
    if (formRef.current && !formRef.current.check()) {
      return;
    }

    if (formValue && formValue.reason) {
      setIsLoading(true);
      apiCall(TestRequestID, { reason: formValue.reason })
        .then(() => {
          setFormValue({ reason: '' });
          setIsModalShow(true);
        })
        .catch(() => {
          setIsModalShow(true);
          setError('Something went wrong. Please try again later.');
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <div className='control-panel_info-navigation'>
      {children}
      <Form
        ref={formRef}
        onChange={formValue => setFormValue(formValue)}
        onCheck={formError => setFormError(formError)}
        formValue={formValue}
        model={optionModel}
      >
        <TextField name='reason' accepter={Textarea} error={formError.reason} value={formValue.reason} />
        <div className='btn-list row'>
          <button className='btn btn-white' disabled={isLoading} onClick={() => onCloseCallback(false)}>
            KEEP REQUEST
          </button>
          <button disabled={isLoading || !(formValue && formValue.reason !== '')} className='btn' onClick={onRequest}>
            {btnSubmitText}
          </button>
        </div>
      </Form>
      {isModalShow && (
        <InfoModalComponent
          onClose={onCloseModal}
          type={error ? 'error' : 'success'}
          texts={error ? optionTexts.error : optionTexts.confirm}
        />
      )}
    </div>
  );
};

export default OptionComponent;
