import { PingScanRequest } from '../../views/patient-ping/utils';

export type MedicationProps = {
  goToPreviousStep: (stepName?: string) => void;
  completeStepCallback: (partialScanData: Partial<PingScanRequest>, stepName?: string) => void;
  agentInfo?: { CxName: string; CxPhone: string; CxEmail: string };
  stepName?: string;
  scanRequestData: Partial<PingScanRequest>;
};

export const Questions = [
  { ID: 1, Response: 'Yes' },
  { ID: 0, Response: 'No' },
  { ID: 2, Response: 'Not sure' },
];
