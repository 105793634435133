import React, { useCallback, useEffect, useMemo, useState } from 'react';
import InfoBox from '../info-box';
import {
  initMammogramGeneralData,
  MAMMOGRAM_STEP_NAMES,
  MammogramBreastSymptomsProps,
  MammogramData,
  MammogramGeneralData,
} from '../mammogram-breast/interfaces';
import { Trans, useTranslation } from 'react-i18next';
import MammogramSymptoms from './mammogram-symptoms';

const MammogramBreastSymptoms: React.FunctionComponent<MammogramBreastSymptomsProps> = ({
  goToPreviousStep,
  scanRequestData,
  completeStepCallback,
}: MammogramBreastSymptomsProps) => {
  const { t } = useTranslation('translations');
  const [mammogramData] = useState<MammogramGeneralData>(() => {
    const {
      SymptomsNotes,
      IsBreastPain,
      IsNippleDischarge,
      IsLumpsInYourBreast,
      HaveNotSymptoms,
      HaveAnyBreastImplants,
      OtherSymptoms,
      HaveAnyFamilyHistoryOfBreastCancer,
    } = scanRequestData;
    const newData = {
      SymptomsNotes: SymptomsNotes ? SymptomsNotes : '',
      IsBreastPain: Boolean(IsBreastPain),
      IsNippleDischarge: Boolean(IsNippleDischarge),
      IsLumpsInYourBreast: Boolean(IsLumpsInYourBreast),
      HaveNotSymptoms: Boolean(HaveNotSymptoms),
      HaveAnyBreastImplants: Boolean(HaveAnyBreastImplants),
      OtherSymptoms: Boolean(OtherSymptoms),
      HaveAnyFamilyHistoryOfBreastCancer: Boolean(HaveAnyFamilyHistoryOfBreastCancer),
    };
    return {
      ...initMammogramGeneralData,
      data: { ...initMammogramGeneralData.data, ...newData },
    };
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [mammogramData.currentStep]);

  const previousMammogramStep = useCallback(
    (stepName?: string): void => {
      goToPreviousStep(stepName || MAMMOGRAM_STEP_NAMES.symptoms);
    },
    [mammogramData],
  );

  const completeMammogramStep = useCallback(
    (stepMetadata?: Partial<MammogramData>, stepName?: string): void => {
      const newData = { ...mammogramData.data, ...stepMetadata };
      completeStepCallback(newData, stepName || MAMMOGRAM_STEP_NAMES.symptoms);
    },
    [mammogramData],
  );

  const currentStepDisplayed = useMemo<React.ReactNode | null>(() => {
    const { currentStep } = mammogramData;
    if (!mammogramData) {
      return null;
    }

    switch (currentStep) {
      case 1:
        return (
          <MammogramSymptoms
            goToPreviousStep={previousMammogramStep}
            completeStepCallback={completeMammogramStep}
            scanRequestData={mammogramData.data}
          />
        );
      default:
        return null;
    }
  }, [mammogramData]);

  return (
    <>
      <>{currentStepDisplayed}</>
      <InfoBox
        title={t('box.WHY_DO_YOU_NEED_INFOBOX_DATA.title')}
        content={<Trans i18nKey='box.WHY_DO_YOU_NEED_INFOBOX_DATA.content' components={{ br: <br /> }} />}
      />
    </>
  );
};

export default MammogramBreastSymptoms;
